import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GenerateURL from '../../../util/APIUrlProvider';
import { GetAuth } from '../../../util/security';
import { Link } from 'react-router-dom';
import { Popover, Tooltip } from '@material-ui/core';
import { CopyToClipboard } from '../../../util/util';
import properties from '../../../properties/properties';
import moment from 'moment';
const CISectionNew = ({ isDynamicJobsExist, ...props }) => {
    var build_details = props.build_details && props.build_details[0] ? props.build_details[0] : props.build_details;
    var promote_details = props.promote_details;
    const service_id = props.service_id ? props.service_id : null;
    const env_id = props.env_id ? props.env_id : null;
    const service_data = props.service_data;
    const selectedEnvName = service_data.component_env.find((envObj) => envObj.id == env_id)
    
    const refresh = props.refresh;
    var build_number = "N/A";
    var artifact = "N/A";
    var commit_sha = "N/A";
    var commit_msg = "N/A";
    var status = "N/A";
    var build_on = "N/A";
    var build_by = "N/A";
    var promoted_on = "N/A";
    var promoted_by = "N/A";

    console.log(build_details, "sdhbfhdsbhbvshd")

    const [state, setState] = useState({
        action_card: "",
    });
    const classes = useStylesForCI();

    useEffect(() => {
        setState({
            ...state,
            action_card: "",
        })

    }, [env_id]);




    if (build_details) {
        build_number = build_details.build_number;
        artifact = build_details.build_tag;
        commit_msg = build_details.commit_message;
        commit_sha = build_details.commit_sha;
        status = build_details.activity_status && build_details.activity_status.status;
        build_on = (new Date(build_details.activity_status && build_details.activity_status.start_time)).toString();
        build_by = build_details.build_by_pipeline ? "Pipeline " + build_details.build_by_pipeline.pipeline?.name + ", " +
            build_details.build_by_pipeline.trigger_type?.name + " by " + (build_details.build_by_pipeline.trigger_by ? build_details.build_by_pipeline.trigger_by : "System") :
            build_details.build_by_user ? build_details.build_by_user : "User Name";
    }

    if (promote_details) {
        var show_promote = false
        if (build_details) {
            if (new Date(promote_details.created_at) > new Date(build_details.created_at)) {
                build_details = null;
                var build_number = "N/A";
                var artifact = promote_details.current_image_tag;
                var commit_sha = "N/A";
                var commit_msg = "N/A";
                var status = "N/A";
                var build_on = "N/A";
                var build_by = "N/A";
                show_promote = true;
            } else {
                promote_details = null;
            }
        } else {
            show_promote = true;
        }
        if (show_promote) {
            promoted_on = (new Date(promote_details.created_at)).toString();
            promoted_by = promote_details.promote_by_pipeline ? "Pipeline " + promote_details.promote_by_pipeline.pipeline.name + ", " +
                promote_details.promote_by_pipeline.trigger_type.name + " by " + (promote_details.promote_by_pipeline.trigger_by ? promote_details.promote_by_pipeline.trigger_by : "System") :
                promote_details ? promote_details.promote_by_user ? promote_details.promote_by_user.name : "User Name" : "";
        }
    }

    function toggleTriggerActionCard(type) {
        switch (type) {
            default:
                setState(fresh_state => ({
                    ...fresh_state,
                    action_card: !fresh_state.action_card ? type : fresh_state.action_card == type ? "" : type,
                }));
        }
    }

    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }


    var url_ci = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url, true);
    const is_build_permited = GetAuth().permission.POST.includes(url_ci);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickForCI = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseForCI = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <div style={{ width: isDynamicJobsExist ? '520px' : '98%', flex: isDynamicJobsExist ? '0 0 auto' : 'auto' }} className={classes.root}>
            <div className="heading">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M4.44153 2.72433C4.99739 2.52599 5.60035 2.50094 6.17075 2.6525C6.74115 2.80407 7.25214 3.12511 7.63623 3.57321C8.02033 4.02132 8.25944 4.57541 8.32198 5.16227C8.38452 5.74914 8.26755 6.34118 7.98653 6.86017L16.9107 15.7852L15.7324 16.9635L6.80736 8.0385C6.28825 8.31842 5.69652 8.43449 5.11014 8.37142C4.52375 8.30834 3.97024 8.06909 3.52254 7.68517C3.07483 7.30126 2.75396 6.79071 2.60219 6.22081C2.45042 5.6509 2.47489 5.04839 2.67236 4.49267L4.53653 6.35683C4.65183 6.47622 4.78977 6.57145 4.94227 6.63696C5.09478 6.70247 5.2588 6.73695 5.42477 6.73839C5.59075 6.73984 5.75535 6.70821 5.90897 6.64536C6.06259 6.58251 6.20215 6.48969 6.31952 6.37233C6.43689 6.25496 6.5297 6.1154 6.59255 5.96177C6.6554 5.80815 6.68703 5.64355 6.68559 5.47758C6.68415 5.31161 6.64966 5.14758 6.58415 4.99508C6.51864 4.84257 6.42341 4.70464 6.30403 4.58933L4.44069 2.7235L4.44153 2.72433ZM13.0807 4.29433L15.7324 2.821L16.9107 3.99933L15.4374 6.651L13.964 6.946L12.1974 8.7135L11.0182 7.53517L12.7857 5.76767L13.0807 4.29433ZM7.48236 11.071L8.66069 12.2493L4.24153 16.6685C4.09128 16.8192 3.88908 16.9067 3.67638 16.913C3.46369 16.9194 3.25662 16.8442 3.09762 16.7028C2.93862 16.5613 2.83975 16.3645 2.82126 16.1525C2.80277 15.9405 2.86608 15.7295 2.99819 15.5627L3.06319 15.4902L7.48236 11.071Z" fill="#787878" />
                </svg>
                <div className='font-12 font-weight-600 color-icon-secondary avtar'>{build_details ? "BUILD STATUS" : promote_details ? "BUILD STATUS" : "Trigger first build"}</div>
            </div>
            {build_details ?
                <>
                    <div className='info-box'>
                        <div className='top-part'>
                            <div className='d-flex align-center'>
                                <Link
                                    className="cursor-pointer text-anchor-blue font-13 font-weight-600"
                                    to={
                                        "/logs?global_task_id=" +
                                        build_details.global_task_id +
                                        "&tab_id=" +
                                        build_details.env_ci_detail_id +
                                        "&service_name=" +
                                        service_data.name +
                                        "&service_env=" +
                                        selectedEnvName.project_env.name +
                                        "&tab_name=BUILD" +
                                        "&num=" +
                                        build_details.build_number
                                    }
                                    target="_blank"
                                >
                                    <span className="font-14 font-weight-600" style={{ color: "#0086FF" }}>Build #{build_number} </span>
                                </Link>

                                {status == "N/A" ? "" :
                                    status == "FAILED" ?
                                        <span className="status-chip-new font-12" style={{ background: "#FFF3F3", color: "#BA0000", border: "1px solid #FBE6E6" }} >Failed</span> :
                                        status == "IN_QUEUE" ?
                                            <div className="status-chip-new font-12" style={{ background: "#FCF6E1", color: "#FEA111", border: "1px solid #FCF6E1" }}>In queue</div> :
                                            status == "RUNNING" ?
                                                <div className="status-chip-new font-12" style={{ background: "#F5FAFF", color: "#0086FF", border: "1px solid #DFEDFF" }}>Running</div> :
                                                status == "REVOKED" ?
                                                    <span className="status-chip-new font-12" style={{ background: "#F4F4F4", color: "#626262", border: "1px solid #E6E6E6" }} >Revoked</span> :
                                                    <span className="status-chip-new font-12" style={{ background: "#EFFFF3", color: "#129E5B", border: "1px solid #DFEDFF" }}>{status == 'IN_QUEUE' ? 'IN QUEUE' : status}</span>
                                }

                            </div>
                            <div className='d-flex align-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path d="M8.00065 15.1693C4.31865 15.1693 1.33398 12.1846 1.33398 8.5026C1.33398 4.8206 4.31865 1.83594 8.00065 1.83594C11.6827 1.83594 14.6673 4.8206 14.6673 8.5026C14.6673 12.1846 11.6827 15.1693 8.00065 15.1693ZM8.00065 13.8359C9.41514 13.8359 10.7717 13.274 11.7719 12.2738C12.7721 11.2736 13.334 9.91709 13.334 8.5026C13.334 7.08812 12.7721 5.73156 11.7719 4.73137C10.7717 3.73117 9.41514 3.16927 8.00065 3.16927C6.58616 3.16927 5.22961 3.73117 4.22941 4.73137C3.22922 5.73156 2.66732 7.08812 2.66732 8.5026C2.66732 9.91709 3.22922 11.2736 4.22941 12.2738C5.22961 13.274 6.58616 13.8359 8.00065 13.8359ZM8.66732 8.5026H11.334V9.83594H7.33398V5.16927H8.66732V8.5026Z" fill="#787878" />
                                </svg>
                                <span className='font-12 font-weight-500 color-key-78 ml-4'>{moment(build_on).fromNow()}</span>
                            </div>
                        </div>
                        <div className='bottom-part'>
                            <div className='bottom-info'>
                                <span className='font-12 font-weight-500 color-key-78'>Artifact:</span>
                                <div className='d-flex align-center'>
                                    <span className="font-12 font-weight-600 color-icon-secondary mr-4" >{artifact}</span>
                                    {artifact && <CopyToClipboard data={artifact} />}
                                </div>
                            </div>
                            <div>
                                <span className='font-12 font-weight-500 more-btn' onClick={handleClickForCI}>more Details</span>
                            </div>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleCloseForCI}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                PaperProps={{ style: { overflow: 'visible' } }}
                            >
                                <div className={classes.popCI}>
                                    <div className='d-flex align-center'>
                                        <span className='font-12 font-weight-500 color-key-78 '>Commit msg: &nbsp;</span>
                                        <Tooltip title={commit_msg ? commit_msg : "NA"} placement="bottom">
                                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{commit_msg ? commit_msg : "N/A"}</span>
                                        </Tooltip>
                                        {
                                            commit_msg && <CopyToClipboard data={commit_msg} />
                                        }
                                    </div>
                                    <div className='d-flex align-center'>
                                        <span className='font-12 font-weight-500 color-key-78 '>Commit ID: &nbsp;</span>
                                        <Tooltip title={commit_sha ? commit_sha : "N/A"} placement="bottom">
                                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{commit_sha ? commit_sha.substring(0, 30) + "..." : "N/A"} </span>
                                        </Tooltip>
                                        {commit_sha && <CopyToClipboard data={commit_sha} />}
                                    </div>
                                    <div className='d-flex align-center'>
                                        <span className='font-12 font-weight-500 color-key-78 '>Build by: &nbsp;</span>
                                        <span className="font-family-v1 font-12 font-weight-500 color-value">{build_by ? build_by : "N/A"}</span>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </>

                :

                promote_details ?
                    <>
                        <div className='info-box'>
                            <div className='top-part'>
                                <div className='d-flex align-center'>
                                    <span className="font-14 font-weight-600 color-value">Promoted on: &nbsp;
                                        <span className="font-family-v1 font-12 font-weight-500 color-value">{moment(promoted_on).fromNow()}</span>
                                    </span>
                                </div>
                                {/* <div className='d-flex align-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                    <path d="M8.00065 15.1693C4.31865 15.1693 1.33398 12.1846 1.33398 8.5026C1.33398 4.8206 4.31865 1.83594 8.00065 1.83594C11.6827 1.83594 14.6673 4.8206 14.6673 8.5026C14.6673 12.1846 11.6827 15.1693 8.00065 15.1693ZM8.00065 13.8359C9.41514 13.8359 10.7717 13.274 11.7719 12.2738C12.7721 11.2736 13.334 9.91709 13.334 8.5026C13.334 7.08812 12.7721 5.73156 11.7719 4.73137C10.7717 3.73117 9.41514 3.16927 8.00065 3.16927C6.58616 3.16927 5.22961 3.73117 4.22941 4.73137C3.22922 5.73156 2.66732 7.08812 2.66732 8.5026C2.66732 9.91709 3.22922 11.2736 4.22941 12.2738C5.22961 13.274 6.58616 13.8359 8.00065 13.8359ZM8.66732 8.5026H11.334V9.83594H7.33398V5.16927H8.66732V8.5026Z" fill="#787878"/>
                                </svg>
                                <span className='font-12 font-weight-500 color-key-78 ml-4'>{moment(build_on).fromNow()}</span>
                            </div> */}
                            </div>
                            <div className='bottom-part'>
                                <div className='bottom-info'>
                                    <span className='font-12 font-weight-500 color-key-78'>Artifact:</span>
                                    <div className='d-flex align-center'>
                                        {
                                            promote_details && promote_details.target_image_tag ?
                                                <Tooltip title={promote_details.target_image_tag} placement="bottom">
                                                    <span className='d-flex align-center' style={{ gap: "4px" }}>
                                                        <span className="font-12 font-weight-600 color-icon-secondary mr-4" >{promote_details.target_image_tag}</span>
                                                        <CopyToClipboard data={promote_details.target_image_tag} />
                                                    </span>

                                                </Tooltip>
                                                : <span className="font-12 font-weight-600 color-icon-secondary mr-4" >N/A</span>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <span className='font-12 font-weight-500 more-btn' onClick={handleClickForCI}>more Details</span>
                                </div>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseForCI}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <div className={classes.popCI}>
                                        <div className='d-flex align-center'>
                                            <span className='font-12 font-weight-500 color-key-78 '>Commit msg: &nbsp;</span>

                                            {
                                                promote_details?.commit_message ?
                                                    <div className="d-flex">
                                                        <Tooltip title={promote_details?.commit_message} placement="bottom">
                                                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{promote_details.commit_message}</span>
                                                        </Tooltip>
                                                        {
                                                            promote_details.commit_message && <CopyToClipboard data={promote_details.commit_message} />
                                                        }

                                                    </div> : <span className="font-11">N/A</span>
                                            }
                                        </div>
                                        <div className='d-flex align-center'>
                                            <span className='font-12 font-weight-500 color-key-78 '>Commit ID: &nbsp;</span>
                                            {/* <Tooltip title={commit_sha} placement="bottom">
                                        <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{commit_sha && commit_sha.substring(0, 30) + "..."} </span>
                                    </Tooltip>
                                    {commit_sha && <CopyToClipboard data={commit_sha} />} */}
                                            {
                                                promote_details?.commit_sha ?
                                                    <div className="d-flex align-center">
                                                        <Tooltip title={promote_details?.commit_sha} placement="bottom">
                                                            <span className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{promote_details.commit_sha} </span>
                                                        </Tooltip>
                                                        {promote_details.commit_sha && <CopyToClipboard data={promote_details.commit_sha} />}
                                                    </div> : <span className="font-11">N/A</span>
                                            }
                                        </div>
                                        <div className='d-flex align-center'>
                                            <span className='font-12 font-weight-500 color-key-78 '>Promoted By: &nbsp;</span>
                                            {
                                                promote_details && promote_details?.promote_by_user ?
                                                    <Tooltip title={promote_details?.promote_by_user} placement="bottom">
                                                        <span title={promote_details.promote_by_user} className="font-family-v1 font-12 font-weight-500 color-value text-wrap">{promote_details.promote_by_user}</span>
                                                    </Tooltip> : <span className="font-11">N/A</span>

                                            }
                                        </div>
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </>
                    :
                    props.manual_build_flag && is_build_permited ?
                        <div className={classes.tiggerCase} >
                            <div className="real-card">
                                <button className="btn-primary" onClick={props.show_build_card}>
                                    <span className="ri-flashlight-line font-weight-500 font-20"></span>
                                    <span className='font-family-v1'> Trigger</span>
                                </button>
                            </div>
                        </div>
                        :

                        <div className={classes.tiggerCase}>
                            <div className='real-card'>
                                <button className="btn-primary btn-disable  cursor-not-allowed">
                                    <span className="ri-flashlight-line font-weight-500 font-20"></span>
                                    <span className='font-family-v1'>Trigger</span>
                                </button>
                            </div>

                        </div>

            }

        </div>
    )

}




export default CISectionNew;

const useStylesForCI = makeStyles((theme) => ({
    root: {
        width: "520px",
        height: '196px',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        flexDirection: "column",
        flex: '0 0 auto',
        '& .heading': {
            display: "flex",
            width: "100%",
            minHeight: "48px",
            padding: "12px 16px",
            alignItems: "center",
            gap: "4px",
        },
        '& .info-box': {
            display: "flex",
            width: "100%",
            padding: "8px 16px 16px 16px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            '& .top-part': {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                '& .status-chip-new': {
                    display: "flex",
                    padding: "4px 6px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    fontWeight: "700",
                    borderRadius: "4px",
                    //fontSize: "12px",
                    marginLeft: "12px",
                    textTransform: "uppercase",
                }
            },
            '& .bottom-part': {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                alignSelf: "stretch",
                '& .bottom-info': {
                    display: "flex",
                    flexDirection: "column",
                    width: "285px",
                    //alignItems: "center",
                    //gap: "16px",
                },
                '& .more-btn': {
                    textTransform: "uppercase",
                    color: "#0086FF",
                    cursor: "pointer",
                    '&:hover': {
                        textDecoration: "underline",
                    }
                }
            },
        }
    },
    popCI: {
        display: "inline-flex",
        padding: "12px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        background: "#FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    tiggerCase: {
        display: "flex",
        padding: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        height: "100%",
        alignSelf: "stretch",
        paddingTop: "0px",
        '& .real-card': {
            display: "flex",
            padding: "0px 16px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            alignSelf: "stretch",
            height: "100%",
            borderRadius: "6px",
            border: "1px solid #F4F4F4",
            //background: "#FAFAFA",
            paddingTop: "10px",
            '& button': {
                display: "flex",
                height: "32px",
                padding: "11px 16px 11px 12px",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                borderRadius: "6px",
                border: "1px solid #F4F4F4",
                background: "##0086FF",
                textTransform: "uppercase",
            },
            '& p': {
                width: "85%",
            }
        }
    }
}))