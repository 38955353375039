import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { styled } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import AddServiceFrom from './components/AddService';




const ServiceAddRevamp = props => {
    const {application_id,component_id} = props.match.params;
    console.log(application_id,"lpop");
    const edit = props.location ? props.location.state ? props.location.state.edit : false : false;
    
    const classes = useStyles();
    const [state, setState] = useState({
        panels: {}
    });
    
  
   
    return (
        <>
        <div className={classes.root}>
       
            <Grid className={classes.ned} container >
                <Grid item lg={4} md={4} className="section-left">
                    <div className={classes.leftContainer}>
                        <ContentBoxTxt>
                            <TextContainer>
                                <span className='chip chip-primary text-uppercase color-white font-12 font-weight-600'
                                style={{ 
                                    backgroundColor:'#0086ff',
                                    padding:'3px 5px',
                                    borderRadius:'4px',
                                    marginBottom: '12px',
                                    display:'inline-block'
                                    }}>Benefits</span>
                            <h1 className="font-14 font-weight-700 text-uppercase mb-16" style={{color:'#FEA111'}}>Benefits of creating<br />Microservices</h1>
                                <TickComponent>
                                    <div className="round-circle-tick"><span className="flaticon-check-symbol"></span></div>
                                    <div className="txt">Go Live with your App in just 24 hours</div>
                                </TickComponent>
                                <TickComponent>
                                    <div className="round-circle-tick"><span className="flaticon-check-symbol"></span></div>
                                    <div className="txt">Spin up new Environment in few clicks</div>
                                </TickComponent>
                                <TickComponent>
                                    <div className="round-circle-tick"><span className="flaticon-check-symbol"></span></div>
                                    <div className="txt">Onboard a Dockerized service in minutes</div>
                                </TickComponent>
                                <TickComponent>
                                    <div className="round-circle-tick"><span className="flaticon-check-symbol"></span></div>
                                    <div className="txt">Enable most robust & secure DevSecOps CI in few clicks</div>
                                </TickComponent>
                                <TickComponent>
                                    <div className="round-circle-tick"><span className="flaticon-check-symbol"></span></div>
                                    <div className="txt">Setup & Run hassle free secured Pipelines</div>
                                </TickComponent>
                                <TickComponent>
                                    <div className="round-circle-tick"><span className="flaticon-check-symbol"></span></div>
                                    <div className="txt">Speed, Security & Compliance together in one Platform</div>
                                </TickComponent>
                                <TickComponent>
                                    <div className="round-circle-tick"><span className="flaticon-check-symbol"></span></div>
                                    <div className="txt">Setup Observability tooling in just 5 mins</div>
                                </TickComponent>
                            </TextContainer>
                        </ContentBoxTxt>
                    </div>
                </Grid>
                <Grid item lg={8} md={8} xs={12} className="" style={{backgroundColor:'#fff'}}>
                        <div style={{width:'100%'}}>
                            <AddServiceFrom edit= {edit} component_id={component_id} application_id={application_id}/>  
                        </div>
                </Grid>
      </Grid>
    </div>
       
    </>
    )
}

ServiceAddRevamp.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default ServiceAddRevamp;










const ContentBoxTxt = styled('div')({
    width: '80%',
    display: 'grid',
    height: '100%',
    alignItems: 'center',
    '& .signup-heading': {
        fontSize: '18px',
        marginTop: '20px',

    },
    '& .input-with-icon > .input-icon': {
        top: '40px'
    },
    '& .heading-pass': {
        marginBottom: '10px',
        '& .signup-heading': {
            marginBottom: '0px'
        },
        '& .sub-heading': {
            marginTop: '5px'
        }
    },
    '& .que': {
        fontSize: '25px',
        lineHeight: '20px',
        color: '#ffffff',
        fontWeight: 300,
        fontFamily: "Inter",
        marginBottom: '20px'
    },
    '& .description': {
        fontSize: '12px',
        lineHeight: '20px',
        color: '#ffffff',
        fontWeight: 400,
        fontFamily: "Inter",
    },
    '& ul': {
        marginTop: '20px',
        listStyle: 'none',
        '& li': {
            fontSize: '11px',
            lineHeight: '25px',
            color: '#ffffff',
            fontWeight: 400,
            fontFamily: "Inter",
            '& .bullet-succes-green': {
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: '5px',
                backgroundColor: '#0db79b',
                height: '10px',
                width: '10px'
            }
        }
    }
})









export const ContentBox = styled('div')({
    width: '100%',
    height: '100%',
    '& .signup-heading': {
        fontSize: '30px',
        letterSpacing: '2px',
        lineHeight: '40px',
        color: '#000000',
        fontWeight: 300,
        fontFamily: "Inter",
        marginTop: '50px',
        marginBottom: '50px'

    },
    '& .input-with-icon > .input-icon': {
        top: '40px'
    },
    '& .heading-pass': {
        marginBottom: '10px',
        '& .signup-heading': {
            marginBottom: '0px'
        },
        '& .sub-heading': {
            marginTop: '5px'
        }
    },
    '& .que': {
        fontSize: '25px',
        lineHeight: '20px',
        color: '#ffffff',
        fontWeight: 300,
        fontFamily: "Inter",
    },
    '& .description': {
        fontSize: '12px',
        lineHeight: '20px',
        color: '#ffffff',
        fontWeight: 400,
        fontFamily: "Inter",
    },
    '& .welcome-text':{
        padding:'8rem 0rem'
    }
})


const TextContainer = styled('div')({
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    gap: '1%',
    margin: '1rem 0px',
    '& .main-heading':{
        fontSize:'35px',
        fontWeight:300,
        lineHeight: 1,
        color:'#fff',
        marginBottom:'15px'
    }
})
const TickComponent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0px',
    '& .txt': {
        fontSize: '12px',
        lineHeight: '30px',
        color: '#fff',
        fontWeight: 400,
        fontFamily: "Inter",
        marginLeft: '15px'
    },
    '& .round-circle-tick': {
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid #0db79b',
        borderRadius: '50%',
        '& .flaticon-check-symbol': {
            color: '#0db79b'
        }
    }
})

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100vh',
        '& .heading-div':{
            padding: '16px 16px 0px'
        },
        '& .card-footer':{
            backgroundColor:'transparent',
            borderTop:'0px'
        },
        '& .md-stepper-horizontal':{
            display:'none'
        }
    },
    grid: {
        height: '100%'
    },
    leftContainer: {
        background: 'linear-gradient(40.64deg, #07192A 24.63%, #030F1B 70.72%, #001A33 90.66%)',
        height: '100vh',
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        '& .logo': {
            '& img': {
                display: 'block',
                margin: 'auto'
            }
        }
    },
    readable: {
        fontSize: '42px',
        letterSpacing: '3px',
        lineHeight: '52px',
        color: '#ffffff',
        fontWeight: 400,
        fontFamily: "Inter",
        textAlign: 'center',
        marginTop: '3rem'
    },
    formSec: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px 50px 0px'
    }
}));

