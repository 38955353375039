
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Header from './Header';
import Pagination from '../../../../components/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';
import GeneralData from './GeneralData';
import properties from '../../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import StageProgressDetail from './StageProgressDetail';
import { Link } from 'react-router-dom';
import CardFooter from './CardFooter';
import ReplayIcon from '@material-ui/icons/Replay';
import StopIcon from '@material-ui/icons/Stop';
import StepProgressDetailed from '../../../../components/genericComponents/StepProgressDetailed';
import { Redirect } from 'react-router-dom';
import RetryPopup from './RetryPopup';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Loading } from '../../../utils/Loading';
import RollbackServices from '../../../rollback/RollbackServices';

const CardParentDiv = styled('div')({
    backgroundColor: "#fff",
    border: '1px solid #dedede',
    borderRadius: '16px',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
    marginBottom: '2rem',
    '& .md-stepper-horizontal': {
        backgroundColor: '#fff'
    }
})

const PipelineStageExectuion = styled('div')({
    backgroundColor: '#fbfbfb',
    display: 'grid',
    padding: '20px',
    gridTemplateColumns: '35% 1fr',
    justifyContent: 'space-between',
    borderTop: '1px solid #eeeeee',
    borderBottom: '1px solid #eeeeee',
    '& .border-blue': {
        border: '2px solid #0086ff!important',
        color: '#0086ff'

    },
    '& .md-stepper-horizontal': {
        backgroundColor: '#fbfbfb'
    },
    '& .md-stepper-horizontal .md-step': {
        width: ''
    },
    '& .button-navigator': {
        display: 'flex',
        justifyContent: 'flex-end',
    }
})

const useStyles = makeStyles((theme) => ({
    '.MuiPaper-root': {
        backgroundColor: '#0f7f7!important'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: '#fff!important'
    },
    timeSection: {
        padding: '4px 10px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '16px',
        fontSize: '12px',
        width: 'fit-content',
        position: 'relative',
        margin: 'auto',
        zIndex: 99
    },
    divider: {
        height: '1px',
        width: '100%',
        backgroundColor: '#ccc',
        top: '1.5rem',
        position: 'absolute',
        zIndex: 9
    },
    mainsec: {
        position: 'relative',
        margin: '32px 0px'
    },
    margin: {
        width: '32px',
        border: '2px solid rgb(0, 134, 255)',
        height: '32px',
        display: 'inline-block',
        borderRadius: '50%',
        '&.btn-filter': {
            marginRight: 5,
            marginLeft: 5
        }
    }
}));

const MainDivFlex = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 20px 10px',
    '& .pipeline-service-data': {
        margin: '0px'
    }

})

const GlobalPipelineHistoryListing = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({
        selected_rollbacks: [],
        rollback_name_list: [],
        is_successfull: false,
        redirect_to_execution: false,
    });
    const location = useLocation();
    const pipeline = props?.location?.state?.pipeline;
    const application_id = props?.location?.state?.application_id;
    const pipeline_instance = props?.location?.state?.pipeline_instance;
    // const revoke_pipeline = props?.location?.state?.revoke_pipeline;

    console.log(pipeline, pipeline_instance, "sdvhvdfk")


    useEffect(() => {
        requestPipelineAllExeccutions();
    }, [pipeline.id]);

    useEffect(() => {
        fetchPipelineServices();
    }, [])

    function requestPipelineAllExeccutions() {
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleResponse, handleError);
        setState(new_state => ({
            ...new_state,
            loaded: false,
        }));
    }

    console.log("pipeline data from props ========>", pipeline)

    function handleResponse(response) {
        updateResponseForRunComponents(response.results)
        console.log(response, "FDsfdsafdsafsaf")
        setState(new_state => ({
            ...new_state,
            data: response.results,
            loaded: true,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: 1,
        }));
    }

    function handleError(error) {
        console.log("pipe exec get error", error);
        setState(new_state => ({
            ...new_state,
            loaded: true,
        }));
    }

    function fetchPipelineServices() {
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.pipeline_services_list),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, fetchPipelineServicesSuccess, fetchPipelineServicesFailure);

        setState((new_state) => ({
            ...new_state,
            loading: true,
        }))
    }

    function fetchPipelineServicesSuccess(response) {
        setState((new_state) => ({
            ...new_state,
            service_list: response,
            loading: false,
        }))
    }

    function fetchPipelineServicesFailure(error) {
        setState((new_state) => ({
            ...new_state,
            error: error,
            loading: false,
        }))
    }

    function updateResponseForRunComponents(pipelines) {
        // if (pipelines) {
        //   pipelines.forEach(pipeline => {
        //     if (pipeline.instance) {
        //       pipeline.instance.forEach(pipeline_instance => {
        //         updateRunComponetsForPipelineHistory(pipeline.components, pipeline_instance.components)
        //       })
        //     }
        //   });
        // }
        if (pipelines) {
            pipelines.forEach((pipeline_instance) => {
                updateRunComponetsForPipelineHistory(pipeline.components, pipeline_instance.components)
            })
        }
    }
    function updateRunComponetsForPipelineHistory(all_components, run_components) {
        var components_map = {}
        if (all_components) {
            run_components.forEach(component => {
                components_map[component.name] = component
            })

            if (run_components) {
                all_components.forEach(component => {
                    if (components_map[component.name]) {
                        components_map[component.name].part_of_execution = true
                    } else {
                        component.part_of_execution = false
                        run_components.push(component)
                    }

                })
            }
        }
    }

    const handleClickOpen = () => {
        setState({
            ...state,
            open: true
        });
        // requestPipelineAllExeccutions();
    };

    const handleClose = () => {
        setState({
            ...state,
            open: false
        });
    };

    console.log(state.loaded, "loaded ============>")


    //prev function 

    function fetchPrevExecutionHistoryInfo(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }
        setState((new_state) => ({
            ...new_state,
            loaded: true,
        }));
        InvokeApi(
            requestInfo,
            fetchPrevExecutionHistoryInfoSuccess,
            fetchPrevExecutionHistoryInfoFailure
        );
    }

    function fetchPrevExecutionHistoryInfoSuccess(response) {
        setState(new_state => ({
            ...new_state,
            data: response.results.reverse(),
            loaded: true,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(new_state.curr_page - 1),
        }));
    }

    function fetchPrevExecutionHistoryInfoFailure(error) {
        console.log("pipe exec get error", error);
    }

    //next function

    function fetchNextExecutionHistoryInfo(data, url) {
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (data) {
            requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
        }

        if (url) {
            requestInfo.endPoint = url;
        }
        setState((new_state) => ({
            ...new_state,
            loaded: true,
        }));
        InvokeApi(
            requestInfo,
            fetchNextExecutionHistoryInfoSuccess,
            fetchNextExecutionHistoryInfoFailure
        );
    }

    function fetchNextExecutionHistoryInfoSuccess(response) {
        setState(new_state => ({
            ...new_state,
            data: response.results.reverse(),
            loaded: true,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(new_state.curr_page + 1),
        }));
    }

    function fetchNextExecutionHistoryInfoFailure(error) {
        console.log("pipe exec get error", error);
    }

    //page function

    function fetchPageExecutionHistoryInfo(enteredPageNumber) {
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        if (enteredPageNumber > 1) {
            requestInfo.endPoint =
                requestInfo.endPoint +
                "?limit=10&offset=" +
                (enteredPageNumber - 1) * 10;
        }

        var current_page = enteredPageNumber;

        setState((new_state) => ({
            ...new_state,
            loaded: true,
        }));
        InvokeApi(
            requestInfo,
            fetchPageExecutionHistoryInfoSuccess,
            fetchPageExecutionHistoryInfoFailure
        );
    }

    function fetchPageExecutionHistoryInfoSuccess(response) {
        setState(new_state => ({
            ...new_state,
            data: response.results.reverse(),
            loaded: true,
            count: response.count,
            next: response.next ? properties.api.baseURL + response.next : null,
            previous: response.previous ? properties.api.baseURL + response.previous : null,
            error_msg: null,
            total_page: Number.isInteger(Number(response.count) / 10)
                ? (Number(response.count) / 10).toFixed(0)
                : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
                    ? (Number(response.count) / 10 + 1).toFixed(0) - 1
                    : (Number(response.count) / 10 + 1).toFixed(0),
            curr_page: Number(count),
        }));
    }

    function fetchPageExecutionHistoryInfoFailure(error) {
        console.log("pipe exec get error", error);
    }


    console.log(pipeline, application_id, "rtghj=========>")

    function handleSaveSelectedRollback(rollback_data, rollback_name_obj) {
        setState((new_state) => ({
            ...new_state,
            selected_rollbacks: [...new_state.selected_rollbacks, rollback_data],
            rollback_name_list: [...new_state.rollback_name_list, rollback_name_obj]
        }))
    }

    function handleRemoveRollbackPoint(rollback_data, clicked_rollback_name) {
        console.log(state.rollback_name_list, "dbjhbsjbjhbsd")
        console.log(clicked_rollback_name, "dbjhbsjbjhbsd")
        var selected_id = rollback_data.id;
        var updated_selected_rollbacks = [];
        state.selected_rollbacks.forEach((item) => {
            if (item.rollback_id != selected_id) {
                updated_selected_rollbacks.push(item);
            }
        })

        var updated_rollback_name_list = [];

        state.rollback_name_list.forEach((item) => {
            if (item.deploy_name != clicked_rollback_name.deploy_name) {
                updated_rollback_name_list.push(item)
            }
        })

        setState((new_state) => ({
            ...new_state,
            selected_rollbacks: [...updated_selected_rollbacks],
            rollback_name_list: [...updated_rollback_name_list]
        }))
    }

    // console.log(state.rollback_name_list, "bfkzhfskdzcjhsj")

    console.log(state.rollback_name_list, "bfkzhfskdzcjhsj")

    function handleRollbackPointSubmit() {
        var post_data = state.selected_rollbacks;
        PostData(GenerateURL({}, properties.api.pipeline_rollback), post_data, onFetchSuccess, onFetchFail);
        setState(new_state => ({
            ...new_state,
            loading: true,
        }));
    }

    function onFetchSuccess(data) {
        console.log(data, "vbjhdsjhdkh")
        setState(new_state => ({
            ...new_state,
            loading: false,
            is_successfull: true,
            task_id: data[0].global_task_id,
        }));
    }

    function onFetchFail(error) {
        var error = error.toString();
        setState(new_state => ({
            ...new_state,
            error_msg: error,
            loading: false,
        }));
    }

    function handleRefresh() {
        setState(new_state => ({
            ...new_state,
            canRedirect: true
        }));
    }
    function redirectToExecution() {
        if (pipeline.last_trigger) {
            if (pipeline.last_trigger.id == pipeline_instance.id) {
                setState((new_state) => ({
                    ...new_state,
                    new_instance_id: pipeline.last_trigger.id,
                    canRedirect: true,
                }));
            }
        }
    }

    function retriggerPipeline(pipleine_id, trigger_id) {
        var url = GenerateURL(
            { pipeline_id: pipleine_id, trigger_id: trigger_id },
            properties.api.retry_pipeline
        );
        var post_data = {};
        // post_data = pipeline_instance.context_param && pipeline_instance.context_param;

        PostData(url, post_data, handleRetrySuccess, handleRetryFaild);
    }

    function handleRetrySuccess(data) {
        // redirectToExecution();
        // requestPipelineAllExeccutions();
        setState((new_state) => ({
            ...new_state,
            pipeline_rerun_status: "SUCCESSS",
            pipeline_rerun_msg: "Pipeline retry has been triggered successfully",
            redirect_to_execution: true,
        }));
    }

    function handleRetryFaild(error) {
        console.log(error, "pipeline_rerun_msg")
        setState((new_state) => ({
            ...new_state,
            pipeline_rerun_msg: "The pipeline retry has encountered an issue and was unsuccessful.",
            pipeline_rerun_status: "FAILED",
            canRedirect: false,
        }));
    }
    const handleCloseTriggerResume = () => {
        setState({
            ...state,
            pipeline_rerun_msg: null,
            pipeline_rerun_status: null,
        });
    };
    console.log("wongmg", state.data);

    function revoke_pipeline(id, id2) {
        var url_temp = GenerateURL(
            { pipeline_id: id, pipeline_instance_id: id2 },
            properties.api.revoke_pipeline
        );
        var fetchOptions = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        PostData(url_temp, {}, handleSuccessRevoke, handleFailedRevoke);
        setState(new_state => ({
            ...new_state,
            loaded: false,
        }));
    }

    function handleSuccessRevoke(respone) {
        requestPipelineAllExeccutions();
    }

    function handleFailedRevoke(error) {
        setState(new_state => ({
            ...new_state,
            loaded: true,
        }));
    }

    return (
        <>
            {
                state.redirect_to_execution &&
                <Redirect to={`/application/${application_id}/pipeline/${pipeline_instance?.pipeline_id}/execution/${Number(pipeline_instance?.id + 1)}`} />

            }
            {
                state.is_successfull ?
                    <>
                        <StepProgressDetailed task_id={state.task_id} placeholders={{ application_id: application_id }} refresh={handleRefresh} />
                        {state.canRedirect ? <Redirect to={`/application/${application_id}/pipeline/listing`} /> : null}
                    </>
                    :
                    <>

                        <div style={{ padding: "15px" }}>
                            <div className="d-flex align-center space-between">
                                <div className="main-div">
                                    <p className="main-heading">{pipeline.name}</p>
                                    <p className="sub-heading">
                                        Displaying Execution History associated with this pipeline.
                                    </p>
                                </div>
                                <div>
                                    <Pagination
                                        total_count={state.total_page}
                                        current_page_count={state.curr_page}
                                        next={state.next}
                                        previous={state.previous}
                                        on_previous_click={() => {
                                            fetchPrevExecutionHistoryInfo(null, state.previous);
                                        }}
                                        on_next_click={() => {
                                            fetchNextExecutionHistoryInfo(null, state.next);
                                        }}
                                        on_pageNumber_click={(pageNumber) => {
                                            fetchPageExecutionHistoryInfo(pageNumber);
                                        }}
                                    />
                                </div>
                            </div>
                            <Grid container style={{ backgroundColor: '#f0f7f7' }}>
                                <Grid item xs={12}>

                                    {
                                        state.loaded ?
                                            <>
                                                <>
                                                    <>
                                                        {
                                                            state.data && state.data.map(pipeline_instance => (
                                                                <CardParentDiv>
                                                                    <div style={{ display: 'flex', alignItems: "center", padding: '20px' }}>
                                                                        <Header varient="without_header" application_id={application_id} pipeline_instance={pipeline_instance} pipeline={pipeline} />
                                                                    </div>
                                                                    <PipelineStageExectuion>

                                                                        <GeneralData pipeline={pipeline} pipeline_instance={pipeline_instance} />
                                                                        <StageProgressDetail pipeline={pipeline} pipeline_instance={pipeline_instance} />
                                                                    </PipelineStageExectuion>
                                                                    <MainDivFlex>
                                                                        <CardFooter components={pipeline_instance.components} tags={pipeline.tags} />
                                                                        <div className="d-flex align-center ">
                                                                            {/* <button className="btn btn-submit" onClick={handleClickOpen}>Rollback</button> */}
                                                                            <Link style={{ fontSize: '12px', color: 'rgb(0, 134, 255)', }} to={"/global/pipeline/" + pipeline_instance.pipeline_id + "/execution/" + pipeline_instance.id}>
                                                                                <IconButton style={{
                                                                                    width: '32px',
                                                                                    border: '2px solid rgb(0, 134, 255)',
                                                                                    height: '32px',
                                                                                    display: 'inline-block',
                                                                                    borderRadius: '50%'
                                                                                }} title="View Details" aria-label="delete" className={classes.margin + classes.borderBlue}>
                                                                                    <span className="flaticon-visibility-button"></span>
                                                                                </IconButton>
                                                                            </Link>

                                                                            {/* <IconButton title="Replay Pipeline" onClick={props.retry_pipeline} style={{ color: '#0086ff', marginLeft: 5 }} className={classes.margin + " " + "text-anchor-blue font-12 "}>
                                                                    <ReplayIcon />
                                                                </IconButton> */}
                                                                            <RetryPopup
                                                                                pipeline={pipeline}
                                                                                application_id={application_id}
                                                                                pipeline_instance={pipeline_instance}
                                                                                variant="execution_history"
                                                                                retryPipeline={retriggerPipeline}
                                                                            />
                                                                            <>
                                                                                {
                                                                                    pipeline_instance.status == "RUNNING" || pipeline_instance.status == "IN_QUEUE" ?
                                                                                        <span className="play-icon" >
                                                                                            <IconButton title="Revoke Pipeline" onClick={() => { revoke_pipeline(pipeline.id, pipeline_instance.id) }} style={{ color: '#0086ff', marginLeft: 5 }} className={classes.margin + " " + "text-anchor-blue font-12 "}>
                                                                                                <span className="flaticon--rounded-button"><StopIcon className="color-danger" style={{ verticalAlign: 'top', fontSize: 22, marginTop: '-2px' }} /></span>
                                                                                            </IconButton>
                                                                                        </span> : null
                                                                                }
                                                                            </>
                                                                        </div>
                                                                    </MainDivFlex>

                                                                </CardParentDiv>

                                                            ))
                                                        }
                                                    </>
                                                </>

                                            </>
                                            :
                                            <>
                                                <Loading />
                                            </>
                                    }
                                </Grid>
                            </Grid>
                            {
                                state.open &&
                                <RollbackServices
                                    isOpen={state.open}
                                    handeCloseDialog={handleClose}
                                    pipeline_id={pipeline.id}
                                    pipeline_data={pipeline}
                                    handleSaveSelectedRollback={handleSaveSelectedRollback}
                                    selected_rollbacks={state.selected_rollbacks}
                                    rollback_name_list={state.rollback_name_list}
                                    handleRemoveRollbackPoint={handleRemoveRollbackPoint}
                                    service_list={state.service_list}
                                    fetchPipelineServices={fetchPipelineServices}
                                    data={state}
                                    handleSubmit={handleRollbackPointSubmit}
                                />
                            }
                        </div>
                    </>

            }
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={
                    state.pipeline_rerun_status === "SUCCESS" ||
                    state.pipeline_rerun_status === "FAILED"
                }
                onClose={handleCloseTriggerResume}
                autoHideDuration={3000}
            >
                {state.pipeline_rerun_status === "SUCCESS" ? (
                    <>
                        <Alert severity="success">{state.pipeline_rerun_msg}</Alert>
                    </>
                ) : state.pipeline_rerun_status == "FAILED" ? (
                    <Alert severity="error">
                        <div className="error-div">{state.pipeline_rerun_msg}</div>
                    </Alert>
                ) : null}
            </Snackbar>
        </>
    )
}

export default GlobalPipelineHistoryListing
