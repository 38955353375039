import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GenerateURL, { GenerateSearchURL } from "../../../util/APIUrlProvider";
import {
    makeStyles,
} from "@material-ui/core/styles";
import InvokeApi from "../../../util/apiInvoker";
import NumberComponent from "./NumberComponent";
import { Loading } from "../../../views/utils/Loading";
import properties from "../../../properties/properties";
import GenericSkeleton from "../Skeletons/GenericSkeleton";

const AdvanceSearchFilterCombo = ({ variant,
    uniqueId,
    getFetchUrl,
    filterDataPraseFunction,
    onUpdate,
    staticList,
    labelName,
    searchVariable,
    autoClosedAfterSelection,
    reset,
    selectedCheckBoxes,
    searchUrl,
    onlySingleValueSelectionAllowed,
    nextUrl,
    count,
    placeholder_name,
    placeholder_value,
    showMoreNotRequired,
    onVelocityInsight,
    skeleton,
    apiHitOnClick
}) => {
    if(skeleton){
        return(
            <div className="d-flex" style={{gap:"6px"}}>
                 {Array.from({length: 3}).map((_,index)=>{
                    return <GenericSkeleton variant={"rect"} width={117} height={40} style={{borderRadius:"6px"}}/>
                })}
            </div>
        );
    }
    // unique Id is must for this component if you are using this component two times one the same screenc
    console.log(getFetchUrl ,'dfvnvnvv_pspsds',uniqueId);
    console.log("dsjbdjss",reset);

    const [advCardVisible, setAdvCardVisible] = useState(false)
    const [state, setState] = useState({
        search: null,
        allFilteredList: null,
        nextUrl: nextUrl,
        previous: null,
        count: count,
        selectedChecboxes: [],
        firstSelectedLabel: 'All',
        loadingData: false,
        first_click_enable: false
    })

    useEffect(() => {
        if (reset > 0) {
            setState(prevState => ({
                ...prevState,
                selectedChecboxes: []
            }))
        }
    }, [reset])

    useEffect(() => {
        if (selectedCheckBoxes) {
            let nameOfSelectedData = null;
            if (state.allFilteredList && selectedCheckBoxes.length > 0) {
                nameOfSelectedData = state.allFilteredList?.find(item => item.value == selectedCheckBoxes[0])
            }
            setState(prevState => ({
                ...prevState,
                selectedChecboxes: selectedCheckBoxes,
                firstSelectedLabel: nameOfSelectedData ? nameOfSelectedData.label : 'All'
            }))
        }
        else {
            setState(prevState => ({
                ...prevState,
                selectedChecboxes: []
            }))
        }
    }, [selectedCheckBoxes, state.allFilteredList])

    useEffect(() => {
        console.log(staticList,onVelocityInsight)
        if (!staticList && onVelocityInsight) {
            getFetchUrl && filterDataPraseFunction && fetchDataForListing();
        }
        else {
            console.log(staticList, 'setgggonsdp')
            setState(prevState => ({
                ...prevState,
                allFilteredList: staticList
            }))
        }
    }, [staticList])

    useEffect(() => {
        if (searchVariable) {
            console.log("uerne", searchVariable);
            if (state.search && state.search?.length >= 3) {
                fetchDataForListing(state.search);
            }
            if (state.search?.length == 0) {
                fetchDataForListing(state.search);
            }
        }
        else {
            console.log("kdsnfn")
            fetchDataForListingOwnFunction(state.search)
        }
    }, [state.search])

    useEffect(() => {
        if(searchVariable && !apiHitOnClick){
            fetchDataForListing();
        }
    }, [])

    const handleClickForApiHit = ()=>{
    
        if(apiHitOnClick&&searchVariable && !state.first_click_enable){
            fetchDataForListing();
            setState(prevState => ({
                ...prevState,
                first_click_enable: true
            }))
            
        }
    }

    const classes = useStyles();
    const handleClick = (event) => {
        const isInsideParentDiv = event.target.closest(`.${uniqueId}`);
        console.log("iewekem", isInsideParentDiv);
        if (isInsideParentDiv)
            return false;
        else
            if (event.target.id === 'show-more') {
                return;
            }
        console.log("wokdkmd");
        setAdvCardVisible(false)
    };
    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const popElementByName = (arr, name) => {
        const index = arr.indexOf(name);
        if (index !== -1) {
            arr.splice(index, 1);
        }
    }

    const getSelectedName = (value) => {
        if (state.allFilteredList) {
            const nameOfSelectedData = state.allFilteredList?.find(item => item.value == value)
            return nameOfSelectedData?.label
        }
    }
    const handleChangeForCheckBox = (e, label) => {
        const value = e.target.value
        const isChecked = e.target.checked
        console.log("checkinggg", e);
        let currentSelectedList = state.selectedChecboxes
        if (isChecked) {

            if (onlySingleValueSelectionAllowed) {
                currentSelectedList = []
            }
            currentSelectedList.push(value);
            setState(prevState => ({
                ...prevState,
                selectedChecboxes: currentSelectedList,
                firstSelectedLabel: currentSelectedList?.length == 1 ? label : prevState.firstSelectedLabel
            }))
        }
        else {

            popElementByName(currentSelectedList, value)
            setState(prevState => ({
                ...prevState,
                selectedChecboxes: currentSelectedList,
                firstSelectedLabel: currentSelectedList?.length == 1 ? getSelectedName(currentSelectedList[0]) : prevState.firstSelectedLabel
            }))
        }

        onUpdate(uniqueId, currentSelectedList);
        if (autoClosedAfterSelection)
            setTimeout(() => {
                console.log("qoimk");
                setAdvCardVisible(false)
            }, 300)
    }

    const handleClearSelection = () => {
        setState(prevState => ({
            ...prevState,
            selectedChecboxes: []
        }))
        console.log("djdkmdsk");
        setAdvCardVisible(false)
        onUpdate(uniqueId, []);
    }

    const handleButtonClicked = () => {
        console.log("nrekek");
        setAdvCardVisible(!advCardVisible);
        handleClickForApiHit();
    }

    const fetchDataForListingOwnFunction = (searchtext) => {

        if (searchtext != '' && state.allFilteredList) {
            console.log(searchtext, 'ssdfdfdfdfdfddd')
            const list = state.allFilteredList
            searchtext = searchtext.toLowerCase();
            const searchResults = list.filter(item => item.label.toLowerCase().includes(searchtext));
            setState(prevState => ({
                ...prevState,
                allFilteredList: searchResults
            }))
        }
        else {
            setState(prevState => ({
                ...prevState,
                allFilteredList: staticList
            }))
        }
    }
    const fetchDataForListing = (searchtext,nextUrl,loadMoreData) => {
        console.log('iman-----ssss>',searchtext,nextUrl,loadMoreData)
        console.log("odnd",advCardVisible);
        if (searchtext && searchtext != ' ') {
            console.log("iman-----ssss>")
            let url;
            if (placeholder_name && placeholder_value) {
                url = GenerateURL({ [placeholder_name]: placeholder_value }, searchUrl ? searchUrl : getFetchUrl)
            } 
            else if(nextUrl){
                url = nextUrl
            }
            else {
                url = GenerateURL({}, searchUrl ? searchUrl : getFetchUrl)
            }
            var requestInfo = {
                endPoint: nextUrl ? url : GenerateSearchURL({ [searchVariable]: searchtext }, url),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
        }
        else {
            console.log("iman-----ssss>")
            let url;
            if (placeholder_name && placeholder_value) {
                url = GenerateURL({ [placeholder_name]: placeholder_value }, searchUrl ? searchUrl : getFetchUrl)
            } else {
                url = GenerateURL({}, searchUrl ? searchUrl : getFetchUrl)
            }
            var requestInfo = {
                endPoint: nextUrl != undefined ? nextUrl : url,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
            setState(prevState => ({
                ...prevState,
                loadingData: true
            }))
        }

        console.log(requestInfo,"dsbcdsbcgsdvghcvsd")

        InvokeApi(requestInfo, (data) => {

            if (data) {
                console.log(data.results, 'dt_023232323')
                let count = data?.count
                let next = data.next ? properties.api.baseURL + data.next : null
                let previous = data?.previous
                let results = data?.results ? data.results : data
                console.log('dafgddffd----------_>', labelName, results)

                let updatedList = filterDataPraseFunction(results)
                console.log(updatedList, 'ghgjfjff')
                if (loadMoreData) {
                    let alreadyLoadedList = state?.allFilteredList
                    updatedList = [...alreadyLoadedList, ...updatedList]
                    //setAdvCardVisible(true);
                }

                console.log(labelName, updatedList, 'upddkldkd')
                setState(prevState => ({
                    ...prevState,
                    allFilteredList: updatedList,
                    loadingData: false,
                    count: count,
                    nextUrl: next,
                    previous: previous,
                    results: results
                }))
            }
        }, (error) => {
            setState(prevState => ({
                ...prevState,
                loadingData: false
            }))
        });
    }

    const handleSearchData = (e) => {
        const value = e.target.value;

        setState(prevState => ({
            ...prevState,
            search: value
        }))
    }


    const checkForChecboxStatus = (name) => {
        if (state?.selectedChecboxes) {
            const found = state?.selectedChecboxes.find(item => item == name)
            if (found) {
                return true
            }
            return false
        }
    }

    console.log(state, 'state_)2233223232')

    const handlShowMoreClick = () => {
        if (nextUrl != undefined) {


        }
        else {
            // local function called
            fetchDataForListing(state.search, state.nextUrl, true);
        }
    }

   console.log(state.allFilteredList)
    
    return (<div key={uniqueId} className={classes.searchContainer + " " + `${uniqueId}`}>
        {
            variant === 'more-button' ?
                <div className='font-family-v1'>
                    <button className="btn btn-outline-primary font-12 font-weight-500 d-flex align-center " style={{padding:'6px 12px'}} onClick={handleButtonClicked}>
                        <span className="ri-add-line font-18"></span>
                        <span>More</span>
                    </button>
                </div>
                :

                variant === "more-button-new" ?
                    <div onClick={handleButtonClicked} className={classes.moreButtonNew}>
                        <span className="ri-add-line font-24" color="white"></span>
                        <div className="font-12 font-weight-600 text-uppercase word-wrap=break" style={{ color: 'white', fontFamily: 'Montserrat', }}>More</div>
                    </div>
                    :

                    <div onClick={handleButtonClicked} className={`${state.selectedChecboxes?.length > 0 ? 'selected-style' : ''} dropdown-container-adv d-flex align-center space-between`}>
                        <div className="mr-12">
                            <label className="filter-name-style mr-2">{labelName}</label>
                            {
                                state.selectedChecboxes?.length == 0 ?
                                    <label style={{ color: '#1a1919' }} className="filter-name-style">: All</label> :

                                    <label style={{ color: '#1a1919' }} className="filter-name-style">{`: ${state.firstSelectedLabel}`}</label>
                            }
                        </div>
                        {
                            state.selectedChecboxes?.length > 1 ?
                                <div className="mr-12">
                                    <NumberComponent number={state.selectedChecboxes?.length - 1} />
                                </div>
                                : null
                        }
                        <KeyboardArrowDownIcon style={{ fontSize: '20px', marginTop: '2px', color: '#5a5a5a' }} />
                    </div>
        }
        {
            advCardVisible ?
                <>
                    {
                        <div className="combo-box">

                            {
                                false   // false
                                 ? <>
                                    <Loading varient='light' />

                                    </> :
                                    <>
                                        <div>
                                            <input
                                                className='search-input-si'
                                                name='search'
                                                autoFocus={true}
                                                placeholder='Search'
                                                value={state?.search}
                                                onChange={handleSearchData}
                                                disabled={false}
                                            />
                                        </div>
                                        {
                                            state?.allFilteredList?.length > 0 ?
                                                <div className='pt-12 pb-12 checkbox-container-ad'>
                                                    {
                                                        state.allFilteredList?.map(option => {
                                                            return (
                                                                <div className="pl-15">
                                                                    <FormControlLabel control={<Checkbox checked={checkForChecboxStatus(option.value)} onChange={(e) => handleChangeForCheckBox(e, option.label)} name={option.value} value={option.value} />} label={option.label} />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div style={{ height: '35px' }} className="pl-12 pr-12">
                                                        {!showMoreNotRequired && state.count != undefined && state?.allFilteredList?.length < state.count &&
                                                            <span onKeyDown={() => {}} onClick={handlShowMoreClick} style={{ color: '#0052CC' }} className="font-13 cursor-pointer" role="button" tabIndex={0} id="show-more">Show more</span>}
                                                    </div>
                                                </div> :
                                                <div className="not-found-box d-flex align-center justify-center">
                                                    <span className="font-14">No match found</span>
                                                </div>
                                        }
                                        {
                                            state?.allFilteredList?.length > 0 &&
                                            <div className="selection-clear-container d-flex align-center pl-12 pr-12">
                                                {selectedCheckBoxes?.length > 0 && <span style={{ color: 'gray' }} onKeyDown={() => {}} onClick={handleClearSelection} className="font-13 cursor-pointer" role="button" tabIndex={0}>Clear Selection</span>
                                                }
                                                {!showMoreNotRequired && state.count != undefined
                                                    &&
                                                    <label style={{ color: 'gray', marginLeft: 'auto' }} className="font-13">{`${state.allFilteredList?.length} of ${state.count}`}</label>
                                                }
                                            </div>
                                        }

                                    </>
                            }

                        </div>
                    }
                </>
                :
                null
        }
    </div>

    )
}

AdvanceSearchFilterCombo.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default AdvanceSearchFilterCombo;

const useStyles = makeStyles(() => ({

    searchContainer: {
        position: 'relative',
        marginRight: '12px',
        '& .combo-box': {
            top: '50px',
            position: 'absolute',
            height: '300px',
            width: '250px',
            zIndex: 999,
            background: '#fff',
            boxShadow: '0px 2px 6px 0px #00000026',
            borderRadius: '5px'
        },
        '& .more-btn': {
            backgroundColor: '#0086ff',
            color: '#fff',
            height: '40px',
            width: '75px',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: "Montserrat,sans-serif",
            border: 'none',
            '&:hover': {
                backgroundColor: '#03234D',
            },
        },
        '& .search-input-si': {
            border: 'none',
            borderBottom: '2px solid #dedede'
        },
        '& .selection-clear-container': {
            height: '45px',
            width: '100%',
            borderTop: '1px solid #dedede',
            position: 'absolute',
            bottom: '0'
        },
        '& .dropdown-container-adv': {
            height: '40px',
            background: '#FFF',//'#F4F4F4',
            border: '1px solid #DDDDDD',
            borderRadius: '5px',
            padding: '12px'
        },
        '& .filter-name-style': {
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '18px',
        },
        '& .checkbox-container-ad': {
            height: 'calc(100% - 90px)',
            overflow: 'auto',
        },
        '& .not-found-box': {
            height: '70%',
        },
        '& .selected-style': {
            background: '#CECECE'
        }

    },
    moreButtonNew: {

        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
        color: "white",
        borderRadius: "6px",
        height: "40px",
        width: "97px",
        padding: "11px 16px 11px 12px",
        background: "#0086FF",

        '&:hover': {
            backgroundColor: '#03234D !important',
        },
    }
}));