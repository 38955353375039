import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Grid } from '@material-ui/core';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions, ResetComponent } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Slide from '@material-ui/core/Slide';
import Questionnaire from './Questionnaire';
import SidePanelQuestionnaire from './SidePanelQuestionnaire';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import EditableTable from '../../../../components/genericComponents/EditableTable';
import { RemoveFromArray } from '../../../../util/util';
import MultipleEnteriesInput from '../../../../components/genericComponents/MultipleEnteries/MultipleEnteriesInput';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const QuestionnaireDialog = (props) => {
    const classes = useStyles();
    const pipeline_data = props.pipeline_data ? props.pipeline_data : null;
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const jira_id_reference_keys_list = props.jira_id_reference_keys_list ? props.jira_id_reference_keys_list : [];
    const [state, setState] = useState(prev_state ? questionnairesDefaultState(prev_state) : questionnairesDefaultState())
    const add_questionnaire_to_stage = props.add_questionnaire_to_stage ? props.add_questionnaire_to_stage : () => { }
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const handleClose = (e) => {
        setState(new_state => ({
            ...new_state,
            open: false,
            data: {
                approval_questions: false
            }
        }));
    };
    const handleClickOpen = () => {
        setState(new_state => ({
            ...new_state,
            open: true,
        }));
    };

    const columnNames = [

        {
            label: 'Questionnaires ',
            width: '40%',
            grey: false,
            name: 'question'
        },
        {
            label: 'Question Type',
            width: '20%',
            grey: true,
            name: 'type'
        },
        // {
        //     label: 'Update to Jira',
        //     grey: true,
        //     width: '15%',
        //     name: 'jira_update',
        // },
        {
            label: 'Default Value',
            grey: true,
            width: '30%',
            name: 'default_value',
        }

    ]


    const tableActionHandler = (type, data) => {

        setState(prevState => ({
            ...prevState,
            show_form: true,
            update_state_fn: true,
            questionnaire: {
                ...prevState.questionnaire,
                ...data
            }
        }))
    }

    const handleDeleteRow = (data) => {
        var total_arr = state.questionnaire_list;

        total_arr.forEach((item, key) => {

            if (item.question === data.question) {

                total_arr.splice(key, 1)

            }

        })
        setState(prevState => ({
            ...prevState,
            questionnaire_list: [
                ...total_arr
            ]
        }))
        add_questionnaire_to_stage(total_arr)
    }

    function pushDataToArray() {
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm() : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var questionnaire_child_data = child_states.questionnaire;

        if (children_vaidation_result.valid) {
            var final_arr = state.questionnaire_list;
            final_arr = [
                ...final_arr,
                {
                    ...questionnaire_child_data.data,
                    jira_update: questionnaire_child_data.data.jira_update ? "true" : "false"
                }]
            setState(new_state => ({
                ...new_state,
                show_form: false,
                questionnaire_list: final_arr
            }));
            add_questionnaire_to_stage(final_arr)
        }


    }
    const toggleForm = () => {
        setState(prevState => ({
            ...prevState,
            show_form: !state.show_form,
            questionnaire: {}
        }))
    }

    const updateStateObj = (keyValue) => {
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm() : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var questionnaire_child_data = child_states.questionnaire;
        console.log(questionnaire_child_data.data,"questionnaire_child_data")
        if (children_vaidation_result.valid) {
            let updatedList = state.questionnaire_list.map(item => {
                if (item.question === keyValue.question) {
                    return {
                        ...item,
                        ...questionnaire_child_data.data,
                        jira_update: questionnaire_child_data.data.jira_update ? "true" : "false",
                        field_name:  questionnaire_child_data.data.jira_update ? questionnaire_child_data.data.field_name : ""
                    };
                } else {
                    return item;
                }
            }
            )
            setState(new_state => ({
                ...new_state,
                update_state_fn: false,
                show_form: false,
                questionnaire_list: updatedList,
            }))
            add_questionnaire_to_stage(updatedList)
        }
    }

    return (
        <>
            <div className="bg-white">
                <div className=" h-250 pr-20 pl-20" style={{ paddingBottom: '20px', overflowY: 'auto' }}>
                    {state.questionnaire_list.length > 0 ?
                        <div className="card box-shadow-none bg-white mb-15 mt-20" >
                            <EditableTable
                                columnNames={columnNames}
                                actionButton={true}
                                apiFetchedData={state.questionnaire_list}
                                tableActionHandler={tableActionHandler}
                                handleDeleteRow={handleDeleteRow}
                                variant="static_delete"
                                fetchAfterAction={() => { }}
                                endPointObj={""}
                                apiUrl={""}
                            />
                            <div className="d-flex card-footer "
                                style={{ backgroundColor: 'transparent' }}>

                                <button className="transparent-btn nowrap text-anchor-blue" onClick={toggleForm}>
                                    <AddIcon style={{ color: 'blue' }} /> Add More Questions
                                </button>
                            </div>
                        </div> :
                        !state.show_form &&
                        <div className="no-data-div" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                            <p style={{ color: '#979797' }}>Please add questions that approver should answer, before approving the pipeline.</p>
                            <button
                                onClick={toggleForm}
                                className="btn btn-primary-v2  mb-15 m-auto  d-flex align-center text-center"
                            >
                                <AddIcon />&nbsp;
                                <span>Add Questions</span>
                            </button>
                        </div>
                    }
                    {
                        state.show_form ?
                            <Questionnaire
                                prev_state={state.questionnaire}
                                toggleForm={toggleForm}
                                parentComponentState={state.questionnaire}
                                jira_id_reference_keys_list={jira_id_reference_keys_list}
                                jira_ref_key_list={getJiraRefid(pipeline_data)}
                                validateAndPushDataToTable={state.update_state_fn ?
                                    () => { updateStateObj(state.questionnaire) } : pushDataToArray

                                }
                                inherits={state.child_inherits.questionnaire} />
                            :
                            null
                    }

                </div>
            </div>

        </>
    )
}

function questionnairesDefaultState(prev_state) {
    return prev_state ?
        {
            open: false,
            fullWidth: true,
            show_form: prev_state && prev_state.length > 0 ? false : true,
            maxWidth: 'lg',
            questionnaire_list: prev_state ? prev_state : [],
            data: {
            },
            error: {},
            child_inherits: {
                questionnaire: {
                    "validateForm": () => { return { valid: true }; },
                    "getState": () => { return {}; },
                    "getData": () => { return {}; }
                },
            }
        }
        :
        {
            open: false,
            fullWidth: true,
            maxWidth: 'lg',
            questionnaire_list: [],
            show_form: false,
            data: {
            },
            error: {},
            child_inherits: {
                questionnaire: {
                    "validateForm": () => { return { valid: true }; },
                    "getState": () => { return {}; },
                    "getData": () => { return {}; }
                },
            }
        }
}

export default QuestionnaireDialog;
const useStyles = makeStyles((theme) => ({
    root: {

    }
}));
export function getJiraRefid(data) {
    var jira_ref_key_list = []
    if (data && data.jira_references) {
        if (data.jira_references.length > 0) {
            data.jira_references.forEach(item => {
                jira_ref_key_list.push({ id: item.jira_reference_key, label: item.jira_reference_key })
            })
        }
        return jira_ref_key_list
    }
}