import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

const AutoCompleteWithMultipleSelect = ({ list = [], selectedValues = [], onChange, placeholder }) => {
  const classes = useStyles();

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.label || '',
    filter: (options, params) => {
        console.log(options,params,"createFilterOptions")
      const filtered = createFilterOptions({ matchFrom: 'start', stringify: (option) => option.label })(options, params);
      const { inputValue } = params;
      const isExisting = options.some(option => inputValue.toLowerCase() === option.label.toLowerCase());
      if (inputValue !== '' && !isExisting) {
        filtered.push(`New Label: ${inputValue}`);
      }
      return filtered;
    },
  });

  const handleOnChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={list.map((option) => option.label)}
        value={selectedValues} // Assuming selectedValues directly represents the selected labels
        onChange={handleOnChange}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip size='small' 
            label={option} 
            {...getTagProps({ index })} 
            deleteIcon={<CloseIcon 
              style={{
                backgroundColor: 'transparent',
                fill:'#fff'
            }} />} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
        filterOptions={(options, params) => {
            const filtered = options.filter((option) => option.toLowerCase().includes(params.inputValue.toLowerCase()));
            
            // Check if the user's input corresponds to a part of any option
            if (params.inputValue !== '' && !filtered.includes(params.inputValue)) {
              filtered.push(`${params.inputValue} (New Label)`);
            }
            
            return filtered;
          }}
      />
    </div>
  );
};

AutoCompleteWithMultipleSelect.propTypes = {
  list: PropTypes.array,
  selectedValues: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default AutoCompleteWithMultipleSelect;
