import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExecutionCard from './components/ExecutionCard';
import { LoadingContainer } from '../../utils/Loading';
import { ErrorComponent } from '../../utils/Error';
import GenerateURL from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';
import InvokeApi, { UnsubscribeToApi, SubscribeToApi } from '../../../util/apiInvoker';

const PipelineExecution = (props) => {
    const { application_id, pipeline_id, pipeline_instance_id } = props.match.params;
    const [state, setState] = useState({
        loaded: false,
        counter: 0,
    });
    const [startPipeline, setStartPipeline] = useState(true);

    useEffect(() => {
        requestInstanceData();
        SubscribeToApi(GenerateURL({
            pipeline_id: pipeline_id,
            pipeline_instance_id: pipeline_instance_id
        }, properties.api.pipeline_execution_detail));
        return () => {
            UnsubscribeToApi(GenerateURL({
                pipeline_id: pipeline_id,
                pipeline_instance_id: pipeline_instance_id
            }, properties.api.pipeline_execution_detail))
        }
    }, [application_id, pipeline_id, pipeline_instance_id, startPipeline]);



    function requestInstanceData(data, counter) {
        console.log(data, counter, "fsjkfsajkajsf")
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline_id, pipeline_instance_id: pipeline_instance_id }, properties.api.pipeline_execution_detail),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, (res) => { handleResponse(res, counter) }, handleError);
    }



    function handleResponse(data, counter) {
        console.log(counter, "ldssaffs")
        if (data.status == "IN_QUEUE" || data.status == "RUNNING" || data.status == "PENDING_APPROVAL" || (counter >= 1 && data.status == "FAILED")) {
            if (counter > 1) {
                console.log(counter, "ldssaffs")
                setInterval(requestInstanceData, 5000);
            } else {
                setTimeout(requestInstanceData, 5000);
            }

        }


        setState({
            ...state,
            loaded: true,
            data_: data
        });
    }

    function handleError(error) {
        setState({
            ...state,
            loaded: true,
            error: error
        });
    }

    console.log(state,"gyjghhgfhgfvhgvh")

    return (
        <>
            {
                !state.loaded ?
                    <LoadingContainer /> : state.error ? <ErrorComponent error={state.error} variant='error-box' /> : null
            }
            {
                state.loaded ?
                    
                        <ExecutionCard
                            application_id={application_id}
                            pipeline_id={pipeline_id}
                            requestInstanceData={requestInstanceData}
                            pipeline_instance={state.data_}
                        />
                    
                    : null
            }

        </>
        // <>
        //     {
        //         state.loaded ?
        //             state.data ?
        //                 <>
        //                     <ExecutionCard
        //                         application_id={application_id}
        //                         pipeline_id={pipeline_id}
        //                         requestInstanceData={requestInstanceData}
        //                         pipeline_instance={state.data_}
        //                     />
        //                 </>
        //                 :
        //                 state.error ?
        //                     (
        //                         <ErrorComponent error={state.error} variant='error-box' />
        //                     )
        //                     :
        //                     <></>
        //             :
        //             <LoadingContainer />
        //     }
        // </>
    )
}

PipelineExecution.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default PipelineExecution;