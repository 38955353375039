import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Input } from '../../../../components/genericComponents/Input';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { ValidateDataSet } from '../../../../util/Validator';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails'
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import DeleteIcon from '@material-ui/icons/Delete';

export default function EditStepEnvVarv2(props) {

    const classes = useStyles();
    const { onSave, stepForEdit, onClose, supportedEnvVariables, version } = props
    const inherits = props.inherits ? props.inherits : {}
    const [state, setState] = useState(getDefaultState())
    const [integrationTypeVarValues, setIntegrationTypeVarValues] = useState({})
    const [integrationState, setIntegrationState] = useState(getIntegrationDefaultState())
    const [stepDetails, setStepDetails] = useState()
    const [addCustomVar, setAddCustomVar] = useState(false)
    const commonFunctions = getCommonFunctions(state, setState, inherits)
    const CommonFunctionsForIntegration = getCommonFunctions(integrationState, setIntegrationState, inherits)

    console.log('stepDetails', stepDetails)

    console.log('notification_state', state)

    console.log('stepForEdit', stepForEdit)

    useEffect(() => {
        if (stepForEdit)
            if (stepForEdit.id)
                getSteById(stepForEdit.id)
            else
                getSteByStepCode(stepForEdit)

    }, [stepForEdit])

    useEffect(() => {

        if (integrationState.integration_variables) {

            Object.keys(integrationState.integration_variables).forEach(integrationType => {
                getIntegrationTypeValues(integrationType)
            })
        }

    }, [integrationState.integration_variables])

    function getIntegrationTypeValues(integrationType) {

        var requestInfo = {
            endPoint: GenerateURL({ integration_type: integrationType }, properties.api.getIntegrationTypeValues),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, handleResponseForIntegrationValues, handleResponseForIntegrationVarsError);
    }


    function handleResponseForIntegrationValues(response) {

        setIntegrationTypeVarValues(prevState => ({
            ...prevState,
            ...response
        }))
    }

    function handleResponseForIntegrationVarsError(error) {
        setState(new_state => ({
            ...new_state,
            error: "Error fetching Integration Types",
        }));
    }


    function getSteById(step_id) {

        let requestInfo = {
            endPoint: GenerateURL({ step_id: step_id }, properties.api.getStep),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onSuccess, onFailure);
    }

    function onSuccess(response) {

        console.log('r_api', response)
        if (response) {
            setWholeState(response, stepForEdit, setState, setIntegrationState)
            setStepDetails(response)
        }

    }

    function onFailure(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
        }))
    }

    const handleChangeForAddedVar = (e) => {

        const key = e.target.name

        const value = e.target.value

        setState(prevState => ({
            ...prevState,
            variableData: { ...prevState.variableData, [key]: value }
        }))
    }

    const vaidateAddedVarData = () => {

        const { variable_name, variable_value } = state.variableData

        console.log('inside_validate', state.variableData)

        console.log('val', state.variableData?.variable_value?.trim())

        if (variable_name && variable_value && variable_name.trim() != '' && variable_value != '') {

            setState(prevState => ({

                ...prevState,

                data: { ...prevState.data, [variable_name]: variable_value },
                customVariables: { ...prevState.customVariables, [variable_name]: true },
                step_variables_for_rendring: [...prevState.step_variables_for_rendring,
                {

                    does_default_active: "N",
                    input_type: "text",
                    is_required: false,
                    key: variable_name,
                    isCustomVar: true
                }
                ],

                variableData: { variable_name: '', variable_value: '' }

            }))
        }

    }

    const validateOnSaveChanges = () => {

        const result = ValidateDataSet(state.data, state.validations)       //validating for required fields

        if (result.valid && stepDetails) {

            let environment_variables = state.data

            let integration_data = integrationState.data

            let env_var_array = []
            let integration_var_array = []

            let customVariables = state.customVariables

            let activeCustomVariables = {}

            customVariables && Object.keys(customVariables).forEach(key=>{
                if(customVariables[key])
                activeCustomVariables[key] = true
            })

            // if (environment_variables) {

            //     env_var_array = Object.keys(environment_variables).map(key => {

            //         return { key: key, value: environment_variables[key], isCustomVar: _customVariables[key] ? true : false }
            //     })
            // }
            // if (integration_data) {

            //     integration_var_array = Object.keys(integration_data).map(key => {

            //         return { integration: key, value: integration_data[key] }
            //     })
            // }

            let env_var_inegration_combined = [...env_var_array, ...integration_var_array]

            const selectedStepData = {
                id: stepDetails.id, step_name: stepDetails.name, step_code: stepDetails.code,
                is_conditional_step: stepForEdit && stepForEdit.data && stepForEdit.data.is_conditional_step,
                order:0,
                customVariables : customVariables,
                environment_variables: environment_variables && Object.keys(environment_variables).length > 0 ? environment_variables : null,
                output_variables:stepForEdit.output_variables
            }
            console.log('calling_onSave')
            if(stepForEdit && stepForEdit.data.order!=undefined){
                selectedStepData['order'] =stepForEdit.data.order
            }
            onSave(selectedStepData)              // callback function
        }

        else {
            setState(prevState => ({
                ...prevState,
                error: result.error
            }))
        }
    }

    function getSteByStepCode(step) {

        let myendpoint = GenerateURL({}, properties.api.stepCatalogs)

        let requestInfo = {
            endPoint: GenerateSearchURL({ step_code: step.code, version: version }, myendpoint),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, onGetStepSuccess, onGetStepFailure);
    }

    function onGetStepSuccess(response) {
        console.log(response,'resdsdsdsdss')
        const { results } = response
        const [step] = results
        setWholeState(step, stepForEdit, setState, setIntegrationState)
        setStepDetails(step?step:stepForEdit && {...stepForEdit.data,name:stepForEdit.data.step_name,code:stepForEdit.data.step_code})
    }

    function onGetStepFailure(error) {
        console.log('ee_e', error)
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
    }

    console.log(state, 'edit_exp_env')

    console.log(integrationState, 'inte_state_001')

    console.log(integrationTypeVarValues, 'int_val')

    console.log(stepDetails,'step_seesss')

    const handleRemoveCustomVariable = (variable,index)=>{

        let allVariables = state.step_variables_for_rendring

        allVariables.splice(index,1);

        let data = state.data
        let customVariables = state.customVariables
        customVariables[variable.key] = false;
        delete data[variable.key]
        setState(prevState =>({
            ...prevState,
            added_step_variables : allVariables,
            data : data,
            customVariables : customVariables
        }))
    }

    return (
        
            <div className={classes.wrapbox}>
                <Grid container>
                    {
                        integrationState.data && Object.keys(integrationState.data).map((integrationType, index) => {

                            return (
                                <Grid key={index} item xs={12}>
                                    <Input
                                        type='select'
                                        name={integrationType}
                                        list={integrationTypeVarValues[integrationType] ?

                                            integrationTypeVarValues[integrationType].map(item => {
                                                return { label: item.name, id: item.name }
                                            }) :

                                            []
                                        }
                                        label={`${integrationType} (Integration)`}
                                        placeholder=""
                                        onChangeHandler={CommonFunctionsForIntegration.onChangeHandler}
                                        data={integrationState.data}
                                        error={integrationState.error} />
                                </Grid>
                            )
                        })
                    }
                    {
                        state.step_variables_for_rendring && state.step_variables_for_rendring.map((variable, index) => {

                            console.log('__dd', variable)
                            return (
                                <>

                                    {!variable['integration'] && !variable['isCustomVar'] &&

                                        <Grid key={index} item xs={12}>
                                            {
                                                variable.input_type === 'text' ?

                                                    <Input
                                                        type='dual-input'
                                                        mandatorySign={variable.is_required}
                                                        name={variable.key}
                                                        list={supportedEnvVariables ? supportedEnvVariables.map(item => {

                                                            return { id: item.key, label: item.key }
                                                        }) : []}
                                                        label={variable.key}
                                                        placeholder=""
                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                        data={state.data}
                                                        error={state.error}
                                                    /> :

                                                    <Input
                                                        type={variable.input_type === 'toggle' ? 'select' : variable.input_type}
                                                        mandatorySign={variable.is_required}
                                                        name={variable.key}
                                                        list={variable.input_type === 'select' || 'toggle' ? variable.select_dropdown_options ?

                                                            variable.select_dropdown_options.split(',').map(item => ({

                                                                id: item.trim(), label: item.trim()
                                                            }))

                                                            : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]

                                                            : []
                                                        }
                                                        label={variable.key}
                                                        placeholder=""
                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                        data={state.data}
                                                        error={state.error}
                                                    />
                                            }

                                        </Grid>


                                    }

                                </>
                            )
                        })
                    }
                    {
                        state.step_variables_for_rendring && state.step_variables_for_rendring.map((variable, index) => {

                            console.log('__dd', variable)
                            return (
                                <>
                                    {!variable['integration'] && variable['isCustomVar'] &&

                                        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={index} item xs={12}>
                                          
                                          <Grid item xs={10}>

                                            {
                                                variable.input_type === 'text' ?

                                                    <Input
                                                        type='dual-input'
                                                        mandatorySign={variable.is_required}
                                                        name={variable.key}
                                                        list={supportedEnvVariables ? supportedEnvVariables.map(item => {

                                                            return { id: item.key, label: item.key }
                                                        }) : []}
                                                        label={variable.key}
                                                        placeholder=""
                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                        data={state.data}
                                                        error={state.error}
                                                    /> :

                                                    <Input
                                                        type={variable.input_type === 'toggle' ? 'select' : variable.input_type}
                                                        mandatorySign={variable.is_required}
                                                        name={variable.key}
                                                        list={variable.input_type === 'select' || 'toggle' ? variable.select_dropdown_options ?

                                                            variable.select_dropdown_options.split(',').map(item => ({

                                                                id: item.trim(), label: item.trim()
                                                            }))

                                                            : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]

                                                            : []
                                                        }
                                                        label={variable.key}
                                                        placeholder=""
                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                        data={state.data}
                                                        error={state.error}
                                                    />
                                            }

                                        </Grid>
                                        <Grid style={{marginTop:'20px'}} item lg={1}>
                                            <button className="delete-btn" onClick={() =>handleRemoveCustomVariable(variable,index)}><DeleteIcon className="font-18" /></button>
                                            </Grid>
                                        </Grid>
                                    }
                                </>
                            )
                        })
                    }
                </Grid>
                <div className='custom-notify-wrap' >
                    <div className='custom-notify-header'>
                        <Grid container style={{ alignItems: 'center' }}>
                            <Grid item xs={9}>
                                <h5>{stepDetails ? `Custom Variables for the ${stepDetails.name} step` : ''}</h5>
                                <p className='font-12 text-grey-83'>{stepDetails ? `Please note: You can add multiple custom variables and add them to the ${stepDetails.name} step` : ''}</p>
                            </Grid>
                            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Link onClick={() => setAddCustomVar(!addCustomVar)} className='addbtn'><AddIcon />Add Custom Variable</Link>
                            </Grid>
                        </Grid>
                    </div>

                    {addCustomVar &&
                        <div className='add-new-variable'>
                            <Grid container spacing={2} className='pd-5' style={{ borderBottom: '1px solid #B7B7B7' }}>
                                <Grid item xs={6}>Add Custom Variable</Grid>
                                <Grid container
                                    direction="row"
                                    justifyContent="flex-end" xs={6} >

                                    {/* <button className="transparent-btn nowrap" onClick={vaidateAddedVarData}>
                                        <SaveIcon fontSize='large' style={{ color: 'blue' }} />
                                    </button> */}
                                    <button onClick={() => setAddCustomVar(!addCustomVar)} className="transparent-btn nowrap"><CloseIcon fontSize='large' /></button>

                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className='' style={{ background: '#F5F5F5' }}>
                                <Grid item xs={6} style={{ paddingBottom: '0' }}>
                                    <Input
                                        type="text"
                                        name='variable_name'
                                        label="Variable Name"
                                        placeholder="Enter Variable Name"
                                        onChangeHandler={handleChangeForAddedVar}
                                        data={state.variableData}
                                        error={state.error}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ paddingBottom: '0' }}>
                                    <Input
                                        type="text"
                                        name='variable_value'
                                        label="Variable Value"
                                        onChangeHandler={handleChangeForAddedVar}
                                        data={state.variableData}
                                        error={state.error}
                                    />
                                </Grid>
                                <Grid container xs={12} style={{ paddingBottom: '12px', paddingRight: '5px' }} justifyContent="flex-end"
                                    alignItems="center">

                                    <button className="btn btn-submit" onClick={vaidateAddedVarData}>Add&nbsp;</button>

                                </Grid>
                            </Grid>
                        </div>

                    }


                </div>
                <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', marginTop: 'auto' }}>

                    <button onClick={onClose}

                        className="btn btn-outline-grey">Close</button>

                    <button className="btn btn-submit" onClick={validateOnSaveChanges} style={{ display: 'flex' }}>Save &nbsp;</button>
                </div>
            </div>
        
    )
}

EditStepEnvVarv2.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const useStyles = makeStyles({
    wrapbox: {
        padding: '15px 32px 0 20px',
        marginTop: '0px',
        background: '#fff',
        '& .close': {
            textAlign: 'right',
        },
        '& .editor-box': {
            '& textarea': {
                border: '1px solid #B7B7B7',
                width: '100% !important',
                height: '100px !important'
            }
        },
        '& .custom-notify-wrap': {
            border: '1px solid #B7B7B7',
            borderRadius: '4px',
            marginTop: '15px',
            marginBottom: '15px',

            '& .custom-notify-header': {
                padding: '10px',
                borderRadius: '4px 4px 0 0',
                '& .addbtn': {
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'end',
                    color: '#03B8EF',
                    fontSize: '13px',
                }
            },
            '& .top-head': {
                padding: '5px 10px',
                background: '#F5F5F5',
                borderTop: '1px solid #B7B7B7',
                borderRadius: '0 0 4px 4px',
                fontSize: '13px',
            },
            '& .add-new-variable': {
                borderTop: '1px solid #B7B7B7',
                padding: '8px'
            }
        }
    }
})

function getIntegrationDefaultState() {
    return {
        data: {},
        variableData: {},           // this is only for add var form
        error: {},
        validations: {},
        integration_variables: []
    }
}
function getDefaultState() {
    return {
        data: {},
        variableData: {},           // this is only for add var form
        error: {},
        validations: {},
        step_variables_for_rendring: [],
        customVariables: {},
        extraVariableError:{}
    }
}

const setWholeState = (apiFetchedStep, localPropStep, setState, setIntegrationState) => {       // here we are mapping env variables and their vlues 

    console.log('inside state m function',apiFetchedStep, localPropStep)

    // setIntegrationEditState(apiFetchedStep, localPropStep, setIntegrationState)

    let data = {}

    let stepVariableForRendring = []

    let integrationData = []

    if ( localPropStep) {

        let envVariables = apiFetchedStep && apiFetchedStep.environment_variables ? apiFetchedStep.environment_variables : []// copying env variables

        let envVariablesFromLocalstep = localPropStep.data && localPropStep.data.environment_variables

        let stepEnvVars = []

        let customVariables = localPropStep?.data?.customVariables

        console.log(envVariablesFromLocalstep, 'ev_fls')

        if (envVariablesFromLocalstep) {

            stepEnvVars = envVariablesFromLocalstep && Object.keys(envVariablesFromLocalstep).map(key => {

                if (key)
                    data[key] = envVariablesFromLocalstep[key]         // adding data for form values

                return {
                    does_default_active: "N",      // adding data for rendering
                    input_type: "text",
                    is_required: false,
                    key: key,
                    isCustomVar: customVariables && customVariables[key] ? true : false
                }
            })
        }

        // now removing varibales that exist orginally in api fetched step (duplicate)

        const filteredVariables = stepEnvVars.filter(variable => {

            const isFound = envVariables.find(item =>{

                if(item['does_default_active']==='Y' && !data[item.key]){
                    data[item.key] = item.default_value
                }
                if(item.key === variable.key){
                    return item
                }
            } )
            console.log('isFound', isFound)
            if (!isFound) {

                return variable
            }
        })

        console.log('filteredVariables', filteredVariables)
        stepVariableForRendring = [...envVariables, ...filteredVariables]

        console.log('stepVariableForRendring', stepVariableForRendring)
        
        let updatedEnvVars = stepVariableForRendring && stepVariableForRendring.map(item => {

            return { ...item, is_required: false }
        })

        setState(prevState => ({
            ...prevState,
            data: data,
            validations: {},
            error:{},
            step_variables_for_rendring: updatedEnvVars,
            integration_variables: integrationData,
            customVariables: customVariables?customVariables:{}
        }))
    }

}

const setIntegrationEditState = (apiFetchedStep, localPropStep, setIntegrationState) => {

    console.log('apiFetched', apiFetchedStep)
    console.log('localPropStep', localPropStep)

    let data = {}

    apiFetchedStep && apiFetchedStep.environment_variables && apiFetchedStep.environment_variables.forEach(varObj => {

        if (varObj['integration']) {
            data[varObj['integration']] = ''
        }
    })

    const { data: localData } = localPropStep
    localData && localData.environment_variables && localData.environment_variables.forEach(({ integration, value }) => {
        if (integration) {
            data[integration] = value
        }
    })

    setIntegrationState(
        {
            integration_variables: data,
            data: data,
            error: {},
            validations: {}
        }
    )
}


















