import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import GenerateURL from '../../../../../util/APIUrlProvider';
import InvokeApi from '../../../../../util/apiInvoker';
import properties from '../../../../../properties/properties';
import PodChart from './PodChart';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { LoadingText } from '../../../../utils/Loading';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SyncIcon from '@material-ui/icons/Sync';

import { GetAuth } from '../../../../../util/security';
import { Tooltip } from '@material-ui/core';


export default function DeploymentAnalytics(props) {
    const classes = useStyles();
    const service_id = props.service_id;
    const env_id = props.env_id;
    const cd_id = props.cd_id ? props.cd_id : null
    console.log(cd_id, "cd__id___")
    const application_id = props.application_id;
    const env_details = props.env_details ? props.env_details : null
    const child_inherits = {};
    const expected_number_of_replicas = 3
    const refresh_count = props.refresh_count;
    const last_deployment_number = props.last_deployment_number;
    const selected_deployement_resource_kind = props?.selected_deployement_resource_kind ? props.selected_deployement_resource_kind : null;
    const [state, setState] = useState({
        deployment_data_load_inprogress: true,
        deployment_dataLoad_failure: false,
        no_deployments_yet: false,
        deployment_resource_error: false,
        deployment_data: {},
        error_message: null,
        count: 1
    });

    console.log("running_this_component==========>")
    useEffect(() => {
        console.log(cd_id,"fdsfjasjf")
        loadDeploymentData()
    }, [service_id, env_id, state.count, refresh_count, cd_id])




    function loadDeploymentData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.service_deployment_status_widget + `?deployment_name=${cd_id}`),
            // endPoint: GenerateURL({ service_id: service_id, env_id: env_id, cd_id: cd_id }, properties.api.service_deployment_status),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, dataLoadSuccessHandler, dataLoadFailureHandler);
        setState(new_state => (
            { ...new_state, deployment_data_load_inprogress: true }
        ))
    }

    function dataLoadSuccessHandler(response) {
        console.log("asjdfh",response)
        if (response) {
            if(response?.deployment_error){
                console.log("asjdfh",response)
                setState(new_state => ({
                    ...new_state,
                    deployment_data_load_inprogress: false,
                    deployment_dataLoad_failure: false,
                    deployment_resource_error: true,
                    error_message: response.deployment_error,
                }));
            } 
            else{
                child_inherits.expected_number_of_replicas = response.spec.replicas;
                child_inherits.deployment_tag = response.metadata.annotations.deployment_tag;
                child_inherits.deployment_date = response.metadata.creationTimestamp;
                child_inherits.namespace = response.metadata.namespace;
                child_inherits.deployment_yaml = response
                setState(new_state => ({
                    ...new_state,
                    deployment_dataLoad_failure: false,
                    deployment_data_load_inprogress: false,
                    deployment_data: response,
                    error_message: null
                }));
            }
        }
        else {
            setState(new_state => ({
                ...new_state,
                deployment_data_load_inprogress: false,
                deployment_dataLoad_failure: false,
                no_deployments_yet: true,
                error_message: null
            }));
        }
    }


    function dataLoadFailureHandler(response) {
        setState(new_state => ({
            ...new_state,
            deployment_data_load_inprogress: false,
            deployment_dataLoad_failure: true,
            error_message: response
        }));
    }
    function refresh() {
        setState(new_state => ({
            ...new_state,
            count: new_state.count + 1,
        }));
    }
    // function triggerDeploy (){
    //     var errors = GenericValidator(state.deploy_tag, [VALIDATION_TYPE_REQUIRED])
    //             if (errors) {
    //                 setState({
    //                     ...state,
    //                     errors: {
    //                         ...state.errors,
    //                         deploy_tag: errors,
    //                     }
    //                 });
    //             } else {
    //                 var data = {
    //                     tag: state.deploy_tag
    //                 }
    //                 var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_deploy_request_url);
    //                 PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);
    //             }
    // }
    var url = GenerateURL({ service_id: service_id, env_id: env_id, cd_id: cd_id }, properties.api.is_pod_restart_permitted,true);
    const is_permited = GetAuth().permission.POST.includes(url);
    console.log("podurllamak",url,is_permited,GetAuth().permission.POST);
    function restartPod() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, cd_id: cd_id }, properties.api.pod_restart),
            httpMethod: "POST",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, restartPodSuccess, restartPodFailed);
    }

    function restartPodFailed(error, exception) {
        setState(new_state => ({
            ...new_state,
            pod_error: exception ? "Something wrong at our servers" : error
        }));
    }

    function restartPodSuccess(response) {
        refresh();
    }


    return (
        <>

            <div className={classes.root}>
                {state.deployment_data_load_inprogress ? <LoadingText /> :
                    <>
                        {state.deployment_dataLoad_failure ?
                            <div className="bg-round-grey pd-lr-c d-flex align-center status-b space-between font-11">
                                <div className="text-grey-83 d-flex align-center">
                                    <InfoIcon className="text-grey-83 mr-5 " fontSize="large" />
                                    <span className="lh-13">No Deployments initiated for this service yet!! This is the message we got:{state.error_message}</span>
                                </div>
                            </div>
                            :
                            state.deployment_resource_error ? 
                            <div className="bg-round-grey pd-lr-c d-flex align-center status-b space-between font-11">
                                <div className="text-grey-83 d-flex align-center">
                                    <InfoIcon className="text-grey-83 mr-5 " fontSize="large" />
                                    <span className="lh-13">This is the message we got:{state.error_message}</span>
                                </div>
                            </div>
                            :
                            <>
                                {state.no_deployments_yet ?
                                    <div className="bg-round-grey pd-lr-c d-flex align-center status-b space-between font-11">
                                        <div className="text-grey-83 d-flex align-center">
                                            <InfoIcon className="color-white mr-5" fontSize="large" />
                                            <span className="color-white text-center font-11">No Deployments initiated for this service yet!! </span>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {state.deployment_data.deployment_status.status == "Success" ?
                                            <div className="bg-round-green pd-lr-c d-flex align-center status-b space-between font-11">
                                                <div className="color-white d-flex align-center">
                                                    <CheckCircleIcon className="color-white mr-5" fontSize="large" />
                                                    <span className="lh-13">{state.deployment_data.deployment_status.status}</span>
                                                </div>
                                            </div> :
                                            state.deployment_data.deployment_status.status == "Running" ?
                                                <div className="bg-round-yellow pd-lr-c d-flex align-center status-b space-between font-11">
                                                    <div className="color-white d-flex align-center">
                                                        <TimelapseIcon className="color-white mr-5" fontSize="large" />
                                                        <span className="lh-13">{state.deployment_data.deployment_status.status}</span>
                                                    </div>

                                                </div> :
                                                state.deployment_data.deployment_status.status == "Failed" ?
                                                    <div className="bg-round-red pd-lr-c d-flex align-center status-b space-between font-11">
                                                        <div className="color-white d-flex align-center">
                                                            <CancelIcon className="color-white mr-5" fontSize="large" />
                                                            <span className="lh-13">{state.deployment_data.deployment_status.status} </span>
                                                        </div>
                                                    </div> : null}
                                    </>
                                }
                            </>


                        }
                    </>
                }
            </div>
            <div className="d-flex align-center space-between pd-10 border-bottom">
                <button onClick={refresh} className="btn-round bg-clear-btn border-navy"><span className="flaticon-refresh-button-1"></span></button>
                <div className="d-flex align-center">
                    {console.log(env_details, "fdsafadfsa")}
                    {is_permited ?
                        <button onClick={restartPod} className="btn btn-transparent text-anchor-blue d-block" >
                            <SyncIcon className=" v-align-sub ml-5" /> Restart Pods
                        </button>
                        :
                        
                            <Tooltip title="You don't have permission to perform this action">
                                <button className="btn btn-transparent d-block cursor-not-allowed" style={{ color: "#838383" }}>
                                    <SyncIcon style={{ color: '#838383' }} className=" v-align-sub ml-5" /> <span style={{ color: "#838383" }}>Restart Pods</span>
                                </button>
                            </Tooltip>
                        
                    }
                    <SimpleMenu service_id={service_id} env_id={env_id} cd_id={cd_id} />

                </div>

            </div>

            <PodChart cd_id={cd_id} last_deployment_number={last_deployment_number} application_id={application_id} count={state.count} service_id={service_id} refresh_prop={state.count} env_id={env_id} expected_number_of_replicas={expected_number_of_replicas}
                deployment_data={state.deployment_data} />


        </>
    )
}

DeploymentAnalytics.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


const useStyles = makeStyles((theme) => ({
    root: {
        height: 53,
        backgroundColor: '#f4f4f4',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #dedede',
        '& .status-b': {
            height: 'inherit',
            width: '100%',

        },
        '& .pd-lr-c': {
            padding: '5px 15px'
        },
        '& .loading-text-cc': {
            height: 'inherit',
            margin: 'auto'
        }
    },
}));


function SimpleMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const service_id = props.service_id;
    const env_id = props.env_id;
    const cd_id = props.cd_id;
    // const [open, setOpen] = React.useState(false);
    const myRef = React.createRef()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // const handleClickAway = () => {
    //     setOpen(false);
    //   };
    const handleClose = () => {
        setAnchorEl(null);
    };
    var handleScroll = (event) => {
        window.addEventListener("scroll", handleClose);
    }

    return (

        <div style={{ justifySelf: 'end' }}>
            <button className="btn btn-transparent text-anchor-blue d-block" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <VisibilityIcon className=" v-align-sub" /> View Yaml
            </button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Link target="_blank" to={"/yamlviewer?yaml_type=replicasets&service_id=" + service_id + "&component_env_id=" + env_id + "&cd_id=" + cd_id} className="btn btn-transparent text-anchor-blue d-block">
                    Replica Set Yaml
                </Link>
                <div className="seprator m-0"></div>
                <Link target="_blank" to={"/yamlviewer?yaml_type=pod_listing&service_id=" + service_id + "&env_id=" + env_id + "&cd_id=" + cd_id} className="btn btn-transparent text-anchor-blue d-block">
                    Pods Yaml
                </Link>
                <div className="seprator m-0"></div>
                <Link target="_blank" to={"/yamlviewer?yaml_type=deployment&service_id=" + service_id + "&component_env_id=" + env_id + "&cd_id=" + cd_id} className="btn btn-transparent text-anchor-blue d-block">
                    Deployment Yaml
                </Link>
            </Menu>
        </div>
    );
}

SimpleMenu.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }
