import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Link } from 'react-router-dom';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { GetAuth } from '../../../../../../util/security';
import { Tooltip } from '@material-ui/core';
const ManifestOverview = props => {
    const maifest_meta_data = props.maifest_meta_data;
    console.log("maifest_meta_data", maifest_meta_data)
    const cd_versioning = props.cd_versioning ? props.cd_versioning : null;
    const cd_id = maifest_meta_data.id ? maifest_meta_data.id : null
    const deployment_name = maifest_meta_data.deployment_name;
    const service_name = maifest_meta_data.service_name;
    const audit_history_status = props.audit_history_status ? props.audit_history_status : "";
    console.log("selected_service_type", audit_history_status)
    const image_name = maifest_meta_data.image_name;
    const manifest_file_paths = maifest_meta_data.manifest_meta_data ? maifest_meta_data.manifest_meta_data.manifest_file_paths : 'NA';
    const application_id = props.application_id;
    const service_id = props.service_id;
    const component_env_id = props.component_env_id;
    const clone_env_id = props.clone_env_id;
    const clone_deploy_id = props.clone_deploy_id;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_cd, true);
    const is_permitted = GetAuth()?.permission?.POST?.includes(url);
    const [state, setState] = useState({
        showValue: false,
    });
    const handleToggle = () => {
        setState({
            ...state,
            showValue: !state.showValue,
        })
    }
    console.log(props.extraProps, "state.selected_cd_for_display")
    return (

        <div className="section-service-overview">
            <div className="overview-single-card">
                <div className="overview-card-heading-section">
                    <p className="overview-card-heading">Deployment Details</p>
                    <Link to={{
                        pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id + "/cd/" + cd_id + "/manifest/edit",
                        search: "?versioning_enabled=" + cd_versioning + "&audit_history_status=" + audit_history_status + "&clone_env_id=" + clone_env_id + "&clone_deploy_id=" + clone_deploy_id + "&is_edit=" + true,
                        state: { ...props.extraProps }
                    }}
                    >
                        <BorderColorIcon style={{ color: '#0086ff' }} />
                    </Link>
                </div>
                <div className="overview-card-value-access">
                    <p className="overview-card-value-text">
                        <span className="color-text-dark-grey">Deployment Name:&nbsp;</span>
                        {
                            maifest_meta_data.deployment_name ?
                                <span className="overview-card-value-text">
                                    {deployment_name}
                                </span> :
                                <span className="no-data__msg ">NA</span>
                        }


                    </p>
                    <p className="overview-card-value-text">
                        Service Name:&nbsp;
                        {
                            maifest_meta_data.service_name ?
                                <span className="overview-card-value-text">
                                    {service_name}
                                </span> :
                                <span className="no-data__msg ">NA</span>
                        }

                    </p>
                    {/* <p className="overview-card-value-text">
                            Image Name:&nbsp;
                            {
                                maifest_meta_data.image_name ?
                                    <span className="overview-card-value-text">
                                        {image_name}
                                    </span> :
                                    <span className="no-data__msg ">NA</span>
                            }
                        </p> */}
                    <p className="overview-card-value-text">
                        File Name:&nbsp;
                        {
                            maifest_meta_data.manifest_meta_data && maifest_meta_data.manifest_meta_data.value_file_paths ?
                                maifest_meta_data.manifest_meta_data.value_file_paths.map(item => (
                                    <span className="Y7 ml-2 mr-2">
                                        {item}
                                    </span>
                                ))
                                :
                                maifest_meta_data.manifest_meta_data && maifest_meta_data.manifest_meta_data.manifest_file_paths ?
                                    maifest_meta_data.manifest_meta_data.manifest_file_paths.map(item => (
                                        <span className="Y7 ml-2 mr-2">
                                            {item}
                                        </span>
                                    ))
                                    :
                                    <span className="no-data__msg ">NA</span>
                        }
                    </p>
                    <p className="overview-card-value-text">
                        Image Pull Policy:&nbsp;
                        {
                            maifest_meta_data.image_pull_policy ?
                                <span className="overview-card-value-text">
                                    {maifest_meta_data.image_pull_policy}
                                </span> :
                                <span className="no-data__msg ">NA</span>
                        }
                    </p>
                </div>
                <div className="overview-card-value-env-var border-top" style={{ padding: 0, height: 'auto' }}>
                    <div className="overview-card-heading-section pd-10">
                        <p className="overview-card-heading">
                            Raw Key Value Pair
                        </p>

                        {maifest_meta_data.env_cd_deploy_variable[0] ?
                            <span className="ml-auto d-inline-block" onKeyDown={() => { }} onClick={handleToggle} role='button' tabIndex={0}>{state.showValue ?
                                <VisibilityIcon style={{ color: '#ccc', verticalAlign: "middle", fontSize: '20px' }} /> :
                                <VisibilityOffIcon style={{ color: '#ccc', verticalAlign: "middle", fontSize: '20px' }} />}
                            </span>
                            : null}


                    </div>
                    <div className="heading-key-value pd-10">
                        <p>Key</p>
                        <p>Value</p>
                    </div>
                    <div className="env-variable-box pd-10">

                        {maifest_meta_data.env_cd_deploy_variable[0] ?
                            <>
                                {maifest_meta_data.env_cd_deploy_variable.map(item => (
                                    <div className="data-key-value-pair">
                                        <p>{item.env_key}</p>
                                        <p className={!state.showValue ? "text-security" : null}>

                                            {item.env_value}


                                        </p>
                                    </div>
                                ))}
                            </> : <p className="no-data__msg-env-var" >No Data Available</p>}
                    </div>
                </div>
            </div>
        </div>

    )
}

ManifestOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default ManifestOverview;