import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import GenerateURL from '../../../util/APIUrlProvider';
import properties from '../../../properties/properties';


import { Link } from 'react-router-dom';
import Delete from '../../../components/genericComponents/Delete';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { ExandableComponentList } from '../../../components/hoc/expandableList';
import { Tooltip } from '@material-ui/core';

import { GetAuth } from '../../../util/security';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';
import VMGroupDisplay from './VMGroupDisplay';

const EnvCard = ({skeleton,...props}) => {
    if(skeleton){
        return (
        <Grid item lg={4} className="pt-0">
            <div className="card border-radius-8 border-color-default" style={{marginTop: "24px"}}>
                <div className="card-body pb-0" style={{minHeight: "282px",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                    <div className="header-part pd-10 border-bottom border-color-default">
                        <GenericSkeleton variant={"text"} width={"45%"} />
                    </div>
                    
                        <GenericSkeleton variant={"rect"} height={182} width={"100%"} />
                    
                    <div className="d-flex align-center justify-flexend border-top border-color-default pd-5">
                        <div className="d-flex space-between" style={{ width: "82px" }}>
                            <GenericSkeleton variant={"rect"} width={36} height={36} count={2} style={{borderRadius:"6px"}} rootStyle={{flexDirection:"row"}} />
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
        );
    }
    const classes = useStyles();
    const addToCompare = props.addToCompare;
    const inherit = props.inherit ? props.inherit : {};
    const [state, setState] = useState({ open: false, data: {}, error: {}, env_count: 0 });
    const commonFunction = getCommonFunctions(state, setState, inherit)
    const toggleOpen = () => {

        setState({
            ...state,
            open: !state.open
        })
    }
    const data = props.data;
    console.log(data, "adbdfbhsdfbsdbcf")
    const inherits = props.inherits ? props.inherits : {};
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    var env_service = [];
    var iteration_count = 2;
    var expandable_component = null;
    useEffect(() => {
        setShowLoading(true);
        setData(props.data);
        console.log(data,"fdsaklfsalkfjas")
        setTimeout(() => {
          setShowLoading(false)
        }, 200);
     
     
    }, [props.data, url])
    
    if(data){
        if (data.component_name_list && data.component_name_list.length > 0) {
            data.component_name_list.map((job, key) => {
                env_service.push(
                    <div className={"chip-sq chip-sq-primary-outline"} key={job}>
                        {
                            job.length > 25 ? 
                                <Tooltip title={job}>
                                    <p className="text-grey-83">{job.substring(0,25)+"..."}</p>
                                </Tooltip>
                            :
    
                            <p className="text-grey-83">{job}</p>
                        }
                        
                    </div>
    
                )
            })
            expandable_component = (
                <>
                    +{data.component_name_list.length - iteration_count}
                </>
            );
        }
    
    }
   

    

    console.log(is_edit_permitted,"is_edit_permitted", is_delete_permitted)
    return (
        <Grid item lg={4} className="pt-0">{
            showLoading ?
            <Loading varient="light" /> :
            data ? 
            <div className="card border-radius-8 border-color-default" style={{marginTop: "24px"}}>
                <div className="card-body pb-0" style={{minHeight: "282px",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                    <div className="header-part pd-10 border-bottom border-color-default">
                        <Link className="text-anchor-blue" to={"/application/" + props.application_id + "/environment/" + data.id + "/dashboard"}><p className="font-18 font-family-nunito">{data.name}</p></Link>
                    </div>
                    <div className="pr-10 pl-10">
                        <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
                            <p>Cluster:</p>
                            <p className="text-grey-83">{data.cluster ? data.cluster.name : "NA"}</p>
                        </div>
                        <VMGroupDisplay data={data} />
                        <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
                            <p>Type:</p>
                            <p className="badge badge-pill badge-sq badge-primary badge-ellipsis font-12 border-radius avtar" style={{ padding: '3px 5px' }}>{data.environment_master.name}</p>
                        </div>
                        <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
                            <p>Namespace:</p>
                            <p className="text-grey-83">{data.project_env_namespace ? data.project_env_namespace.namespace.name : "NA"}</p>
                        </div>
                        <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
                            <p>Vault Token:</p>
                            <p className="text-grey-83">{data.vault_access_token ? data.vault_access_token.name : "NA"}</p>
                        </div>
                        <div className="d-flex align-center space-between pd-5 font-12 border-bottom border-color-default">
                            <p>Sonar:</p>
                            <p className="text-grey-83">{data.sonar_access_token ? data.sonar_access_token.name : "NA"}</p>
                        </div>
                        <div className="d-flex align-center space-between pd-5 font-12 border-color-default env-service">
                            <p>Service:</p>
                            {
                                data.component_name_list.length > 0 ? <span className='d-flex'><ExandableComponentList compoenent_list={env_service} iteration_count={iteration_count} expandable_component={expandable_component} /></span> : <p className="text-grey-83">NA</p>
                            }
                        </div>
                    </div>
                    <div className="d-flex align-center justify-flexend border-top border-color-default pd-5">
                        
                        <div className="d-flex space-between" style={{ width: "82px" }}>
                            {
                                is_edit_permitted ?
                                    <Link className="text-anchor-blue" to={"/application/" + props.application_id + "/env/" + data && data.id + "/edit"}>
                                        {/* <IconButton className="btn btn-with-icon btn-round">
                                            <BorderColorIcon className="text-anchor-blue" />
                                        </IconButton> */}
                                        <button className='btn-sq-icon-primary mr-5'>
                                            <span className='ri-edit-line vertical-align-super'></span>
                                        </button>
                                    </Link>
                                    :
                                    <Tooltip title="You are not allowed to perform this action">
                                        {/* <IconButton className="btn btn-with-icon btn-round" >
                                            <BorderColorIcon style={{ color: '#818078' }} />
                                        </IconButton> */}
                                        <button className='btn-sq-icon-primary mr-5' disabled>
                                            <span className='ri-edit-line vertical-align-super' style={{ color: '#818078' }}></span>
                                        </button>
                                    </Tooltip>

                            }
                            <Delete
                                display_data_name={data.name}
                                varient="new_button"
                                data={{ entity_id: data && data.id, name: "project_env" }}
                                refresh={props.refresh}
                                api_link={GenerateURL({ env_id: data&& data.id }, properties.api.get_env)}
                                is_edit_permited = {is_delete_permitted}
                            />
                        </div>
                    </div>
                </div>
            </div> 
            : "N/A"
        }
            
        </Grid>
    )

}

EnvCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default EnvCard;
const useStyles = makeStyles(theme => ({
    root: {
        padding: '32px',
        marginTop: '32px',
        '& .main-heading': {
            fontSize: '24px',
            fontWeight: '300',
            lineHeight: 1
        },
        '& .MuiGrid-item': {
            paddingTop: '0px!important'
        },
        '& .img-box': {
            width: '144px',
            height: '168.5px',
            margin: '8px',
            border: '1px solid #dedede',
            borderRadius: '8px',
            backgroundColor: '#fff',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            '& .img-pos': {
                width: 'auto',
                border: 'none',
                height: '50px',
                display: 'block',
                margin: 'auto',
            },
            '& .img-box-footer': {
                height: "41px",
                display: 'flex',
                padding: '8px',
                background: '#f0f3f5',
                borderTop: '2px solid #dedede',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '11px',
                color: '#16232b'
            }
        },
        '& .card': {
            '& .card-header': {
                fontSize: '12px',
                height: 'auto',
                backgroundColor: '#fff'
            },
            '& .pd-20': {
                padding: '10px 20px!important'
            },
            '& .with-header': {
                borderTop: '1px solid #dedede'
            }
        },
        '& .sub-header': {
            padding: '5px 10px',
            borderBottom: '1px solid #dedede',
            backgroundColor: '#f6f8f8',
            fontSize: '12px',
            '& .input-component': {
                marginBottom: '0px'
            }
        },
        '& .card-controller': {
            '& .card': {
                border: 'none',
                borderRadius: '0px'
            },
            '& .makeStyles-cardHeader-228': {
                padding: '5px 10px'
            }
        },
        '& .env-service': {
            '& .chip-sq': {
                fontSize: '11px',
                padding: '3px',
                border: '2px solid #0086ff',
                maxWidth: 90,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                marginRight: 2,
                marginLeft: 2,
                '&:first-child': {
                    marginLeft: 0
                },
                '&:last-child': {
                    marginRight: 0
                },
                '&:hover': {
                    cursor: 'pointer'
                }
            },
            '& .chip-sq-primary-outline': {
                borderColor: '#0086ff',
                color: '#0086ff',
                backgroundColor: 'transparent'
            },
        }
    },

    rootCard: {
        marginLeft: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // width : "215px",

        "& p": {
            color: "#000",
            fontSize: "13px",
        },

        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
    }
}));