import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const nonConditionStepCard = (props) => {

    const { stepData } = props

    console.log(stepData, 'step_date_values)9991');
    return (
        

            <div className="m-20 non-conditional-step">

                <div className="non-conditional-step-header">
                    <span className='font-14 text-color-black'>Environment Variables</span>
                </div>

                {
                    props.envVars?.length > 0 ?
                        <>
                            <div className='align-center pl-16 pr-16' style={{ height: '40px', width: '100%', display: 'flex', backgroundColor: 'whitesmoke', borderTop: '1px solid #c9c9c9' }}>
                                <div style={{ width: '50%' }}><span className='font-13'>Variable Name</span></div>
                                <div style={{ width: '50%' }}><span className='font-13 pl-10'>Variable Value</span></div>
                            </div>
                            {

                                <>
                                    {
                                        props.envVars && props.envVars?.map((envVarObj, index) => {


                                            return (

                                                <div key={index} className='align-center pl-16 pr-16' style={{ height: '40px', width: '100%', display: 'flex', borderTop: '1px solid #c9c9c9' }}>
                                                    <div className='env-var-table' style={{ width: '50%' }}>
                                                        <Tooltip title={envVarObj.key}>
                                                            <label className='font-13 text-color-dark-gray'>{envVarObj.key}</label>
                                                        </Tooltip>
                                                    </div>

                                                    <div className='env-var-table pl-10' style={{ width: '50%' }}>
                                                        <Tooltip title={envVarObj.value}><label className='font-13 text-color-dark-gray'>{envVarObj.value?envVarObj.value:'NA'}</label>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }
                        </> :

                        <div className='align-center justify-center pl-16 pr-16' style={{ height: '40px', width: '100%', display: 'flex', backgroundColor: '#f2f2f2', borderTop: '1px solid #c9c9c9' }}>
                            <span className='font-13 text-color-dark-gray'>No Environment Variables</span>

                        </div>
                }

            </div>

        
    )

}


export default nonConditionStepCard