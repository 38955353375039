import React, { useEffect, useState } from 'react'
import { EnvMonitoringDataParser } from '../../../util/APIParser';
import GenerateURL from '../../../util/APIUrlProvider';
import InvokeApi, { SubscribeToApi } from '../../../util/apiInvoker';
import properties from '../../../properties/properties';
import ReplicationToolBarNew from './components/ReplicationToolBarNew';
import { ProgressContainer } from './components/CustomStackedProgress';
import { IconLinkList } from '../../../components/genericComponents/IconLink';
import HealthStatusRoundMeter from '../../../components/genericComponents/HealthStatusRoundMeter';
import { CircularMeterListNew } from '../../../components/genericComponents/CircularMeterNew';
import { Dialog, Slide, Tooltip, makeStyles } from '@material-ui/core';
import { LoadingText } from '../../utils/Loading';
import PropTypes from 'prop-types';

const MonitoringSection = (props) => {
    const [state, setState] = useState({
        loaded: false,
        deployment_list: [],
    });
    const count = props.count;
    const deploy_id = props.deploy_id ? props.deploy_id : null
    console.log(deploy_id, "fdskajfjksaj")
    const cd_callback = props.callback;
    const application_id = props.application_id;
    const service_id = props.service_id;
    const env_id = props.env_id;
    const showMonitoring = props.showMonitoring
    const handleCloseMonitoring = props.handleCloseMonitoring;

    const classes = useStylesForMonitoringDialog();
    useEffect(() => {
        if (state.deployment_list.length > 0 && deploy_id) {
            let find_current_deployment = state.deployment_list.find(item => item.deployment_name == deploy_id)
            console.log(state.deployment_list, "fdskajfjksaj")
            console.log(find_current_deployment, "find_current_deployment")
            var monitoring = EnvMonitoringDataParser(find_current_deployment);

            if (monitoring) {
                cd_callback(monitoring.service_data);
            }
            setState({
                ...state,
                loaded: true,
                monitoring: monitoring,
                health: find_current_deployment ? find_current_deployment.monitoring && find_current_deployment.monitoring.infra ? find_current_deployment.monitoring.infra : null : null
            });
        }
        console.log("ncjncjds", state.monitoring);
    }, [deploy_id, state.deployment_list, env_id])

    // Managing API Calls
    const handleSuccessApiHit = (response) => {
       
        setState({
            ...state,
            loaded: true,
            deployment_list: response
        });
    }
    const handleFailedApiHit = (error) => {
        setState({
            ...state,
            error: error,
        })
    }


    useEffect(() => {
        const placeholders = {
            application_id: application_id,
            service_id: service_id,
            env_id: env_id
        }
        var requestInfo = {
            endPoint: GenerateURL(placeholders, properties.api.env_monitoring_data_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        SubscribeToApi(GenerateURL(placeholders, properties.api.env_monitoring_data_url));
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailedApiHit);
        setState({
            ...state,
            loaded: false
        });
    }, [env_id, count, deploy_id]);

    function resolveReplicationStatus(current, max) {
        if (!max) {
            return "disabled";
        }
        const result = Math.round((current / max) * 100);
        if (result > 80) {
            return "danger";
        }
        if (result > 60) {
            return "warning";
        }
        return "success";
    }

    function getSection(monitoring) {
        console.log("jijtiijew", monitoring, state);
        switch (monitoring.status) {
            case "NOT_WORKING":
                return (
                    <div className='width-full'>
                        <div>
                            <CircularMeterListNew list={empty_data_list} />
                            <div className={classes.notMontInfo}>
                                <p className="font-14 text-grey-72 font-family-v1 font-weight-600">Monitoring data not available</p>
                                <p className="font-12 text-grey-72 font-family-v1 font-weight-500">Either there is no deployment or
                                    there is some issue with<br />the deployment. <Tooltip title={monitoring.description}><span className="text-anchor-blue cursor-pointer font-11">View Details</span></Tooltip></p>
                            </div>

                        </div>
                        <div className="replication_preview">
                            
                        </div>

                    </div>
                );
            case "NOT_SETUP":
                return (
                    <div className='width-full'>
                        <div>
                            <CircularMeterListNew list={empty_data_list} />
                            <div className={classes.notMontInfo}>
                                <p className="font-14 text-grey-72 font-family-v1 font-weight-600">Monitoring data not available</p>
                                <p className="font-12 text-grey-72 font-family-v1 font-weight-500">Either there is no deployment or
                                    there is some issue with<br />the deployment. <Tooltip title={"Service Monitoring is not Active. Kindly Contact to Super Admin"}><span className="text-anchor-blue cursor-pointer font-11">View Details</span></Tooltip></p>
                            </div>

                        </div>



                        <div className="replication_preview">
                          
                            <div>

                            </div>
                        </div>

                    </div>
                );
            default:
                return (
                    <div className="hardware-usage width-full">

                        {
                            state.health ?
                                <>
                                    {/* <span className="tab-label-inner">Service Health</span> */}
                                    <HealthStatusRoundMeter data={state.health} useNewMeter={true} />
                                </>
                                : null
                        }

                        {
                            state.monitoring.urls ?

                                <div className="monitoring-box">
                                    <span className="tab-label-inner">Monitor Using</span>
                                    <IconLinkList list={state.monitoring.urls} />
                                </div>
                                : null
                        }

                        {
                            monitoring.replication_bar ?
                                <div className="replication_preview">
                                    <ProgressContainer>
                                        {/* <HorizontalMeter
                                            current={state.monitoring.replication_bar.current}
                                            max={state.monitoring.replication_bar.max ? state.monitoring.replication_bar.max : state.monitoring.replication_bar.current}
                                            status={resolveReplicationStatus(state.monitoring.replication_bar.current, state.monitoring.replication_bar.max)}
                                        /> */}
                                        {/* {resolveReplicationStatus(state.monitoring.replication_bar.current, state.monitoring.replication_bar.max) == "disabled" ? <p className="auto-scale-disabled">Auto Scaling Disabled</p> : null} */}
                                        <ReplicationToolBarNew service_id={service_id} env_id={env_id} cd_id={deploy_id} replication_bar={state.monitoring.replication_bar} />
                                    </ProgressContainer>

                                </div> : null
                        }
                    </div>
                );
        }
    }
    const empty_data_list = [
        {
            label: "CPU",
            allocatedLabel: "Utilized/Allocated",
            allocatedValue: '--'
        },
        {
            label: "RAM",
            allocatedLabel: "Utilized/Allocated",
            allocatedValue: "--"
        }
    ]


    return (
        <>
            <Dialog
                open={showMonitoring}
                onClose={handleCloseMonitoring}
                aria-labelledby="alert-dialog-title"
                className="integrations-dialog-for-monitoring action_card_dialog_width"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        margin: "0",
                        overflow: "visible"
                    },
                }}
                TransitionComponent={Slide} // Use Slide transition for the dialog
                transitionDuration={500} // Adjust the duration of the transition
                TransitionProps={{
                    direction: 'left', // Slide from right to left
                }}
            >
                <div className={classes.root}>
                    <div className='heading'>
                        <span className='font-18 font-weight-600'>Service Health</span>
                        <button onClick={handleCloseMonitoring}><span className='ri-close-fill font-24'></span></button>
                    </div>
                    <div className='d-flex mt-20 align-center justify-center' style={{ padding: "0px 16px" }}>
                        {
                            state.loaded ?
                                <>
                                    {
                                        state.monitoring ?

                                            <>

                                                {getSection(state.monitoring)}

                                            </>
                                            :
                                           
                                            <div >
                                                <div className={classes.noDeploy}>
                                                    <div className='iconCont'>
                                                        <span className='ri-alert-fill line-height-normal' style={{ fontSize: "32px", color: "#0086FF" }}></span>
                                                    </div>
                                                    <span className='font-14 font-weight-600 color-value' style={{ width: "70%", textAlign: "center" }}>Please configure Deploy Details to setup monitoring.</span>
                                                </div>
                                                <CircularMeterListNew list={empty_data_list} />
                                            </div>
                                    }

                                </>
                                :
                                state.error ?
                                    <div>
                                        <CircularMeterListNew list={empty_data_list} />
                                        <div>
                                            <p className="font-13">Monitoring data not available</p>
                                            <p className="font-12 text-grey-72">Either there is no deployment or
                                                there is some issue with<br />the deployment. <Tooltip title={state.error}><span className="text-anchor-blue cursor-pointer font-11">View Details</span></Tooltip></p>
                                        </div>

                                    </div>
                                    : <LoadingText />
                        }
                    </div>

                </div>



            </Dialog>

        </>
    );
}

const useStylesForMonitoringDialog = makeStyles((theme) => ({
    root: {
        '& .heading': {
            display: "flex",
            padding: "16px",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "stretch",
            background: "#0086FF",
            color: "#FFF",
            position: "relative",

            '& button': {
                display: "flex",
                width: "54px",
                //height: "54px",
                padding: "16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flexShrink: "0",
                borderRadius: "6px 0px 0px 6px",
                background: "#C11212",
                border: "none",
                color: "#fff",
                position: "absolute",
                top: "0",
                left: "-54px",
            }
        }
    },
    noDeploy: {
        padding: "12px",
        border: "1px solid #DFEDFF",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F5FAFF",
        gap: "10px",
        "& .iconCont ": {
            padding: "12px",
            border: "1px solid #DFEDFF",
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFF",
        }
    },
    notMontInfo: {
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        padding: "12px",
        width: "100%",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        background: "#FAFAFA",
    }
}))


MonitoringSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export default MonitoringSection