import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { getCommonFunctions } from '../../../../serviceRevamp/add/ci_flow/SourceDetails';

import VisibilityIcon from '@material-ui/icons/Visibility';

import Delete from '../../../../../components/genericComponents/Delete';
import SendForApprovalConfirmationDialog from './SendForApprovalConfirmationDialog';
import moment from 'moment';
import GenerateURL from '../../../../../util/APIUrlProvider';
import properties from '../../../../../properties/properties';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import 'remixicon/fonts/remixicon.css'
const AuditCard = (props) => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const [state, setState] = useState({ data: {}, error: {} });
    const commonFunction = getCommonFunctions(state, setState, inherits);
    const audit_card_data = props.audit_card_data ? props.audit_card_data : null
    const showOverviewView = props.showOverviewView;
    const service_id = props.service_id;
    const component_env_id = props.component_env_id;
    const service_env_details = props.service_env_details
    const application_id = props.application_id
    const clone_env_id = props.clone_env_id
    const env_cd_detail_id = props.env_cd_detail_id
    const sidecars_data = props.sidecars_data;
    const sidecars_configured = props.sidecars_configured;
    const cd_configured = props.cd_configured;
    const ci_configured = props.ci_configured;
    const showSideCarsOverview = props.showSideCarsOverview;
    console.log("showSideCarsOverview", showSideCarsOverview)
    const isCdDeleteInProgress = props.isCdDeleteInProgress;
    const setCdDeleteInProgress = props.setCdDeleteInProgress;
    const extraProps = props.extraProps ? props.extraProps : null;
    const approvedCiData = props.approvedCiData
    const child_build_configured = props.child_build_configured
    const child_build_data = props.child_build_data
    const multiple_build_enabled = props.multiple_build_enabled
    console.log(props, "wqwqwqqwq")
    return (
        <>
            {
                cd_configured &&
                <div className={classes.rootCardParent}>
                    <div className={audit_card_data.status == "DRAFT" || audit_card_data.status == "DRAFT_PENDING" ? classes.rootCard + " " + "draft" : audit_card_data.status == "APPROVAL_REQUESTED" || audit_card_data.status == "APPROVAL_REQUESTED_PENDING" ? classes.rootCard + " " + "in-approval" : classes.rootCard + " " + "in-use"}>
                        <div className="rootcard-header">
                            <div className="d-flex align-center space-between">
                                {
                                    audit_card_data.status == "DRAFT" || audit_card_data.status == "DRAFT_PENDING" ?
                                        <>
                                            <div className='d-grid' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                <div className='versioning-round draft_round'>
                                                    <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                </div>
                                                <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                <div className='d-flex align-center space-between'>
                                                    <div>
                                                        {
                                                            audit_card_data.status == "DRAFT_PENDING" &&
                                                            <div className='versioning-chip draft-pending-chip mb-5 text-transform-uppercase'>
                                                                Task to create draft branch is in queue
                                                            </div>
                                                        }
                                                        {
                                                            audit_card_data.status == "DRAFT" &&
                                                            <div className='versioning-chip draft-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                Draft Created
                                                            </div>
                                                        }
                                                        <div>
                                                            <div className='d-flex align-center'>
                                                                <div className='color-key-78 mr-5'>
                                                                    Configuration for deployment: <span className='color-value'>{audit_card_data.deployment_name}</span>
                                                                </div>

                                                            </div>
                                                            <span className='color-key-78 font-12 font-weight-500'>Created On:&nbsp; </span><span className='color-value font-12 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="d-flex align-center space-between btn-group-v1 ">
                                                {audit_card_data.status == "DRAFT" || audit_card_data.status == "DRAFT_PENDING" ? <SendForApprovalConfirmationDialog variant={"new_button"} audit_card_data={audit_card_data} sendForApproval={props.sendForApproval} /> : <></>}
                                                {
                                                    <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.DRAFT_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                        <button className='icon-btn-v1 icon-btn-outline-default'><span className='ri-file-list-line'></span></button>
                                                    </Link>
                                                }
                                                <button className="icon-btn-v1 icon-btn-outline-default" onClick={showOverviewView}><span className='ri-edit-line'></span></button>
                                                <Delete
                                                    display_data_name={""}
                                                    varient={"new_button"}
                                                    fontSize="font-20"
                                                    data={{ entity_id: audit_card_data.id, name: "env_ci_detail" }}
                                                    refresh={props.refreshFn}
                                                    api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: env_cd_detail_id }, properties.api.edit_ci)}
                                                />
                                            </div>
                                        </>
                                        :
                                        audit_card_data.status == "DRAFT_FAILED" ?
                                            <>
                                                <div className='d-grid' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                    <div className='versioning-round draft_round'>
                                                        <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                    </div>
                                                    <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                    <div className='d-flex align-center space-between'>
                                                        <div>

                                                            <div className='versioning-chip draft-failed-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                DRAFT CREATION HAS FAILED
                                                            </div>
                                                            <div>
                                                                <div className='d-flex align-center'>
                                                                    <div className='color-key-78 mr-5'>
                                                                        Configuration for deployment: <span className='color-value'>{audit_card_data.deployment_name}</span>
                                                                    </div>

                                                                </div>
                                                                <span className='color-key-78 font-12 font-weight-500'>Created On:&nbsp; </span><span className='color-value font-12 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="d-flex align-center space-between btn-group-v1 ">
                                                    <SendForApprovalConfirmationDialog variant={"new_button"} audit_card_data={audit_card_data} sendForApproval={props.sendForApproval} />
                                                    {
                                                        <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.DRAFT_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                            <button className='icon-btn-v1 icon-btn-outline-default'><span className='ri-file-list-line'></span></button>
                                                        </Link>
                                                    }
                                                    <button className="icon-btn-v1 icon-btn-outline-default" onClick={showOverviewView}><span className='ri-edit-line'></span></button>
                                                    <Delete
                                                        display_data_name={""}
                                                        varient={"new_button"}
                                                        fontSize="font-20"
                                                        data={{ entity_id: audit_card_data.id, name: "env_ci_detail" }}
                                                        refresh={props.refreshFn}
                                                        api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: env_cd_detail_id }, properties.api.edit_ci)}
                                                    />
                                                </div>
                                            </>
                                            :
                                            audit_card_data.status == "APPROVAL_REQUESTED_FAILED" ?
                                                <>
                                                    <div className='d-grid' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                        <div className='versioning-round draft_round'>
                                                            <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                        </div>
                                                        <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                        <div className='d-flex align-center space-between'>
                                                            <div>
                                                                <div className='versioning-chip draft-failed-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                    Approval Request has failed
                                                                </div>
                                                                <div className='d-flex align-center'>
                                                                    <div className='color-key-78 mr-5'>
                                                                        Configuration for deployment: <span className='color-value'>{audit_card_data.deployment_name}</span>
                                                                    </div>

                                                                </div>
                                                                <div>
                                                                    <span className='color-key-78 font-12 font-weight-500'>Created On:&nbsp; </span><span className='color-value font-12 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-center space-between btn-group-v1 ">
                                                        <SendForApprovalConfirmationDialog variant={"new_button"} audit_card_data={audit_card_data} sendForApproval={props.sendForApproval} />
                                                        {
                                                            <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.APPROVAL_REQUESTED_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                                <button className='icon-btn-v1 icon-btn-outline-default'><span className='ri-file-list-line'></span></button>
                                                            </Link>
                                                        }
                                                        <button className="icon-btn-v1 icon-btn-outline-default" onClick={showOverviewView}><span className='ri-edit-line'></span></button>
                                                        <Delete
                                                            display_data_name={""}
                                                            varient={"new_button"}
                                                            fontSize="font-20"
                                                            data={{ entity_id: audit_card_data.id, name: "env_ci_detail" }}
                                                            refresh={props.refreshFn}
                                                            api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: env_cd_detail_id }, properties.api.edit_ci)}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                audit_card_data.status == "APPROVAL_REQUESTED" || audit_card_data.status == "APPROVAL_REQUESTED_PENDING" ?
                                                    <>
                                                        <div className='d-grid' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                            <div className='versioning-round draft_round'>
                                                                <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                            </div>
                                                            <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                            <div className='d-flex align-center space-between'>
                                                                <div>

                                                                    <div className='versioning-chip draft-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                        Approval Requested
                                                                    </div>
                                                                    <div className='d-flex align-center'>
                                                                        <div className='color-key-78 mr-5'>
                                                                            Configuration for deployment: <span className='color-value'>{audit_card_data.deployment_name}</span>
                                                                        </div>

                                                                    </div>
                                                                    <div>
                                                                        <span className='color-key-78 font-12 font-weight-500'>Created On:&nbsp; </span><span className='color-value font-12 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-center space-between btn-group-v1 ">
                                                            <SendForApprovalConfirmationDialog variant={"new_button"} audit_card_data={audit_card_data} confirmApproval="confirmApproval" confirmForApproval={props.confirmForApproval} />
                                                            {
                                                                <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.APPROVAL_REQUESTED_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                                    <button className='icon-btn-v1 icon-btn-outline-default'><span className='ri-file-list-line'></span></button>
                                                                </Link>
                                                            }
                                                            <button className="icon-btn-v1 icon-btn-outline-default" onClick={showOverviewView}><span className='ri-edit-line'></span></button>
                                                            <Delete
                                                                display_data_name={""}
                                                                varient={"new_button"}
                                                                fontSize="font-20"
                                                                data={{ entity_id: audit_card_data.id, name: "env_ci_detail" }}
                                                                refresh={props.refreshFn}
                                                                api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: env_cd_detail_id }, properties.api.edit_ci)}
                                                            />
                                                        </div>

                                                    </>
                                                    :
                                                    audit_card_data.status == "DRAFT_FAILED" ?

                                                        <>
                                                            <div>
                                                                <p className="top-heading-rootcard d-flex align-center">
                                                                    <CheckCircleIcon style={{ fontSize: '16px', color: '#ffbf00', }} />
                                                                    <div className="font-12 ml-5">Configuration in Draft</div>
                                                                    <span className="font-10 chip chip-yellow ml-5">
                                                                        {audit_card_data.status == "DRAFT_PENDING" ? "Draft in progress" : "IN DRAFT"}
                                                                    </span>
                                                                </p>
                                                                <p className="font-12" style={{ marginLeft: '21px' }}>
                                                                    <span>Last Updated On:&nbsp;</span><span className="text-grey-84 ">{moment(audit_card_data.updated_at).fromNow()}</span>
                                                                </p>
                                                            </div>
                                                            <div className="round-btn-group d-flex align-center justify-center">
                                                                {audit_card_data.status == "DRAFT" ? <SendForApprovalConfirmationDialog audit_card_data={audit_card_data} sendForApproval={props.sendForApproval} /> : <></>}
                                                                <Tooltip title={isCdDeleteInProgress ? "Delete is in progress" : ""}>
                                                                    <button className="btn btn-round-v2" onClick={showOverviewView} disabled={isCdDeleteInProgress}><VisibilityIcon className="text-anchor-blue font-16" /></button>
                                                                </Tooltip>
                                                                <Delete
                                                                    display_data_name={""}
                                                                    varient={"OnlyIcon"}
                                                                    fontSize="font-20"
                                                                    data={{ entity_id: audit_card_data.id, name: "env_cd_detail" }}
                                                                    refresh={props.refreshFn}
                                                                    api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, cd_id: audit_card_data.id }, properties.api.cd_single_object)}
                                                                    isCdDeleteInProgress={isCdDeleteInProgress}
                                                                    setCdDeleteInProgress={setCdDeleteInProgress}
                                                                />
                                                            </div>
                                                        </> :
                                                        
                                                            <div className='d-grid width-full' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                                <div className='versioning-round inuse_round position-relative' style={{ alignSelf: 'center' }}>
                                                                    <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                                    <div className='static-round-icon-bg bg-white size-20 position-absolute'>
                                                                        <span className='sm-round-green ri-check-line font-14 color-white d-flex align-center space-between font-weight-bold'></span>
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                                <div className='d-flex align-center space-between width-full'>
                                                                    <div>
                                                                        {
                                                                            audit_card_data.status == "APPROVED_PENDING" &&
                                                                            <>
                                                                                <div className='versioning-chip draft-chip mb-5 text-transform-uppercase'>
                                                                                    APPROVAL IN-PROGRESS
                                                                                </div>
                                                                                <div className='d-flex align-center'>
                                                                                    <div className='color-key-78 mr-5'>
                                                                                        Configuration for deployment: <span className='color-value'>{audit_card_data.deployment_name}</span>
                                                                                    </div>

                                                                                </div>
                                                                            </>
                                                                        }

                                                                        {
                                                                            audit_card_data.status == "APPROVED" &&
                                                                            <div className='d-flex align-center'>
                                                                                <div className='color-key-78 mr-5'>
                                                                                    Stable Configuration for deployment: <span className='color-value'>{audit_card_data.deployment_name?.length>35? 
                                                                                    
                                                                                        <Tooltip title={audit_card_data.deployment_name}>
                                                                                            <span>{audit_card_data.deployment_name.substring(0,35)+"..." }</span>
                                                                                        </Tooltip>
                                                                                    
                                                                    
                                                                                    
                                                                                    :audit_card_data.deployment_name }</span>
                                                                                </div>
                                                                                <div className='versioning-chip in-use-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                                    In Use
                                                                                </div>
                                                                            </div>

                                                                        }
                                                                        <div>
                                                                            <span className='color-key-78 font-14 font-weight-500'>Created On:&nbsp;</span><span className='color-value font-14 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="round-btn-group d-flex align-center justify-center">
                                                                        <button className='btn-sq-icon-primary mr-5' onClick={showOverviewView} >
                                                                            <span className='ri-edit-line vertical-align-super'></span>
                                                                        </button>
                                                                        {
                                                                            <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.APPROVED_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                                                <button className='icon-btn-v1 icon-btn-outline-default mr-5'><span className='ri-file-list-line'></span></button>
                                                                            </Link>
                                                                        }
                                                                        <Delete
                                                                            display_data_name={""}
                                                                            varient={"new_button"}
                                                                            fontSize="font-20"
                                                                            data={{ entity_id: audit_card_data.id, name: "env_cd_detail" }}
                                                                            refresh={props.refreshFn}
                                                                            api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, id: env_cd_detail_id }, properties.api.edit_cd)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='sidecars-div'>
                {
                    sidecars_configured && sidecars_data && sidecars_data.length > 0 &&
                    sidecars_data.map(item => (
                        <div className={classes.sidecar_container + " sidecar-container"}>
                            <div className='side-car-border-lines'></div>
                            <div className={classes.rootCardParent + " " + 'sideCardCard'}>
                                <div className={audit_card_data.status == "DRAFT" || audit_card_data.status == "DRAFT_PENDING" ? classes.rootCard + " " + "draft" : audit_card_data.status == "APPROVAL_REQUESTED" || audit_card_data.status == "APPROVAL_REQUESTED_PENDING" ? classes.rootCard + " " + "in-approval" : classes.rootCard + " " + "in-use"}>
                                    <div className="rootcard-header">
                                        <div className="d-flex align-center space-between">
                                            
                                                <div>
                                                    <p className="top-heading-rootcard d-flex align-center">
                                                        <CheckCircleIcon style={{ fontSize: '16px', color: '#69df9b' }} />
                                                        <span className="font-12 ml-5" >{item.container_type && item.container_type == "INIT" ? "Init Container" : "Sidecar Container"}  Stable Configuration </span>
                                                        <span className="font-10 chip chip-green ml-5">
                                                            IN USE
                                                        </span>
                                                    </p>
                                                    <p className="font-12" style={{ marginLeft: '21px' }}>
                                                        <span>Created On:&nbsp;</span><span className="text-grey-84 ">{moment(item.updated_at).fromNow()}</span>
                                                    </p>

                                                </div>
                                                <div className="round-btn-group d-flex align-center justify-center">
                                                    <button className="btn btn-round-v2" onClick={() => { showSideCarsOverview(item.id) }}><VisibilityIcon className="text-anchor-blue font-16" /></button>
                                                    <Delete
                                                        display_data_name={""}
                                                        varient={"OnlyIcon"}
                                                        fontSize="font-20"
                                                        data={{ entity_id: item.id, name: "env_cd_init_container" }}
                                                        refresh={props.refreshFn}
                                                        api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, cd_id: env_cd_detail_id, side_car_id: item.id }, properties.api.single_side_car)}
                                                    />
                                                </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className='div-border-left'></div>
                            </div>
                        </div>
                    ))

                }
            </div>
            {
                !ci_configured && props.approvedCdData &&
                <span className={classes.rootCardParent + " sideCardCard d-block"}>
                    <div className={classes.rootCard + " " + "draft"}>
                        <div className="rootcard-header">
                            <div className="d-flex align-center space-between">
                                
                                    <div>
                                        <p className="top-heading-rootcard d-flex align-center">
                                            <CheckCircleIcon style={{ fontSize: '16px', color: '#656565' }} />
                                            <span className="font-12 ml-5">Sidecars Or Init Container</span>
                                        </p>
                                        <p className="font-12" style={{ marginLeft: '21px' }}>
                                            <span className="text-grey-84 ">Not Configured</span>
                                        </p>
                                    </div>
                                    <div className="round-btn-group d-flex align-center justify-center">
                                        <Link to={{
                                            pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id + "/cd_id/" + env_cd_detail_id + "/container/add",
                                            state: {
                                                service_env_details: service_env_details,
                                                clone_env_id: clone_env_id,
                                                env_cd_detail_id: env_cd_detail_id,
                                            }
                                        }}
                                        >
                                            <button className='btn btn-submit'>Configure</button>
                                        </Link>
                                    </div>
                                
                            </div>
                        </div>
                    </div>

                </span>
            }
            {
                ci_configured &&
                <div className={classes.rootCardParent}>
                    <div className={audit_card_data.status == "DRAFT" ? classes.rootCard + " " + "draft" : audit_card_data.status == "DRAFT_PENDING" ? classes.rootCard + " " + "draft_pending" : audit_card_data.status == "APPROVAL_REQUESTED" ? classes.rootCard + " " + "in-approval" : classes.rootCard + " " + "in-use"}>
                        <div className="rootcard-header">
                            <div className="d-flex align-center space-between">
                                {
                                    audit_card_data.status == "DRAFT" || audit_card_data.status == "DRAFT_PENDING" ?
                                        <>
                                            <div className='d-grid' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                <div className='versioning-round draft_round'>
                                                    <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                </div>
                                                <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                <div className='d-flex align-center space-between'>
                                                    <div>
                                                        {
                                                            audit_card_data.status == "DRAFT_PENDING" &&
                                                            <div className='versioning-chip draft-pending-chip mb-5 text-transform-uppercase'>
                                                                Task to create draft branch is in queue
                                                            </div>
                                                        }
                                                        {
                                                            audit_card_data.status == "DRAFT" &&
                                                            <div className='versioning-chip draft-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                Draft Created
                                                            </div>
                                                        }
                                                        <div>
                                                            <span className='color-key-78 font-12 font-weight-500'>Created On:&nbsp; </span><span className='color-value font-12 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="d-flex align-center space-between btn-group-v1 ">
                                                {audit_card_data.status == "DRAFT" || audit_card_data.status == "DRAFT_PENDING" ? <SendForApprovalConfirmationDialog variant={"new_button"} audit_card_data={audit_card_data} sendForApproval={props.sendForApproval} /> : <></>}
                                                {
                                                    <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.DRAFT_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                        <button className='icon-btn-v1 icon-btn-outline-default'><span className='ri-file-list-line'></span></button>
                                                    </Link>
                                                }
                                                <button className="icon-btn-v1 icon-btn-outline-default" onClick={() => showOverviewView(audit_card_data.id)}><span className='ri-edit-line'></span></button>
                                                <Delete
                                                    display_data_name={""}
                                                    varient={"new_button"}
                                                    fontSize="font-20"
                                                    data={{ entity_id: audit_card_data.id, name: "env_ci_detail" }}
                                                    refresh={props.refreshFn}
                                                    api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: env_cd_detail_id }, properties.api.edit_ci)}
                                                />
                                            </div>
                                        </>
                                        :
                                        audit_card_data.status == "DRAFT_FAILED" ?
                                            <>
                                                <div className='d-grid' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                    <div className='versioning-round draft_round'>
                                                        <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                    </div>
                                                    <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                    <div className='d-flex align-center space-between'>
                                                        <div>

                                                            <div className='versioning-chip draft-failed-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                DRAFT CREATION HAS FAILED
                                                            </div>
                                                            <div>
                                                                <span className='color-key-78 font-12 font-weight-500'>Created On:&nbsp; </span><span className='color-value font-12 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="d-flex align-center space-between btn-group-v1 ">
                                                    <SendForApprovalConfirmationDialog variant={"new_button"} audit_card_data={audit_card_data} sendForApproval={props.sendForApproval} />
                                                    {
                                                        <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.DRAFT_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                            <button className='icon-btn-v1 icon-btn-outline-default'><span className='ri-file-list-line'></span></button>
                                                        </Link>
                                                    }
                                                    <button className="icon-btn-v1 icon-btn-outline-default" onClick={() => showOverviewView(audit_card_data.id)}><span className='ri-edit-line'></span></button>
                                                    <Delete
                                                        display_data_name={""}
                                                        varient={"new_button"}
                                                        fontSize="font-20"
                                                        data={{ entity_id: audit_card_data.id, name: "env_ci_detail" }}
                                                        refresh={props.refreshFn}
                                                        api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: env_cd_detail_id }, properties.api.edit_ci)}
                                                    />
                                                </div>
                                            </>
                                            :
                                            audit_card_data.status == "APPROVAL_REQUESTED_FAILED" ?
                                                <>
                                                    <div className='d-grid' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                        <div className='versioning-round draft_round'>
                                                            <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                        </div>
                                                        <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                        <div className='d-flex align-center space-between'>
                                                            <div>
                                                                <div className='versioning-chip draft-failed-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                    Approval Request has failed
                                                                </div>
                                                                <div>
                                                                    <span className='color-key-78 font-12 font-weight-500'>Created On:&nbsp; </span><span className='color-value font-12 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-center space-between btn-group-v1 ">
                                                        <SendForApprovalConfirmationDialog variant={"new_button"} audit_card_data={audit_card_data} sendForApproval={props.sendForApproval} />
                                                        {
                                                            <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.APPROVAL_REQUESTED_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                                <button className='icon-btn-v1 icon-btn-outline-default'><span className='ri-file-list-line'></span></button>
                                                            </Link>
                                                        }
                                                        <button className="icon-btn-v1 icon-btn-outline-default" onClick={() => showOverviewView(audit_card_data.id)}><span className='ri-edit-line'></span></button>
                                                        <Delete
                                                            display_data_name={""}
                                                            varient={"new_button"}
                                                            fontSize="font-20"
                                                            data={{ entity_id: audit_card_data.id, name: "env_ci_detail" }}
                                                            refresh={props.refreshFn}
                                                            api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: env_cd_detail_id }, properties.api.edit_ci)}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                audit_card_data.status == "APPROVAL_REQUESTED" || audit_card_data.status == "APPROVAL_REQUESTED_PENDING" ?
                                                    <>
                                                        <div className='d-grid' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                            <div className='versioning-round draft_round'>
                                                                <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                            </div>
                                                            <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                            <div className='d-flex align-center space-between'>
                                                                <div>

                                                                    <div className='versioning-chip draft-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                        Approval Requested
                                                                    </div>
                                                                    <div>
                                                                        <span className='color-key-78 font-12 font-weight-500'>Created On:&nbsp; </span><span className='color-value font-12 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-center space-between btn-group-v1 ">
                                                            <SendForApprovalConfirmationDialog variant={"new_button"} audit_card_data={audit_card_data} confirmApproval="confirmApproval" confirmForApproval={props.confirmForApproval} />
                                                            {
                                                                <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.APPROVAL_REQUESTED_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                                    <button className='icon-btn-v1 icon-btn-outline-default'><span className='ri-file-list-line'></span></button>
                                                                </Link>
                                                            }
                                                            <button className="icon-btn-v1 icon-btn-outline-default" onClick={() => showOverviewView(audit_card_data.id)}><span className='ri-edit-line'></span></button>
                                                            <Delete
                                                                display_data_name={""}
                                                                varient={"new_button"}
                                                                fontSize="font-20"
                                                                data={{ entity_id: audit_card_data.id, name: "env_ci_detail" }}
                                                                refresh={props.refreshFn}
                                                                api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: env_cd_detail_id }, properties.api.edit_ci)}
                                                            />
                                                        </div>

                                                    </>
                                                    :
                                                    audit_card_data.status == "APPROVED_FAILED" ?
                                                        
                                                            <div className='d-grid width-full' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                                <div className='versioning-round draft_round'>
                                                                    <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                                </div>
                                                                <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                                <div className='d-flex align-center space-between width-full'>
                                                                    <div>
                                                                        {
                                                                            audit_card_data.status == "APPROVED_FAILED" &&
                                                                            <div className='versioning-chip  draft-failed-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                                APPROVAL FAILED
                                                                            </div>
                                                                        }

                                                                        {
                                                                            audit_card_data.status == "APPROVED" &&
                                                                            <div className='d-flex align-center'>
                                                                                <div className='color-key-78 mr-5'>
                                                                                    {multiple_build_enabled ? "Main Build" : "Stable Configuration"}
                                                                                </div>
                                                                                <div className='versioning-chip in-use-chip mb-5 text-transform-uppercase d-inline-block'>
                                                                                    In Use
                                                                                </div>
                                                                            </div>

                                                                        }
                                                                        <div>
                                                                            <span className='color-key-78 font-14 font-weight-500'>Created On:&nbsp;</span><span className='color-value font-14 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="round-btn-group d-flex align-center justify-center">
                                                                        <SendForApprovalConfirmationDialog variant={"new_button"} audit_card_data={audit_card_data} confirmApproval="confirmApproval" confirmForApproval={props.confirmForApproval} />
                                                                        {
                                                                            <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.APPROVAL_REQUESTED_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                                                <button className='icon-btn-v1 icon-btn-outline-default mr-5'><span className='ri-file-list-line'></span></button>
                                                                            </Link>
                                                                        }
                                                                        <button className='btn-sq-icon-primary mr-5' onClick={() => showOverviewView(audit_card_data.id)} >
                                                                            <span className='ri-edit-line vertical-align-super'></span>
                                                                        </button>

                                                                        <Delete
                                                                            display_data_name={""}
                                                                            varient={"new_button"}
                                                                            fontSize="font-20"
                                                                            data={{ entity_id: audit_card_data.id, name: "env_ci_detail" }}
                                                                            refresh={props.refreshFn}
                                                                            api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: env_cd_detail_id }, properties.api.edit_ci)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                        :
                                                        
                                                            <div className='d-grid width-full' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                                <div className='versioning-round inuse_round position-relative' style={{ alignSelf: 'center' }}>
                                                                    <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                                    <div className='static-round-icon-bg bg-white size-20 position-absolute'>
                                                                        <span className='sm-round-green ri-check-line font-14 color-white d-flex align-center space-between font-weight-bold'></span>
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                                <div className='d-flex align-center space-between width-full'>
                                                                    <div>
                                                                        {
                                                                            audit_card_data.status == "APPROVED_PENDING" &&
                                                                            <div className='versioning-chip draft-chip mb-5 text-transform-uppercase'>
                                                                                APPROVAL IN-PROGRESS
                                                                            </div>
                                                                        }

                                                                        {
                                                                            audit_card_data.status == "APPROVED" &&
                                                                            <div className='d-flex align-center'>
                                                                                <div className='build-type mr-5'>
                                                                                    {multiple_build_enabled ? "Main Build" : "Stable Configuration"}
                                                                                </div>
                                                                                <div className='versioning-chip in-use-chip mb-5 ml-5 text-transform-uppercase d-inline-block'>
                                                                                    In Use
                                                                                </div>
                                                                            </div>

                                                                        }
                                                                        <div>
                                                                            <span className='color-key-78 font-14 font-weight-500'>Created On:&nbsp;</span><span className='color-value font-14 font-weight-500'>{moment(audit_card_data.created_at).fromNow()}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="round-btn-group d-flex align-center justify-center">
                                                                        <button className='btn-sq-icon-primary mr-5' onClick={() => showOverviewView(audit_card_data.id)} >
                                                                            {/* <span className='ri-edit-line vertical-align-super'></span> */}
                                                                            <VisibilityIcon className="btn-sq-icon-primary-icon font-16" style={{color : "#124D9B"}} />
                                                                        </button>
                                                                        {
                                                                            <Link target="_blank" to={"/logs?global_task_id=" + audit_card_data?.versioning_meta_data?.APPROVED_PENDING + "&service_name=" + extraProps?.service_name + "&service_env=" + extraProps?.project_env_name}>
                                                                                <button className='icon-btn-v1 icon-btn-outline-default mr-5'><span className='ri-file-list-line'></span></button>
                                                                            </Link>
                                                                        }
                                                                        <Delete
                                                                            display_data_name={""}
                                                                            varient={"new_button"}
                                                                            fontSize="font-20"
                                                                            data={{ entity_id: audit_card_data.id, name: "env_ci_detail" }}
                                                                            refresh={props.refreshFn}
                                                                            api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: env_cd_detail_id }, properties.api.edit_ci)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                multiple_build_enabled &&
                <div className='sidecars-div'>
                    {
                        child_build_configured && child_build_data && child_build_data.length > 0 &&
                        child_build_data.map(item => (
                            <div className={classes.child_build_container + " sidecar-container"}>
                                <div className='side-car-border-lines'></div>
                                <div className={classes.rootCardParent + " " + 'sideCardCard'}>
                                    <div className={child_build_data.status == "DRAFT" || child_build_data.status == "DRAFT_PENDING" ? classes.rootCard + " " + "draft" : child_build_data.status == "APPROVAL_REQUESTED" || child_build_data.status == "APPROVAL_REQUESTED_PENDING" ? classes.rootCard + " " + "in-approval" : classes.rootCard + " " + "in-use"}>
                                        <div className="rootcard-header">
                                            <div className="d-flex align-center space-between">
                                                <>
                                                    <div className='d-grid width-full' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                                        <div className='versioning-round configured_round position-relative' style={{ alignSelf: 'center' }}>
                                                            <span className='ri-code-s-slash-line font-24 color-white'></span>
                                                            {/* <div className='static-round-icon-bg bg-white size-20 position-absolute'>
                                                                <InfoIcon style={{ color: "#FEA112", fontSize: "large" }} />
                                                            </div> */}
                                                        </div>
                                                        <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                                        <div>
                                                            <div className='d-flex align-center'>
                                                                <p className="top-heading-rootcard d-flex align-center">
                                                                    <span className="build-type">Secondary Build</span>
                                                                </p>
                                                                <div className='versioning-chip in-use-chip mb-5 ml-5 text-transform-uppercase d-inline-block'>
                                                                    In Use
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <InfoOutlinedIcon style={{ color: "#797979", fontSize: "large" }} />
                                                                <div style={{ width: "80%" }}>
                                                                    <p className="font-12 ml-5">
                                                                        <span className="text-grey-84 ">The secondary build will always happen along with the main build. The image name will be same with additional suffix of the secondary build name.</span>
                                                                    </p>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="round-btn-group d-flex align-center justify-center">
                                                        {/* <button className='btn-sq-icon-primary mr-5' onClick={showOverviewView} >
                                                            <span className='ri-edit-line vertical-align-super'></span>
                                                        </button> */}
                                                        <button className="icon-btn-v1 icon-btn-outline-default" style={{marginRight : "5px"}} onClick={() => { showOverviewView(item.id, true) }}><VisibilityIcon className="text-anchor-blue font-16" /></button>
                                                        <Delete
                                                            display_data_name={""}
                                                            varient={"new_button"}
                                                            fontSize="font-20"
                                                            data={{ entity_id: item.id, name: "env_ci_detail" }}
                                                            refresh={props.refreshFn}
                                                            api_link={GenerateURL({ service_id: service_id, component_env_id: component_env_id, ci_id: item.id }, properties.api.edit_ci)}
                                                        />
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='div-border-left'></div>
                                </div>
                            </div>
                        ))

                    }
                </div>

            }
            {
                multiple_build_enabled && ci_configured && props.approvedCiData &&
                <span className={classes.rootCardParent + "d-block"}>
                    <div className={classes.rootCard + " " + "draft"} style={{ margin: "auto 15px", border: "1px solid #FEA112" }}>
                        <div className="rootcard-header">
                            <div className="d-flex align-center space-between">
                                <>
                                    <div className='d-grid width-full' style={{ gridTemplateColumns: '48px 31px 1fr' }}>
                                        <div className='versioning-round not_configured_round position-relative' style={{ alignSelf: 'center' }}>
                                            <span className='ri-code-s-slash-line font-24 color-white'></span>
                                            <div className='static-round-icon-bg bg-white size-20 position-absolute'>
                                                <InfoIcon style={{ color: "#FEA112", fontSize: "large" }} />
                                                {/* <span className='sm-round-green ri-check-line font-14 color-white d-flex align-center space-between font-weight-bold'></span> */}
                                            </div>
                                        </div>
                                        <div style={{ width: '1px', margin: "0px 15px", backgroundColor: '#0000001F' }}></div>
                                        <div>
                                            <div className='d-flex align-center'>
                                                <p className="top-heading-rootcard d-flex align-center">
                                                    <span className="build-type">Secondary Build</span>
                                                </p>
                                                <div className='secondary-build-chip not-configured-chip mb-5 ml-10 text-transform-uppercase d-inline-block'>
                                                    Not Configured
                                                </div>
                                            </div>
                                            <div className='d-flex'>
                                                <InfoOutlinedIcon style={{ color: "#797979", fontSize: "large" }} />
                                                <div style={{width : "80%"}}>
                                                    <p className="font-12 ml-5">
                                                        <span className="text-grey-84 ">The secondary build will always happen along with the main build. The image name will be same with additional suffix of the secondary build name.</span>
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="round-btn-group d-flex align-center justify-center">
                                        <Link to={{
                                            pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id + "/ci/add",
                                            state: {
                                                service_env_details: service_env_details,
                                                clone_env_id: clone_env_id,
                                                env_cd_detail_id: env_cd_detail_id,
                                                child_build: true,
                                                audit_card_data: audit_card_data
                                            }
                                        }}
                                        >
                                            <button className='btn btn-submit' style={{backgroundColor : "#0086FF"}}>Configure</button>
                                        </Link>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>

                </span>
            }
        </>
    )
}

AuditCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default AuditCard;
const useStyles = makeStyles(theme => ({
    sidecar_container: {
        position: 'relative',
        '& .side-car-border-lines': {
            position: 'absolute',
            width: '2px',
            height: '100px',
            left: '50px',
            top: '-15px',
            backgroundImage: 'url(/images/border-vertical.png)',
            backgroundSize: '2px',
            backgroundRepeatX: 'no-repeat',
            backgroundPosition: 'center'
            // border: '1px dashed #718BAD',

        },
        '&:last-child': {
            '& .side-car-border-lines': {
                height: '60px!important'
            }

        }
    },
    child_build_container: {
        position: 'relative',
        '& .side-car-border-lines': {
            position: 'absolute',
            width: '2px',
            height: '130px',
            left: '50px',
            top: '-15px',
            backgroundImage: 'url(/images/border-vertical.png)',
            backgroundSize: '2px',
            backgroundRepeatX: 'no-repeat',
            backgroundPosition: 'center'
            // border: '1px dashed #718BAD',

        },
        '&:last-child': {
            '& .side-car-border-lines': {
                height: '60px!important'
            }

        }
    },
    rootCardParent: {
        // width: '300px',
        margin: 'auto 15px',
        marginBottom: '15px',
        marginTop: '15px',
        '&.sideCardCard': {
            marginLeft: '100px',
            position: 'relative',
            '& .div-border-left': {
                backgroundImage: 'url(/images/border-horizontal.png)',
                backgroundSize: '35px 2px',
                backgroundRepeatY: 'no-repeat',
                backgroundPosition: 'center',
                width: '50px',
                height: '2px',
                position: 'absolute',
                display: 'block',
                left: '-50px',
                top: '45px'
            },

        },
        '& .border-draft': {
            borderBottom: '1px solid rgba(230,175,78,0.61)',
            borderLeft: '1px solid rgba(230,175,78,0.61)',
            borderRight: '1px solid rgba(230,175,78,0.61)',
        },
        '& .border-approval-pending': {
            borderBottom: '1px solid rgba(225,225,225,0.61)',
            borderLeft: '1px solid rgba(225,225,225,0.61)',
            borderRight: '1px solid rgba(225,225,225,0.61)',
        },
        '& .border-success': {
            borderBottom: '1px solid #69e09c',
            borderLeft: '1px solid #69e09c',
            borderRight: '1px solid #69e09c',
        },
        '& .btn-round-v2': {
            borderRadius: '50%',
            backgroundColor: '#fff',
            height: '35px',
            width: '35px',
            filter: 'drop-shadow(0.877px 1.798px 4px rgba(0,0,0,0.06))',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .rootcard-footer': {
            padding: '15px',
            borderTop: '0px solid #dedede',
            backgroundColor: '#ffff'
        },
        "& .not-configured": {
            backgroundColor: '#fff',
            border: '1px solid #FEA112;'
        },
    },
    rootCard: {
        border: '1px solid transparent',
        borderRadius: '8px',
        padding: '20px',
        '& .rootcard-body': {
            padding: '5px 10px'
        },

        '&.in-approval': {
            backgroundColor: '#fff',
            border: '1px solid  #10489166'

        },
        '&.draft': {
            backgroundColor: '#fff',
            border: '1px solid  #10489166'
        },
        "&.draft_pending": {
            backgroundColor: '#fff',
            border: '1px solid  #10489166'
        },
        "& .not-configured": {
            backgroundColor: '#fff',
            border: '1px solid  #10489166'
        },

        '&.in-use': {
            backgroundColor: '#fff',
            border: '1px solid  #10489166'
        },
        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
        '& .text-grey-84': {
            color: '#848484'
        },
        '& .text-success': {
            color: '#69df9b'
        },
        '& .pd-custom-5y': {
            fontSize: '12px',
            padding: '5px 0px'
        },
        '& .input-component': {
            marginBottom: '0px'
        }
    }
}))
