import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { ContentBox } from "../Index";
import { Grid } from "@material-ui/core";
import { Input } from "../../../components/genericComponents/Input";
import properties from "../../../properties/properties";
import GenerateURL, { GenerateSearchURL } from "../../../util/APIUrlProvider";
import InvokeApi, { PostData } from "../../../util/apiInvoker";
import { Link, Redirect } from "react-router-dom";
import {
    ValidateDataSet,
    VALIDATION_TYPE_REQUIRED,
} from "../../../util/Validator";
import {
    getCommonFunctions,
} from "../add/ci_flow/SourceDetails";
import { makeStyles } from "@material-ui/styles";
import { styled } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Loading } from "../../utils/Loading";
import { ErrorComponent } from "../../utils/Error";

import AddIcon from "@material-ui/icons/Add";
import AlertStrip from "../../../components/AlertStrips";
import { getListableArray } from "../../../util/util";
import InputWithDropdownVersions from "../../../components/genericComponents/InputWithDropdownVersions";
import InputWithDropdownJobTemplate from "../../../components/genericComponents/InputWithDropdown";


const EnvAndUserGroup = (props) => {
    const { application_id, component_id } = props.match.params;
    const component_id_number = Number(component_id);
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const [canRedirect, setRedirect] = useState(false);
    const [serviceId, setServiceId] = useState("1");

    const edit = props.location
        ? props.location.state
            ? props.location.state.edit
            : false
        : false;
    const env_id = props.location
        ? props.location.state
            ? props.location.state.env_id
            : null
        : null;

    const [state, setState] = useState({
        data: {
            roles: [],
            manual_build: "false",
            manual_deploy: "false",
            hyperbuild: 'false',
            job_template_version: 'v2'
        },
        error_msg: null,
        data_loading: false,
        canRedirect: false,
        selected_job_template_name: null,
        selected_job_template_version: null,
        selected_job_template_taborder: null,
        selected_job_template_version_taborder: null,
        selected_job_template_type: null,
        job_template_versions: [],
        option_list: [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
        ],
        template_option: [
            { label: "Job Template Version 2", value: 'v2' },
            { label: "Job Template Version 3", value: 'v3' },
        ],
        error: {},
        validations: {
            env_id: [VALIDATION_TYPE_REQUIRED],
            // roles: [VALIDATION_TYPE_REQUIRED],
            // image_name: [VALIDATION_TYPE_REQUIRED],
            // registry_id: [VALIDATION_TYPE_REQUIRED]
        },
        job_template_list: [],
        env_list: [],
        fetching_env_list: true,
        roles_list: [],
        fileterdByVersionTemplates: []
    });
    
    useEffect(() => {
        if(!edit){
            if (state.job_template_list?.length > 0) {
                let defaultTempalteId = state.job_template_list?.find(jobTemplate => {
                    if (jobTemplate.default) {
                        return jobTemplate
                    }
                })?.id
    
                setState(prevState => ({
                    ...prevState,
                    data: { ...prevState.data, project_job_template_id: defaultTempalteId }
                }))
            }
        }
    }, [state.job_template_list])

    useEffect(() => {
        console.log(state.job_template_list,"sjhbvgsf")
        if (state?.job_template_list?.length > 0 && state.data.job_template_version) {
            if (state.data.job_template_version === 'v2') {
                const v2Templates = state.job_template_list.filter(tempalte => tempalte.version === 'v2')
                setState(prevState => ({
                    ...prevState,
                    fileterdByVersionTemplates: v2Templates
                }))
            }
            else if (state.data.job_template_version === 'v3') {
                const v3Templates = state.job_template_list.filter(tempalte => tempalte.version === 'v3')

                setState(prevState => ({
                    ...prevState,
                    fileterdByVersionTemplates: v3Templates
                }))
            }
        }

    }, [state.job_template_list, state.data.job_template_version])


    useEffect(() => {

        if (env_id !== undefined && state.job_template_list !== undefined && state.data?.project_job_template_id != undefined) {
            const version = state.job_template_list?.find(template => template.id === state?.data?.project_job_template_id)?.version
            if (version) {
                setState(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        job_template_version: version
                    }
                }))
            }
        }
    }, [env_id, state.job_template_list, state.selected_job_template_taborder])

    useEffect(() => {
        if (component_id) {
            getService();
        }

        fetchClusterInfo();
        fetchAssociatedVMInfo()
    }, []);

    function fetchAssociatedVMInfo() {
        var requestInfo = {
            endPoint: GenerateURL({ project_id: application_id }, properties.api.associated_vm_data),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handlefetchAssociatedVMSuccessApiHit, handlefetchAssociatedVMFailApiHit);
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
    }
    const handlefetchAssociatedVMSuccessApiHit = (response) => {

        setState(new_state => ({
            ...new_state,
            data_loading: false,
            response_data: response,
        }));
    }

    const handlefetchAssociatedVMFailApiHit = (error) => {
        setState(new_state => ({
            ...new_state,
            data_loading: false,
        }));
    }
    function fetchClusterInfo() {
        var requestInfo = {
            endPoint: GenerateURL({ project_id: application_id }, properties.api.env_form_data),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessApiHit, handleFailApiHit);
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
    }
    const handleSuccessApiHit = (response) => {

        setState(new_state => ({
            ...new_state,
            data_loading: false,
            response_data: response,
        }));
    }

    const handleFailApiHit = (error) => {
        setState(new_state => ({
            ...new_state,
            data_loading: false,
        }));
    }

    function getService() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: component_id }, properties.api.service_basic_details_get_url),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        };
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
        InvokeApi(requestInfo, handleGetServiceSuccess, handleGetServiceFailed);
    }
    const handleGetServiceSuccess = (data) => {
        fetchSystemSettingsData()
        setState((new_state) => ({
            ...new_state,
            data_loading: false,
            service_name: data && data.name
        }));
    }
    const handleGetServiceFailed = (data) => {
        setState((new_state) => ({
            ...new_state,
            data_loading: false,
            service_name: null
        }));
    }
    const commonFunctions = getCommonFunctions(state, setState, inherits);

    function validateAndSave() {
        console.log(state.data, "dsjbjsdbchsbch")
        var result = ValidateDataSet({...state.data,selected_job_template_taborder:state.selected_job_template_taborder}, {...state.validations,selected_job_template_taborder: [VALIDATION_TYPE_REQUIRED]});

        var post_url = GenerateURL(
            { service_id: component_id_number },
            properties.api.env_data_post_url
        );
        if (env_id) {
            post_url = GenerateURL(
                { service_id: component_id_number, env_id: Number(env_id) },
                properties.api.env_data_update_url
            );
        }
        if (result.valid) {
            // var env_role_Access = getAssignedAccess(
            //     state.data.roles,
            //     state.roles_list
            // );
            var env_role_Access = []
            var post_data = {
                component_id: component_id_number,
                project_env_id: state.data.env_id,
                image_name: state.data&& state.data.image_name?state.data.image_name : null,
                env_role_access: env_role_Access,
                manual_build: state.data.manual_build == "true" ? true : false,
                manual_deploy: state.data.manual_deploy == "true" ? true : false,
                hyperbuild: state.data.hyperbuild == "true" ? true : false,
                project_job_template_id: state.selected_job_template_taborder
                    ? state.selected_job_template_taborder
                    : null,
                template_version: state.selected_job_template_version ? state.selected_job_template_version: null
            };
            setState((new_state) => ({
                ...new_state,
                data_loading: true,
                error_msg: null,
            }));
            PostData(post_url, post_data, postSuccess, postFail);
        } else {
            setState((new_state) => ({
                ...new_state,
                error: result.error,
            }));
        }
    }

    function postSuccess(response) {
        setServiceId(response.component_id);
        setState((new_state) => ({
            ...new_state,
            new_env_id: response.id,
            data_loading: false,
            error_msg: null,
            canRedirect: true,
        }));
    }

    function postFail(response) {
        let error_msg = showErrorHandlerUpdated(response)
        setState((new_state) => ({
            ...new_state,
            data_loading: false,
            error_msg: error_msg
        }));
    }


    const handleFailedApiHit = (error) => {
        var error_msg = error.toString();
        setState((new_state) => ({
            ...new_state,
            data_loading: false,
            error_msg: "Unable to fetch data due to following reasons: " + error_msg,
        }));
    };
    const handleSuccessRoleApiHit = (response) => {
        var ui_roles = mapRoles(response);
        setState((new_state) => ({
            ...new_state,
            data_loading: false,
            roles_list: ui_roles,
        }));
    };


    function getProjectRoles() {
        var requestInfo = {
            endPoint: GenerateURL(
                { application_id: application_id },
                properties.api.application_role_detail_url
            ),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        InvokeApi(requestInfo, handleSuccessRoleApiHit, handleFailedApiHit);
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
    }

    function getEnvList() {
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
            fetching_env_list: true,
        }));
        var url_temp = GenerateURL(
            { application_id: application_id },
            properties.api.env_listing
        );
        var requestInfo = {
            endPoint: GenerateSearchURL({ all: true }, url_temp),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        InvokeApi(requestInfo, fetchSuccessEnvListing, fetchFailEnvListing);

    }

    function fetchSuccessEnvListing(response) {
        getProjectRoles();
        fetchJobTemplatesList();
        const EnvNames = [];
        response.results.forEach((element) => {

            EnvNames.push({
                label: element.name,
                id: element.id,
                manual_build: element.manual_build,
                manual_deploy: element.manual_deploy,
                hyperbuild: element.hyperbuild,
                type: element.environment_master && element.environment_master.name ? element.environment_master.name : null,
                cluster_available: element.cluster_id ? element.cluster_id : null,
                vm_group_available: element.vm_group_id ? element.vm_group_id : null,
            });
        });
        if (env_id) {
            getComponentEnvData();
        }
        setState((new_state) => ({
            ...new_state,
            env_list: EnvNames,
            env_data: response.results,
            data_loading: false,
            fetching_env_list: false,
        }));
    }

    function fetchFailEnvListing(error) {
        setState((new_state) => ({
            ...new_state,
            error_msg: error.toString(),
            data_loading: false,
            fetching_env_list: false,
        }));
    }

    useEffect(() => {
        getEnvList();

    }, []);

    useEffect(() => {
        if (state.data.env_id) {
            getNamespaceId()
        }
    }, [state.data.env_id])

    console.log(state, "svbgshvchjsdbvhjbsdvj")
    useEffect(() => {
        if (state.selected_namespace_id) {
            getRegistryList()
        }
    }, [state.selected_namespace_id])

    useEffect(() => {
        if (state.available_registries) {
            getRegistryListOptions()
        }
    }, [state.available_registries])


    function getRegistryList() {
        console.log(state.response_data, state.selected_namespace_id, "zcvghdvcscvsdhvc")
        var available_registries = []
        let selected_data = state.response_data && state.response_data.length > 0 && state.response_data.find((item) => {
            if (item.project_cluster_namespace && item.project_cluster_namespace.length > 0) {
                return item.project_cluster_namespace.find((single_item) =>
                    single_item.namespace_id == state.selected_namespace_id
                )
            }
        })
        let selected_project_cluster_namespace = selected_data && selected_data.project_cluster_namespace
        console.log(selected_project_cluster_namespace, "sbvhjbhjvbfvjfd")
        // state.response_data && state.response_data[0] && state.response_data[0].project_cluster_namespace.forEach((item) => {
        //     if (item.namespace_id == state.selected_namespace_id) {
        //         available_registries = item.registries
        //     }
        // })
        selected_project_cluster_namespace && selected_project_cluster_namespace.forEach((item) => {
            if (item.namespace_id == state.selected_namespace_id) {
                available_registries = item.registries
            }
        })
        setState((new_state) => ({
            ...new_state,
            available_registries: available_registries ? available_registries : []
        }))
    }

    function getRegistryListOptions() {
        var registry_list = []
        state.available_registries.forEach((item) => {
            if (state.selected_registry_id && state.selected_registry_id.includes(item.id)) {
                registry_list.push(item)
            }
        })
        console.log(registry_list, "sbhvcscdfgvd")
        setState((new_state) => ({
            ...new_state,
            registry_option_list: getListableArray(registry_list),
            data: {
                ...new_state.data,
                registry_id: registry_list.length == 1 ? registry_list[0].id : null,
            }
        }))
    }

    function getComponentEnvData() {
        var url_temp = GenerateURL(
            { service_id: component_id_number, env_id: Number(env_id) },
            properties.api.env_data_update_url
        );
        var requestInfo = {
            endPoint: url_temp,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        InvokeApi(requestInfo, getEnvDataSuccess, getEnvDataFail);
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
    }

    function getEnvDataSuccess(response) {
        console.log(response.template_version, "sdbchsbjsbs")
        if(response.project_job_template_id){
            fetchJobTemplateVersions(response.project_job_template_id)
        }
        setState((new_state) => ({
            ...new_state,
            // ...response,
            data: {
                ...new_state.data,
                env_id: response.project_env_id,
                roles: getRolesEdit(response.env_role_access),
                image_name: response.image_name,
                manual_build:
                    response.project_env && response.project_env.manual_build
                        ? "true"
                        : "false",
                manual_deploy:
                    response.project_env && response.project_env.manual_deploy
                        ? "true"
                        : "false",
                hyperbuild: response.hyperbuild ? "true" : "false",
                selectedEnv: getSelectedEnv(
                    response.project_env.id,
                    new_state.env_list
                ),
                registry_id: response.registry_id,
                artifact_name: response.artifact_name
            },
            data_loading: false,
            selected_job_template_taborder: response.project_job_template_id,
            selected_job_template_version_taborder: response.template_version,
        }));
    }

    function getSelectedRegistries() {

    }

    function getRolesEdit(data) {
        var roles = new Array();

        for (let i = 0; i < data.length; i++) {
            if (data[i].access) {
                roles = [...roles, properties.roles[i].label];
            }
        }
        return roles;
    }

    function setErrorNull(setState) {
        setTimeout(() => {
            setState(prevState => ({
                ...prevState,
                error_null: null
            }))
        }, 3500);
    }

    useEffect(() => {
        if (state.error_null) {
            setErrorNull(setState);
        }
    }, [state.error_null]);

    function getEnvDataFail(error) {
        setState((new_state) => ({
            ...new_state,
            error_msg: error.toString(),
            data_loading: false,
        }));
    }

    function onChangeSelectHandler(e) {
        if (state.env_list.length > 0) {
            var selectedEnv = getSelectedEnv(e.target.value, state.env_list);
            var new_data_elements = {
                [e.target.name]: e.target.value,
                manual_build: selectedEnv?.manual_build ? "true" : "false",
                manual_deploy: selectedEnv?.manual_deploy ? "true" : "false",
                hyperbuild: selectedEnv?.hyperbuild ? "true" : "false",
                selectedEnv: selectedEnv,
                cluster_available: selectedEnv.cluster_available,
                vm_group_available: selectedEnv.vm_group_available
            };
            commonFunctions.updateMultipleData(new_data_elements, e.target.name);
            setServiceName(selectedEnv, e.target.value)
        }
    }
    const setServiceName = (selectedEnv, env_type) => {
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                image_name: new_state?.service_name + "/" + selectedEnv?.type + "/" + selectedEnv?.label
            }
        }));
    }
    function getSelectedEnv(env_id, env_list) {
        var selected_env = null;
        env_list.forEach((env) => {
            if (env.id == env_id) {
                selected_env = env;
            }
        });
        return selected_env;
    }



    function fetchJobTemplatesList() {
        var requestInfo = {
            endPoint: GenerateURL(
                { application_id: application_id },
                properties.api.get_all_job_template + "?all=true&global=true"
            ),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };
        InvokeApi(
            requestInfo,
            fetchJobTemplatesListSuccess,
            fetchJobTemplatesListFailure
        );
        setState((new_state) => ({
            ...new_state,
            data_loading: true,
        }));
    }
    function fetchJobTemplatesListSuccess(response) {
        console.log(response)
        setState((new_state) => ({
            ...new_state,
            job_template_list: response?.results ? response.results : [],
            data_loading: false,
        }));
    }
    function fetchJobTemplatesListFailure(error) {
        var error_msg = error.toString();
        setState((new_state) => ({
            ...new_state,
            error: error_msg,
            data_loading: false,
        }));
    }
    function fetchJobTemplateVersions(id) {
        console.log(id, "fdsafdsaoi")
        let myEndPoint = GenerateURL({ template_id: id },
            properties.api.get_global_template_versions + "?all=true"
        );
        console.log("check if fn called")

        var requestInfo = {
            endPoint: myEndPoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" },
        };

        InvokeApi(
            requestInfo,
            jobTemplateVersionsFetchSuccess,
            jobTemplateVersionsFetchFailure
        );
        setState((new_state) => ({
            ...new_state,
            template_version_loading: true,
        }));
    }

    function jobTemplateVersionsFetchSuccess(data) {
        console.log(data, "fdlsfsdlfklas")
        let activeVersion = data.filter((item)=>{
            if(item.active){
                return item;
            }
        });
        console.log(activeVersion, "fdlsfsdlfklas")
        setState((new_state) => ({
            ...new_state,
            template_version_loading: false,
            job_template_versions: data,
            selected_job_template_version_taborder: activeVersion[0]?.template_version,
            selected_job_template_version: activeVersion[0]?.template_version,
        }));
    }
    function jobTemplateVersionsFetchFailure(error, exception) {
        setState((new_state) => ({
            ...new_state,
            template_version_loading: false,
            error: error,
        }));
    }


    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setState(new_state => ({
            ...new_state,
            data_loading: true
        }))

    }

    function fetchSystemSettingsDataSuccess(data) {
        var hyper_build_flag = data.filter(item => item.key == "HYPERBUILD_ENABLE_FLAG");
        setState(new_state => ({
            ...new_state,
            hyper_build_flag: hyper_build_flag,
        }));

    }
    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: false
        }))
    }
    const onChangeHandlerRadio = (e) => {
        const key = e.target.name;
        var value = e.target.value;
        updateData(key, value);
    }
    function updateData(k, v) {
        setState(new_state => ({
            ...new_state,
            data: {
                ...new_state.data,
                [k]: v
            },
            error: {
                ...new_state.error,
                [k]: ""
            }
        }))
    }

    if (state.data_loading || state.fetching_env_list) {
        return (
            <div className="" style={{ height: "500px" }}>
                <Loading varient="light" />
            </div>
        );
    }

    function getNamespaceId() {
        var selected_env_id = state.data.env_id
        var selected_env_data;
        var supported_registeries = []

        state.env_data.forEach((item) => {
            if (item.id == Number(selected_env_id)) {
                selected_env_data = item
            }
        })

        setState((new_state) => ({
            ...new_state,
            selected_namespace_id: selected_env_data && selected_env_data.project_env_namespace ? selected_env_data.project_env_namespace.namespace_id : null,
            selected_registry_id: selected_env_data && selected_env_data.registry ? selected_env_data.registry : null
        }))

        // return supported_registeries;
    }

    console.log(state, "sdcbsbjbd")


    function onChangeHandlerForKeyValue(key, value) {
        console.log(key, value, "sdcsjdcsbcbsdhc")
        if (key == "registry_id") {
            value.forEach((id, index) => {
                value[index] = Number(id);
            });
        }
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [key]: value
            },
            error: {
                ...new_state.error,
                [key]: null,
            }
        }))
    }

    console.log(state, "sjdbcdbsdcsdb")

    const updateJobTemplatefn = (order, name) => {
        let selected_job_template = state.fileterdByVersionTemplates.find((ele,_)=>ele.id == order);

        setState((new_state) => ({
            ...new_state,
            selected_job_template_taborder: order,
            selected_job_template_name: name,
            selected_job_template_version_taborder: null,
            selected_job_template_version: null,
            selected_job_template_type: selected_job_template.project_id ? 'l' : 'g',
            error:{
                ...new_state.error,
                selected_job_template_taborder: "",
            }
        }))
        if(order && name){
            fetchJobTemplateVersions(order);
        }
    }

    const updateJobTemplateVersionfn = (order, version) => {
        console.log("jdhhs",order,version);
        setState((new_state) =>({
            ...new_state,
            selected_job_template_version_taborder: order,
            selected_job_template_version: version
        }))
    }

    console.log("gshjkhdvg",state);

    return (
        <div
            className="d-grid"
            style={{ gridTemplateColumns: "365px 1fr", height: "100vh" }}
        >
            {state.canRedirect ? (
                (
                    <Redirect
                        to={
                            "/application/" +
                            application_id +
                            "/service/" +
                            serviceId +
                            "/detail?env_id=" +
                            state.new_env_id
                        }
                    />
                )
            ) : null}
            <StaticPart />
            <div
                style={{
                    paddingTop: "65px",
                    backgroundColor: "#fff",
                    overflow: "auto",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                {state.error_msg ? (
                    <ErrorComponent variant="msg-box" error={state.error_msg} />
                ) : (
                    <>
                        <div style={{ paddingLeft: "35px", paddingRight: "35px" }}>
                            {state.data_loading ?
                                (
                                    <div className="" style={{ height: "500px" }}>
                                        <Loading varient="light" />
                                    </div>
                                )
                                :
                                state.env_list.length > 0 ? (
                                    <div style={{}}>
                                        <div className="d-flex align-center space-between">
                                            <div className="service-form-heading-section ">
                                                <p className="service-form-heading">
                                                    Environment Basic Details{" "}
                                                </p>
                                                <p className="hint-text-main-heading">
                                                    To learn more about deployment please read the{" "}
                                                    <span className="text-anchor-blue">
                                                        {" "}
                                                        Documentation
                                                    </span>
                                                </p>
                                            </div>
                                            <IconButton className="ml-auto d-block">
                                                <Link
                                                    to={
                                                        "/application/" +
                                                        application_id +
                                                        "/service/" +
                                                        component_id +
                                                        "/detail"
                                                    }
                                                >
                                                    {" "}
                                                    <CloseIcon fontSize="large" />
                                                </Link>
                                            </IconButton>
                                        </div>

                                        <div className={classes.formSec}>
                                            <ContentBox>
                                                {state.error_null ? (
                                                    <>
                                                        {" "}
                                                        <ErrorComponent error={state.error_null} variant="msg-box" />

                                                    </>
                                                ) : null}
                                                <div className="d-flex align-center justify-flexend">
                                                    <Link
                                                        className="text-anchor-blue"
                                                        style={{ display: "flex" }}
                                                        to={"/application/" + application_id + "/env/add"}
                                                    >
                                                        <AddIcon />
                                                        <span>Add Environment</span>
                                                    </Link>
                                                </div>
                                                <div className="">
                                                    <Input
                                                        type="select"
                                                        mandatorySign
                                                        name="env_id"
                                                        label="Select Environment"
                                                        list={state.env_list}
                                                        error={state.error}
                                                        onChangeHandler={onChangeSelectHandler}
                                                        data={state.data}
                                                    />
                                                </div>
                                                { state.data.selectedEnv && state.data.selectedEnv.cluster_available ?
                                                     state.data.env_id ?
                                                        <Grid item lg={12} className="" style={{ marginTop: "20px" }}>
                                                            <Input
                                                                type="select"
                                                                name="registry_id"
                                                                mandatorySign
                                                                list={state.registry_option_list ? state.registry_option_list : []}
                                                                label="Select Registry"
                                                                onChangeHandler={commonFunctions.onChangeHandler}
                                                                data={state.data}
                                                                error={state.error} />

                                                        </Grid> 
                                                        :null
                                                        :null
                                                    }
                                                {state.data.selectedEnv && state.data.selectedEnv.cluster_available ?
                                                    <Grid item lg={12} style={{ marginTop: "20px" }}>
                                                        <Input
                                                            type="text"
                                                            placeholder="imagename"
                                                            name="image_name"
                                                            mandatorySign
                                                            label="Image Name"
                                                            error={state.error}
                                                            onChangeHandler={commonFunctions.onChangeHandler}
                                                            data={state.data}
                                                        />
                                                    </Grid>
                                                    :
                                                    <Grid item lg={12} style={{ marginTop: "20px" }}>
                                                        <Input
                                                            type="text"
                                                            placeholder="Enter Artifact Name"
                                                            name="artifact_name"
                                                            mandatorySign
                                                            label="Artifact Name"
                                                            error={state.error}
                                                            onChangeHandler={commonFunctions.onChangeHandler}
                                                            data={state.data}
                                                        />
                                                    </Grid>}

                                                <div
                                                    className="d-grid align-center space-between radio-cont"
                                                    style={{ gridTemplateColumns: "1fr 1fr 1fr", gap: 20 }}
                                                >
                                                    {
                                                        state.hyper_build_flag ?
                                                            state.data.selectedEnv ? (state.data.selectedEnv?.hyperbuild ? (
                                                                <Input
                                                                    type="radio"
                                                                    list={state.option_list}
                                                                    label={"Enable Hyper Build"}
                                                                    name="hyperbuild"
                                                                    mandatorySign
                                                                    onChangeHandler={onChangeHandlerRadio}
                                                                    data={state.data}
                                                                    error={state.error}
                                                                />
                                                                
                                                            ) : null) :null
                                                        
                                                        
                                                        : null
                                                       
                                                    }
                                                    {state.data.selectedEnv ? (
                                                        state.data.selectedEnv.manual_build ? (
                                                            <Input
                                                                type="radio"
                                                                list={state.option_list}
                                                                label={"Allow Manual Build"}
                                                                name="manual_build"
                                                                mandatorySign
                                                                onChangeHandler={
                                                                    commonFunctions.onChangeHandler
                                                                }
                                                                data={state.data}
                                                                error={state.error}
                                                            />
                                                        ) : (
                                                            <div style={{ marginTop: '12px' }}>
                                                                <AlertStrip
                                                                    variant="info"
                                                                    message="Manual Build Not Allowed"
                                                                />
                                                            </div>

                                                        )
                                                    ) : null}

                                                    {state.data.selectedEnv ? (
                                                        state.data.selectedEnv.manual_deploy ? (
                                                            <Input
                                                                type="radio"
                                                                list={state.option_list}
                                                                label="Allow Manual Deploy"
                                                                name="manual_deploy"
                                                                mandatorySign
                                                                onChangeHandler={
                                                                    commonFunctions.onChangeHandler
                                                                }
                                                                data={state.data}
                                                                error={state.error}
                                                            />
                                                        ) : (
                                                            <div style={{ marginTop: '12px' }}>
                                                                <AlertStrip
                                                                    variant="info"
                                                                    message="Manual Deploy Not Allowed"
                                                                />
                                                            </div>
                                                        )
                                                    ) : null}
                                                </div>

                                                <div className="" style={{ marginTop: '30px' }}>
                                                    <div className="version_change mb-15">
                                                        <Input
                                                            type="radio"
                                                            list={state.template_option}
                                                            label="Select Job Template"
                                                            name="job_template_version"
                                                            mandatorySign
                                                            onChangeHandler={
                                                                commonFunctions.onChangeHandler
                                                            }
                                                            data={state.data}
                                                            error={state.error}
                                                        />

                                                    </div>

                                                    <div className="mb-15" style={{display:'grid', gridTemplateColumns:'1fr 1fr', gap:'16px'}}>
                                                        <InputWithDropdownJobTemplate
                                                            jobTemplateList={state.fileterdByVersionTemplates}
                                                            selectedJobTemplateId={state.selected_job_template_taborder}
                                                            jobTemplateChangeHandler={updateJobTemplatefn}
                                                            name={"selected_job_template_taborder"}
                                                            error={state.error}
                                                            label={"Select Job Template"}
                                                            popupWidth="407px"
                                                        />
                                                        {state.selected_job_template_type == 'g' ? 
                                                            
                                                                state.template_version_loading ? 
                                                                <Loading varient="light" />
                                                                : 
                                                                <InputWithDropdownVersions
                                                                jobTemplateVersionsList={state.job_template_versions}
                                                                selectedJobTemplateVersion={state.selected_job_template_version_taborder}
                                                                jobTemplateChangeHandler={updateJobTemplateVersionfn}
                                                                variant="version"
                                                                popupWidth="407px"
                                                                label={"Select Version of the job template"} />
                                                            
                                                        : null}
                                                        
                                                        
                                                    </div>


                                                    <div className="select_version">




                                                        <div className="version-description">
                                                            {
                                                                state?.data?.job_template_version === 'v2' ?
                                                                    <p>
                                                                        Automate Build &amp; Deployment process by using
                                                                        pre-configured Job Templates
                                                                    </p> :
                                                                    <p>
                                                                        veriosn 3 Job Templates are more flexible templates
                                                                        and allows you to configure
                                                                    </p>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </ContentBox>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={classes.formSec}>
                                        <ContentBox>
                                            <div className="welcome-text">
                                                <h1 className={classes.helloText}>
                                                    Sorry,your application has no Environments!
                                                </h1>
                                                <div className={classes.paraWithButton}>
                                                    <div className={classes.textPara}>
                                                        To onboard a service you need to make an Environment
                                                        first.
                                                        <br />
                                                    </div>
                                                </div>
                                                <Link
                                                    to={"/application/" + application_id + "/env/add"}
                                                    className={classes.btnblue + " " + "btn-blue-next"}
                                                >Create Environment<span className="flaticon-keyboard-right-arrow-button"></span></Link>
                                            </div>
                                        </ContentBox>
                                    </div>
                                )}
                        </div>
                        <div
                            className={"d-flex align-center space-between mt-30 pd-10 border-top"}
                            style={{ backgroundColor: "#fff" }}
                        >
                            {!edit ? (
                                <Link
                                    to={
                                        "/application/" +
                                        application_id +
                                        "/service/" +
                                        component_id +
                                        "/edit"
                                    }
                                >
                                    {" "}
                                    <button className="btn btn-outline-grey" onClick={() => { }}>
                                        Back
                                    </button>
                                </Link>
                            ) : (
                                <div></div>
                            )}

                            <button className="btn btn-submit" onClick={validateAndSave}>
                                Save &amp; Continue
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
EnvAndUserGroup.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default EnvAndUserGroup;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: "100vh",
        "& .heading-div": {
            padding: "20px 34px 0px",
            marginBottom: "10px",
            "& .service-form-heading": {
                fontSize: "24px ",
            },
            "& .hint-text-main-heading": {
                fontSize: "13px",
            },
        },
        "& .main-form-div": {
            background: "#f0f7f7",
            position: "relative",
            "& .main-form-div-inner": {
                margin: "auto",
                minWidth: "100%",
                padding: "0px 0px 0 20px",
                "& .service-form-heading": {
                    fontSize: "24px",
                    fontWeight: "300",
                },
            },
        },
    },
    grid: {
        height: "100%",
    },
    leftContainer: {
        backgroundColor: "#041939",
        height: "100%",
        padding: "200px 20px 0 40px",
        fontSize: "13px",
        lineHeight: "22px",
        color: "#fff",
        "& .main-heading": {
            fontSize: "26px",
            fontWeight: 300,
            lineHeight: 1,
            color: "#fff",
            marginBottom: "15px",
            position: "relative",
            "&:after": {
                content: '""',
                position: "absolute",
                width: "73px",
                height: "3px",
                background: "#2e2af3",
                left: "0",
                bottom: "-5px",
            },
        },
    },

    readable: {
        fontSize: "42px",
        letterSpacing: "3px",
        lineHeight: "52px",
        color: "#ffffff",
        fontWeight: 400,
        fontFamily: "Inter",
        textAlign: "center",
        marginTop: "3rem",
    },
    formSec: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingRight: "15px",
        // overflowY: 'auto',
        //padding: '0px 34px 0px',
        "& .radio-cont": {
            "& .alert-dismissible": {
                marginBottom: 0,
            },
            "& .input-component": {
                marginBottom: 0,
                marginTop: '30px',
                "&:nth-child(2)": {
                    alignSelf: "end",
                },

                "& .MuiFormControlLabel-label": {
                    color: '#888888',
                    fontSize: '13px',
                },
            },
        },
        "& .version_change": {
            "& .label-controller": {
                color: '#16232b !important',
                fontSize: '14px',
            },
        },
        "& .select_version": {
            "& .label-controller": {
                color: '#828282; !important',
                fontSize: '12px',
            },
        },

        "& .input-component": {
            marginBottom: '0 !important',
        },
        "& .selectJob_heading": {
            marginTop: '30px',
            "& h4": {
                fontSize: "20px",
                fontWeight: "400",
            },
            "& p": {
                fontSize: "13px",
                color: "#888",
                paddingBottom: "5px",
            },
        },
        "& .version-description": {
            color: '#888888',
            fontSize: '12px',
            marginTop: '6px',
        },
    },
    helloText: {
        fontSize: "36px",
        fontFamily: "nunito",
        fontWeight: 900,
        color: "#5262c8 ",
    },
    paraWithButton: {
        display: " flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    btnblue: {
        width: "240px",
        height: "60px",
        fontWeight: 900,
        fontFamily: "nunito",
        marginTop: "1rem",
    },
    textPara: {
        fontSize: "20px",
        fontWeight: "700",
        fontFamily: "nunito",
        lineHeight: "1.5",
        color: "#525a8c",
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '50px'
    }
}));

const TextContainer = styled("div")({
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "center",
    gap: "1%",
    margin: "1rem 0px",
    "& .main-heading": {
        fontSize: "35px",
        fontWeight: 300,
        lineHeight: 1,
        color: "#fff",
        marginBottom: "15px",
    },
});
const TickComponent = styled("div")({
    display: "flex",
    alignItems: "center",
    padding: "5px 0px",
    "& .txt": {
        fontSize: "12px",
        lineHeight: "30px",
        color: "#fff",
        fontWeight: 400,
        fontFamily: "Inter",
        marginLeft: "15px",
    },
    "& .round-circle-tick": {
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "2px solid #0db79b",
        borderRadius: "50%",
        "& .flaticon-check-symbol": {
            color: "#0db79b",
        },
    },
});

const ContentBoxTxt = styled("div")({
    width: "80%",
    display: "grid",
    height: "100%",
    alignItems: "center",
    "& .signup-heading": {
        fontSize: "18px",
        marginTop: "20px",
    },
    "& .input-with-icon > .input-icon": {
        top: "40px",
    },
    "& .heading-pass": {
        marginBottom: "10px",
        "& .signup-heading": {
            marginBottom: "0px",
        },
        "& .sub-heading": {
            marginTop: "5px",
        },
    },
    "& .que": {
        fontSize: "25px",
        lineHeight: "20px",
        color: "#ffffff",
        fontWeight: 300,
        fontFamily: "Inter",
        marginBottom: "20px",
    },
    "& .description": {
        fontSize: "12px",
        lineHeight: "20px",
        color: "#ffffff",
        fontWeight: 400,
        fontFamily: "Inter",
    },
    "& ul": {
        marginTop: "20px",
        listStyle: "none",
        "& li": {
            fontSize: "11px",
            lineHeight: "25px",
            color: "#ffffff",
            fontWeight: 400,
            fontFamily: "Inter",
            "& .bullet-succes-green": {
                borderRadius: "50%",
                display: "inline-block",
                marginRight: "5px",
                backgroundColor: "#0db79b",
                height: "10px",
                width: "10px",
            },
        },
    },
});

function mapRoles(backendRoles) {
    var uiRoles = properties.roles;
    console.log(backendRoles, "jkjk");
    backendRoles.forEach((role) => {
        if (role.role_master.code == "DEV") {
            uiRoles[0].backendRole = role;
        }
        if (role.role_master.code == "QA") {
            uiRoles[1].backendRole = role;
        }
        if (role.role_master.code == "DEVOPS") {
            uiRoles[2].backendRole = role;
        }
    });
    return uiRoles;
}

function getAssignedAccess(selectedRoles, ui_roles) {
    var env_role_Access = [];
    ui_roles.forEach((role) => {
        var access = selectedRoles.includes(role.label) ? true : false;
        var role_access = { project_role_id: role.backendRole.id, access: access };
        env_role_Access = [...env_role_Access, role_access];
    });
    return env_role_Access;
}

const StaticPart = () => {
    const classes = useStylesStatic();
    return (
        <div
            className={classes.staticwrap}
            style={{ backgroundColor: "#051939", height: "100%" }}
        >
            <div className="staticwrap-inner">
                <h1 className="h1">Create a Environment</h1>
                <p style={{ color: "#fff", paddingTop: "25px" }}>
                    A Environment aims to mitigate such scenarios by encouraging
                    automation and documentation, there by increasing communication. In
                    this environment, every release is committed in an automated fashion,
                    enabling the rapid building, testing and deployment of every project.
                    <br /> <br />
                    To learn more about how to setup a Microservice please read{" "}
                    <span style={{ color: "#e89317" }}>
                        Documentation{" "}
                    </span>
                </p>
            </div>
        </div>
    );
};

const useStylesStatic = makeStyles({
    staticwrap: {
        "& .staticwrap-inner": {
            padding: "215px 30px 0 40px",
            color: "#fff",
            "& p": {
                fontSize: "13px",
                lineHeight: "23px",
                marginTop: "30px",
            },
        },
        "& .h1": {
            fontSize: "25px",
            lineHeight: "36px",
            color: "#ffffff",
            fontWeight: "300",
            position: "relative",

            "&:before": {
                content: '""',
                borderBottom: "1px solid #000",
                position: "absolute",
                borderLeft: "none",
                bottom: "0",
                left: 0,
                display: "inline-block",
                width: "40px",
                height: "4px",
                background: "#2e2af3",
            },
        },
    },
});