import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Grid } from '@material-ui/core';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions, ResetComponent } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Slide from '@material-ui/core/Slide';
import Questionnaire from './Questionnaire';
import SidePanelQuestionnaire from './SidePanelQuestionnaire';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import EditableTable from '../../../../components/genericComponents/EditableTable';
import { RemoveFromArray } from '../../../../util/util';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const QuestionnaireDialog1 = (props) => {
    const classes = useStyles();
    const pipeline_data = props.pipeline_data ? props.pipeline_data : null;
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const jira_id_reference_keys_list = props.jira_id_reference_keys_list ? props.jira_id_reference_keys_list: [];
    const [state, setState] = useState(prev_state ? questionnairesDefaultState(prev_state) : questionnairesDefaultState())
    const add_questionnaire_to_stage = props.add_questionnaire_to_stage ? props.add_questionnaire_to_stage : () => { }
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const handleClose = (e) => {
        setState(new_state => ({
            ...new_state,
            open: false,
            data: {
                approval: false
            }
        }));
    };
    const handleClickOpen = () => {
        setState(new_state => ({
            ...new_state,
            open: true,
        }));
    };
    
    const columnNames = [

        {
            label: 'Questionnaires ',
            width: '40%',
            grey: false,
            name: 'question'
        },
        {
            label: 'Question Type',
            width: '20%',
            grey: true,
            name: 'type'
        },
        // {
        //     label: 'Update to Jira',
        //     grey: true,
        //     width: '15%',
        //     name: 'jira_update',
        // },
        {
            label: 'Default Value',
            grey: true,
            width: '30%',
            name: 'default_value',
        }

    ]


    const tableActionHandler = (type,data)=> {
       
        setState(prevState=>({
            ...prevState,
            show_form: true,
            update_state_fn: true,
            questionnaire : {
                ...prevState.questionnaire,
                ...data
            }
        }))
    }

    const handleDeleteRow = (data) => {
        var total_arr = state.questionnaire_list;
        
        total_arr.forEach((item, key) => {

            if (item.question === data.question) {

                total_arr.splice(key, 1)

            }

        })
        setState(prevState => ({
            ...prevState,
            questionnaire_list: [
                ...total_arr
            ]
        }))
    }

    function validateAndSaveData() {
        
                    add_questionnaire_to_stage(state.questionnaire_list);
            handleClose();

    }

    function pushDataToArray() {
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm(): { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var questionnaire_child_data = child_states.questionnaire;
        if (children_vaidation_result.valid) {
                    setState(new_state => ({
                        ...new_state,
                        show_form: false,
                        questionnaire_list: [
                            ...new_state.questionnaire_list, 
                            { 
                                ...questionnaire_child_data.data,
                                jira_update: questionnaire_child_data.data.jira_update ? "true" : "false"   
                            }
                        ],
                    }));
                    
                }
    }
const toggleForm = () => {
    setState(prevState=>({
        ...prevState,
        show_form: !state.show_form,
        questionnaire:{}
    }))
}

const updateStateObj = (keyValue) => {
    var children_vaidation_result = inherits.validateForm ? inherits.validateForm(): { valid: true };
    var child_states = inherits.getState ? inherits.getState() : null;
    var questionnaire_child_data = child_states.questionnaire;
   console.log(questionnaire_child_data,"jira_referencejira_reference")
    if(children_vaidation_result.valid){
        let updatedList = state.questionnaire_list.map(item => 
            {
                if(item.question === keyValue.question){
                    return {...item, 
                            ...questionnaire_child_data.data,
                            jira_update: questionnaire_child_data.data.jira_update ? "true" : "false"  
                        };
                }else{
                    return item;
                }
            }
        )
        setState(new_state => ({
            ...new_state,
            update_state_fn: false,
            show_form: false,
            questionnaire_list: updatedList,
        }))
    }
}

    return (
        <>
            <div class="bg-f8-grey">
                <div class="card-header with-btn">
                    <div class="heading">Approval Question?</div>
                    <button className="btn btn-transparent text-anchor-blue d-flex align-center" onClick={handleClickOpen}>
                        <AddIcon className="text-anchor-blue" /> {state.questionnaire_list && state.questionnaire_list.length > 0 ? "Add or Edit Question" : "Add Question"}</button>
                </div>
            </div>
            <Dialog
                fullWidth={state.fullWidth}
                maxWidth={state.maxWidth}
                open={state.open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <div className="ml-15 d-flex align-center space-between">
                        <p className="font-18 font-family-nunito">Stage Approval Questions</p>
                        <Toolbar>
                            <IconButton style={{ marginLeft: 'auto' }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon className="font-24" />
                            </IconButton>
                        </Toolbar>
                    </div>
                </AppBar>
                <div className='d-grid' style={{ gridTemplateColumns: '365px 1fr', height: '100vh' }}>
                    <SidePanelQuestionnaire />
                    <div className="bg-white">
                        <div className=" h-250 pr-20 pl-20" style={{ height: '75vh', overflowY: 'auto' }}>
                            {state.questionnaire_list.length > 0 ?
                            <div className="card box-shadow-none bg-white mb-15 mt-20" >
                                
                                <EditableTable
                                    columnNames={columnNames}
                                    actionButton={true}
                                    apiFetchedData={state.questionnaire_list}
                                    tableActionHandler={tableActionHandler}
                                    handleDeleteRow={handleDeleteRow}
                                    variant="static_delete"
                                    fetchAfterAction={() => { }}
                                    endPointObj={""}
                                    apiUrl={""}
                                />
                                <div className="d-flex card-footer justify-flexend">
                                    <button className="transparent-btn nowrap" onClick={toggleForm}>
                                        <AddIcon style={{ color: 'blue' }} /> Add More
                                    </button>
                                </div>
                                </div> :
                                !state.show_form &&
                                <div className="no-data-div" style={{marginTop:'15%'}}>
                                <p>Please add questions that approver should answer, before approving the pipeline.</p>
                                <button
                                    onClick={toggleForm}
                                    className="btn btn-primary-v2  mb-15 m-auto  d-flex align-center text-center"
                                >
                                    <AddIcon />&nbsp;
                                    <span>Add Questions</span>
                                </button>
                            </div>
                            }
                            {
                                state.show_form ? 
                                <Questionnaire
                                prev_state={state.questionnaire}
                                toggleForm={toggleForm}
                                parentComponentState={state.questionnaire}
                                jira_id_reference_keys_list={jira_id_reference_keys_list}
                                jira_ref_key_list={getJiraRefid(pipeline_data)}
                                validateAndPushDataToTable={state.update_state_fn ? 
                                    ()=>{updateStateObj(state.questionnaire)} :pushDataToArray
                                    
                                }
                                inherits={state.child_inherits.questionnaire} />
                                :
                            null
                            }
                            
                        </div>
                        {
                            state.questionnaire_list.length > 0 &&
                            <div className="d-flex align-center justify-end border-top pd-10 ">
                                <button className="btn btn-outline-grey" onClick={handleClose}>Cancel</button>
                                <button className="btn btn-submit" onClick={validateAndSaveData}>Save</button>
                            </div>
                        }

                    </div>

                </div>
            </Dialog>
        </>
    )
}

function questionnairesDefaultState(prev_state) {
    return prev_state ?
        {
            open: false,
            fullWidth: true,
            show_form: prev_state && prev_state.length > 0 ? false: true,
            maxWidth: 'lg',
            questionnaire_list: prev_state ? prev_state :[],
            data: {
                approval: prev_state && prev_state.length > 0 ? true : false
            },
            error: {},
            child_inherits: {
                questionnaire: {
                    "validateForm": () => { return { valid: true }; },
                    "getState": () => { return {}; },
                    "getData": () => { return {}; }
                },
            }
        }
        :
        {
            open: false,
            fullWidth: true,
            maxWidth: 'lg',
            questionnaire_list: [],
            show_form: true,
            data: {
                approval: false
            },
            error: {},
            child_inherits: {
                questionnaire: {
                    "validateForm": () => { return { valid: true }; },
                    "getState": () => { return {}; },
                    "getData": () => { return {}; }
                },
            }
        }
}

export default QuestionnaireDialog1;
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    root: {
        display:'block'
        // '& .h-300': {
        //     height: 350,
        //     overflowy: 'scroll'
        // }
    }
}));
export function getJiraRefid(data) {
    var jira_ref_key_list = []
    if (data && data.jira_references) {
        if (data.jira_references.length > 0) {
            data.jira_references.forEach(item => {
                jira_ref_key_list.push({ id: item.jira_reference_key, label: item.jira_reference_key  })
            })
        }
        return jira_ref_key_list
    }
}