import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Grid } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import CloseIcon from '@material-ui/icons/Close';
import { Input } from '../../../../components/genericComponents/Input';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';

import { Loading } from '../../../utils/Loading';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import SidePanelApprovalQuestions from './SidePanelApprovalQuestions';
import { useParams } from 'react-router-dom';

import AlertStrip from '../../../../components/AlertStrips';
import EditIcon from '@material-ui/icons/Edit';

//making dialog responsive
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FillApprovalQuestions = (props) => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    var questionnaires = props.questionnaires;
    const pipeline_id = props.pipeline_id ? props.pipeline_id : "";
    const pipeline_instance_id = props.pipeline_instance_id ? props.pipeline_instance_id : ""
    const stage_instance_id = props.stage_instance_id ? props.stage_instance_id : null;
    const variant = props.variant;
    console.log(variant, 'variant_003lll')
    const btnVariant = props.btnVariant;
    const stage_instance_status = props.stage_instance_status ? props.stage_instance_status : ""
    const { application_id } = useParams();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [state, setState] = useState({
        open: false,
        fullWidth: true,
        maxWidth: 'lg',
        data: {},
        all_templates: [],
        scheduleEnabled: true,
        question_count: 0,
        error: {},
        user_object: {},
        approval_processing: false,
        child_inherits: {
            questionnaire: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            },
            approved_comment: {
                "validateForm": () => { return { valid: true }; },
                "getState": () => { return {}; },
                "getData": () => { return {}; }
            }
        }
    })
    const [loading, setLoading] = useState(true)
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    const stage_name = props.stage_name ? props.stage_name : null

    const handleClose = (e) => {
        setState(new_state => ({
            ...new_state,
            open: false,

        }));
    };
    const handleClickOpen = () => {
        if (stage_instance_id) {
            fetch_approved_data();
        }

        setState(new_state => ({
            ...new_state,
            open: true,
        }));
    };
    const disapprovePipeline = (e) => {
        var result = ValidateDataSet(state.data, state.validations);
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm() : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var apporved_comment_data = child_states.approved_comment;
        if (result.valid && children_vaidation_result.valid) {
            var final_post_data = {};
            var questionnaires_array = [];
            Object.keys(state.data).forEach((item, key) => {
                if (item !== "user" && !item == "schedule" && !item == "schedule_date" && !item == "schedule_time") {
                    questionnaires_array.push({ "pipeline_question_id": item, "answer": state.data[item] })
                }
            })

            final_post_data = {
                ...final_post_data,
                approved: 2,
                comment: apporved_comment_data.data.comment,
                questionnaires: [...questionnaires_array]
            }
            props.postFinalData(final_post_data, stage_instance_id);
            setState(new_state => ({
                ...new_state,
                open: false,
                approval_processing: true
            }));
        }
    };

    function getMinutesHours(type) {
        var time = state.data.schedule_time
        const timeArr = time.split(":")
        if (type == "minute") {
            return timeArr[1]
        }
        if (type == "hour") {
            return timeArr[0]
        }
    }

    function getDate(type) {
        var date = state.data.schedule_date
        const dateArr = date.split("-")
        if (type == "month") {
            return dateArr[1]
        }
        if (type == "date") {
            return dateArr[2]
        }
    }

    function validateAndSave() {
        var result = ValidateDataSet(state.data, state.validations);
        var children_vaidation_result = inherits.validateForm ? inherits.validateForm() : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var apporved_comment_data = child_states.approved_comment;
        if (result.valid && children_vaidation_result.valid) {
            var final_post_data = {};
            var questionnaires_array = [];
            Object.keys(state.data).forEach((item, key) => {
                if (!item.includes('user_') && item != "schedule" && item != "schedule_date" && item != "schedule_time") {
                    questionnaires_array.push({ "pipeline_question_id": item, "answer": state.data[item] })
                }
            })

            final_post_data = {
                ...final_post_data,
                approved: 1,
                comment: apporved_comment_data.data.comment,
                questionnaires: [...questionnaires_array],
            }

            if (state.data.schedule) {
                var schedule_time = {
                    minute: getMinutesHours("minute"),
                    hour: getMinutesHours("hour"),
                    date: getDate("date"),
                    month: getDate("month"),
                    timezone: "Asia/Kolkata"
                }
                final_post_data.time = schedule_time
            }
            props.postFinalData(final_post_data, stage_instance_id)
            setState(new_state => ({
                ...new_state,
                open: false,
                approval_processing: true
            }));

        }
    }

    const setting_default_answer = () => {
        var duplicate_questionnaires = questionnaires;

        var question_arr = {};
        var validations_arr = {}
        if (duplicate_questionnaires && duplicate_questionnaires.length > 0) {
            duplicate_questionnaires.forEach(item => {

                question_arr[item.id] = item.type === "checkbox" && item.default_value ? [item.default_value] : item.default_value
                validations_arr[item.id] = [VALIDATION_TYPE_REQUIRED]
            })
            setState(new_state => ({
                ...new_state,
                data: {
                    ...question_arr,
                    ...new_state.data
                },
                validations: {
                    ...validations_arr,
                    ...new_state.validations
                }
            }));

        }
    }
    useEffect(() => {
        if (questionnaires && questionnaires.length > 0) {
            setting_default_answer();
        }

        getPipelineScheduling();

    }, [])

    function getPipelineScheduling() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.is_pipeline_scheduling_enabled),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSchedulingSuccess, handleSchedulingFailed);
        setState(new_state => ({
            ...new_state,
            show_loading_icon: true,
            response_success: false
        }));
    }

    function handleSchedulingSuccess(response) {
        setState(new_state => ({
            ...new_state,
            show_loading_icon: false,
            approval_flag: response.details.APPROVAL_SCHEDULING_FLAG
        }));
    }


    function handleSchedulingFailed(error) {
        console.log(error);
    }

    const fetch_approved_data = () => {
        var requestInfo = {
            endPoint: GenerateURL({ pipeline_id: pipeline_id, pipeline_instance_id: pipeline_instance_id, stage_instance_id: stage_instance_id }, properties.api.approve_pipeline),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleAprrovedDataSuccess, handleAprrovedDataFailed);
        setState(new_state => ({
            ...new_state,
            show_loading_icon: true,
            response_success: false
        }));

    }

    function handleAprrovedDataSuccess(data) {
        var question_arr = {};
        if (data.answer && data.answer.length > 0) {
            data.answer.forEach(item => {
                question_arr[item.pipeline_question.id] = item.answer

            })
        }
        setState(new_state => ({
            ...new_state,
            data: {
                ...question_arr,
                ...new_state.data,
                schedule: data.schedule ? true : false,
                schedule_date: data.schedule && getScheduleDate(data.schedule.date, data.schedule.month),
                schedule_time: data.schedule && getScheduleTime(data.schedule.hour, data.schedule.minute),
            },
            approved_comment: {
                comment: data.approval && data.approval.comment ? data.approval.comment : null
            },
            question_arr: data.answer,
            approver_name: data.approval && data.approval.approved_by ? data.approval.approved_by : "",
            response_success: true,
            show_loading_icon: false,
            scheduleEnabled: data.schedule ? false : true
        }));

    }


    function handleAprrovedDataFailed(error) {
        setState(new_state => ({
            ...new_state,
            show_loading_icon: false,
            error: error,
        }))
    }

    function getScheduleDate(day, month) {
        const d = new Date();
        const year = d.getFullYear()
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    function getScheduleTime(hours, minute) {

        return [hours, minute].join(":");
    }


    function getBackgroundColor() {
        if (!stage_instance_id) {
            return classes.failedStage
        } else {
            switch (stage_instance_status) {
                case "PENDING_APPROVAL":
                    return (
                        classes.approvalStage
                    );
                case "SUCCESS":
                    return (
                        classes.succesStage
                    );
                case "FAILED":
                    return (
                        classes.failedStage
                    );
                case "RUNNING":
                    return (
                        classes.runningStage
                    )
                case "IN_QUEUE":
                    return (
                        classes.initiatedStage
                    );
                case "SKIPPED":
                    return (
                        classes.skippedStage
                    );
                case "DISAPPROVED":
                    return (
                        classes.failedStage
                    )
                case "SUSPENDED":
                    return (
                        classes.failedStage
                    );
                case "PENDING_RESUME":
                    return (
                        classes.runningStage
                    );
                case "REVOKED":
                    return (
                        classes.revokedStage
                    )

            }
        }
    }
    function onChangeHandlerForKeyValue(key, value) {
        commonFunctions.updateDataKeyVal(key, value);
    }


    const fetchJiraUserList = (id, user, variableId) => {

        if (user) {
            if (user.length > 0) {
                if (user.length > 2) {
                    var requestInfo = {
                        endPoint: GenerateURL({ template_id: id, user_query: user }, properties.api.jira_users),
                        httpMethod: "GET",
                        httpHeaders: { "Content-Type": "application/json" }
                    }
                    InvokeApi(requestInfo, (data) => handleJiraUserListSuccess(data, variableId), (error) => handleJiraUserListFailed(error, variableId));
                    setState(new_state => ({
                        ...new_state,
                        user_object: {
                            ...new_state.user_object,
                            [`user_${variableId}_loading_jira_user_list`]: true
                        }
                    }));
                } else {

                    setState(state => ({
                        ...state,

                        error: {
                            ...state.error,
                            [`user_${variableId}`]: "Please enter atleast first three characters of name"
                        }
                    }))

                }
            } else {
                setState(state => ({
                    ...state,

                    error: {
                        ...state.error,
                        [`user_${variableId}`]: "Please enter atleast first three characters of name"
                    }
                }))
            }


        } else {
            setState(new_state => ({
                ...new_state,
                no_jira_user_found: false,
                no_user_found: false,
                error: {
                    ...new_state.error,
                    user: "please enter atleast first three characters of name"
                }
            }));
        }


    }

    function handleJiraUserListSuccess(data, variableId) {

        var jira_user_list = data.user;
        if (jira_user_list.length > 0) {
            setState(new_state => ({
                ...new_state,
                // loading_jira_user_list: false,
                // jira_user_list: jira_user_list,
                // show_user_dropdown: true,
                // no_jira_user_found: false,
                user_object: {
                    ...new_state.user_object, [`user_${variableId}_jira_user_list`]: jira_user_list,
                    [`user_${variableId}_show_user_dropdown`]: true,
                    [`user_${variableId}_no_jira_user_found`]: false,
                    [`user_${variableId}_loading_jira_user_list`]: false
                }

            }));
        } else {
            setState(new_state => ({
                ...new_state,
                // loading_jira_user_list: false,
                // jira_user_list: [],
                // no_user_found: "user not found with these initials",
                user_object: {
                    ...new_state.user_object, [`user_${variableId}_jira_user_list`]: jira_user_list,
                    [`user_${variableId}_no_user_found`]: "user not found with these initials",
                    [`user_${variableId}_loading_jira_user_list`]: false
                }
            }));
        }

    }

    function handleJiraUserListFailed(error, variableId) {
        setState(new_state => ({
            ...new_state,
            // no_jira_user_found: true,
            // loading_jira_user_list: false,
            // error_msg_jira_list: error,
            // jira_user_list: [],
            user_object: {
                ...new_state.user_object,
                [`user_${variableId}_no_jira_user_found`]: true,
                [`user_${variableId}_loading_jira_user_list`]: false,
                [`user_${variableId}_error_msg_jira_list`]: error,
                [`user_${variableId}_jira_user_list`]: []

            }
        }))
    }


    const selectJiraUserHandler = e => {
        const k = e.target.name;
        const v = e.target.value;
        commonFunctions.onChangeHandler(e)

    }
    const onEnterKeyPress = (e, id, variableId) => {
        if (e.key === 'Enter') {
            fetchJiraUserList(id, state.data[e.target.name], variableId)
        }
    }
    const onChangeHandlerSearchInput = e => {
        const k = e.target.name;
        const v = e.target.value;

        commonFunctions.onChangeHandler(e)

    }


    function handleScheduleAdd() {
        setState((new_state) => ({
            ...new_state,
            scheduleEnabled: false,
        }))
    }

    function handleScheduleCancel() {
        setState((new_state) => ({
            ...new_state,
            scheduleEnabled: true,
        }))
    }

    function getFormatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    function getDateAfterYear() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year + 1, month, day].join('-');
    }

    return (
        <>
            {
                btnVariant == "re_attempt" ?
                    <button className="btn btn-primary-v2" onClick={handleClickOpen}>
                        Re Attempt Jira
                    </button>
                    :
                    <div className={classes.stageStatus + " " + getBackgroundColor()}>
                        <div onKeyDown={()=>{}} onClick={handleClickOpen} className=" flaticon-thumb-up-button" role="button" tabIndex={0}></div>
                    </div>
            }


            <Dialog
                fullWidth={state.fullWidth}
                maxWidth={state.maxWidth}
                fullScreen={fullScreen}
                open={state.open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <div className="ml-15 d-flex align-center space-between">
                        {variant === "only-view" ?
                            <p className="font-18 font-family-nunito">This stage is approved by : {state.approver_name}</p> :
                            <p className="font-18 font-family-nunito">Approve {stage_name}</p>}

                        <Toolbar>
                            <IconButton style={{ marginLeft: 'auto' }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon className="font-24" />
                            </IconButton>
                        </Toolbar>
                    </div>
                </AppBar>
                <div className='d-grid' style={{ gridTemplateColumns: '365px 1fr', height: '100vh', overflow: "hidden" }}>

                    {
                        variant === "only-view" ?
                            <SidePanelApprovalQuestions
                                stage_instance_status={stage_instance_status ? stage_instance_status : null}
                            // variant="only-view"
                            />
                            :
                            <SidePanelApprovalQuestions
                                stage_instance_status={stage_instance_status ? stage_instance_status : null}
                                variant="only-view"
                            />
                    }

                    <div className="" style={{ overflow: "hidden", position: "relative", height: "100%" }}>
                        <div className="d-flex" style={{ flexDirection: "column", width: "100%", height: "85%", overflowY: "auto" }}>  {/*height: "540px"*/}
                            <div className="" >

                                <div className="bg-fc-grey border-bottom">
                                    {
                                        state.show_loading_icon ?
                                            <div className='d-flex align-center justify-center' style={{ height: 250 }}>
                                                <Loading varient="light" />
                                            </div>

                                            :
                                            <div className='card' style={{ margin: '30px' }}>
                                                <div className="card-body">
                                                    {variant !== "only-view" ?
                                                        
                                                            <Grid container alignItems="center" className="">
                                                                {
                                                                    questionnaires && questionnaires.length > 0 ?
                                                                        <>
                                                                            <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={6}>
                                                                                <p>Questions</p>
                                                                            </Grid>
                                                                            <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={6}>
                                                                                <p>Add Your Response</p>
                                                                            </Grid>
                                                                        </>
                                                                        :
                                                                        <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={12}>
                                                                            <p>Add your comment</p>
                                                                        </Grid>
                                                                }
                                                                <Grid className=" pd-10 font-12" item xs={12}>
                                                                    {
                                                                        questionnaires && questionnaires.length > 0 ? questionnaires.map((variable, index) => {
                                                                            return (
                                                                                <Grid container className="border-bottom">
                                                                                    <Grid key={index} item xs={6} className="d-flex align-center"
                                                                                        style={
                                                                                            variable.type === 'checkbox' ?
                                                                                                { height: '120px', padding: '10px' } :
                                                                                                variable.type === "people" ?
                                                                                                    { minHeight: '80px', maxHeight: '300px', padding: '10px' } :
                                                                                                    { height: '80px', padding: '10px' }}>
                                                                                        <p className="font-12">{variable.question}&nbsp;<span className="font-12" style={{ color: 'red' }}>*</span></p>
                                                                                    </Grid>
                                                                                    <Grid key={index} item xs={6} className=""
                                                                                        style={
                                                                                            variable.type === 'checkbox' ?
                                                                                                { height: '120px', padding: '10px', overflowY: 'scroll' } :
                                                                                                variable.type === 'people' ? { minHeight: '80px', maxHeight: '300px', padding: '10px' } :
                                                                                                    { height: '80px', padding: '10px' }}>
                                                                                        {
                                                                                            variable.type === "people" ?
                                                                                                <>

                                                                                                    <Grid item lg={12}>
                                                                                                        <span style={{ fontSize: '12px', color: '#828282' }}>
                                                                                                            Search the user here.</span><span style={{ fontSize: '11px', color: '#828282' }}>&nbsp;(Please enter atleast first three characters of name)</span>
                                                                                                        <div className={classes.searchBar}>

                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                varient="handle-keys"
                                                                                                                placeholder="Search User"
                                                                                                                name={`user_${variable.id}`}
                                                                                                                data={state.data}
                                                                                                                error={state.error}
                                                                                                                onChangeHandler={onChangeHandlerSearchInput}
                                                                                                                onKeyPress={(e, template_id) => { onEnterKeyPress(e, variable.job_template, variable.id) }}
                                                                                                            />
                                                                                                            <button className='btn btn-searchbar' onClick={() => { fetchJiraUserList(variable.job_template, state.data[`user_${variable.id}`], variable.id) }}>
                                                                                                                <span className="material-icons material-symbols-outlined">
                                                                                                                    search
                                                                                                                </span>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </Grid>

                                                                                                    {
                                                                                                        state.user_object[`user_${variable.id}_loading_jira_user_list`] ?
                                                                                                            <Loading varient="light" /> :
                                                                                                            state.user_object[`user_${variable.id}_show_user_dropdown`] ?
                                                                                                                state.user_object[`user_${variable.id}_jira_user_list`] && state.user_object[`user_${variable.id}_jira_user_list`].length > 0 ?
                                                                                                                    <Grid item lg={12}>
                                                                                                                        <Input
                                                                                                                            type="select"
                                                                                                                            name={variable.id}
                                                                                                                            mandatorySign
                                                                                                                            list={
                                                                                                                                state.user_object[`user_${variable.id}_jira_user_list`].map(item => {
                                                                                                                                    return { label: item, id: item }
                                                                                                                                })}
                                                                                                                            label="Available Jira Users"
                                                                                                                            placeholder="Jira Users"
                                                                                                                            onChangeHandler={selectJiraUserHandler}
                                                                                                                            data={state.data}
                                                                                                                            error={state.error} />
                                                                                                                    </Grid>
                                                                                                                    : state.user_object[`user_${variable.id}_no_user_found`] ?
                                                                                                                        <AlertStrip
                                                                                                                            message={state.user_object[`user_${variable.id}_no_user_found`]}
                                                                                                                            variant="error" /> : null
                                                                                                                : state.user_object[`user_${variable.id}_no_jira_user_found`] ?
                                                                                                                    <AlertStrip
                                                                                                                        message={typeof (state.user_object[`user_${variable.id}_error_msg_jira_list`]) === "string" ? state.user_object[`user_${variable.id}_error_msg_jira_list`] : "Jira Users not found in this Job Template"}
                                                                                                                        variant="error" />
                                                                                                                    :
                                                                                                                    state.user_object[`user_${variable.id}_no_user_found`] ?
                                                                                                                        <AlertStrip
                                                                                                                            message={state.user_object[`user_${variable.id}_no_user_found`]}
                                                                                                                            variant="error" />
                                                                                                                        :
                                                                                                                        <Grid item lg={12}>

                                                                                                                            <Input
                                                                                                                                type="select"
                                                                                                                                name={variable.id}
                                                                                                                                mandatorySign
                                                                                                                                list={[]}
                                                                                                                                label="Available Jira Users"
                                                                                                                                placeholder="Jira Users"
                                                                                                                                onChangeHandler={null}
                                                                                                                                data={state.data}
                                                                                                                                error={state.error} />
                                                                                                                        </Grid>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                
                                                                                                    <Input
                                                                                                        type={variable.type === 'toggle' ? 'select' : variable.type}
                                                                                                        mandatorySign={variable.is_required}
                                                                                                        name={variable.id}
                                                                                                        list={variable.type === 'select' ?
                                                                                                            variable.possible_value ?
                                                                                                                variable.possible_value.split(',').map(item => ({

                                                                                                                    id: item.trim(), label: item.trim()
                                                                                                                }))
                                                                                                                : []
                                                                                                            :
                                                                                                            variable.type === 'checkbox' ?
                                                                                                                variable.possible_value ?

                                                                                                                    variable.possible_value.split(',').map(item => ({

                                                                                                                        id: item.trim(), label: item.trim()
                                                                                                                    }))
                                                                                                                    : []

                                                                                                                : variable.type === 'toggle' ? [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]
                                                                                                                    : []
                                                                                                        }
                                                                                                        label={variable.key}
                                                                                                        placeholder=""
                                                                                                        disable={variant == "only-view" ? true : false}
                                                                                                        onChangeHandler={variant == "only-view" ? null : variable.type === 'checkbox' ? onChangeHandlerForKeyValue : commonFunctions.onChangeHandler}
                                                                                                        data={state.data}
                                                                                                        error={state.error}
                                                                                                    />
                                                                                                
                                                                                        }




                                                                                    </Grid>
                                                                                </Grid>
                                                                            )
                                                                        }) : null
                                                                    }
                                                                </Grid>
                                                                {variant !== "only-view" ? <div className='border-bottom'></div> : null}
                                                            </Grid>
                                                        
                                                        :
                                                        
                                                            <Grid container alignItems="center" className="">
                                                                {
                                                                    state.question_arr && state.question_arr.length > 0 ?
                                                                        <>
                                                                            <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={6}>
                                                                                <p>Questions</p>
                                                                            </Grid>
                                                                            <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={6}>
                                                                                <p>Add Your Response</p>
                                                                            </Grid>
                                                                        </>
                                                                        :
                                                                        
                                                                            <Grid className="bg-f5-grey d-grid pd-10 font-12  border-bottom" item xs={12}>
                                                                                <p>Please enter your comment</p>
                                                                            </Grid>
                                                                        
                                                                }
                                                                {
                                                                    state.question_arr && state.question_arr.length > 0 ? state.question_arr.map((variable, index) => {
                                                                        return (
                                                                            <>
                                                                                {console.log(variable, "pipeline_question")}
                                                                                {
                                                                                    variable.pipeline_question ?
                                                                                        <>
                                                                                            <Grid key={index} item xs={6} className="border-bottom d-flex align-center" style={
                                                                                                variable.pipeline_question.type === 'checkbox' ?
                                                                                                    { height: '120px', padding: '10px' } :
                                                                                                    variable.pipeline_question.type === "people" ?
                                                                                                        { minHeight: '80px', maxHeight: '300px', padding: '10px' } :
                                                                                                        { height: '80px', padding: '10px' }}>
                                                                                                <p className="font-12">{variable.pipeline_question && variable.pipeline_question.question ? variable.pipeline_question.question : ""}&nbsp; <span className="font-12" style={{ color: 'red' }}>*</span> </p>
                                                                                            </Grid>
                                                                                            <Grid key={index} item xs={6} className="border-bottom" style={
                                                                                                variable.pipeline_question.type === 'checkbox' ?
                                                                                                    { height: '120px', padding: '10px' } :
                                                                                                    variable.pipeline_question.type === "people" ?
                                                                                                        { minHeight: '80px', maxHeight: '300px', padding: '10px' } :
                                                                                                        { height: '80px', padding: '10px' }}>

                                                                                                <Input
                                                                                                    type={variable.pipeline_question.type === 'toggle' ? 'select' : variable.pipeline_question.type === "select" || variable.pipeline_question.type === "people" ? "text" : variable.pipeline_question.type}
                                                                                                    mandatorySign={true}
                                                                                                    name={variable.pipeline_question.id}
                                                                                                    list={variable.pipeline_question.type === 'select' || 'toggle' ? variable.pipeline_question.possible_value ?

                                                                                                        variable.pipeline_question.possible_value.split(',').map(item => ({

                                                                                                            id: item.trim(), label: item.trim()
                                                                                                        }))

                                                                                                        : [{ id: 'yes', label: 'Yes' }, { id: 'no', label: 'No' }]

                                                                                                        : []
                                                                                                    }
                                                                                                    label={variable.pipeline_question.key}
                                                                                                    placeholder=""
                                                                                                    disable={variant == "only-view" ? true : false}
                                                                                                    onChangeHandler={variant == "only-view" ? null : commonFunctions.onChangeHandler}
                                                                                                    data={state.data}
                                                                                                    error={state.error}
                                                                                                />
                                                                                            </Grid>
                                                                                        </>
                                                                                        : null
                                                                                }
                                                                            </>


                                                                        )
                                                                    }) : null
                                                                }
                                                                <div className='border-bottom'></div>

                                                                <ApprovedContent
                                                                    inherits={state.child_inherits.approved_comment}
                                                                    prev_state={state.approved_comment}
                                                                    btnVariant={btnVariant}
                                                                />

                                                            </Grid>
                                                        

                                                    }


                                                    {
                                                        variant == "only-view" ? null :
                                                            <ApprovedContent
                                                                inherits={state.child_inherits.approved_comment}
                                                                prev_state={state.approved_comment}
                                                                btnVariant={btnVariant}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                    }

                                </div>

                            </div>

                            {
                                state.approval_flag &&
                                <div style={{ margin: "0px 30px" }}>
                                    <div className='switch-input-wrapper'>
                                        <Input
                                            type="switch"
                                            name="schedule"
                                            label="Do you want to schedule the execution of the pipeline?"
                                            onChangeHandler={variant != 'only-view' ? commonFunctions.toggleState : false}
                                            error={state.error}
                                            data={state.data}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                state.data.schedule &&
                                <div style={{ margin: "30px 30px", border: "1px solid #EAEAEA", borderRadius: "4px 4px 0px 0px" }}>
                                    <div className='top-bar bg-f5-grey d-grid pd-10 font-12  border-bottom' style={{ background: "#FAFAFA", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <p style={{ fontFamily: 'Heebo', fontStyle: "normal", fontWeight: "400", fontSize: "16px", lineHeight: "24px", color: "#000000", }}>Schedule the execution of the pipeline</p>
                                        {/* <button style={{color : "#007EFF", background : "none" , border : "0px"}}><EditIcon style={{color : "#007EFF"}} />Reschedule</button> */}
                                        {
                                            variant == "only-view" ?
                                                <button className='btn btn-link cursor-not-allowed' style={{ color: '#808080' }} onClick={() => { }}>
                                                    <EditIcon style={{ color: '#808080', fontSize: '16px', verticalAlign: 'text-bottom' }} />&nbsp;Reschedule
                                                </button>
                                                :
                                                <button className='btn btn-link' style={{ color: '#007EFF' }} onClick={handleScheduleCancel}>
                                                    <EditIcon className="text-anchor-blue" style={{ color: '#007EFF', fontSize: '16px', verticalAlign: 'text-bottom' }} />&nbsp;Reschedule
                                                </button>
                                        }

                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 20px", color: "#EAEAEA" }}>
                                        <div style={{ width: "100%", paddingRight: "3px" }}>
                                            <Input
                                                type="date"
                                                name="schedule_date"
                                                label="Date"
                                                mandatorySign
                                                onChangeHandler={state.scheduleEnabled && commonFunctions.onChangeHandler}
                                                error={state.error}
                                                data={state.data}
                                                min={getFormatDate()}
                                                max={getDateAfterYear()}
                                            />
                                        </div>
                                        <div style={{ width: "100%", paddingLeft: "3px" }}>
                                            <Input
                                                type="time"
                                                name="schedule_time"
                                                label="Time"
                                                placeholder="Add Time"
                                                mandatorySign
                                                onChangeHandler={state.scheduleEnabled && commonFunctions.onChangeHandler}
                                                error={state.error}
                                                data={state.data}
                                            />
                                        </div>
                                    </div>
                                    {
                                        variant == 'only-view' ?
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#FAFAFA", borderTop: "1px solid #D2D2D2", height: "50px", padding: "0px 8px" }}>
                                                <button className="btn btn-outline-grey btn-disable" style={{ width: "100px" }} onClick={() => { }}>Cancel</button>
                                                <button className="btn btn-submit btn-disable" style={{ width: "100px" }} onClick={() => { }}>Add</button>
                                            </div>
                                            :
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#FAFAFA", borderTop: "1px solid #D2D2D2", height: "50px", padding: "0px 8px" }}>
                                                <button className="btn btn-outline-grey" style={{ width: "100px" }} onClick={handleScheduleCancel}>Cancel</button>
                                                <button className="btn btn-submit" style={{ width: "100px" }} onClick={handleScheduleAdd}>Add</button>
                                            </div>
                                    }
                                </div>
                            }
                        </div>

                        {
                            variant == "only-view" ? null :
                                <div className="d-flex align-center pd-10 border-top" style={{ position: "absolute", bottom: "0px", width: "100%", height: "15%", justifyContent: "space-between", background: "white" }}>
                                    <button className="btn btn-outline-grey" onClick={disapprovePipeline}>Decline</button>
                                    {
                                        state.data.schedule ?
                                            <button className="btn btn-submit" onClick={validateAndSave}>Approve and Schedule</button>
                                            :
                                            <button className="btn btn-submit" onClick={validateAndSave}>Approve and Run</button>
                                    }
                                </div>
                        }

                    </div>

                </div>
            </Dialog>
        </>
    )
}

FillApprovalQuestions.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };


export default FillApprovalQuestions;


const ApprovedContent = (props) => {
    const inherits = props.inherits ? props.inherits : {};
    const prev_state = props.prev_state ? props.prev_state : null;
    const [state, setState] = useState(prev_state && prev_state.comment !== null ? { data: { comment: prev_state.comment }, error: {} } : {
        data: {}, error: {}, validations: {
            comment: [VALIDATION_TYPE_REQUIRED]
        }
    });
    const btnVariant = props.btnVariant ? props.btnVariant : null;
    console.log(btnVariant, "btnVariantbtnVariant")
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    return (
        <Grid item lg={12} className="border-bottom" style={{ height: '', padding: '10px' }}>

            <div className="border-none-input card-body pd-10">
                <Input
                    type="text"
                    label="Add Comments"
                    placeholder="Comments"
                    mandatorySign
                    name="comment"
                    error={state.error}
                    disable={prev_state && (prev_state.comment !== null && btnVariant != "re_attempt") ? true : false}
                    onChangeHandler={prev_state && (prev_state.comment !== null && btnVariant != "re_attempt") ? null : commonFunctions.onChangeHandler }
                    data={state.data} />
            </div>

        </Grid>
    )
}

ApprovedContent.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    searchBar: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 60px',
        border: '1px solid #b7b7b7',
        marginBottom: '15px',
        borderRadius: '4px',
        '& .input-component': {
            marginBottom: '0!important',
            borderRight: '1px solid #b7b7b7',
            '& input': {
                border: 'none',
                '&:focus': {
                    outline: 'none',
                    border: '0px!important'
                },
                '&:disabled': {
                    cursor: 'not-allowed'
                }
            }
        },
        '& .btn': {
            marginRight: 0,
            padding: '.3rem 1rem'
        }
    },
    stageStatus: {
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    revokedStage: {
        backgroundColor: '#6b74a8',
        '& .flaticon-round-info-button:before': {
            color: '#fff',
        },
        '& .flaticon-thumb-up-button': {
            color: '#fff!important',
            '&:before': {
                color: '#fff!important',
                fontSize: '16px!important'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
    },
    failedStage: {
        backgroundColor: '#ff8969',
        '& .flaticon-round-info-button:before': {
            color: '#fff',
        },
        '& .flaticon-thumb-up-button': {
            color: '#fff!important',
            '&:before': {
                color: '#fff!important',
                fontSize: '16px!important'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },

    },
    approvalStage: {
        backgroundColor: '#c294f1!important',
        '& .flaticon-round-info-button:before': {
            color: '#fff',
        },
        '& .flaticon-thumb-up-button': {
            color: '#fff!important',
            '&:before': {
                color: '#fff!important',
                fontSize: '16px!important'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
    },
    succesStage: {
        backgroundColor: '#61e187',
        '& .flaticon-round-info-button:before': {
            color: '#fff',
        },
        '& .flaticon-thumb-up-button': {
            color: '#fff!important',
            '&:before': {
                color: '#fff!important',
                fontSize: '16px!important'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
    },
    runningStage: {
        backgroundColor: '#ffbf00',
        '& .flaticon-round-info-button:before': {
            color: '#fff',
        },
        '& .flaticon-thumb-up-button': {
            color: '#fff!important',
            '&:before': {
                color: '#fff!important',
                fontSize: '16px!important'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
    },
    initiatedStage: {
        backgroundColor: '#ffbf00',
        '& .flaticon-round-info-button:before': {
            color: '#fff',
        },
        '& .flaticon-thumb-up-button': {
            color: '#fff!important',
            '&:before': {
                color: '#fff!important',
                fontSize: '16px!important'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
    },
    skippedStage: {
        backgroundColor: '#52bfe7',
        '& .flaticon-round-info-button:before': {
            color: '#fff',
        },
        '& .flaticon-thumb-up-button': {
            color: '#fff!important',
            '&:before': {
                color: '#fff!important',
                fontSize: '16px!important'
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
    },
    root: {
        '& .border-none-input': {
            '& .input-component': {
                '& input': {
                    border: 'none!important',
                    '&:focus': {
                        outline: '0!important',
                        border: 'none!important'
                    }
                }
            }
        },


        // '& .h-300': {
        //     height: 350,
        //     overflowy: 'scroll'
        // }
    }
}));