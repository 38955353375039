import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import SingleServiceCard from './SingleServiceCard';
// import DialogActions from '@material-ui/core/DialogActions';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
// import GenerateURL from '../../util/APIUrlProvider';
// import properties from '../../properties/properties';
// import { PostData } from '../../util/apiInvoker';
import { Loading } from '../utils/Loading';
import { Redirect, useParams } from 'react-router-dom';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import StepProgressDetailed from "../../components/genericComponents/StepProgressDetailed";
import RollbackServiceListing from './RollbackServiceListing';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    '.MuiPaper-root': {
        backgroundColor: '#0f7f7!important'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: '#fff!important'
    },
    timeSection: {
        padding: '4px 10px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '16px',
        fontSize: '12px',
        width: 'fit-content',
        position: 'relative',
        margin: 'auto',
        zIndex: 99
    },
    divider: {
        height: '1px',
        width: '100%',
        backgroundColor: '#ccc',
        top: '1.5rem',
        position: 'absolute',
        zIndex: 9
    },
    mainsec: {
        position: 'relative',
        margin: '32px 0px'
    },
    margin: {
        width: '32px',
        border: '2px solid rgb(0, 134, 255)',
        height: '32px',
        display: 'inline-block',
        borderRadius: '50%',
        '&.btn-filter': {
            marginRight: 5,
            marginLeft: 5
        }
    },

    root: {
        padding: theme.spacing(4),
        marginBottom: "30px",

        "& .body": {
            
        }
    },

    confirmationDialog: {

        "& .MuiPaper-root": {
            width: "700px !important",
            height: "500px !important",
            position: "relative"
        },

        "& .MuiDialogTitle-root": {
            padding: "0px !important",

        },

        "& .dialog-header": {

            backgroundColor: "#124D9B",
            borderBottom: "1px solid #DDDDDD",
            height: "64px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "25px",


            "& .confirmation-heading": {
                // fontFamily: 'Monsterrat',   
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#FFFFFF",
            },

            "& .confirmation-subheading": {
                // fontFamily: 'Monsterrat',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "16px",
                color: "#FFFFFF",
            }
        }
    },

    confirmationBody: {
        padding: "30px !important",
        paddingBottom: "80px !important",

        "& .dialog-content": {
            "& table": {
                borderCollapse: "collapse",
                width: "100%",
                tableLayout: "fixed",
                background: " #FFFFFF",
                border: "1px solid #DDDDDD",

                "& tbody": {
                    "& tr:nth-child(even)": {
                        backgroundColor: "#F4F4F4",
                    },

                    "& tr:last-child": {

                        "& td": {
                            borderBottom: "0",
                            // fontFamily : "Monsterrat"
                        }

                    }
                }
            },

            "& td,th": {
                // fontFamily : "Monsterrat",
                borderBottom: "1px solid #D0D0D0",
                textAlign: "left",
                padding: "5px",
            },

            "& th": {
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "24px",
                color: "#000000",
            },

            "& td": {
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "21px",
                color: "#373737",
            },
        }
    }

}));


const RollbackServices = (props) => {
    const classes = useStyles();
    const open = props.isOpen;
    const handeCloseDialog = props.handeCloseDialog;
    const pipeline_data = props.pipeline_data;
    const pipeline_id = props.pipeline_id;
    const handleSaveSelectedRollback = props.handleSaveSelectedRollback;
    const selected_rollbacks = props.selected_rollbacks;
    const rollback_name_list = props.rollback_name_list;
    const handleRemoveRollbackPoint = props.handleRemoveRollbackPoint;
    const service_list = props.service_list;
    const fetchPipelineServices = props.fetchPipelineServices;
    const new_rollback_data = props.new_rollback_data;
    const { application_id } = useParams();
    const data = props.data;
    const handleSubmit = props.handleSubmit

    const [state, setState] = useState({
        isOpen: false,
        is_successfull: false,
        canRedirect: false
    })


    
    useEffect(() => {
        setState((new_state) => ({
            ...new_state,
            service_names : getServiceData(new_rollback_data),
        }))
    },[new_rollback_data])


    function getServiceData(data){
        var service_names = data && Object.keys(data)

        return service_names;
    }


    function handleConfirmationDialog() {
        setState((prevState) => ({
            ...prevState,
            isOpen: !state.isOpen
        }))
    }

    function handleClose() {
        console.log('closed')
    }

    const ConfirmationTransition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
  

    function handleSelectedRollbackSubmit() {
        setState(new_state => ({
            ...new_state,
            isOpen: false,
        }));

        handleSubmit();
        handeCloseDialog();
    }

    console.log(rollback_name_list, "nzdbdbzdbsbdhjzd")

    return (
        <>
            <div>
                <Dialog fullScreen open={open} TransitionComponent={Transition} style={{ backgroundColor: '#f0f7f7' }}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                <div>
                                    <p>{pipeline_data.name}</p>
                                    {/* <p style={{ fontSize: "12px" }}>Environment Name:        Type: </p> */}
                                </div>
                            </Typography>
                            <div style={{ margin: "15px" }}>
                                <IconButton edge="end" color="inherit" onClick={fetchPipelineServices} aria-label="close">
                                    <RefreshIcon />
                                </IconButton>
                            </div>
                            <IconButton edge="end" color="inherit" onClick={handeCloseDialog} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {
                        data.loading ?
                            <div style={{ minHeight: '420px', display: 'flex' }}>
                                <Loading varient="light" />
                            </div>  
                            :
                            new_rollback_data && Object.keys(new_rollback_data).length > 0 ?
                                <>
                                    <p style={{
                                        padding: "25px 0 0 33px",
                                        color: "var(--CONTENT-SECONDARY, #505050)",
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        lineHeight: "normal",
                                    }}>For following services deployment was attempted</p>
                                    <div className={classes.root}>
                                        <RollbackServiceListing service_names={state.service_names} rollback_data={new_rollback_data} handleSaveSelectedRollback={handleSaveSelectedRollback} selected_rollbacks={selected_rollbacks} application_id={application_id}/>
                                    </div>
                                </>
                                :
                                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <div className="svg" style={{ width: "400px", marginTop: "100px" }}>
                                        <div className="servicePanel">
                                            <div className="blank-div">
                                                <div className="blank-div-text">
                                                    None of the services were deployed with this pipeline
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    }
                    <div style={{ position: "fixed", bottom: "0", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", height: "75px", border: "1px solid #D2D2D2", background: "#FAFAFA", padding: "0 15px" }}>
                        <button className="btn btn-outline-dark-grey" style={{ width: "100px" }} onClick={handeCloseDialog}>Back</button>
                        <button className="btn btn-submit" style={{ width: "100px" }} onClick={handleConfirmationDialog}>Rollback</button>
                    </div>
                </Dialog>
                {
                    state.isOpen &&
                    <Dialog
                        open={state.isOpen}
                        TransitionComponent={ConfirmationTransition}
                        keepMounted
                        onClose={handleClose}
                        className={classes.confirmationDialog}
                        maxWidth="md"
                    >
                        <DialogTitle>
                            <div className='dialog-header'>
                                <div>
                                    <p className='confirmation-heading'>Rollback points</p>
                                    <p className='confirmation-subheading'>are you sure, you want to rollback to selected rollback points?</p>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent className={classes.confirmationBody}>
                            {
                                rollback_name_list && rollback_name_list.length > 0 ?
                                    <div className='dialog-content'>
                                        <Grid container>
                                            <Grid item lg={12}>
                                                <table style={{ width: "100%" }}>
                                                    <thead>
                                                        <tr style={{ backgroundColor: "#F4F4F4", height: "45px" }}>
                                                            <th>Service</th>
                                                            <th>Deployment Name</th>
                                                            <th>Rollback points</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            rollback_name_list && rollback_name_list.map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{item.service_name}</td>
                                                                        <td>{item.deployment_name}</td>
                                                                        <td>{item.deploy_id}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    :
                                    <div className="blank-div">
                                        <div className="blank-div-text">
                                            No Rollback points are selected.
                                        </div>
                                    </div>
                            }

                        </DialogContent>
                        <div style={{ position: "absolute", bottom: "0", width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", height: "65px", border: "1px solid #D2D2D2", background: "#FAFAFA", padding: "0 15px" }}>
                            <button className="btn btn-outline-dark-grey" style={{ width: "100px" }} onClick={handleConfirmationDialog}>CLOSE</button>
                            <button className="btn btn-submit" style={{ width: "100px" }} onClick={handleSelectedRollbackSubmit}>CONFIRM</button>
                        </div>
                    </Dialog>
                }
            </div>
        </>
    )
}
RollbackServices.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default RollbackServices