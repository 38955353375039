import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import { Loading } from '../../../utils/Loading';
import { defaultLinkedIssueState } from './LinkedIssuesDialog';
import { Category } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import resolve from 'resolve';

export default function CreateSnowTicket({ pipelineVars, 
    jiraIssueKeys, 
    jobStepOrderOutputVars,snow_refrence_key,pipeline, ...props }) {
    const inherits = props.inherits;
    //const jira_id_reference_key = props.jira_id_reference_key
    const prev_state = props.prev_state;
    const default_job_template = props.default_job_template ? props.default_job_template : null;
    //const getIssueType = props.getIssueType;
    console.log("heujsansa",snow_refrence_key);
    const [state, setState] = useState(prev_state ? getCreateSnowTicketDefaultState(prev_state,snow_refrence_key) : getCreateSnowTicketDefaultState(snow_refrence_key));
    useEffect(() => {
        if (default_job_template ) {
            // fetchSnowCategoryList();
            // fetchSnowServiceList();
            // fetchSnowConfList();
            fetchData();
        }

    }, [default_job_template])

    useEffect(()=>{
        setState((prev_state)=>({
            ...prev_state,
            data: {
                ...prev_state.data,
                issue_key : prev_state.data.issue_key ? prev_state.data.issue_key : snow_refrence_key?.label,
            }
        }))
    },[snow_refrence_key])

    const validationKeys = [
        "snow_description",
        "short_description",
        "snow_ticket_type",
        "service",
        "category",
        "configuration_items",
        "implementation_plan",
        "justification",
        "risk_impact_analysis",
        "backout_plan",
        "test_plan",
        "start_date",
        "end_date",
        "impact",
        "risk",
        "snow_priority"
    ];

    console.log("rnjdnjew",jobStepOrderOutputVars);

    function fetchData(){
        const requestCategory = new Promise((resolve, reject) => {
            fetchSnowCategoryList()
                .then(resolve)
                .catch(error => {
                    reject({ api: 'category', error }); // Marking error from category API
                });
        });
    
        const requestService = new Promise((resolve, reject) => {
            fetchSnowServiceList()
                .then(resolve)
                .catch(error => {
                    reject({ api: 'service', error }); // Marking error from service API
                });
        });

        const requestSystemSetting = new Promise((resolve,reject) => {
            fetchSystemSetting()
                .then(resolve)
                .catch(error => {
                    reject({ api: 'systemSetting', error });
                })
        })

        setState((prev_state)=>({
            ...prev_state,
            data: {
                ...prev_state.data,
                loading: true,
            }
        }))
        Promise.all([requestCategory, requestService,requestSystemSetting])
        .then(([categoryResponse, serviceResponse,systemSettingResponse]) => {
            // Render UI with both responses
            storeInState(categoryResponse, serviceResponse,systemSettingResponse);
        })
        .catch(({ api, error }) => {
            // Handle error from the specific API
            console.error(`Error from ${api} API:`, error);
            setState((prev_state)=>({
                ...prev_state,
                data: {
                    ...prev_state.data,
                    loading: false,
                },
                error: {
                    ...prev_state.error,
                    ...error,
                }
            }))
        });
    }



    function fetchSnowCategoryList() {
        return new Promise((resolve,reject)=>{
            let requestInfo = {
                endPoint: GenerateURL({}, properties.api.snow_category_list) + "?job_template_id=" + default_job_template,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
    
            InvokeApi(requestInfo, (fetchData)=>resolve(fetchData), (error)=>reject(error));
            // setState(new_state => ({
            //     ...new_state,
            //     loading_data: true
            // }));
        })
        
    }

    
    function fetchSnowServiceList() {
        return new Promise((resolve,reject)=>{
            let requestInfo = {
                endPoint: GenerateURL({}, properties.api.snow_service_list) + "?job_template_id=" + default_job_template,
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }
    
            InvokeApi(requestInfo,(fetchData)=>resolve(fetchData), (error)=>reject(error));
        })
        
        // setState(new_state => ({
        //     ...new_state,
        //     loading_data: true
        // }));
    }

    function fetchSystemSetting() {
        return new Promise((resolve,reject)=>{
            let requestInfo = {
                endPoint: GenerateURL({}, properties.api.system_settings),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            InvokeApi(requestInfo, (fetchData)=>resolve(fetchData),(error)=>reject(error) );
        })
    }

    function storeInState(categoryResponse, serviceResponse,systemSettingResponse){
        let snowMadatory = systemSettingResponse.find(item => item.key == "SNOW_MANDATORY_FIELDS")
        let snowMadatoryField =  snowMadatory ? JSON.parse(snowMadatory.value) : [];
        // let validationObj = {};
        // snowMadatoryField.forEach((item)=>{
        //     validationObj[item] = [VALIDATION_TYPE_REQUIRED]
        // })
        const validationObj = snowMadatoryField.reduce((obj, item) => {
            obj[item] = [VALIDATION_TYPE_REQUIRED];
            return obj;
          }, {}); 
        console.log("sahjhjsbhda",validationObj);
        setState((prev_state)=>({
            ...prev_state,
            data: {
                ...prev_state.data,
                loading: false,
            },
            servicesList: serviceResponse.map(item=>({label: item,id:item})),
            categoryList: categoryResponse.map(item=>({label: item,id:item})),
            snowMadatoryField : [...snowMadatoryField],
            validations: {
                ...prev_state.validations,
                ...validationObj,
            }
        }))
    }



    const commonFunctions = getCommonFunctions(state, setState, inherits);
    console.log('state_data_oo1', prev_state)
   
    const ticketTypes = [
        { label: "Normal", id: 'normal' },  // Unplanned service disruptions
        { label: "Emergency", id: 'emergency' },  // Standard service requests
      ];

    const impactList = [
        {label:"High",id:"1"},
        {label: "Medium",id: "2"},
        {label: "Low",id:"3"},
    ]

    const riskList = [
        {label:"High",id:"1"},
        {label: "Moderate",id: "2"},
        {label: "Low",id:"3"},
    ]

    const priorityList = [
        {label:"Critical",id:"1"},
        {label:"High",id:"2"},
        {label: "Moderate",id: "3"},
        {label: "Low",id:"4"},
    ]


    console.log("jdsjhsaj",state);
    return (
        <>
        {state.data.loading ? //state.data.loading  
        <div style={{paddingBottom: "14px"}}>
            <Loading varient="light" />
        </div>
         :
            <Grid container spacing={2} style={{padding:"9px"}}>            
                    <Input
                        type="text"
                        mandatorySign={state.snowMadatoryField.includes("snow_description")}
                        name="snow_description"
                        placeholder="Description"
                        label="Description"
                        onChangeHandler={commonFunctions.onChangeHandler}
                        data={state.data}
                        error={state.error} />

            {/* <Input
                type="text"
                name="status"
                mandatorySign
                label="Status"
                placeholder="Status"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} /> */}
            <Input
                type="text"
                name="short_description"
                label="Short Description"
                mandatorySign={state.snowMadatoryField.includes("short_description")}
                placeholder="Short Description"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />


            <Input
                type="select"
                name="snow_ticket_type"
                label="Snow Ticket Type"
                //placeholder="Ticket Summary"
                mandatorySign={state.snowMadatoryField.includes("snow_ticket_type")}
                list={ticketTypes}
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            <Input
                type="select"
                name="service"
                label="Service"
                mandatorySign={state.snowMadatoryField.includes("service")}
                //placeholder="description for the ticket
                list={state.servicesList}
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />

            <Input
                type="select"
                name="category"
                label="Category"
                mandatorySign={state.snowMadatoryField.includes("category")}
                //placeholder="description for the ticket
                list={state.categoryList}
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            <Input
                type="text"
                name="configuration_items"
                label="Configuration Items"
                mandatorySign={state.snowMadatoryField.includes("configuration_items")}
                //placeholder="description for the ticket
                //list={state.configurationItemsList}
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />

            <Input
                type="select"
                name="snow_priority"
                label="Priority"
                mandatorySign={state.snowMadatoryField.includes("snow_priority")}
                list={priorityList}
                placeholder="Priority"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />

            <Input
                type="select"
                name="risk"
                label="Risk"
                mandatorySign={state.snowMadatoryField.includes("risk")}
                list={riskList}
                placeholder="Risk"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            
            <Input
                type="select"
                name="impact"
                label="Impact"
                mandatorySign={state.snowMadatoryField.includes("impact")}
                list={impactList}
                placeholder="Impact"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            
            <Input
                type="text"
                name="implementation_plan"
                label="Implementation Plan"
                mandatorySign={state.snowMadatoryField.includes("implementation_plan")}
                placeholder="Implementation Plan"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            <Input
                type="text"
                name="justification"
                label="Justification"
                mandatorySign={state.snowMadatoryField.includes("justification")}
                placeholder="Justification"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            

            <Input
                type="text"
                name="risk_impact_analysis"
                label="Risk Impact Analysis"
                mandatorySign={state.snowMadatoryField.includes("risk_impact_analysis")}
                placeholder="Risk Impact Analysis"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            
            <Input
                type="text"
                name="backout_plan"
                label="Backout Plan"
                mandatorySign={state.snowMadatoryField.includes("backout_plan")}
                placeholder="Backout Plan"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            
            <Input
                type="text"
                name="test_plan"
                label="Test Plan"
                mandatorySign={state.snowMadatoryField.includes("test_plan")}
                placeholder="Test Plan"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error} />
            <Grid item lg={6}>
                <Input
                    type="date"
                    name="start_date"
                    label="Start Date"
                    mandatorySign={state.snowMadatoryField.includes("start_date")}
                    placeholder="Start Date"
                    onChangeHandler={commonFunctions.onChangeHandler}
                    data={state.data}
                    error={state.error} />
            </Grid>
            
            <Grid item lg={6}>
                <Input
                    type="date"
                    name="end_date"
                    label="End Date"
                    mandatorySign={state.snowMadatoryField.includes("end_date")}
                    placeholder="End Date"
                    onChangeHandler={commonFunctions.onChangeHandler}
                    data={state.data}
                    error={state.error} />
            </Grid>
            

        </Grid>
        }
            
        </>
        
    )
}

CreateSnowTicket.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


function getCreateSnowTicketDefaultState(prev_state, snow_refrence_key) {
    console.log(prev_state, "fdsafjknjkjknkjkjsd")
    return {
        data: prev_state ? {
            snow_description: prev_state.snow_description ? prev_state.snow_description : "",
            // status: prev_state.status ? prev_state.status : null,
            short_description: prev_state.short_description ? prev_state.short_description : null,
            snow_ticket_type: prev_state.snow_ticket_type ? prev_state.snow_ticket_type : null,
            service: prev_state.service ? prev_state.service : null,
            category: prev_state.category ? prev_state.category : null,
            configuration_items: prev_state.configuration_items ? prev_state.configuration_items: null,
            implementation_plan: prev_state.implementation_plan ? prev_state.implementation_plan: null,
            justification: prev_state.justification ? prev_state.justification: null,
            risk_impact_analysis: prev_state.risk_impact_analysis? prev_state.risk_impact_analysis : null,
            backout_plan: prev_state.backout_plan ? prev_state.backout_plan : null,
            test_plan: prev_state.test_plan ? prev_state.test_plan : null,
            start_date: prev_state.start_date ? prev_state.start_date: null,
            end_date: prev_state.end_date ? prev_state.end_date : null,
            impact : prev_state.impact ? prev_state.impact : null,
            risk : prev_state.risk ? prev_state.risk : null,
            snow_priority : prev_state.snow_priority ? prev_state.snow_priority : null,
            issue_key: prev_state.issue_key ? prev_state.issue_key : snow_refrence_key?.label,
        } : {
            issue_key: snow_refrence_key.label,
        },
        
        error: {},
        snowMadatoryField: [],
        validations: {
            // snow_description: [VALIDATION_TYPE_REQUIRED],
            // short_description: [VALIDATION_TYPE_REQUIRED],
            // snow_ticket_type: [VALIDATION_TYPE_REQUIRED],
            // service: [VALIDATION_TYPE_REQUIRED],
            // category: [VALIDATION_TYPE_REQUIRED],
            // configuration_items: [VALIDATION_TYPE_REQUIRED],
            // implementation_plan: [VALIDATION_TYPE_REQUIRED],
            // justification: [VALIDATION_TYPE_REQUIRED],
            // risk_impact_analysis: [VALIDATION_TYPE_REQUIRED],
            // backout_plan: [VALIDATION_TYPE_REQUIRED],
            // test_plan: [VALIDATION_TYPE_REQUIRED],
            // start_date: [VALIDATION_TYPE_REQUIRED],
            // end_date: [VALIDATION_TYPE_REQUIRED],
            // impact : [VALIDATION_TYPE_REQUIRED],
            // risk : [VALIDATION_TYPE_REQUIRED],
            // snow_priority : [VALIDATION_TYPE_REQUIRED],
        },
    };
}
