import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getCommonFunctions } from '../../../views/serviceRevamp/add/ci_flow/SourceDetails';
import Grid from '@material-ui/core/Grid';
import { Input } from '../Input';
import EditableTable from '../EditableTable';
import { ValidateDataSet } from '../../../util/Validator';
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles((theme) => ({
  root_class: {
    backgroundColor: '#fff',
    marginBottom: '20px',
    borderRadius: '6px',
    overflow: 'hidden',
    border: '1px solid #dedede!important',
    '& .conditions-div-header': {
      borderBottom: '0px solid #dedede!important',
      backgroundColor: ' #fafafa',
      '& .switch-input-wrapper': {
        '& .input-component': {
          marginBottom: '0px',
          paddingBottom: 0,
          '& .switch': {
            width: 'auto'
          }
        }
      }
    },
    '& .editable-table-wrapper': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #dedede!important',
      '& .label-style-wrapper': {
        fontWeight: 400
      }
    }
  },
  root: {
    margin: 0,
    backgroundColor: '#fff',
    '& .no-data-msg': {
      fontSize: 12,
      color: '#979797',
      textAlign: 'center',
      width: '335px',
      margin: '20px auto',
      minHeight: '80px',
      '& p': {
        marginBottom: '5px'
      }
    },
    '& .add-more-strip': {
      borderBottom: '1px solid #f5f5f5',
      borderTop: '1px solid #f5f5f5',
      padding: '7px 16px',
      textAlign: 'center',
      '& p': {
        '& span': {
          color: '#747373',
          fontSize: '12px'
        }
      }
    },
    '& .multiple-enteries-card': {
      border: '1px solid #dedede',
      borderRadius: '8px',
      margin: '20px',
      overflow: 'hidden',
      boxShadow: 'rgba(0, 0, 0, 0.6) 0.877px 1.798px 10px 0px',
      '& .multiple-enteries-card-header': {
        backgroundColor: '#fafafa',
        padding: '13px 10px',
        borderBottom: '1px solid #dedede',
        color: '#595656',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'space-between'
      },
      '& .multiple-enteries-card-body': {
        padding: '10px 20px'
      },
      '& .multiple-enteries-card-footer': {
        padding: '10px 20px',
        borderTop: '1px solid #dedede',
        backgroundColor: '#fafafa'
      }
    },
    '& .btn-with-icon': {
      border: '1px solid transparent',
      backgroundColor: 'transparent',
      fontSize: '12px!important',
      borderRadius: '4px',
      padding: '5px 9px !important',
      minWidth: '85px !important',
      overflow: 'hidden',
      '& .material-icons': {
        fontSize: '15px',
        marginRight: '5px'
      }
    },
    '& .btn-save': {
      border: '2px solid #007EFF !important',
      backgroundColor: '#fff',
      color: '#007EFF'
    },
    '& .btn-cancel': {
      border: '2px solid #959595 !important',
      backgroundColor: '#fff',
      color: '#959595'
    },
  }
}));
const MultipleEnteriesInput = (props) => {
  const inherits = props.inherits ? props.inherits : {};
  const prev_state = props.prev_state ? props.prev_state : null;
  //const [error, setError] = useState();
  const [state, setState] = useState(prev_state ? prev_state : getMultipleEnteries());
  const commonFunctions = getCommonFunctions(state, setState, inherits);
  const layout_variant = props.layout_variant ? props.layout_variant : null;
  const input_list = props.input_list ? props.input_list : [];
  const card_label = props.card_label ? props.card_label : "N/A";
  const clearAndCloseFrom = props.clearAndCloseFrom ? props.clearAndCloseFrom : () => { };
  const setDatatoEdit = props.setDatatoEdit ? props.setDatatoEdit : () => { };
  const handleDeleteRow = props.handleDeleteRow ? props.handleDeleteRow : () => { };
  const columnNames = props.columnNames ? props.columnNames : [];
  const no_data_added_msg = props.no_data_added_msg ? props.no_data_added_msg : null;
  const no_data_added_button = props.no_data_added_button ? props.no_data_added_button : null;
  const multi_enteries_swtich_title = props.multi_enteries_swtich_title ? props.multi_enteries_swtich_title : "Multiple enteries form title will goes here..."
  const classes = useStyles();
  const custom_save_fn_flag = props.custom_save_fn_flag;
  const custom_save_fn = props.custom_save_fn;
  const updateSwitchState = props.updateSwitchState ? props.updateSwitchState : ()=>{};
  useEffect(() => {
    console.log(prev_state, "fdjksajksa")
    if (prev_state) {
      setState(new_state => ({
        ...new_state,
        ...prev_state
      }));
    }
  }, [prev_state])

  const validateAndPushDataToTable = () => {
    let result = ValidateDataSet(state.data, state.validations);
    if(state.data.open){
      if (result.valid ) {
        let final_arr = state.input_enteries_list;
  
        if (final_arr.length > 0) {
          final_arr.forEach(element => {
            input_list.forEach(item => {
              console.log(element[item.name], state.data[item.name], "final_arr >>> with length")
              if (element[item.name] == state.data[item.name]) {
                console.log(element[item.name], state.data[item.name], "final_arr >>> with length")
  
              } else {
                final_arr.push({ ...state.data })
              }
            })
          });
        } else {
          final_arr.push({ ...state.data })
        }
        setState(new_state => ({
          ...new_state,
          data: {
            condition_key: "",
            condition_value: false,
            open: true
          },
          open_div_form: false,
          open_grid_form: false,
          error: {},
          input_enteries_list: [
            ...final_arr,
          ]
  
        }))
  
      }
    }else{
      setState(new_state => ({
        ...new_state,
        data: {
          condition_key: "",
          condition_value: false,
          open: true
        },
        open_div_form: false,
        open_grid_form: false,
        error: {},
        input_enteries_list: []

      }))
    }
    
  }

  const toggleFormState = () => {
    setState(new_state => ({
      ...new_state,
      open_div_form: !new_state.open_div_form
    }))
  }

  // const handleDeleteRow = (data) => {
  //   console.log(data, "lnjplnjp")
  //   var total_arr = state.input_enteries_list;
  //   total_arr.forEach((item, key) => {

  //     if (item.condition_key == data.condition_key) {

  //       total_arr.splice(key, 1)

  //     }

  //   })

  //   setState(prevState => ({
  //     ...prevState,
  //     input_enteries_list: [
  //       ...total_arr,
  //     ]
  //   }))
  // }

  const tableActionHandler = (type, data) => {
    setState(prevState => ({
      ...prevState,
      open: true,
      update_state_fn: true,
      single_form_data: {
        ...prevState.single_form_data,
        ...data
      }
    }))
  }
  const onChangeHandlerToggle = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    console.log(value, key, "switch-input-wrapper")
    var switch_key = [
      "open"
    ]

    if (switch_key.includes(key)) {
      value = !state.data[key];
    }
    setState((new_state) => ({
      ...new_state,
      data: {
        ...new_state.data,
        [key]: value,
      },
      error: {
        ...new_state.error,
        [key]: null,
      },
    }));
    updateSwitchState(value)
   
  }
  console.log(state.data.open,"fdsjkfkjasjfa")
  return (
    <div className={classes.root_class}>
      <div className="card-header with-btn conditions-div-header d-flex align-center space-between">
        <div className="heading font-family-v1 font-weight-500 font-14 color-value">{multi_enteries_swtich_title}</div>
        <div className="switch-input-wrapper">
          <Input
            type="only-switch"
            name="open"
            data={state.data}
            error={state.error}
            onChangeHandler={onChangeHandlerToggle}
          />
        </div>
      </div>
      {
        state.data.open &&
        <div className={'multiple-entries-grid-and-input ' + classes.root}>
          <EditableTable
            columnNames={columnNames}
            actionButton={true}
            apiFetchedData={state.input_enteries_list}
            tableActionHandler={setDatatoEdit}
            fetchAfterAction={() => { }}
            endPointObj={{}}
            handleDeleteRow={handleDeleteRow}
            variant="static_delete"
            apiUrl={""}
          />

          {
            state.input_enteries_list && state.input_enteries_list.length === 0 ?
              <div className="no-data-msg">
                <p>{no_data_added_msg}</p>
                <button
                  onClick={toggleFormState}
                  className="btn btn-primary-v2  mb-15 m-auto  d-flex align-center text-center"
                >
                  <AddIcon />&nbsp;
                  <span>{no_data_added_button}</span>
                </button>
              </div>
              :
              <div className='add-more-strip'>
                <p className='d-flex align-center justify-center'>
                  <span>Add More Conditions </span>
                  <button onClick={toggleFormState} className='btn btn-link d-flex align-center text-anchor-blue'><AddIcon /> Add More</button>
                </p>
              </div>
          }
          {
            layout_variant === "grid" ?
              <>
                {
                  state.open_grid_form &&
                  <Grid
                    container
                    className={"multiple-enteries-grid-custom-css-wrapper"}
                    spacing="2">
                    {input_list.map(item => {
                      return (
                      
                          <Input
                            type={item.type}
                            name={item.name}
                            placeholder={item.placeholder}
                            label={item.label}
                            list={item.list}
                            data={state.data}
                            error={state.error}
                            onChangeHandler={item.type == "true-false-switch" ? commonFunctions.toggleState : commonFunctions.onChangeHandler}
                          />
                        
                      )
                    })

                    }
                  </Grid>
                }
              </>
              :
              <>
                {
                  state.open_div_form &&
                  <div
                    className={"multiple-enteries-div-custom-css-wrapper multiple-enteries-card "}
                  >
                    <div className="multiple-enteries-card-header align-center">
                      <span>{card_label}</span>
                      <span className="material-icons material-symbols-outlined font-24 cursor-pointer" onKeyDown={()=>{}} onClick={toggleFormState} tabIndex={0} role='button'>
                        close
                      </span>
                    </div>
                    <div className="multiple-enteries-card-body">
                      {input_list.map(item => {
                        return (
                        
                            <Input
                              type={item.type}
                              name={item.name}
                              placeholder={item.placeholder}
                              label={item.label}
                              list={item.list}
                              data={state.data}
                              error={state.error}
                              onChangeHandler={item.type == "true-false-switch" ? commonFunctions.toggleState : commonFunctions.onChangeHandler}
                            />
                          
                        )
                      })
                      }
                    </div>
                    <div className="multiple-enteries-card-footer d-flex align-center justify-flexend">
                      <div className='d-flex align-center '>
                        <button className="btn btn-with-icon btn-save"
                          onClick={
                            custom_save_fn_flag ?
                              custom_save_fn :
                              validateAndPushDataToTable
                          }>
                          <span className="material-icons material-symbols-outlined">
                            done
                          </span>
                          <span>Save</span>
                        </button>
                        <button
                          className="btn btn-with-icon btn-cancel"
                          onClick={clearAndCloseFrom}
                        >
                          <span className="material-icons material-symbols-outlined">
                            close
                          </span>
                          <span>Cancel</span>
                        </button>
                      </div>
                    </div>
                  </div>
                }
              </>
          }
        </div>
      }
    </div>

  )
}

MultipleEnteriesInput.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}

export default MultipleEnteriesInput;

export const getMultipleEnteries = () => {
  return {
    data: {},
    error: {},
    input_enteries_list: [],
    open: false
  }
}