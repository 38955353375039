import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Input } from '../../../../components/genericComponents/Input';
import { VALIDATION_TYPE_REQUIRED, ValidateDataSet } from '../../../../util/Validator';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import GenerateURL from '../../../../util/APIUrlProvider';
import { Loading } from '../../../utils/Loading';
import properties from '../../../../properties/properties';
import { IconButton } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '500px'
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    title: {
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: 1.2,
        fontFamily: 'Inter',
        marginBottom: '30px'
    },
    btnSection: {
        padding: '10px',
        // backgroundColor: '#f9f9f9',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // borderTop: '1px solid #e0e0e0',
        '& button': {
            marginLeft: '4px',
            width: '80px'
        }
    }
}));

export default function CredentialsDialogue(props) {
    const classes = useStyles();
    const open = props.open;
    const maxWidth = "md";
    const fullWidth = true;

    var List = [
        {
            label: 'Password',
            value: "USERNAME_PASSWORD"
        },
        {
            label: 'SSH',
            value: "SSH_KEY"
        },
        {
            label: 'Token',
            value: "TOKEN"
        },
        {
            label: 'Access Token',
            value: "ACCESS_TOKEN"
        },
        {
            label: 'AWS',
            value: "AWS_CREDENTIAL"
        }
    ]

    const [state, setState] = useState({
        post_status: "POST_SUCCESS",
        error: {},
        count: 0,
        count1: 0,
        count_sonar: 0,
        count_cb: 0,
        count_ssh: 0,
        list: List

    })

    const [data, setData] = useState(
        {
            username: "",
            password: "",
            credential_type: "USERNAME_PASSWORD",
            name: ""

        }
    );
    const [validation, setValidation] = useState(
        {
            username: [VALIDATION_TYPE_REQUIRED],
            password: [VALIDATION_TYPE_REQUIRED],
            name: [VALIDATION_TYPE_REQUIRED],
            credential_type: [VALIDATION_TYPE_REQUIRED]
        })

    function fetchCredentialInfo() {

        var requestInfo = {
            endPoint: GenerateURL({ credential_id: props.credential_id }, properties.api.credential),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, CredentialFetchSuccess, CredentialFetchFailure);
        setState(new_state => ({
            ...new_state,
            post_status: "POST_REQUESTED"
        }));

    }
    function CredentialFetchSuccess(response) {
        setData(new_state => ({
            ...new_state,
            username: response.username,
            credential_type: response.credential_type,
            name: response.name,
            id: response.id
        }))
        setState(new_state => ({
            ...new_state,
            error: {}, post_status: "POST_SUCCESS"
        }));
    }
    function CredentialFetchFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
    }
    if (props.isEdit && state.count < 1) {

        fetchCredentialInfo();

        setState(new_state => ({
            ...new_state,
            count: 1
        }));

    }
    if (props.isSonar && state.count_sonar < 1) {
        setState(new_state => ({
            ...new_state,

            count_sonar: 1,
            list: [{
                label: 'Access Token',
                value: "ACCESS_TOKEN"
            }],

        }));
        setData({
            username: null,
            password: null,
            credential_type: "ACCESS_TOKEN",
            name: null

        })
        setValidation({
            credential: [VALIDATION_TYPE_REQUIRED],
            name: [VALIDATION_TYPE_REQUIRED],
            credential_type: [VALIDATION_TYPE_REQUIRED]
        })

    }
    if (props.isClusterBackup && state.count_cb < 1) {
        setState(new_state => ({
            ...new_state,

            count_cb: 1,
            list: [{
                label: 'AWS',
                value: "AWS_CREDENTIAL"
            }],

        }));
        setData({
            username: null,
            password: null,
            credential_type: "AWS_CREDENTIAL",
            name: null

        })
    }
    if (props.isSSH && state.count_ssh < 1) {
        setState(new_state => ({
            ...new_state,

            count_ssh: 1,
            list: [{
                label: 'SSH',
                value: "SSH_KEY"
            },],

        }));
        setData({
            username: null,
            password: null,
            credential_type: "SSH_KEY",
            name: null

        })
    }
    function onChangeHandler(e) {
        var key = e.target.name;
        var value = e.target.value;
        if (key == "name") {
            if (value.slice(-1) == " ") {
                value = value.slice(0, -1) + "-"
            }
        }
        var data_temp = {
            ...data,
            [key]: value,
        }
        setData(new_state => ({
            ...new_state,
            ...data_temp,
        }));
        setState(new_state => ({
            ...new_state,
            error: {}
        }));
        if (data.credential_type == "SSH_KEY" || data.credential_type == "ACCESS_TOKEN") {
            setValidation({
                credential: [VALIDATION_TYPE_REQUIRED],
                name: [VALIDATION_TYPE_REQUIRED],
                credential_type: [VALIDATION_TYPE_REQUIRED]
            })

        }
    }
    const onSave = () => {
        let validations = { ...validation };

        var data_final = { ...data }
        if (data.credential_type == "ACCESS_TOKEN") {
            data_final = {
                ...data,
                password: null,
                username: null
            }
        }
        if (data.credential_type == "SSH_KEY") {
            data_final = {
                ...data,
                password: null,

            }
        }
        if (data.credential_type == "AWS_CREDENTIAL") {
            data_final = {
                name: data.name,
                credential_type: data.credential_type,
                password: null,
                username: null,
                key_value: {
                    aws_access_key: data.aws_access_key,
                    aws_secret_access_key: data.aws_secret_access_key
                }

            }
            validations = {
                name: [VALIDATION_TYPE_REQUIRED],
                aws_access_key: [VALIDATION_TYPE_REQUIRED],
                aws_secret_access_key: [VALIDATION_TYPE_REQUIRED],
                credential_type: [VALIDATION_TYPE_REQUIRED]
            }
        }

        if (data.credential_type != "SSH_KEY" && data.credential_type != "ACCESS_TOKEN") {
            delete validations.credential
        }

        var validation_result = ValidateDataSet(data, validations);

        var saveUrl = GenerateURL({}, properties.api.credential_all);
        if (validation_result.valid) {
            if (props.isEdit) { saveUrl = GenerateURL({ credential_id: props.credential_id }, properties.api.credential); }

            PostData(saveUrl, data_final, handleSaveSuccess, handleSaveFailure)
            setState({
                ...state,
                post_status: "POST_REQUESTED",
            });
        } else {
            setState({
                ...state,
                error: validation_result.error,
            });
        }
    }

    function handleSaveSuccess(response) {

        setState(state => ({ ...state, error: {}, post_status: "POST_SUCCESS" }))
        setTimeout(() => {
            props.handleClose();
            setData({
                username: "",
                password: "",
                credential_type: "USERNAME_PASSWORD",
                name: ""

            })

        }, 400);


    }
    function handleSaveFailure(response) {
        var temp_error = {
            name: "This must be unique."
        }

        setState(state => ({ ...state, error: response, post_status: "POST_FAILED" }))
    }


    const handleKeyPress = (event) => {
        if (event.keyCode == 13) {
            onSave();
        }
    }

    return (
        <React.Fragment value={props.open}>
            {
                state.post_status == "POST_REQUESTED" ?
                    <Loading /> : null
            }
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={props.handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                    <div className=' pd-10 font-14 d-flex align-center space-between bg-primary' >
                        {props.isEdit ? <div className={"color-white"}>
                            Edit Credential
                        </div> : <div className={"color-white"}>
                            Add Credentials
                        </div>}
                        <IconButton onClick={props.handleClose}>
                            <span className='ri-close-fill font-24 color-white'></span>
                        </IconButton>
                    </div>
                    <form className={`${classes.form} pd-10`} >
                        <Input
                            type="radio"
                            name="credential_type"
                            label="Credential Type"
                            mandatorySign
                            list={state.list}
                            error={state.error}
                            data={data}
                            onChangeHandler={onChangeHandler}
                        />
                        <Input
                            type="text"
                            name="name"
                            label="Credential Name"
                            placeholder="Unique Credential Name"
                            mandatorySign
                            error={state.error}
                            data={data}
                            onChangeHandler={onChangeHandler}
                        />
                        {
                            data.credential_type == "ACCESS_TOKEN" || data.credential_type == "AWS_CREDENTIAL" ? null : <Input
                                type="text"
                                name="username"
                                label="Username"
                                mandatorySign
                                placeholder="Username"
                                error={state.error}
                                data={data}
                                onChangeHandler={onChangeHandler}
                            />}

                        {(() => {

                            switch (data.credential_type) {
                                case 'USERNAME_PASSWORD':
                                    return (
                                        <Input
                                            type="password"
                                            name="password"
                                            mandatorySign
                                            label="Password"
                                            placeholder="Password"
                                            error={state.error}
                                            data={data}
                                            onChangeHandler={onChangeHandler}

                                        />
                                    )
                                case 'SSH_KEY':
                                    return (
                                        <Input
                                            type="textarea"
                                            name="credential"
                                            mandatorySign
                                            label="SSH"
                                            placeholder="SSH KEY"
                                            error={state.error}
                                            data={data}

                                            onChangeHandler={onChangeHandler}
                                        />
                                    )

                                case 'TOKEN':
                                    return (
                                        <Input
                                            type="textarea"
                                            name="password"
                                            mandatorySign
                                            label="TOKEN"
                                            placeholder="Token"
                                            error={state.error}
                                            data={data}
                                            onChangeHandler={onChangeHandler}
                                            onKeyPress={handleKeyPress}
                                        />
                                    )


                                case 'ACCESS_TOKEN':
                                    return (
                                        <Input
                                            type="textarea"
                                            name="credential"
                                            mandatorySign
                                            label="ACCESS TOKEN"
                                            placeholder="Access Token"
                                            error={state.error}
                                            data={data}
                                            onChangeHandler={onChangeHandler}
                                            onKeyPress={handleKeyPress}
                                        />
                                    )
                                case 'AWS_CREDENTIAL':
                                    return (
                                        <>
                                            <Input
                                                type="textarea"
                                                name="aws_access_key"
                                                mandatorySign
                                                label="AWS access key"
                                                placeholder="aws_access_key"
                                                error={state.error}
                                                data={data}
                                                onChangeHandler={onChangeHandler}
                                                onKeyPress={handleKeyPress}
                                            />
                                            <Input
                                                type="textarea"
                                                name="aws_secret_access_key"
                                                mandatorySign
                                                label="AWS secret access key"
                                                placeholder="aws_secret_access_key"
                                                error={state.error}
                                                data={data}
                                                onChangeHandler={onChangeHandler}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </>
                                    )

                            }
                        })()}



                    </form>
                    <div className={classes.btnSection}>
                        <button className="btn btn-transparent" onClick={props.handleClose} >Cancel</button>
                        <button className="btn btn-primary mr-0" onClick={onSave} onKeyPress={handleKeyPress}>Save</button>
                    </div>
                
            </Dialog>

        </React.Fragment>
    );
}
CredentialsDialogue.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}