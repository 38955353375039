import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';

import StepDetail from '../step_detail/StepDetail';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';


const ITEM_HEIGHT = 48;
export default function MoreMenu(props) {

    const { type, data, order, onEditHandler, onDeleteHandler, layer_index, version, onQueueNameEdit, isQueueEnabled,item,onRollbackSelect,onViewReportSelect} = props

    console.log('jt_001_pl', type, data);
    const [anchorEl, setAnchorEl] = useState(null);

    const [state, setstate] = useState();

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    console.log(type,"dbvhsdvhjsdvbsd")
    const onClickItems = (data, order, type, layer_index, category) => {
        console.log('data_001', data, order, type, layer_index, category)
        setAnchorEl(null);
        if (version == "v2") {
            if (category == "job" && type == 'v2Job') {
                onQueueNameEdit(data, order, type, layer_index)
            } else {
                onEditHandler(data, order, type, layer_index);
            }
        }
        else {
            onEditHandler(data, order, type, layer_index);
        }
    }

    const onDelete = () => {
        setAnchorEl(null);
        onDeleteHandler(layer_index, order, data, type)
    }

    console.log(version, "tyugvbn ==============>")
    console.log(isQueueEnabled, 'cfm_0p')

    return (
        <div>
            <div className="d-flex align-centre">
                <button
                    className="transparent-btn nowrap"
                    onClick={handleClick}
                >
                    <MoreVertOutlinedIcon />
                </button>
            </div>

            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        // width: '120px',
                        paddingLeft: '12px',
                        backgroundColor: type == "canary" ? "#ffffff" : '#f0f7f7'
                    },
                }}
            >
                {type === 'step' && <StepDetail stepData={{ ...data, version: version }} onClose={handleClose} />}

                {
                    type == "canary" ?
                        <div className='canary-menu-item'>
                            <MenuItem style={{}} onClick={() => onRollbackSelect(item)}>
                                <div style={{ display: "flex" }}>
                                    <span className='ri-refresh-line' style={{ color: "#505050", fontSize: "20px" }}></span>
                                    <p style={{ fontFamily: "Montserrat", fontWeight: "500", fontSize: "12px", color: "#505050", marginLeft: "8px" }}>Mark for Rollback</p>
                                </div>
                            </MenuItem>
                            <MenuItem onClick={() => onViewReportSelect(type)}>
                                <div style={{ display: "flex" }}>
                                    <span className='ri-bar-chart-fill' style={{ color: "#505050", fontSize: "20px" }}></span>
                                    <p style={{ fontFamily: "Montserrat", fontWeight: "500", fontSize: "12px", color: "#505050", marginLeft: "8px" }}>View Report</p>
                                </div>
                            </MenuItem>
                        </div>
                        :
                        type != "job" ?
                            <div className='job-template-menu-item'>
                                <MenuItem style={{ paddingLeft: '0px', fontSize: '14px', display: "grid" }} onClick={() => onClickItems(data, order, 'conditional_step', layer_index, type)}>
                                    <div style={{ display: "flex" }}><CreateIcon style={{ marginRight: '8px' }} /> {'Edit Step'}</div>
                                </MenuItem>
                            </div>
                            :
                            (version == "v3" || data?.is_dynamic) ?
                                <div className='job-template-menu-item'>
                                    <MenuItem style={{ paddingLeft: '0px', fontSize: '14px', display: "grid" }} onClick={() => onClickItems(data, order, 'conditional_step', layer_index, type)}>
                                        <div style={{ display: "flex" }}><CreateIcon style={{ marginRight: '8px' }} />{'Edit Job'} </div>
                                    </MenuItem>
                                </div>
                                :
                                isQueueEnabled ?
                                    <div className='job-template-menu-item'>
                                        <MenuItem style={{ paddingLeft: '0px', fontSize: '14px', display: "grid" }} onClick={() => onClickItems(data, order, 'v2Job', layer_index, type)}>
                                            <div style={{ display: "flex" }}><CreateIcon style={{ marginRight: '8px' }} />{'Select Custom Queue'} </div>
                                        </MenuItem>
                                    </div>
                                    :
                                    <></>
                }
                {
                    type == "canary" ?
                        <></>
                        :
                        <div className='job-template-menu-item'>
                            <MenuItem style={{ paddingLeft: '0px', fontSize: '14px', display: "grid" }} onClick={onDelete}>
                                <div style={{ display: "flex" }}><DeleteIcon style={{ marginRight: '8px' }} />{type === 'job' ? 'Delete Job' : 'Delete Step'}</div>
                            </MenuItem>
                        </div>
                }
            </Menu>
        </div>
    );
}

MoreMenu.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};


