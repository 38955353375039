import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Slide, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoStaticPanel from '../../components/InfoStaticPanel';
import EditStepEnvVarV2 from '../../components/editStepEnvVariables/EditStepEnvVarV2';
import EditStepEnvVar from '../../components/editStepEnvVariables/EditStepEnvVar';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import { Input } from '../../../../components/genericComponents/Input';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

export default function StepEditTemplate(props) {

    const [state, setState] = useState({});
    const { stepData, changeParentState, jobTemplate, updateJobTemplate, version } = props

    const { step, openDialog, layer } = stepData

    useEffect(() => {
        jobTemplate && !state.SupportedEnvVariables && fetchSupportedEnvVariables()
    }, [jobTemplate])

    console.log(step, 'j_b_t_001')

    const handleClose = () => {

        changeParentState({ step: null, openDialog: false, layer: null, version: null })
    }

    const handleOnSaveForEdit = (updatedStep) => {           //callback function for edit
        const { layer_index, allLayer, currentStepOrder } = layer
        let wholeJobTemplate = jobTemplate
        let job_template_data = wholeJobTemplate.job_template
        let jobs = job_template_data.jobs     //jobs
        if (layer_index === 1) {
            jobs[allLayer.job].steps[currentStepOrder] = updatedStep
            wholeJobTemplate.job_template['jobs'] = jobs
            updateJobTemplate(wholeJobTemplate)
        }

        else if (layer_index === 3) {
            jobs[allLayer.job].steps[allLayer.conditionalStep].values_step_mapping[allLayer.condition].steps[currentStepOrder] = updatedStep
            wholeJobTemplate.job_template['jobs'] = jobs
            updateJobTemplate(wholeJobTemplate)

        }

        handleClose()
    }

    const fetchSupportedEnvVariables = () => {
        let requestInfo = {
            endPoint: GenerateURL({}, properties.api.supported_env_variables),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        InvokeApi(requestInfo, handleSuccess, handleFailure);

    }

    const handleSuccess = (data) => {

        setState(new_state => ({
            ...new_state,
            SupportedEnvVariables: data
        }));

    }

    const handleFailure = (error) => {
        setState(new_state => ({
            ...new_state,
            error_msg: "Error fetching data" + " " + error
        }))
    }

    return (
        
            <Dialog
                fullWidth
                maxWidth='lg'
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title" TransitionComponent={Transition}>

                <div className='pop-heading' style={{ display: 'flex', background: '#124d9b', fontSize: '20px', justifyContent: 'space-between' }}>
                    <DialogTitle style={{ color: 'white' }} id="max-width-dialog-title" className='heading'>
                        <label style={{ color: 'white' }}>{` To run "${step && step.step_name || ''}" step you need to address the following requirement`}</label>

                    </DialogTitle>
                    <IconButton onClick={handleClose} aria-label="close" style={{ color: '#fff', marginRight: '15px' }}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className='d-grid' style={{ gridTemplateColumns: '365px 1fr', height: '100vh' }}>

                    <InfoStaticPanel version={version} step_code={step && step.step_code} />
                    {
                        version === 'v3' ?
                            <EditStepEnvVar supportedEnvVariables={state.SupportedEnvVariables} version={version ? version : ''} stepForEdit={step ? { label: step.step_name, code: step.step_code, data: { ...step } } : null} onSave={handleOnSaveForEdit} onClose={handleClose} />
                            :
                            <EditStepEnvVarV2 supportedEnvVariables={state.SupportedEnvVariables} version={version ? version : ''} stepForEdit={step ? { label: step.step_name, code: step.step_code, data: { ...step } } : null} onSave={handleOnSaveForEdit} onClose={handleClose} />
                    }
                </div>
            </Dialog>
        
    )
}

StepEditTemplate.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };