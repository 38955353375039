import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import { Loading } from '../../../utils/Loading';

import SourceDetailsOverview from './ServiceRevampOverview/CiOverView/SourceDetailsOverview';
// import CiDetailsOverviewNew from './ServiceRevampOverview/CiOverView/CiDetailsOverviewNew';
import CiEnvVarOverview from './ServiceRevampOverview/CiOverView/CiEnvVarOverview';
import CiHooksOverview from './ServiceRevampOverview/CiOverView/CiHooksOverview';
import { Redirect } from 'react-router-dom';
import CloneEnv from '../../../serviceRevamp/add/components/CloneEnv';
import InvokeApi from '../../../../util/apiInvoker';
import { Grid } from '@material-ui/core';
import { GetAuth } from '../../../../util/security';
import { Tooltip } from '@material-ui/core';
import AuditCard from './auditHistory/AuditCard';
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { PostData } from '../../../../util/apiInvoker';

const CiDetailsOverviewFlow = ({ templateName,templateVersion, ...props }) => {
    const selectedEnvDetails = props.selectedEnvDetails;
    const serviceEnv = props.service_env;

    const component_env_id = serviceEnv.id;
    const extraProps = props.extraProps;
    const button_state = props.button_state;
    const setCiCompleteData = props.setCiCompleteData
    const multiple_build_enabled= props.multiple_build_enabled;
    const [showLoading, setShowLoading] = useState(false);
    const [state, setState] = useState({ ci_configured: null, edit_flag: true, count: 1, multiple_build : null})
    console.log('Initial_State:', setCiCompleteData);
    var url = GenerateURL({ service_id: selectedEnvDetails.service_id, component_env_id: component_env_id }, properties.api.save_ci, true);
    const is_permitted = GetAuth().permission.POST.includes(url);
    function fetchCiData() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: selectedEnvDetails.service_id, component_env_id: component_env_id }, properties.api.save_ci),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (multiple_build_enabled){
            requestInfo.endPoint = GenerateURL({ service_id: selectedEnvDetails.service_id, component_env_id: component_env_id }, properties.api.get_ci_child)
        }
        setShowLoading(true);
        InvokeApi(requestInfo, onFetchCiDataSuccess, onFetchCiDataFail)
    }

    function onFetchCiDataSuccess(response) {
        console.log(response, "rtyudfgcv ================>")
        var api_data = [...response]
        setShowLoading(false);
        setCiCompleteData(response)
        setState(new_state => ({
            ...new_state,
            data: response,
            parent_ci_data: response[0],
            ci_configured: response.length > 0 ? true : false,
            show_audit_card: response.length > 0 ? true : false,
            child_build_configured: response.length > 1 ? true : false,
            child_build_data: response.length > 1 ? getChildBuildList(api_data) : null
        }));
    }

    function onFetchCiDataFail() {
        setState(new_state => ({
            ...new_state,
            ci_configured: false,
        }));
        setShowLoading(false)
    }

    function getChildBuildList(data) {
        var new_arr = data;
        new_arr.shift()
        return data
    }

    console.log(state, "jhcdjdsvcsdvcnsdvc")

    useEffect(() => {
        fetchSystemSettingsData()
    }, [])

    useEffect(() => {
        console.log('Effect_Triggered_Current_State:', state);
        fetchCiData();
    }, [multiple_build_enabled,serviceEnv, state.count])

    console.log(state,"sdmbcdsnbcbhds")


    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);
        setShowLoading(true);

    }

    function fetchSystemSettingsDataSuccess(data) {
        console.log(data,"jsbdvcjgsdvcvsdnbcb")
        var multiple_build_data = data.find(item => item.key === "MULTIPLE_BUILD_CONFIGURATION")
        var shallow_cloning = data.find(item => item.key === "SHALLOW_CLONING_ENABLE")
        var depth = data.find(item => item.key === "DEPTH")
        setState(new_state => ({
            ...new_state,
            multiple_build : multiple_build_data?.value == "true" ? true : false,
            shallow_cloning : shallow_cloning?.value == "true" ? true : false,
            depth : depth?.value
        }));

    }
    function fetchSystemSettingsDataFailed(error) {
        setShowLoading(true);   
        setState(new_state => ({
            ...new_state,
            error: error,
        }))
    }


    //state.ci_configured
    const [isCloneVisible, setIsCloneVisible] = useState(false);
    const [cloneList, setcloneList] = useState([]);
    const [canRedirect, setRedirect] = useState(false)
    function getCloneEnvList() {
        var requestInfo = {
            endPoint: GenerateURL({ service_id: selectedEnvDetails.service_id }, properties.api.get_clone_env_list),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        setShowLoading(true);
        InvokeApi(requestInfo, onFetchCloneSuccess, onFetchCloneFail)
    }
    function onFetchCloneFail() {
        setShowLoading(false)
    }
    function onFetchCloneSuccess(response) {
        setShowLoading(false)
        var list = [];
        response.forEach(env => {
            if (env.env_ci_detail) {
                list = [...list, {
                    id: env.id,
                    label: env.project_env.name
                }]
            }
        })
        if (list.length > 0) {
            setcloneList(list)
            setIsCloneVisible(true);
        }
        else {
            setRedirect(true);
        }
    }



    function onCloneHandler(clone_env_id, approved_ci_id) {
        console.log(clone_env_id, approved_ci_id, "rtyufghjdfg=====>-===>")
        if (clone_env_id) {
            setState(prev_state => ({
                ...prev_state,
                clone_env_id: clone_env_id,
                approved_ci_id: approved_ci_id,
            }))
        }
        setRedirect(true);
    }

    function sendApprovalRequest(id) {
        var post_url = GenerateURL({}, properties.api.approval_request);

        var post_obj = {
            "name": "env_ci_detail",
            "entity_id": id
        }

        PostData(post_url, post_obj, sendApprovalRequestSuccess, sendApprovalRequestFailed);
        setState(new_state => ({
            ...new_state,
            loading: true,
            error_msg: null,
            open: true
        }));
    }

    function sendApprovalRequestSuccess(response) {
        refreshState()
        var msg = response.detail
        setState(new_state => ({
            ...new_state,
            approval_request_success_msg: msg,
        }));

    }

    function sendApprovalRequestFailed(error) {
        var error = error.toString()
        setState(new_state => ({
            ...new_state,
            deployment_failed_msg: error
        }));

    }

    function confirmForApproval(id) {
        var post_url = GenerateURL({}, properties.api.confirm_approval);

        var post_obj = {
            "name": "env_ci_detail",
            "entity_id": id
        }

        PostData(post_url, post_obj, confirmApprovalRequestSuccess, confirmApprovalRequestFailed);
        setState(new_state => ({
            ...new_state,
            loading: true,
            error_msg: null,
            open: true
        }));
    }

    function confirmApprovalRequestSuccess(response) {
        refreshState()
        var msg = response.detail
        setState(new_state => ({
            ...new_state,
            approval_confirm_success_msg: msg,
        }));
    }

    function confirmApprovalRequestFailed(error) {
        var error = error.toString()
        setState(new_state => ({
            ...new_state,
            approval_failed_msg: error
        }));
    }

    function refreshState() {
        console.log("function running")
        setState(new_state => ({
            ...new_state,
            count: new_state.count + 1
        }));
    }

    function handleShowOverviewData(ci_id, secondary_build) {
        console.log(state.data, "vdsgvdgvcdsvc")
        var ci = state.data.filter(item => item.id == ci_id)
        setState(new_state => ({
            ...new_state,
            show_overview_view: true,
            selected_ci_for_display: ci[0],
            selected_ci_id: ci_id,
            show_audit_card: false,
            secondary_build: secondary_build ? true : false
        }))
    }

    function handleShowAuditCard() {
        setState(new_state => ({
            ...new_state,
            show_overview_view: false,
            selected_ci_for_display: null,
            show_audit_card: true
        }))
    }

    function refreshCiData() {
        setState((new_state) => ({
            ...new_state,
            count: new_state.count + 1,
        }))
    }

    console.log(state, "wertyufg=================>")


    return (
        <>
            {showLoading ?
                <div className="light-loading-container">
                    <Loading varient="light" />
                </div> :
                <div className="section-service-overview" style={{ border: '0px', padding: '10px 0px 0px' }}>

                    <div className='d-flex space-between' style={{ padding: '10px 16px 10px 16px'}}>
                        <div>
                            <span style={{ color: '#787878' }} className='mr-5 font-12 font-weight-500'>Job Template:</span>
                            <span style={{ color: "#2F2F2F" }} className='font-12 font-weight-500'>{templateName} ({templateVersion})</span>
                        </div>
                        <div>

                            <div>
                                <button class="btn-without-border d-flex align-center" style={{ justifySelf: 'flex-end' }} onClick={() => refreshCiData()} ><span class="ri-restart-line font-20"></span></button>
                            </div>

                        </div>
                    </div>

                    {
                        state.selected_ci_for_display &&
                        <div className="d-flex align-center space-between">
                            <p className="font-12 text-grey-83 ml-10">You are viewing build details with status: &nbsp;
                                {state.selected_ci_for_display ?
                                    state.selected_ci_for_display.selected_ci_for_display == "APPROVAL_REQUESTED" ? "Approval Request" :
                                        state.selected_ci_for_display.status == "DRAFT" ? "Draft" : "Approved" : ""}</p>
                            {
                                state.selected_ci_for_display &&
                                <IconButton onClick={handleShowAuditCard} className="ml-auto d-block"><CloseIcon className="font-18" /></IconButton>
                            }
                        </div>
                    }
                    {console.log(state,"dsbcjhdsbcjhbsdhjcbsd")}
                    {state.ci_configured && state.data.length > 0 ?
                        <>
                            {
                                state.show_audit_card ?
                                    <div className="" >
                                        <Grid container spacing="2">
                                            <Grid item lg="12" className="pd-10">
                                                <AuditCard
                                                    audit_card_data={state.parent_ci_data}
                                                    ci_configured={state.ci_configured}
                                                    service_id={selectedEnvDetails.service_id}
                                                    component_env_id={component_env_id}
                                                    application_id={selectedEnvDetails.application_id}
                                                    refreshFn={refreshState}
                                                    sendForApproval={sendApprovalRequest}
                                                    confirmForApproval={confirmForApproval}
                                                    showOverviewView={handleShowOverviewData}
                                                    service_env_details={serviceEnv?.project_env}
                                                    clone_env_id={state.clone_env_id ? state.clone_env_id : null}
                                                    env_cd_detail_id={state.parent_ci_data.id}
                                                    extraProps={extraProps}
                                                    approvedCiData={state.parent_ci_data.status == "APPROVED" ? true : false}
                                                    child_build_configured={state.child_build_configured}
                                                    child_build_data={state.child_build_data}
                                                    multiple_build_enabled={multiple_build_enabled}
                                                />
                                            </Grid>
                                            {/* {
                                                state.parent_ci_data.map(single_data =>
                                                (
                                                    <Grid item lg="12" className="pd-10">
                                                        <AuditCard
                                                            audit_card_data={single_data}
                                                            ci_configured={state.ci_configured}
                                                            service_id={selectedEnvDetails.service_id}
                                                            component_env_id={component_env_id}
                                                            application_id={selectedEnvDetails.application_id}
                                                            refreshFn={refreshState}
                                                            sendForApproval={sendApprovalRequest}
                                                            confirmForApproval={confirmForApproval}
                                                            showOverviewView={() => { handleShowOverviewData(single_data.id) }}
                                                            service_env_details={serviceEnv?.project_env}
                                                            clone_env_id={state.clone_env_id ? state.clone_env_id : null}
                                                            env_cd_detail_id={single_data.id}
                                                            extraProps={extraProps}
                                                            approvedCiData={single_data.status == "APPROVED" ? true : false}
                                                        />
                                                    </Grid>
                                                )
                                                )
                                            } */}


                                        </Grid>
                                    </div>
                                    :
                                    <Grid container  spacing={2} style={{padding: '8px', marginLeft: '0px', marginRight: '0px', width:'100%'}}>
                                        <Grid item lg={12}>
                                        </Grid>
                                        <Grid item lg={6}>
                                            <SourceDetailsOverview extraProps={extraProps} repo_details={state.selected_ci_for_display} application_id={selectedEnvDetails.application_id} service_id={selectedEnvDetails.service_id} component_env_id={component_env_id} ci_id={state.selected_ci_id} image_name={serviceEnv?.image_name} label={state.selected_ci_for_display.type_label} secondary_build={state.secondary_build} parent_ci_data={state.parent_ci_data} />
                                        </Grid>
                                        {/* <Grid item lg={6}>
                                            <CiDetailsOverviewNew ci_data={state.selected_ci_for_display} ci_details={state.selected_ci_for_display} extraProps={extraProps} application_id={selectedEnvDetails.application_id} service_id={selectedEnvDetails.service_id} component_env_id={component_env_id} />
                                        </Grid> */}
                                        <Grid item lg={6}>
                                            <CiEnvVarOverview env_var={state.selected_ci_for_display} env_details={state.selected_ci_for_display} extraProps={extraProps} application_id={selectedEnvDetails.application_id} service_id={selectedEnvDetails.service_id} component_env_id={component_env_id} ci_id={state.selected_ci_id} secondary_build={state.secondary_build} parent_ci_data={state.parent_ci_data} />
                                        </Grid>
                                        <CiHooksOverview hooks_data={state.selected_ci_for_display} hooks_details={state.selected_ci_for_display} extraProps={extraProps} application_id={selectedEnvDetails.application_id} service_id={selectedEnvDetails.service_id} component_env_id={component_env_id} ci_id={state.selected_ci_id} secondary_build={state.secondary_build} parent_ci_data={state.parent_ci_data} />
                                    </Grid>
                            }
                        </>
                        :
                        <>
                            {
                                canRedirect && <Redirect to={{
                                    pathname: "/application/" + selectedEnvDetails.application_id + "/service/" + selectedEnvDetails.service_id + "/env/" + component_env_id + "/ci/add",
                                    state: { clone_env_id: state.clone_env_id ? state.clone_env_id : null, approved_ci_id: state.approved_ci_id ? state.approved_ci_id : null , shallow_cloning : state.shallow_cloning, depth : state.depth , is_add : true}
                                }}></Redirect>
                            }
                            <div className="overviewCard__body d-flex align-center justify-center" style={{ minHeight: '400px' }}>


                                
                                    {/* <Link  to={{
                                pathname: "/application/" + selectedEnvDetails.application_id + "/service/" + selectedEnvDetails.service_id + "/env/" + component_env_id + "/ci/add",
                                state: { ...props.extraProps }
                            }}> */}
                                    {!isCloneVisible ?
                                        is_permitted ?
                                            <div className="text-center alert alert-primary bg-white border-style-dashed" style={{ width: '400px' }}>
                                                <div className="text-box">
                                                    <p className="main-text mb-10">Build Details Not Configured</p>
                                                    <span className="sub-text mb-10">
                                                        <div>Please note to build a service CI is required.</div>
                                                        <div>please click the button below to add Build Details.</div>
                                                    </span>
                                                    <div style={{ margin: '15px 0px 0px' }}>
                                                        <button className="btn btn-submit" onClick={getCloneEnvList}>Configure Build</button>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="text-center alert alert-primary bg-white border-style-dashed disabled-text-box" style={{ width: '400px' }}>
                                                <div className="text-box ">
                                                    <p className="main-text mb-10">Build Details Not Configured</p>
                                                    <span className="sub-text mb-10">
                                                        <div>Please note to build a service CI is required.</div>
                                                        <div>please click the button below to add Build Details.</div>
                                                    </span>
                                                    <div style={{ margin: '15px 0px 0px' }}>
                                                        <Tooltip title="You are not allowed to perform this action">
                                                            <button className="btn  btn-disable cursor-not-allowed" >Configure Build</button>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        : null
                                    }
                                    {/* </Link> */}
                                    {
                                        isCloneVisible && <CloneEnv list={cloneList} variant="cloing_ci" onCloneHandler={onCloneHandler} service_id={selectedEnvDetails.service_id} />
                                    }

                                
                            </div></>
                    }
                </div>
            }
        </>
    )
}

CiDetailsOverviewFlow.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  };

export default CiDetailsOverviewFlow
