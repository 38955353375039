import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Loading, LoadingText } from '../../../../../utils/Loading';
import { Input } from '../../../../../../components/genericComponents/Input';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import InvokeApi from '../../../../../../util/apiInvoker';

const LogSection = props => {
    const selected_pod = props.selected_pod;
    const env_id = props.env_id;
    const selected_pod_name = props.selected_pod_name;
    const logs = props.logs;
    const service_id = props.service_id;
    const cd_id = props.cd_id;
    console.log(logs, "lflflflf")
    const refreshTab = props.refreshTab;
    const loading_logs = props.loading_logs;
    const logs_error = props.logs_error;

    const [state, setState] = useState({ data: {}, error: {} })

    console.log('dhsdjd', selected_pod);

    useEffect(() => {
        if (selected_pod) {
            getAllAvailableContainers();
        }
    }, [selected_pod])

    useEffect(() => {
        if (state.data.container_name) {
            loadLogsForPod()
        }
    }, [state.data.container_name])

    function getAllAvailableContainers() {
        console.log(selected_pod,"erjfgbkwelrkjgrunning")
        let container_data = selected_pod?.spec?.containers
        let init_container = selected_pod?.spec?.initContainers

        let list = []

        container_data.forEach((item) => {
            console.log(item, "sbvhsgvbhfsdhvb")
            let obj = {
                id: item.name,
                label: item.name
            }
            list.push(obj)
        });

        if(init_container && init_container.length > 0){
            init_container.forEach((item)=>{
                let obj = {
                    id: item.name,
                    label: item.name,
                }
                list.push(obj);
            });
        }

        setState((new_state) => ({
            ...new_state,
            container_list: list,
            data: {
                container_name: list[0].id
            }
        }))
    }

    function loadLogsForPod() {

        var requestInfo = {
            // endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, pod_name: selected_pod.metadata ? selected_pod.metadata.name:null, cd_id:cd_id }, properties.api.pod_log),
            endPoint: GenerateURL({ service_id: service_id, component_env_id: env_id, pod_name: selected_pod.metadata ? selected_pod.metadata.name : null }, properties.api.pod_log_widget + `?deployment_name=${cd_id}&container_name=${state.data.container_name}`),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleSuccessLogsload, handleFailedLogsLoad);

        setState(new_state => ({
            ...new_state,
            loading_logs: true,
        }));
    }
    function handleFailedLogsLoad(error, exception) {
        setState(new_state => ({
            ...new_state,
            loading_logs: false,
            logs_loaded: true,
            logs_error: exception ? "Something wrong at our servers" : error,
            logs: null,
        }));
    }
    function handleSuccessLogsload(response) {
        let logs_list = response.logs && response.logs?.length > 0 ? response.logs.split('\n') : [];
        setState(new_state => ({
            ...new_state,
            logs_loaded: true,
            loading_logs: false,
            logs_error: null,
            logs: {
                ...new_state.logs,
                [selected_pod.metadata.name]: {logs: logs_list}
            }
        }));
    }

    function getFullScreenElement() {
        return document.fullscreenElement
            || document.webkitFullscreenElement
            || document.mozFullscreenElement
            || document.msFullscreenElement
    }
    function toggleFullScreen() {
        if (getFullScreenElement()) {
            document.exitFullscreen();
        } else {
            document.getElementById("podsLogViewSCreen").requestFullscreen().catch(console.log)
        }
    }

    function onChangeDropdownHandler(e) {
        var key = e.target.name;
        var value = e.target.value;
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value,

            },
            error: {
                ...state.error,
                [key]: null,
            },

        });
    }

    console.log(state.cluster_list, "abdcgasvcgsdvcsdg")

    return (
        <div>
            {
                !loading_logs ?
                    <>

                        <HeadingSection>
                            <div className="text-setion">
                                <div className="main-heading">
                                    Logs
                                </div>
                                <div className="small-text">
                                    Displaying all the Logs
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className='d-grid' style={{ gridTemplateColumns: '1fr 300px', alignItems: 'baseline' }}>
                                    <div className='font-11'>Select Container:</div>
                                    <div className="input-mb-0 mb-0">
                                        <Input
                                            type="select"
                                            label=""
                                            name="container_name"
                                            list={state.container_list ? state.container_list : []}
                                            onChangeHandler={onChangeDropdownHandler} //onChangeDropdownHandler
                                            data={state.data}
                                            error={state.error}
                                        />
                                    </div>
                                </div>
                                <div className="count-chip-section" style={{ marginLeft: "10px" }}>
                                    <button style={{ marginLeft: 'auto', display: "block" }} onClick={loadLogsForPod} class="btn-round border-navy bg-clear-btn"><span class="flaticon-refresh-button-1"></span></button>
                                </div>
                            </div>
                        </HeadingSection>
                        {
                            state.loading_logs ?
                                <Loading varient="light" />
                                :
                                <LogDiv id="podsLogViewSCreen">
                                    <LogHeader>
                                        POD: {selected_pod_name}
                                        <span onClick={toggleFullScreen} className={!getFullScreenElement() ? "flaticon-switch-to-full-screen-button cursor-pointer" : "flaticon-full-screen-exit cursor-pointer"}></span>
                                    </LogHeader>
                                    <div className="logbody">
                                        {
                                            state.logs && state.logs[selected_pod_name] && state.logs[selected_pod_name].logs ?
                                                state.logs[selected_pod_name].logs.length > 0 ?
                                                    state.logs[selected_pod_name].logs.map((log, key) => {
                                                        { return log != "" && <p><span className='' style={{ marginRight: 5, display: 'inline-block' }}>{key + 1}</span><span>{log}</span></p> }
                                                    })
                                                    : <p>No logs to show</p> : <p>Logs Not Available</p>
                                        }
                                    </div>

                                </LogDiv>
                        }
                    </>
                    : <LoadingText />}
        </div>
    )
}

LogSection.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }


export default LogSection;
const LogHeader = styled('div')({
    padding: '10px',
    borderBottom: '1px solid #fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff',
    fontSize: '11px',
    '& .flaticon-switch-to-full-screen-button': {
        color: '#fff'
    }
})

const LogDiv = styled('div')({
    backgroundColor: '#000',
    color: '#000',
    borderRadius: '0px 0px 8px 8px',
    '& p': {
        color: '#fff',
        fontSize: '11px'
    },
    '& .logbody': {
        padding: '10px',
        height: '250px',
        overflowY: 'scroll',
    },
    '& #podsLogViewSCreen:fullscreen .logbody': {
        height: '100%!important',
        overflowY: 'scroll',
    }
})

const HeadingSection = styled('div')({
    margin: '10px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .main-heading': {
        fontSize: '18px',
        lineHeight: '24px',
        color: '#526174',
        fontFamily: 'Nunito',
        fontWeight: 300
    },
    '& .small-text': {
        fontSize: '11px',
        lineHeight: '13px',
        color: '#999'
    },
    '& .count-chip-section': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '& .chip-large': {
        borderRadius: '4px',
        padding: '5px',
        minWidth: '50px',
        margin: '5px',
        '& .count-chip-large': {
            fontSize: '14px',
            fontFamily: 'nunito',
            fontWeight: 300,
            color: '#fff',
            textAlign: 'center'
        },
        '& .text-chip-large': {
            fontSize: '11px',
            fontFamily: 'nunito',
            fontWeight: 300,
            color: '#fff',
            textAlign: 'center'
        },
    }

})