import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';


function LicenseExpirationStatusComponent({ onClose, licenseData, user, showStrip, remainingDays, additionalDrawer, ...props }) {

  const classes = useStyles();

  function handleCloseStrip() {
    onClose(false)
  }

  function formatDate() {
    const inputDate = licenseData?.License_end_date
    // Define an array to map month numbers to month names
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    // Split the input date into year, month, and day
    const dateParts = inputDate?.split('-');
    const year = dateParts && dateParts[0];
    const month = dateParts && dateParts[1];
    const day = dateParts && dateParts[2];
    // Convert the month number to the corresponding month name
    const monthName = monthNames[parseInt(month, 10) - 1];
    // Create the formatted date string
    const formattedDate = `${day}-${monthName}-${year}`;
    return formattedDate;
  }

  const formattedDate = formatDate()

  return (
    <>
      {
        licenseData && showStrip &&
        <>
          {
            remainingDays < 31 ?
              user == "normal-user" ?
                <div className={(remainingDays > 15 || remainingDays == 15) ? classes.container : remainingDays > 7 && remainingDays < 15 ? classes.warning_container : (remainingDays < 7 || remainingDays == 7) ? classes.danger_container : {}}>
                  <div style={{ display: "flex", alignItems: "center" }} >
                    {
                      remainingDays > 15 ?
                        <InfoIcon style={{ color: "#FFFFFF", width: "24px", height: "24px" }} />
                        :
                        remainingDays > 7 && remainingDays < 15 ?
                          <ReportProblemIcon style={{ color: "#FFFFFF", width: "24px", height: "24px" }} />
                          :
                          remainingDays < 7 ?
                            <ReportProblemIcon style={{ color: "#FFFFFF", width: "24px", height: "24px" }} />
                            :
                            <></>
                    }
                    {
                      remainingDays < 0 ?
                        <div className={classes.sentence}>
                          Your {licenseData?.License_Type} license has been expired on <span className='font-12'>{formattedDate}</span>, Please contact your Administration Team
                        </div> :

                        <div className={classes.sentence}>
                          Your {licenseData?.License_Type} license is valid till <span className='font-12'>{formattedDate}</span>, Please contact your Administration Team
                        </div>
                    }
                  </div>
                  <div className={additionalDrawer ? classes.additionalMarginRight : classes.marginRight} style={{ display: "flex", alignItems: "center", width: "155px", justifyContent: 'space-between' }}>
                    <div className={classes.daysLeft} style={(remainingDays > 15 || remainingDays == 15) ? { color: "#0086FF" } : remainingDays > 7 && remainingDays < 15 ? { color: "#FEA111" } : (remainingDays < 7 || remainingDays == 7) ? { color: "#E95454" } : {}}> {
                      remainingDays < 0 ?
                        'EXPIRED'
                        :
                        `${remainingDays} days left`
                    }</div>
                    <CloseIcon style={{ color: "#ffffff", width: "20px", height: "20px" }} onClick={handleCloseStrip} />
                  </div>
                </div>
                :
                <div className={(remainingDays > 15 || remainingDays == 15) ? classes.container : remainingDays > 7 && remainingDays < 15 ? classes.warning_container : (remainingDays < 7 || remainingDays == 7) ? classes.danger_container : {}}>
                  <div style={{ display: "flex", alignItems: "center" }} >
                    {
                      remainingDays > 15 ?
                        <InfoIcon style={{ color: "#FFFFFF", width: "24px", height: "24px" }} />
                        :
                        remainingDays > 7 && remainingDays < 15 ?
                          <ReportProblemIcon style={{ color: "#FFFFFF", width: "24px", height: "24px" }} />
                          :
                          remainingDays < 7 ?
                            <ReportProblemIcon style={{ color: "#FFFFFF", width: "24px", height: "24px" }} />
                            :
                            <></>
                    }
                    {
                      remainingDays < 0 ?
                        <div className={classes.sentence}>
                          Your {licenseData?.License_Type} license has been expired on <span className='font-12'>{formattedDate}</span>, Please contact Buildpiper Team
                        </div> :
                        <div className={classes.sentence}>
                          Your {licenseData?.License_Type} license is valid till <span className='font-12'>{formattedDate}</span>, Please contact Buildpiper Team
                        </div>
                    }

                  </div>
                  <div className={additionalDrawer ? classes.additionalMarginRight : classes.marginRight} style={{ display: "flex", alignItems: "center", width: "155px", justifyContent: 'space-between' }}>
                    <div style={{ display: "flex", alignItems: "center", marginRight: "24px" }}>
                      <div className={classes.daysLeft} style={(remainingDays > 15 || remainingDays == 15) ? { color: "#0086FF" } : remainingDays > 7 && remainingDays < 15 ? { color: "#FEA111" } : (remainingDays < 7 || remainingDays == 7) ? { color: "#E95454" } : {}}>
                        {
                          remainingDays < 0 ?

                            'EXPIRED'
                            :
                            `${remainingDays} days left`
                        }
                      </div>
                      {/* <div className={classes.manage_plan} onClick={handleManagePlanClick}>
                     <img src="/images/vip-crown-line.svg" style={{ width: "24px", height: "24px" }} />
                     <p>Manage Plan</p>
                   </div> */}
                    </div>
                    <CloseIcon style={{ color: "#ffffff", width: "20px", height: "20px" }} onClick={handleCloseStrip} />
                  </div>
                </div> :
              null
          }
        </>
      }
    </>
  );
}
export default LicenseExpirationStatusComponent;
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: '#407BCA',
    color: 'white',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '50px',
  },

  warning_container: {
    width: "100%",
    backgroundColor: '#E1941D',
    color: 'white',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '50px',

  },
  danger_container: {
    width: "100%",
    backgroundColor: '#E95454',
    color: 'white',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '50px',
  },

  infoIcon: {
    fontSize: '24px',
  },
  sentence: {
    flexGrow: 1,
    margin: '0 10px',
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
    marginLeft: "8px",
    "& span": {
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "22px",
      letterSpacing: "0em",
      textAlign: "left",
    }
    //styleName: Headers/H4-Semi Bold;
    // font-family: Montserrat;
    // font-size: 16px;
    // font-weight: 600;
    // line-height: 20px;
    // letter-spacing: 0em;
    // text-align: left;
    // //styleName: Headers/H4;
    // font-family: Montserrat;
    // font-size: 16px;
    // font-weight: 500;
    // line-height: 20px;
    // letter-spacing: 0em;
    // text-align: left;
    // //styleName: Headers/H3;
    // font-family: Montserrat;
    // font-size: 18px;
    // font-weight: 500;
    // line-height: 22px;
    // letter-spacing: 0em;
    // text-align: left;
  },
  daysLeft: {
    backgroundColor: 'white',
    // color: '#0086FF',
    border: 'none',
    borderRadius: '6px',
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "17px",
    letterSpacing: "0em",
    textAlign: "left",
    marginLeft: "10px",
    height: '23px',
    width: '106px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  manage_plan: {
    display: "flex",
    alignItems: "center",
    border: 'none',
    // width: "153px",
    // height: "40px",
    padding: '6px 20px',
    borderRadius: "6px",
    backgroundColor: "#FEA111",
    marginLeft: "10px",
    "& p": {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "15px",
      letterSpacing: "0em",
      textAlign: "left",
      marginLeft: "6px",
    }
  },
  closeIcon: {
  },
  marginRight: {
    marginRight: "82px",
  },
  additionalMarginRight: {
    marginRight: "252px",
    '@media screen and (max-width: 1440px)': {
      marginRight: "252px"
    },
    '@media screen and (min-width: 1440px) and (max-width: 1920px)': {
      marginRight: "288px"
    },
  }
}));