import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import ServiceExecutionCard from './ServiceExecutionCard';
import JiraJobExecutionCard from './JiraJobExecutionCard';
import { FormatTime, getListableArray } from '../../../../util/util';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import { Loading } from '../../../utils/Loading';
import InvokeApi, { UnsubscribeToApi, SubscribeToApi, PostData } from '../../../../util/apiInvoker';
import { ErrorComponent } from '../../../utils/Error';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ApiJobExecutionCard from './ApiJobExecutionCard';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import StopIcon from '@material-ui/icons/Stop';
import InvokePipelineJobExecutionCard from './InvokePipelineJobExecutionCard';
import ConfigmapExecutionCard from './ConfigmapExecutionCard';
import SecretsExecutionCard from './SecretsExecutionCard';
import { Tooltip } from '@material-ui/core';
import SnowJobExecutionCard from './SnowJobExecutionCard';
import CanaryJobExecutionCard from './CanaryJobExecutionCard';
import DeplayJobExecutionCard from './DeplayJobExecutionCard';
import ServiceExecutionCardForDynamicJobs from './ServiceExecutionCardForDynamicJobs';
import { Dialog, Slide, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import MoreMenu from '../../../jobTemplates/components/MoreMenu';
import ManageCanary from './ManageCanary';


const JobExecutionCard = ({ isDynamicJob, ...props }) => {
    console.log(props, "dsafvhfshvbjfvhfd")
    const { pipeline_id, pipeline_instance_id, stage_instance_id, task_instance_id, requestInstanceData } = props;
    const application_id = props.application_id;
    const task = props.task;
    console.log(task, 'task_001_pp')
    var status = "";
    var time_taken = ""
    const stage_data = props.stage;

    const showLogs = props.showLogs;
    const showLogsCallback = props.showLogs;
    const version = props.version;
    const counter = props.counter;
    const pipeline_instance = props.pipeline_instance
    const inherits = props.inherits ? props.inherits : {};
    console.log(isDynamicJob, 'task_2323322323')
    initializeVariable();

    function initializeVariable() {
        if (task.instance) {
            status = task.instance.status;
            const start_time = new Date(task.instance.created_at);
            const end_time = new Date(task.instance.updated_at);
            const diff = end_time - start_time;
            time_taken = FormatTime(diff);
        }
    }

    const [state, setState] = useState({ show_service: status == "RUNNING" || status == "IN_QUEUE", openManageDialog: false, data: {}, error: {} });

    const commonFunctions = getCommonFunctions(state, setState, inherits);

    const [pop, setPop] = useState(null);

    function toggleService(e) {

        if (task.instance) {
            setState((new_state) => ({
                ...new_state,
                show_service: !new_state.show_service
            }))
        }
        handlePopOpen(e);
    }
    const handlePopOpen = e => setPop(e.currentTarget);

    const handleClosePopover = () => setPop(null);

    function getProgressStatusIcon() {
        console.log(task.instance, status, "sabdhabcjgsvadgcsd")
        if (!task.instance) {
            return (
                <div className="circle-status"></div>
            );
        } else {
            switch (status) {
                case "SUCCESS":
                    return (
                        <div className="circle-status circle-status-approved"><div className="flaticon-check-symbol"></div></div>
                    );
                case "FAILED":
                    return (
                        <div className="circle-status circle-status-failed"><div className="flaticon-warning-sign"></div></div>
                    );
                case "RUNNING":
                    return (
                        <div className="circle-status circle-status-pending"><div className="flaticon-clock-with-white-face"></div></div>
                    );
                case "SKIPPED":
                    return (
                        <div className="circle-status circle-status-skipped" ><div className="flaticon-merge-calls-arrow"></div></div>
                    );
                case "REVOKED":
                    return (
                        <div className="circle-status circle-status-revoked" ><TimelapseIcon fontSize="large" className="color-revoked" /></div>
                    );
                case "STOPPED":
                    return (
                        <div className="circle-status circle-status-stopped" ><StopIcon fontSize="large" className="color-stopped" /></div>
                    );
                case "IN_QUEUE":
                    return (
                        <div className="circle-status circle-status-inqueue"><div className="flaticon-clock-with-white-face"></div></div>
                    );
                default:
                    return (
                        <div className="circle-status circle-status-pending"><div className="flaticon-clock-with-white-face"></div></div>
                    );
            }
        }
    }
    const component_task_instance = props.component_task_instance;

    // const updateMutipleDeploymentTaskIdForComponent = (informationTaskId,componentId)=>{
    //     setState(prevState=>({
    //         ...prevState,

    //     }))
    // }

    console.log(pop, "sfbcdsvbhvbfhd")


    const isIndependentJob = isDynamicJob && task?.components?.length < 1

    function handleOpenManageDialog() {
        setState((new_state) => ({
            ...new_state,
            openManageDialog: true,
        }))
        // getServiceDetails()
    }
    function handleCloseDialog() {
        console.log("running")
        setState((new_state) => ({
            ...new_state,
            openManageDialog: false,
        }))
    }
    console.log(isDynamicJob, "sbvgsbdhbvsdhgvb")

    return (

        <>
            {
                state.openManageDialog &&
                <ManageCanary open={state.openManageDialog} handleCloseDialog={handleCloseDialog} {...props} task_instance_id={task?.instance?.id} canary_task_instance_id={state.canary_task_instance_id} requestInstanceData={requestInstanceData} />
            }

            <JobCard >
                <div className="vertical-border-pipeline-jobcard"></div>
                <div className="vertical-border-pipeline-top"></div>
                <div className="border-stage-card">
                    {getProgressStatusIcon()}
                </div>
                <JobCardHeader>
                    <div>
                        <span>{task.name} : </span>
                        <span>Job Type:</span>
                        {console.log(isDynamicJob, "lqlql+++")}
                        {
                            !isDynamicJob ?
                                <span> {task.operation ? task.operation : task.task_type.name == "Canary Analysis" ? "Canary" : task.task_type.name}  </span>
                                :
                                <span> {task.job_code}  </span>
                        }
                    </div>
                    {
                        !isDynamicJob && task.task_type.name == "Canary Analysis" && task.instance &&
                        <div style={{ position: "relative", cursor: "pointer" }} onClick={handleOpenManageDialog}>
                            <div class="manage-canary-button">Manage</div>
                            <span className='ri-information-fill' style={{ position: "absolute", top: "-8px", right: "-8px", fontSize: "16px", color: "#FEA111" }}></span>
                        </div>
                    }
                    <div className="d-flex" style={{ alignContent: "center" }}>

                        {!isDynamicJob && <span>{task.task_run_condition && <CallSplitIcon />}</span>}
                        {time_taken}
                        {
                            state.show_service ?
                                <div style={{ marginLeft: '10px', cursor: 'pointer' }} className="flaticon-expand-arrow" onKeyDown={() => { }} onClick={e => { toggleService(e) }} role="button" tabIndex={0}></div> :
                                <div style={{ marginLeft: '10px', cursor: 'pointer' }} className="flaticon-expand-button" onKeyDown={() => { }} onClick={e => { toggleService(e) }} role="button" tabIndex={0}></div>
                        }

                    </div>

                </JobCardHeader>
                <JobCardBody>

                    {

                        !isDynamicJob ? <>
                            {/* {
                            task.task_type.id != 5 ?
                                <>

                                    <div className="tag-under-card"> {task.project_env ? task.project_env.name : null} </div>
                                    {
                                        task.target_env_master ?
                                            <>
                                                <span className="flaticon-right-arrow-forward" style={{ color: '#fff' }}></span>
                                                <div className="tag-under-card"> {task.target_env_master.name} </div>
                                            </> : null
                                    }
                                    <div className="tag-under-card tag-bg"> {task.tag} </div>
                                    <div className="ml-auto">Services<span className="tag-under-card tag-bg m-25"> {task.component_count} </span></div>
                                </> : null
                        } */}
                            {
                                task.task_type.id == 5 &&
                                <>
                                    <div className="tag-under-card"> {task.method} </div>
                                    <div className="ml-auto">Url:<span className="vertical-bottom tag-under-card tag-bg m-25 text-ellipsis-200 d-inline-block " style={{ maxWidth: '140px' }}> {task.url} </span></div>
                                </>
                            }
                            {
                                task.task_type.id == 10 &&
                                <>
                                    <div className="tag-under-card"> {task.issuetype ? task.issuetype : "NA"} </div>
                                    {task.operation == "create_release" ?
                                        <div className="d-flex align-center">Release Name<Tooltip title={task.issue_key ? task.release_name : "N/A"}><span style={{ maxWidth: 150 }} className="tag-under-card tag-bg m-25 text-ellipsis-200"> {task.issue_key ? task.release_name : "N/A"}</span></Tooltip></div>
                                        :
                                        <div className="d-flex align-center">Issue Key<Tooltip title={task.issue_key ? task.issue_key : task.dynamic_fields.jira_issue_list ? task.dynamic_fields.jira_issue_list.value : "N/A"}><span style={{ maxWidth: 150 }} className="tag-under-card tag-bg m-25 text-ellipsis-200"> {task.issue_key ? task.issue_key : task.dynamic_fields.jira_issue_list ? task.dynamic_fields.jira_issue_list.value : "N/A"}</span></Tooltip></div>
                                    }

                                </>
                            }
                            {
                                task.task_type.id == 16 &&
                                <>
                                    {/* <div className="tag-under-card"> {task.issuetype ? task.issuetype : "NA"} </div> */}
                                    {task.snow_operation == "snow_create" ?
                                        <div className="d-flex align-center">Issue Key<Tooltip title={task.issue_key ? task.issue_key : "N/A"}><span style={{ maxWidth: 150 }} className="tag-under-card tag-bg m-25 text-ellipsis-200">{task.issue_key ? task.issue_key : "N/A"}</span></Tooltip></div>
                                        :
                                        <div className="d-flex align-center">Issue Key<Tooltip title={task.dynamic_fields.issue_key ? task.dynamic_fields.issue_key.value : "N/A"}><span style={{ maxWidth: 150 }} className="tag-under-card tag-bg m-25 text-ellipsis-200">{task.dynamic_fields.issue_key ? task.dynamic_fields.issue_key.value : "N/A"}</span></Tooltip></div>
                                    }
                                </>
                            }


                            {
                                task.task_type.id != 5 && task.task_type.id != 10 && task.task_type.id != 16 ?
                                    <>
                                        {task.project_env && task.project_env.name ?
                                            <div>
                                                <div className="tag-under-card">
                                                    {task.project_env.name}
                                                </div>
                                                {
                                                    task.task_type.name == "Canary Analysis" && task.instance &&
                                                    <div style={{ display: "flex", alignItems: "center", marginTop: "13px" }}>
                                                        <span className='ri-information-fill' style={{ color: "#FEA111", fontSize: "16px" }}></span>
                                                        <p style={{ marginLeft: "4px", fontWeight: "500", fontSize: "12px", color: "#FFFFFF" }}>Manual approval is required, Click on manage </p>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            null
                                        }

                                        {
                                            task.target_project_env ?
                                                <>
                                                    <span className="flaticon-right-arrow-forward" style={{ color: '#fff' }}></span>
                                                    <div className="tag-under-card"> {task.target_project_env.name} </div>
                                                </> : null
                                        }
                                        {task.task_type.id != 13 ?
                                            task.task_type.id != 11 && task.task_type.id != 12 && task.task_type.id != 6 && task.task_type.id != 7 && task.task_type.id != 15 ?
                                                <div className="tag-under-card tag-bg"> {task.tag} </div> : null : null
                                        }
                                        {
                                            task.task_type.id == 13 &&
                                            <div className='d-flex align-center'>Suspend pipeline: <div className="tag-under-card tag-bg">{task.suspend_pipeline ? "True" : "False"}</div> </div>
                                        }
                                        {
                                            task.task_type.id == 2 && task.pod_shift_percentage ?
                                            <div className="tag-under-card tag-bg"> Pod Shift : {task.pod_shift_percentage}% </div> : null
                                        }
                                        {/* <div className="ml-auto">Services<span className="tag-under-card tag-bg m-25"> {task.component_count} </span></div> */}
                                    </> : null
                            }
                        </>
                            :
                            <>
                                {task.project_env && task.project_env.name ? <div className="tag-under-card"> {task.project_env.name} </div> : null}
                                {isIndependentJob ?
                                    <div className="ml-auto d-flex align-center">
                                        <span className="tag-under-card-non-dependent-job m-25"> Independent Job </span>
                                    </div> :
                                    <div className="ml-auto d-flex align-center">
                                        <span>Services</span>
                                        <span className="tag-under-card tag-bg m-25"> {task.component_count} </span>
                                    </div>

                                }

                            </>
                    }


                </JobCardBody>
                {
                    state.show_service ?
                        <ServiceList
                            stage={stage_data}
                            showLogs={showLogs}
                            {...props}
                            counter={counter}
                            task_instance_id={task.instance.id}
                            version={version}
                            isDynamicJob={isDynamicJob}
                            task_details={task}
                            pop={pop}
                            handlePopOpen={handlePopOpen}
                            handleClosePopover={handleClosePopover}
                            application_id={application_id}
                        /> : null
                }

            </JobCard>
        </>
    )
}

const useStylesForMonitoringDialog = makeStyles((theme) => ({
    noDeploy: {
        padding: "12px",
        border: "1px solid #DFEDFF",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F5FAFF",
        gap: "10px",
        "& .iconCont ": {
            padding: "12px",
            border: "1px solid #DFEDFF",
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFF",
        }
    },
    notMontInfo: {
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        padding: "12px",
        width: "100%",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        background: "#FAFAFA",
    }
}))

JobExecutionCard.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

export default JobExecutionCard;

const ServiceList = ({ isDynamicJob, ...props }) => {
    console.log(props, "rtygb =============>")
    const { pipeline_id, pipeline_instance_id, stage_instance_id, task_instance_id, application_id } = props;
    const showLogs = props.showLogs;
    const showLogsCallback = props.showLogs;
    const [state, setState] = useState({
        loaded: false,
        selected_component_task_id: null
    });
    const task_details = props.task_details ? props.task_details : null;
    console.log(task_details, "fdsafdsafadfa")
    const env_id = task_details && task_details.project_env ? task_details.project_env.id : null;
    const task_type = task_details && task_details.task_type ? task_details && task_details.task_type : null
    const counter = props.counter
    const version = props.version
    useEffect(() => {
        getServiceDetails();
        SubscribeToApi(GenerateURL({
            pipeline_id: pipeline_id,
            pipeline_instance_id: pipeline_instance_id,
            stage_instance_id: stage_instance_id,
            task_instance_id: task_instance_id,
        }, properties.api.task_service_details));

        return () => {
            UnsubscribeToApi(GenerateURL({
                pipeline_id: pipeline_id,
                pipeline_instance_id: pipeline_instance_id,
                stage_instance_id: stage_instance_id,
                task_instance_id: task_instance_id,
            }, properties.api.task_service_details));
        }
    }, []);


    useEffect(() => {
        setTimeout(getServiceDetails, 5000);
    }, [task_details]);

    function getServiceDetails() {
        var requestInfo = {
            endPoint: GenerateURL({
                pipeline_id: pipeline_id,
                pipeline_instance_id: pipeline_instance_id,
                stage_instance_id: stage_instance_id,
                task_instance_id: task_instance_id,
            }, properties.api.task_service_details),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleResponse, handleError);
    }

    function handleResponse(data) {

        if (data.status == "RUNNING" || data.status == "IN_QUEUE" || data.status == "PENDING_RESUME") {
            setTimeout(getServiceDetails, 5000);
        }


        setState(new_state => ({
            ...new_state,
            loaded: true,
            data: data,
        }));
    }

    function handleError(error) {
        setState((new_state) => ({
            ...new_state,
            loaded: false,
            error: error
        }));
    }

    function updateSelectedComponentTask(id) {
        setState((new_state) => ({
            ...new_state,
            selected_component_task_id: id
        }));
    }
    function onResume(service_id, component_env_id, id) {
        if (task_type && task_type.code === "BUILD") {
            var url_temp = GenerateURL({ service_id: service_id, env_id: component_env_id, id: id }, properties.api.build_resume);
        }
        if (task_type && task_type.code === "DEPLOY") {
            var url_temp = GenerateURL({ service_id: service_id, env_id: component_env_id, id: id }, properties.api.deploy_resume);
        }
        if (task_type && task_type.code === "PROMOTE") {
            var url_temp = GenerateURL({ service_id: service_id, env_id: component_env_id, id: id }, properties.api.promote_resume);
        }
        if (task_type && task_type.code === "INTEGRATION") {
            var url_temp = GenerateURL({ service_id: service_id, env_id: component_env_id, id: id }, properties.api.integration_resume);
        }
        PostData(url_temp, {}, handleSuccessResume, handleFailedResume);
        setState(new_state => ({
            ...new_state,
            loaded: false,
        }));
    }


    function handleSuccessResume(data) {
        if (data.status == "RUNNING" || data.status == "IN_QUEUE" || data.status == "PENDING_RESUME") {
            setTimeout(getServiceDetails, 2000);
        }
        setState(new_state => ({
            ...new_state,
            loaded: true,
        }));
    }

    function handleFailedResume(error) {
        setState(new_state => ({
            ...new_state,
            loaded: false,
            error: error
        }));
    }

    const updateSelectedInformationId = (infoId) => {
        console.log(infoId, 'gfpt')
        setState(prevState => ({
            ...prevState,
            selectedInfoId: infoId
        }))
    }
    console.log(state.data, 'defr')

    console.log(state, 'state_001_pp')

    return (
        <span>
            {
                !state.loaded ? <Loading />
                    : state.error ? <ErrorComponent /> :
                        <>
                            {
                                state.data && (state.data.jira_integration_task_instance && state.data.jira_integration_task_instance.length > 0) ?
                                    state.data.jira_integration_task_instance.map(item => (
                                        <JiraJobExecutionCard
                                            stage={props.stage}
                                            showLogs={showLogs}
                                            selected_component_task_id={state.selected_component_task_id}
                                            update_selected_component_task={updateSelectedComponentTask}
                                            {...props}
                                            jira_task_instance={item} />
                                    ))
                                    :
                                    state.data && (state.data.snow_integration_task_instance && state.data.snow_integration_task_instance.length > 0) ?
                                        state.data.snow_integration_task_instance.map(item => (
                                            <SnowJobExecutionCard
                                                stage={props.stage}
                                                showLogs={showLogs}
                                                selected_component_task_id={state.selected_component_task_id}
                                                update_selected_component_task={updateSelectedComponentTask}
                                                {...props}
                                                snow_task_instance={item} />
                                        ))
                                        :
                                        state.data && (state.data.rest_api_task_instance && state.data.rest_api_task_instance.length > 0) ?
                                            state.data.rest_api_task_instance.map(data => (
                                                <ApiJobExecutionCard
                                                    stage={props.stage}
                                                    showLogs={showLogs}
                                                    selected_component_task_id={state.selected_component_task_id}
                                                    update_selected_component_task={updateSelectedComponentTask}
                                                    {...props}
                                                    rest_api_task_instace={data} />
                                            ))
                                            :
                                            state.data && (state.data.invoke_pipeline_task_instance && state.data.invoke_pipeline_task_instance.length > 0) ?
                                                state.data.invoke_pipeline_task_instance.map(data => (
                                                    <InvokePipelineJobExecutionCard
                                                        stage={props.stage}
                                                        showLogs={showLogs}
                                                        selected_component_task_id={state.selected_component_task_id}
                                                        update_selected_component_task={updateSelectedComponentTask}
                                                        {...props}
                                                        rest_api_task_instace={data} />
                                                ))
                                                :
                                                state.data && (state.data.configmap_deploy_task_instance && state.data.configmap_deploy_task_instance.length > 0) ?
                                                    state.data.configmap_deploy_task_instance.map(component_task => (
                                                        <ConfigmapExecutionCard
                                                            stage={props.stage}
                                                            showLogs={showLogs}
                                                            project_env_id={env_id}
                                                            component_env_id={""}
                                                            selected_component_task_id={state.selected_component_task_id}
                                                            update_selected_component_task={updateSelectedComponentTask}
                                                            {...props}
                                                            task_type_obj={task_type}
                                                            component_task_instance={component_task}
                                                            onResume={onResume}
                                                            task_details={task_details}
                                                            pop={props.pop}
                                                            handlePopOpen={props.handlePopOpen}
                                                            handleClosePopover={props.handleClosePopover}
                                                        />
                                                    ))
                                                    :
                                                    state.data && (state.data.secret_deploy_task_instance && state.data.secret_deploy_task_instance.length > 0) ?
                                                        state.data.secret_deploy_task_instance.map(component_task => (
                                                            <SecretsExecutionCard
                                                                stage={props.stage}
                                                                showLogs={showLogs}
                                                                project_env_id={env_id}
                                                                component_env_id={""}
                                                                selected_component_task_id={state.selected_component_task_id}
                                                                update_selected_component_task={updateSelectedComponentTask}
                                                                {...props}
                                                                task_type_obj={task_type}
                                                                component_task_instance={component_task}
                                                                onResume={onResume}
                                                                task_details={task_details}
                                                                pop={props.pop}
                                                                handlePopOpen={props.handlePopOpen}
                                                                handleClosePopover={props.handleClosePopover}
                                                            />
                                                        ))
                                                        :
                                                        state.data && (state.data.canary_analysis_task_instance && state.data.canary_analysis_task_instance.length > 0) ?
                                                            state.data.canary_analysis_task_instance.map((component_task) => (
                                                                <CanaryJobExecutionCard
                                                                    application_id={application_id}
                                                                    component_task_instance={component_task}
                                                                    project_env_id={env_id}
                                                                    component_env_id={""}
                                                                    selected_component_task_id={state.selected_component_task_id}
                                                                    update_selected_component_task={updateSelectedComponentTask}
                                                                    {...props}
                                                                    task_type_obj={task_type}
                                                                    onResume={onResume}
                                                                    task_details={task_details}
                                                                    pop={props.pop}
                                                                    handlePopOpen={props.handlePopOpen}
                                                                    handleClosePopover={props.handleClosePopover}
                                                                    pipeline_id={pipeline_id}
                                                                    pipeline_instance_id={pipeline_instance_id}
                                                                    complete_data={state.data}
                                                                // pipeline_instance={pipeline_instance}
                                                                />
                                                            ))
                                                            :
                                                            state.data && (state.data.delay_task_instance && state.data.delay_task_instance.length > 0) ?
                                                                state.data.delay_task_instance.map(component_task => (
                                                                    <DeplayJobExecutionCard
                                                                        stage={props.stage}
                                                                        showLogs={showLogs}
                                                                        project_env_id={env_id}
                                                                        component_env_id={""}
                                                                        selected_component_task_id={state.selected_component_task_id}
                                                                        update_selected_component_task={updateSelectedComponentTask}
                                                                        {...props}
                                                                        task_type_obj={task_type}
                                                                        delay_deploy_task_instance={component_task}
                                                                        onResume={onResume}
                                                                        task_details={task_details}
                                                                        version={version}
                                                                        pop={props.pop}
                                                                        handlePopOpen={props.handlePopOpen}
                                                                        handleClosePopover={props.handleClosePopover}
                                                                        updateSelectedInformationId={updateSelectedInformationId}
                                                                        selectedInfoId={state.selectedInfoId}
                                                                    />
                                                                ))
                                                                :

                                                                state?.data.pipeline_task_job_instance?.length > 0 ?
                                                                    state?.data?.pipeline_task_job_instance?.map(component_task => (
                                                                        <ServiceExecutionCardForDynamicJobs
                                                                            stage={props.stage}
                                                                            showLogs={showLogs}
                                                                            project_env_id={env_id}
                                                                            component_env_id={""}
                                                                            selected_component_task_id={state.selected_component_task_id}
                                                                            update_selected_component_task={updateSelectedComponentTask}
                                                                            {...props}
                                                                            task_type_obj={task_type}
                                                                            component_task_instance={component_task}
                                                                            onResume={onResume}
                                                                            task_details={task_details}
                                                                            version={version}
                                                                            isDynamicJob={isDynamicJob}
                                                                            pop={props.pop}
                                                                            handlePopOpen={props.handlePopOpen}
                                                                            handleClosePopover={props.handleClosePopover}
                                                                            updateSelectedInformationId={updateSelectedInformationId}
                                                                            selectedInfoId={state.selectedInfoId}
                                                                        />
                                                                    ))


                                                                    :
                                                                    state.data.component_task_instance.map(component_task => (
                                                                        <ServiceExecutionCard
                                                                            stage={props.stage}
                                                                            showLogs={showLogs}
                                                                            project_env_id={env_id}
                                                                            component_env_id={""}
                                                                            selected_component_task_id={state.selected_component_task_id}
                                                                            update_selected_component_task={updateSelectedComponentTask}
                                                                            {...props}
                                                                            task_type_obj={task_type}
                                                                            component_task_instance={component_task}
                                                                            onResume={onResume}
                                                                            task_details={task_details}
                                                                            version={version}
                                                                            isDynamicJob={isDynamicJob}
                                                                            pop={props.pop}
                                                                            handlePopOpen={props.handlePopOpen}
                                                                            handleClosePopover={props.handleClosePopover}
                                                                            updateSelectedInformationId={updateSelectedInformationId}
                                                                            selectedInfoId={state.selectedInfoId}
                                                                        />
                                                                    ))
                            }

                        </>
            }
        </span>
    );
}

ServiceList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

const JobCard = styled('div')({
    margin: '1.5rem 6rem',
    maxWidth: '324px',
    position: 'relative',
    '&:only-child > .vertical-border-bottom': {
        display: 'none'
    },
    '& div.border-stage-card': {
        height: '3px',
        backgroundColor: '#666',
        width: '30px',
        position: 'absolute',
        left: '-28px',
        top: '36px'
    },
    '& .vertical-border-pipeline-jobcard': {
        top: '5rem',
        left: '-28px',
        width: '3px',
        height: '100%',
        position: 'absolute',
        backgroundColor: '#666'
    },
    '&:last-child .vertical-border-pipeline-jobcard': {
        display: 'none'
    },
    '&:only-child .vertical-border-pipeline-jobcard': {
        display: 'none'
    },
    '& .vertical-border-bottom ': {
        width: '3px',
        backgroundColor: '#666',
        height: 'calc( 100% - 4.5rem )',
        position: 'absolute',
        left: '10px',
        top: '79px'
    },
    '& .circle-status': {
        height: '30px',
        width: '30px',
        border: '3px solid #666',
        position: 'relative',
        left: '-16px',
        bottom: '14px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        zIndex: 99,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .circle-status-approved': {
        border: '3px solid #61e187'
    },
    '& .circle-status-approved .flaticon-check-symbol:before': {
        fontSize: '12px!important',
        color: '#61e187'
    },
    '& .circle-status-pending': {
        border: '3px solid #ffbf00'
    },
    '& .circle-status-revoked': {
        border: '3px solid #c294f1'
    },
    '& .circle-status-stopped': {
        border: '3px solid #e9797e'
    },
    '& .circle-status-inqueue': {
        border: '3px solid #b1cde3 !important'
    },
    '& .circle-status-inqueue .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#b1cde3',
    },
    '&  .circle-status-pending .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#ffbf00'
    },
    '& .circle-status-failed': {
        border: '3px solid #ff8969'
    },
    '&  .circle-status-failed .flaticon-warning-sign:before': {
        fontSize: '16px!important',
        color: '#ff8969'
    },
    '& .circle-status-skipped': {
        border: '3px solid #52bfe7'
    },
    '& .circle-status-skipped .flaticon-merge-calls-arrowe:before': {
        fontSize: '12px!important',
        color: '#52bfe7'
    },
})
const JobCardHeader = styled('div')({
    backgroundColor: '#4652a2',
    borderRadius: '4px 4px 0px 0px',
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& div span': {
        fontSize: '12px',
        color: '#fff',
        lineHeight: '18px',
    },
    '& div': {
        fontSize: '12px',
        color: '#fff',
        lineHeight: '18px',
    },
    "& .manage-canary-button": {
        padding: "6px 12px 6px 12px",
        borderRadius: "4px",
        border: "1px solid #FFFFFF",
        backgroundColor: "rgba(255, 255, 255, 0.12)",
        color: "#FFFFFF",
        fontWeight: "600",
    }
})
const JobCardBody = styled('div')({
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#6b74a8',
    borderRadius: '0px 0px 4px 4px',
    '& div': {
        fontSize: '12px',
        color: '#fff',
        lineHeight: '18px',
    }
})