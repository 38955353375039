import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import ServiceExecutionCard from './ServiceExecutionCard';
import JiraJobExecutionCard from './JiraJobExecutionCard';
import { FormatTime } from '../../../../util/util';
import properties from '../../../../properties/properties';
import GenerateURL from '../../../../util/APIUrlProvider';
import { Loading } from '../../../utils/Loading';
import InvokeApi, { UnsubscribeToApi, SubscribeToApi, PostData } from '../../../../util/apiInvoker';
import {  ErrorComponent } from '../../../utils/Error';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ApiJobExecutionCard from './ApiJobExecutionCard';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import StopIcon from '@material-ui/icons/Stop';
import InvokePipelineJobExecutionCard from './InvokePipelineJobExecutionCard';
import ConfigmapExecutionCard from './ConfigmapExecutionCard';
import SecretsExecutionCard from './SecretsExecutionCard';
import { Tooltip } from '@material-ui/core';


const JobExecutionCard = props => {
    const task = props.task;
    var status = "";
    var time_taken = ""
    const stage_data = props.stage;
   
    const showLogs = props.showLogs;
    const showLogsCallback = props.showLogs;
    const version = props.version;
    const counter = props.counter
    console.log(task, 'task_2323322323',counter)
    initializeVariable();

    function initializeVariable() {
        if (task.instance) {
            status = task.instance.status;
            const start_time = new Date(task.instance.created_at);
            const end_time = new Date(task.instance.updated_at);
            const diff = end_time - start_time;
            time_taken = FormatTime(diff);
        }
    }

    const [state, setState] = useState({ show_service: status == "RUNNING" || status == "IN_QUEUE" });

    const [pop, setPop] = useState(null);

    function toggleService(e) {
        
        if (task.instance) {
            setState({
                ...state,
                show_service: !state.show_service
            })
        }
        handlePopOpen(e);
    }
    const handlePopOpen = e => setPop(e.currentTarget);

    const handleClosePopover = () => setPop(null);

    function getProgressStatusIcon() {
        if (!task.instance) {
            return (
                <div className="circle-status"></div>
            );
        } else {
            switch (status) {
                case "SUCCESS":
                    return (
                        <div className="circle-status circle-status-approved"><div class="flaticon-check-symbol"></div></div>
                    );
                case "FAILED":
                    return (
                        <div className="circle-status circle-status-failed"><div className="flaticon-warning-sign"></div></div>
                    );
                case "RUNNING":
                    return (
                        <div className="circle-status circle-status-pending"><div class="flaticon-clock-with-white-face"></div></div>
                    );
                case "SKIPPED":
                    return (
                        <div className="circle-status circle-status-skipped" ><div class="flaticon-merge-calls-arrow"></div></div>
                    );
                case "REVOKED":
                    return (
                        <div className="circle-status circle-status-revoked" ><TimelapseIcon fontSize="large" className="color-revoked" /></div>
                    );
                case "STOPPED":
                    return (
                        <div className="circle-status circle-status-stopped" ><StopIcon fontSize="large" className="color-stopped" /></div>
                    );
                case "IN_QUEUE":
                    return (
                        <div className="circle-status circle-status-inqueue"><div class="flaticon-clock-with-white-face"></div></div>
                    );
                default:
                    return (
                        <div className="circle-status circle-status-pending"><div class="flaticon-clock-with-white-face"></div></div>
                    );
            }
        }
    }
    const component_task_instance = props.component_task_instance;

    // const updateMutipleDeploymentTaskIdForComponent = (informationTaskId,componentId)=>{
    //     setState(prevState=>({
    //         ...prevState,

    //     }))
    // }

   

    return (
        <>
            <JobCard >
                <div className="vertical-border-pipeline-jobcard"></div>
                <div className="vertical-border-pipeline-top"></div>
                <div className="border-stage-card">
                    {getProgressStatusIcon()}
                </div>
                <JobCardHeader>
                    <div>
                        <span>{task.name} : </span>
                        <span>Job Type:</span>
                        {console.log(task, "lqlql+++")}
                        {
                            version === 'v2' ?
                            <>
                         
                            <span> {task.operation ? task.operation : task.task_type.name}  </span>
                            </>
                                :
                                <span> {task.job_code}  </span>
                        }
                    </div>
                    <div className="d-flex" style={{ alignContent: "center" }}>

                        {version === 'v2' && <span>{task.task_run_condition && <CallSplitIcon />}</span>}
                        {time_taken}
                        {
                            state.show_service ?
                                <div style={{ marginLeft: '10px', cursor: 'pointer' }} className="flaticon-expand-arrow" onClick={e => { toggleService(e) }} ></div> :
                                <div style={{ marginLeft: '10px', cursor: 'pointer' }} className="flaticon-expand-button" onClick={e => { toggleService(e) }} ></div>
                        }

                    </div>

                </JobCardHeader>
                <JobCardBody>

                    {

                        version === 'v2' ? <>
                            {/* {
                            task.task_type.id != 5 ?
                                <>

                                    <div className="tag-under-card"> {task.project_env ? task.project_env.name : null} </div>
                                    {
                                        task.target_env_master ?
                                            <>
                                                <span className="flaticon-right-arrow-forward" style={{ color: '#fff' }}></span>
                                                <div className="tag-under-card"> {task.target_env_master.name} </div>
                                            </> : null
                                    }
                                    <div className="tag-under-card tag-bg"> {task.tag} </div>
                                    <div className="ml-auto">Services<span className="tag-under-card tag-bg m-25"> {task.component_count} </span></div>
                                </> : null
                        } */}
                            {
                                task.task_type.id == 5 &&
                                <>
                                    <div className="tag-under-card"> {task.method} </div>
                                    <div className="ml-auto">Url:<span className="vertical-bottom tag-under-card tag-bg m-25 text-ellipsis-200 d-inline-block " style={{ maxWidth: '140px' }}> {task.url}</span></div>
                                </>
                            }
                            {
                                task.task_type.id == 10 &&
                                <>
                                    <div className="tag-under-card"> {task.issuetype ? task.issuetype : "NA"} </div>
                                    <div className="d-flex align-center">Issue Key<Tooltip title={task.issue_key ? task.issue_key : "N/A"}><span style={{maxWidth: 150}} className="tag-under-card tag-bg m-25 text-ellipsis-200"> {task.issue_key}</span></Tooltip></div>
                                </>
                            }


                            {
                                task.task_type.id != 5 && task.task_type.id != 10 ?
                                    <>
                                    {task?.project ? <div className="tag-under-card">  {task.project} </div> : null}
                                    
                                        {task.project_env && task.project_env.name ? <div className="tag-under-card">  {task.project_env.name} </div> : null}
                                        {
                                            task.target_project_env ?
                                                <>
                                                    <span className="flaticon-right-arrow-forward" style={{ color: '#fff' }}></span>
                                                    <div className="tag-under-card"> {task.target_project_env.name} </div>
                                                </> : null
                                        }
                                        {task.task_type.id != 13 ?
                                            task.task_type.id != 11 && task.task_type.id != 12 && task.task_type.id != 6 && task.task_type.id != 7 ?
                                                <div className="tag-under-card tag-bg"> {task.tag} </div> : null : null
                                        }
                                        {
                                            task.task_type.id == 13 &&
                                            <div className='d-flex align-center'>Suspend pipeline: <div className="tag-under-card tag-bg">{task.suspend_pipeline ? "True" : "False"}</div> </div>
                                        }
                                        {/* <div className="ml-auto">Services<span className="tag-under-card tag-bg m-25"> {task.component_count} </span></div> */}
                                    </> : null
                            }
                        </>
                            :
                            <>
                                {task.project_env && task.project_env.name ? <div className="tag-under-card"> {task.project_env.name} </div> : null}
                                <div className="ml-auto">Services<span className="tag-under-card tag-bg m-25"> {task.component_count} </span></div>
                            </>
                    }


                </JobCardBody>

                {
                    state.show_service ?
                        <ServiceList
                            stage={stage_data}
                            showLogs={showLogs}
                            {...props}
                            counter={counter}
                            task_instance_id={task.instance.id}
                            version={version}
                            task_details={task}
                            pop={pop}
                            handlePopOpen={handlePopOpen}
                            handleClosePopover={handleClosePopover}
                        /> : null
                }

            </JobCard>

        </>
    )
}

export default JobExecutionCard;

const ServiceList = (props) => {
    console.log(props, "rtygb =============>")
    const { pipeline_id, pipeline_instance_id, stage_instance_id, task_instance_id } = props;
    const showLogs = props.showLogs;
    const showLogsCallback = props.showLogs;
    const [state, setState] = useState({
        loaded: false,
        selected_component_task_id: null
    });
    const task_details = props.task_details ? props.task_details : null;
    console.log(task_details,"fdsafdsafadfa")
    const env_id = task_details && task_details.project_env ? task_details.project_env.id : null;
    const task_type = task_details && task_details.task_type ? task_details && task_details.task_type : null
    const counter = props.counter
    const version = props.version
    useEffect(() => {
        getServiceDetails();
        SubscribeToApi(GenerateURL({
            pipeline_id: pipeline_id,
            pipeline_instance_id: pipeline_instance_id,
            stage_instance_id: stage_instance_id,
            task_instance_id: task_instance_id,
        }, properties.api.task_service_details));
        return () => {
            UnsubscribeToApi(GenerateURL({
                pipeline_id: pipeline_id,
                pipeline_instance_id: pipeline_instance_id,
                stage_instance_id: stage_instance_id,
                task_instance_id: task_instance_id,
            }, properties.api.task_service_details));
        }
    }, []);


    useEffect(() => {
        setTimeout(getServiceDetails, 2000);
    }, [task_details]);

    function getServiceDetails() {
        var requestInfo = {
            endPoint: GenerateURL({
                pipeline_id: pipeline_id,
                pipeline_instance_id: pipeline_instance_id,
                stage_instance_id: stage_instance_id,
                task_instance_id: task_instance_id,
            }, properties.api.task_service_details),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, handleResponse, handleError);
    }

    function handleResponse(data) {

        if (data.status == "RUNNING" || data.status == "IN_QUEUE" || data.status == "PENDING_RESUME") {
            setTimeout(getServiceDetails, 2000);
        }


        setState(new_state => ({
            ...new_state,
            loaded: true,
            data: data,
        }));
    }

    function handleError(error) {
        setState({
            ...state,
            loaded: false,
            error: error
        });
    }

    function updateSelectedComponentTask(id) {
        setState({
            ...state,
            selected_component_task_id: id
        });
    }
    function onResume(service_id, component_env_id, id) {
        if (task_type && task_type.code === "BUILD") {
            var url_temp = GenerateURL({ service_id: service_id, env_id: component_env_id, id: id }, properties.api.build_resume);
        }
        if (task_type && task_type.code === "DEPLOY") {
            var url_temp = GenerateURL({ service_id: service_id, env_id: component_env_id, id: id }, properties.api.deploy_resume);
        }
        if (task_type && task_type.code === "PROMOTE") {
            var url_temp = GenerateURL({ service_id: service_id, env_id: component_env_id, id: id }, properties.api.promote_resume);
        }
        if (task_type && task_type.code === "INTEGRATION") {
            var url_temp = GenerateURL({ service_id: service_id, env_id: component_env_id, id: id }, properties.api.integration_resume);
        }
        PostData(url_temp, {}, handleSuccessResume, handleFailedResume);
        setState(new_state => ({
            ...new_state,
            loaded: false,
        }));
    }


    function handleSuccessResume(data) {
        if (data.status == "RUNNING" || data.status == "IN_QUEUE" || data.status == "PENDING_RESUME") {
            setTimeout(getServiceDetails, 2000);
        }
        setState(new_state => ({
            ...new_state,
            loaded: true,
        }));
    }

    function handleFailedResume(error) {
        setState(new_state => ({
            ...new_state,
            loaded: false,
            error: error
        }));
    }

    const updateSelectedInformationId = (infoId) => {
        console.log(infoId, 'gfpt')
        setState(prevState => ({
            ...prevState,
            selectedInfoId: infoId
        }))
    }
    console.log(state.selectedInfoId, 'defr')

    return (
        <span>
            {
                !state.loaded ? <Loading />
                    : state.error ? <ErrorComponent /> :
                        <>
                            {
                                state.data && (state.data.jira_integration_task_instance && state.data.jira_integration_task_instance.length > 0) ?
                                    state.data.jira_integration_task_instance.map(item => (
                                        <JiraJobExecutionCard
                                            stage={props.stage}
                                            showLogs={showLogs}
                                            selected_component_task_id={state.selected_component_task_id}
                                            update_selected_component_task={updateSelectedComponentTask}
                                            {...props}
                                            jira_task_instance={item} />
                                    ))
                                    :
                                    state.data && (state.data.rest_api_task_instance && state.data.rest_api_task_instance.length > 0) ?
                                        state.data.rest_api_task_instance.map(data => (
                                            <ApiJobExecutionCard
                                                stage={props.stage}
                                                showLogs={showLogs}
                                                selected_component_task_id={state.selected_component_task_id}
                                                update_selected_component_task={updateSelectedComponentTask}
                                                {...props}
                                                rest_api_task_instace={data} />
                                        ))
                                        :
                                        state.data && (state.data.invoke_pipeline_task_instance && state.data.invoke_pipeline_task_instance.length > 0) ?
                                            state.data.invoke_pipeline_task_instance.map(data => (
                                                <InvokePipelineJobExecutionCard
                                                    stage={props.stage}
                                                    showLogs={showLogs}
                                                    selected_component_task_id={state.selected_component_task_id}
                                                    update_selected_component_task={updateSelectedComponentTask}
                                                    {...props}
                                                    rest_api_task_instace={data} />
                                            ))
                                            :
                                            state.data && (state.data.configmap_deploy_task_instance && state.data.configmap_deploy_task_instance.length > 0) ?
                                                state.data.configmap_deploy_task_instance.map(component_task => (
                                                    <ConfigmapExecutionCard
                                                        stage={props.stage}
                                                        showLogs={showLogs}
                                                        project_env_id={env_id}
                                                        component_env_id={""}
                                                        selected_component_task_id={state.selected_component_task_id}
                                                        update_selected_component_task={updateSelectedComponentTask}
                                                        {...props}
                                                        task_type_obj={task_type}
                                                        component_task_instance={component_task}
                                                        onResume={onResume}
                                                        task_details={task_details}
                                                        pop={props.pop}
                                                        handlePopOpen={props.handlePopOpen}
                                                        handleClosePopover={props.handleClosePopover}
                                                    />
                                                ))
                                                :
                                                state.data && (state.data.secret_deploy_task_instance && state.data.secret_deploy_task_instance.length > 0) ?
                                                    state.data.secret_deploy_task_instance.map(component_task => (
                                                        <SecretsExecutionCard
                                                            stage={props.stage}
                                                            showLogs={showLogs}
                                                            project_env_id={env_id}
                                                            component_env_id={""}
                                                            selected_component_task_id={state.selected_component_task_id}
                                                            update_selected_component_task={updateSelectedComponentTask}
                                                            {...props}
                                                            task_type_obj={task_type}
                                                            component_task_instance={component_task}
                                                            onResume={onResume}
                                                            task_details={task_details}
                                                            pop={props.pop}
                                                            handlePopOpen={props.handlePopOpen}
                                                            handleClosePopover={props.handleClosePopover}
                                                        />
                                                    ))
                                                    :
                                                    state.data.component_task_instance.map(component_task => (
                                                        <ServiceExecutionCard
                                                            stage={props.stage}
                                                            showLogs={showLogs}
                                                            project_env_id={env_id}
                                                            component_env_id={""}
                                                            selected_component_task_id={state.selected_component_task_id}
                                                            update_selected_component_task={updateSelectedComponentTask}
                                                            {...props}
                                                            task_type_obj={task_type}
                                                            component_task_instance={component_task}
                                                            onResume={onResume}
                                                            task_details={task_details}
                                                            version={version}
                                                            pop={props.pop}
                                                            handlePopOpen={props.handlePopOpen}
                                                            handleClosePopover={props.handleClosePopover}
                                                            updateSelectedInformationId={updateSelectedInformationId}
                                                            selectedInfoId={state.selectedInfoId}
                                                        />
                                                    ))
                            }

                        </>
            }
        </span>
    );
}

const JobCard = styled('div')({
    margin: '1.5rem 6rem',
    maxWidth: '324px',
    position: 'relative',
    '&:only-child > .vertical-border-bottom': {
        display: 'none'
    },
    '&:only-child > .vertical-border-bottom': {
        display: 'none'
    },
    '& div.border-stage-card': {
        height: '3px',
        backgroundColor: '#666',
        width: '30px',
        position: 'absolute',
        left: '-28px',
        top: '36px'
    },
    '& .vertical-border-pipeline-jobcard': {
        top: '5rem',
        left: '-28px',
        width: '3px',
        height: '100%',
        position: 'absolute',
        backgroundColor: '#666'
    },
    '&:last-child .vertical-border-pipeline-jobcard': {
        display: 'none'
    },
    '&:only-child .vertical-border-pipeline-jobcard': {
        display: 'none'
    },
    '& .vertical-border-bottom ': {
        width: '3px',
        backgroundColor: '#666',
        height: 'calc( 100% - 4.5rem )',
        position: 'absolute',
        left: '10px',
        top: '79px'
    },
    '& .circle-status': {
        height: '30px',
        width: '30px',
        border: '3px solid #666',
        position: 'relative',
        left: '-16px',
        bottom: '14px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        zIndex: 99,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .circle-status-approved': {
        border: '3px solid #61e187'
    },
    '& .circle-status-approved .flaticon-check-symbol:before': {
        fontSize: '12px!important',
        color: '#61e187'
    },
    '& .circle-status-pending': {
        border: '3px solid #ffbf00'
    },
    '& .circle-status-revoked': {
        border: '3px solid #c294f1'
    },
    '& .circle-status-stopped': {
        border: '3px solid #e9797e'
    },
    '& .circle-status-inqueue': {
        border: '3px solid #b1cde3 !important'
    },
    '& .circle-status-inqueue .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#b1cde3',
    },
    '&  .circle-status-pending .flaticon-clock-with-white-face:before': {
        fontSize: '12px!important',
        color: '#ffbf00'
    },
    '& .circle-status-failed': {
        border: '3px solid #ff8969'
    },
    '&  .circle-status-failed .flaticon-warning-sign:before': {
        fontSize: '16px!important',
        color: '#ff8969'
    },
    '& .circle-status-skipped': {
        border: '3px solid #52bfe7'
    },
    '& .circle-status-skipped .flaticon-merge-calls-arrowe:before': {
        fontSize: '12px!important',
        color: '#52bfe7'
    },
})
const JobCardHeader = styled('div')({
    backgroundColor: '#4652a2',
    borderRadius: '4px 4px 0px 0px',
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& div span': {
        fontSize: '12px',
        color: '#fff',
        lineHeight: '18px',
    },
    '& div': {
        fontSize: '12px',
        color: '#fff',
        lineHeight: '18px',
    }
})
const JobCardBody = styled('div')({
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#6b74a8',
    borderRadius: '0px 0px 4px 4px',
    '& div': {
        fontSize: '12px',
        color: '#fff',
        lineHeight: '18px',
    }
})