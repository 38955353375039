import React, { useEffect, useState } from "react";
import JobCard from "./jobCard/JobCard";
import { makeStyles } from '@material-ui/core/styles';
import JobCardServiceCard from "./jobCard/JobCardServiceCard";
import JobTemplateTriggerHistory from "./JobTemplateTriggerHistory";
import { Link } from 'react-router-dom';


const JobTemplateListingV3ServiceCard = (props) => {
    const {
        allJobs,
        configuredJobs,
        service_data,
        filters,
        setSnackBar,
        application_id,
        selectedServiceEnv,
        wideget_deployment_name,
        masterLabel,
        component_env_id,
        component_id,
        sub_env_id,
        showOnlyConfigured,
        actions,
        fetchLastTriggeredData,
        templateName,
        service_id,
        env_id,
    } = props;
    const [state, setState] = useState({
        configuredJobs: [],
        selectedJobData: null,
        historyView: false,
    })
    const classes = useStyles();

    useEffect(() => {

        if (allJobs && configuredJobs) {
            console.log('inside_ndss_001', allJobs, configuredJobs)
            let configuredJobsList = allJobs?.filter(job => {
                if (configuredJobs[job?.job_code]) {
                    return job
                }
            })

            setState(prevState => ({
                ...prevState,
                configuredJobs: configuredJobsList
            }))

        }
    }, [allJobs, configuredJobs])

    console.log('st_upliom', configuredJobs)


    return (
        <div className={classes.root}>
            <div className="d-flex align-center space-between">
                <div className="mb-12 ml-2">
                    <span className="template mr-5">Job Template:</span>
                    <span className="value-name">{templateName}</span>
                </div>
                {
                    state?.configuredJobs?.length > 0 &&
                    <div onClick={() => setState(prevState => ({
                        ...prevState,
                        historyView: true
                    }))} style={{ cursor: "pointer" }}>
                        <span style={{ color: "#0086FF" }} className="ri-history-line"></span>
                        <span className="font-12 font-weight-500 history-label">View History</span>
                    </div>
                }

            </div>
            <div className='container'>
                {
                    state?.configuredJobs?.length > 0 ? state.configuredJobs?.map((job, index) => {
                        let job_code = job.job_code
                        let metaInfo = { configured: configuredJobs && configuredJobs[job_code] }
                        let isLastCard = allJobs?.length - 1 == index

                        return (
                            <>
                                {
                                    <JobCardServiceCard
                                        isLastCardastCard={isLastCard}
                                        service_data={service_data}
                                        filters={filters}
                                        wholeJob={job}
                                        index={index}
                                        fetchLastTriggeredData={fetchLastTriggeredData}
                                        setSnackBar={setSnackBar}
                                        application_id={application_id}
                                        selectedServiceEnv={selectedServiceEnv}
                                        wideget_deployment_name={wideget_deployment_name}
                                        masterLabel={masterLabel}
                                        configuredJobs={configuredJobs}
                                        component_env_id={component_env_id}
                                        component_id={component_id}
                                        sub_env_id={sub_env_id}
                                        showOnlyConfigured={showOnlyConfigured}
                                        service_id={service_id}
                                        actions={actions}
                                        env_id={env_id}
                                        data={{ ...job, templateName: templateName, ...metaInfo }} />
                                }

                            </>
                        )
                    }) :

                        <div className={classes.configureInfoRoot}>
                            <div className="heading">
                                <span className='ri-tools-line font-20 d-flex align-center color-key-78'></span>
                                <div className='font-12 font-weight-600 color-icon-secondary avtar'>{`No configured jobs`}</div>
                            </div>
                            <div className={classes.tiggerCase}>
                                <div className="real-card">
                                    <Link className="text-anchor-blue d-block text-center" to={"/application/" + application_id + "/service/" + service_id + "/detail?build_details=true&env_id=" + env_id}>
                                        <button className="btn-primary conf-btn">
                                            <span className="ri-settings-4-line font-weight-500 font-20"></span>
                                            <span className='avtar font-family-v1'>Configure</span>
                                        </button>
                                    </Link>


                                </div>
                            </div>
                        </div>
                }
            </div>
            {
                state.historyView && <JobTemplateTriggerHistory
                    job_name={'Build'}
                    service_data={service_data}
                    configuredJobs={configuredJobs}
                    component_id={component_id}
                    handleClose={() => {
                        setState(prevState => ({
                            ...prevState,
                            historyView: false
                        }))
                    }
                    }
                    sub_env_id={sub_env_id}
                    env_id={env_id}
                    job_code={'build_job'}
                    allJobs={allJobs}
                    open={state.historyView} />
            }
        </div>
    )
}

export default JobTemplateListingV3ServiceCard;
const useStyles = makeStyles({
    root: {
        padding: '12px',
        marginBottom: '5px',
        '& .container': {
            display: 'flex',
            width: "100%",
            overflow: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '0',
            },
            '&::-webkit-scrollbar-thumb': {
                display: 'none',
            },
            '&::-webkit-scrollbar-track': {
                display: 'none', /* For hiding scrollbar track */
            },
        },
        '& .template': {
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '14.63px',
            textAlign: 'left',
            color: '#787878',
        },
        '& .value-name': {
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '14.63px',
            textAlign: 'left',
            color: '#2F2F2F',
        },
        '& .history-label': {
            lineHeight: '14.63px',
            color: '#0086FF'

        }
    },
    configureInfoRoot: {

        marginRight: '12px',
        width: '100%',
        height: '196px',
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "6px",
        border: "1px solid #E6E6E6",
        '& .heading': {
            display: "flex",
            width: "100%",
            height: "48px",
            padding: "12px 16px",
            alignItems: "center",
            gap: "4px",
        },
    },
    tiggerCase: {
        display: "flex",
        padding: "8px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        alignSelf: "stretch",
        height: "100%",
        paddingTop: "0px",
        '& .real-card': {
            display: "flex",
            padding: "0px 16px",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            borderRadius: "6px",
            border: "1px solid #F4F4F4",
            height: "100%",
            //background: "#FAFAFA",
            paddingTop: "10px",
            justifyContent: "center",
            '& .conf-btn': {
                display: "flex",
                height: "32px",
                padding: "11px 16px 11px 12px",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                borderRadius: "6px",
                border: "1px solid #F4F4F4",
                background: "#0086FF",
                textTransform: "uppercase",
            }
        }
    }
})
