import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import CredentialsDialogue from './CredentialsDialogue';


const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '380px'
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: 1.2,
    fontFamily: 'Inter',
    marginBottom: '30px'
  },
  btnSection: {
    padding: '10px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: '1px solid #e0e0e0',
    '& button': {
      marginLeft: '4px',
      width: '80px'
    }
  },
  btn:{
    border:'none',
    backgroundColor:'transparent'
  }
}));

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  
  const [open, setOpen] = React.useState(false);

  
  const handleClickOpen = () => {
    setOpen(true);
  };

  
  const handleClose = () => {
  //  if(props.viewCred){}
  props.refresh()
  //
  console.log("props",props) 
  setOpen(false);
  };
  
  console.log("open",open)
  return (
    <React.Fragment value={true}>
        <button className="btn btn-outline-primary ml-auto mr-0"  onClick={handleClickOpen}>
          
            {props.isSonar?<>Add Token</>:<>Add Credential</>}
        </button> 
     <CredentialsDialogue isVault={props.isVault} isSonar={props.isSonar} handleClose={handleClose} open={open} isEdit ={false} isClusterBackup={props.isClusterBackup} isSSH={props.isSSH} />
    </React.Fragment>
  );
}
MaxWidthDialog.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}
