import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import CommonHorizontalTab from '../../../../../../components/genericComponents/CommonHorizontalTab';

import BorderColorIcon from '@material-ui/icons/BorderColor';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import { GetAuth } from '../../../../../../util/security';
import properties from '../../../../../../properties/properties';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
import InvokeApi from '../../../../../../util/apiInvoker';

const ServiceHooksOverview = (props) => {

    const extraProps = props.extraProps;
    const application_id = extraProps.application_id;
    const service_id = extraProps.service_id;
    const component_env_id = extraProps.component_env_id;
    const auto_approval_flag = extraProps.auto_approval_flag;
    const prev_state = props.prev_state;
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const [state, setState] = useState({
        selectedTabOrderTwo: 1
    });
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_cd, true);
    const is_permitted = GetAuth().permission.POST.includes(url);

    useEffect(() => {
        loadValueVisibilityStatus()
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state])
    console.log(prev_state,"___11111")

    const handleToggle = () => {
        setState({
            ...state,
            showValue: !state.showValue,
        })
    }
    const tabListCICD = [
        {
            order: 1,
            tabName: 'Pre Hooks'
        },
        {
            order: 2,
            tabName: 'Post Hooks'
        },
    ]
    const updateSelectedTabTwo = (selectedTabOrderTwo) => {
        setState({
            ...state,
            selectedTabOrderTwo: selectedTabOrderTwo
        });

    }

    function loadValueVisibilityStatus() {
        console.log(properties.api, 'p_1_viii')
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.getCommandHookVisibility),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        console.log('req_0001', requestInfo);
        InvokeApi(requestInfo, onSuccess, onFailure);

    }

    function onSuccess(response) {

        if (response) {
            let data = response['detail']
            setState(new_state => ({
                ...new_state,
                showValue: !data['hook_command_hidden'],
            }));
        }

    }

    function onFailure(error) {
        setState(new_state => ({
            ...new_state,
            error_msg: "Error fetching data of this template " + " " + error
        }));
    }
    return (
        <Grid item lg={6}>
            <div className="section-service-overview">
                <div className="overview-single-card kv-border">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Hook Variables
                        </p>

                        {is_permitted ?

                            state.status == "DRAFT" ?
                                <Link to={{
                                    pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id +  "/cd/"+cd_id+"/edit",
                                    query: { selectedTabOrder: 10, selected_cd_id : extraProps?.selected_cd_data?.id }
                                }}>
                                    <BorderColorIcon style={{ color: '#0086ff' }} />
                                </Link> 
                                : 
                                <EditDetailsConfirmationDialog
                                    application_id={application_id}
                                    service_id={service_id}
                                    auto_approval_flag={auto_approval_flag}
                                    component_env_id={component_env_id}
                                    selectedTabOrder={10}
                                    cd_data={prev_state}
                                    selected_cd_id = {extraProps?.selected_cd_data?.id}
                                />

                            : <Tooltip title="You are not allowed to perform this action" >
                                <BorderColorIcon style={{ color: '#818078' }} />
                            </Tooltip>}
                    </div>

                    <div className="overview-card-value-env-var">
                        <div className="heading-key-value d-flex space-between align-center">
                            <CommonHorizontalTab
                                tabList={tabListCICD}
                                selectedTabOrder={state.selectedTabOrderTwo}
                                updateSelectedTab={updateSelectedTabTwo} />

                            {/* <>
                                {state.env_cd_hook ?
                                    <span className="ml-auto d-inline-block" onClick={handleToggle}>{state.showValue ?
                                        <VisibilityIcon style={{ color: '#ccc', fontSize: '20px' }} /> :
                                        <VisibilityOffIcon style={{ color: '#ccc', fontSize: '20px' }} />}
                                    </span>
                                    : null}
                            </> */}
                        </div>
                        {state.selectedTabOrderTwo == 1 ?
                            <div className="env-variable-box">
                                {state.env_cd_hook ?
                                    <>
                                        {state.env_cd_hook.map(prehook => (
                                            prehook.action_type == "PRE" ?
                                                <>
                                                    <>
                                                        {prehook.command ? 
                                                            <p className="data-key-value-pair overview-card-value-text">
                                                                <span>Step: {prehook.order} </span>Command:&nbsp;<span className="overview-card-value-text"><Tooltip placement="bottom" title={!state.showValue ? prehook.command : ""}><span className={!state.showValue ? "text-security" : null}>{prehook.command}</span></Tooltip></span>
                                                            </p>
                                                        
                                                            : null}
                                                    </>
                                                    <>
                                                        {prehook.manifest_meta_data ?
                                                            <>
                                                                {prehook.manifest_meta_data.strategy == "GIT_MANIFEST" ?
                                                                    

                                                                        <p className="data-key-value-pair overview-card-value-text"><span>Step: {prehook.order} </span>Via Git:&nbsp;<span className="overview-card-value-text">{prehook.manifest_meta_data.manifest_file_paths}</span></p>
                                                                    
                                                                    : null}
                                                            </>
                                                            : null}
                                                    </>
                                                    <>
                                                        {prehook.manifest_meta_data ?
                                                            <>

                                                                {prehook.manifest_meta_data.strategy == "UPLOADED_MANIFEST" ?
                                                                    
                                                                        <p className="data-key-value-pair overview-card-value-text">
                                                                            <span>Step: {prehook.order} </span>Via File Upload:&nbsp;<span className="overview-card-value-text">{prehook.manifest_meta_data.manifest_file_paths}</span></p>
                                                                    
                                                                    : null}
                                                            </>
                                                            : null}
                                                    </>
                                                </>
                                                : null
                                        ))}
                                    </> : <p className="no-data__msg-env-var">No Data Available</p>}
                            </div> :
                            state.selectedTabOrderTwo == 2 ?
                                <div className="env-variable-box">
                                    {state.env_cd_hook ?
                                        <>
                                            {state.env_cd_hook.map(posthook => (
                                                posthook.action_type == "POST" ?
                                                    <>
                                                        <>
                                                            {posthook.command ?
                                                                
                                                                    <p className="data-key-value-pair overview-card-value-text">
                                                                        <span>Step: {posthook.order} </span>Command:&nbsp;<span className="font-12"><Tooltip placement="bottom" title={state.showValue ? posthook.command : ""}><span className={!state.showValue ? "text-security" : null}>{posthook.command}</span></Tooltip></span>
                                                                        {/* <span className="ml-auto d-inline-block" onClick={handleToggle}>{state.showValue ?
                                                                            <VisibilityIcon style={{ color: '#ccc', fontSize: '20px' }} /> :
                                                                            <VisibilityOffIcon style={{ color: '#ccc', fontSize: '20px' }} />
                                                                        }
                                                                        </span> */}
                                                                    </p>
                                                                
                                                                : null}
                                                        </>
                                                        <>
                                                            {posthook.manifest_meta_data ?
                                                                <>
                                                                    {posthook.manifest_meta_data.strategy == "GIT_MANIFEST" ?
                                                                        

                                                                            <p className="data-key-value-pair overview-card-value-text"><span>Step: {posthook.order} </span>Via Git:&nbsp;<span className="overview-card-value-text">{posthook.manifest_meta_data.manifest_file_paths}</span></p>
                                                                        
                                                                        : null}
                                                                </>
                                                                : null}
                                                        </>
                                                        <>
                                                            {posthook.manifest_meta_data ?
                                                                <>
                                                                    {posthook.manifest_meta_data.strategy == "UPLOADED_MANIFEST" ?
                                                                        

                                                                            <p className="data-key-value-pair overview-card-value-text">
                                                                                <span>Step: {posthook.order} </span>Via File Upload:&nbsp;<span className="overview-card-value-text">{posthook.manifest_meta_data.manifest_file_paths}</span>
                                                                            </p>
                                                                        
                                                                        : null}
                                                                </>
                                                                : null}
                                                        </>
                                                    </>
                                                    : null
                                            ))}
                                        </> : <p className="no-data__msg-env-var">No Data Available</p>}
                                </div> : null}
                    </div>
                </div>
            </div>
        </Grid>)
}

ServiceHooksOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default ServiceHooksOverview;