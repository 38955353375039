import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CommonHorizontalTab from '../../../../components/genericComponents/CommonHorizontalTab';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import properties from '../../../../properties/properties';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { Loading } from '../../../utils/Loading';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import MonitoringDialogue from '../../../service/detail/components/MonitoringDialogue';
import { ErrorComponent } from '../../../utils/Error';
import { Input } from '../../../../components/genericComponents/Input';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';

const CanaryAnalysisReport = (props) => {
    const classes = useStyles();
    const chartRef = useRef(null);
    const location = useLocation();
    const data = props?.location.state?.canary_analysis_data;
    const complete_data = props?.location?.state?.complete_data;
    const deploy_name = props?.location?.state?.deploy_name;
    const application_id = props?.location?.state?.application_id;
    const pipeline_id = props?.location?.state?.pipeline_id;
    const pipeline_instance_id = props?.location.state.pipeline_instance_id;
    const canary_dep_name = props.location.state.canary_dep_name;
    const component_env_id = props.location.state.component_env_id;
    const component_id = props.location.state.component_id;
    const metric_data_name_arr = props.location.state.metric_data_name_arr
    const inherits = props.inherits ? props.inherits : {};
    const [state, setState] = useState({
        selectedTabOrder: 0,
        canaryData: {},
        counter: 0,
        data: {},
        error: {}
    })

    const commonFunctions = getCommonFunctions(state, setState, inherits);

    console.log(deploy_name, "sdfhsdbchjsdcsdcdhb")

    useEffect(() => {
        fetchCanaryMetricData()
    }, [state.counter, state.data.deployment_name])

    useEffect(() => {
        let deployment_list = []
        metric_data_name_arr && metric_data_name_arr.forEach((item) => {
            let obj = {
                id: item,
                label: item
            }

            deployment_list.push(obj);
        })

        setState((new_state) => ({
            ...new_state,
            deployment_list: deployment_list,
        }))
        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.chart.reflow();
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial reflow on mount
        if (chartRef.current) {
            chartRef.current.chart.reflow();
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        getCurrentMetricData();
        // setState((new_state) => ({
        //     ...new_state,
        //     current_metric_data: getCurrentMetricData()
        // }))
    }, [state.canaryData, state.selectedTabOrder])

    function fetchCanaryMetricData() {
        let myendpoint = GenerateURL({ task_id: complete_data.id, service_deployment_key: state.data.deployment_name ? state.data.deployment_name : deploy_name }, properties.api.canary_metric_data);

        var requestInfo = {
            endPoint: myendpoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setState(prevState => ({
            ...prevState,
            loading: true
        }))

        InvokeApi(requestInfo, chartDataFetchSuccess, chartDataFetchFailure);
    }

    function chartDataFetchSuccess(response) {
        console.log(response, "sbvhbfvhbfdhvbfd")
        setState((new_state) => ({
            ...new_state,
            canaryData: response,
            metric_tab_list: getMetricTabs(response),
            metrices_count: Object.keys(response).length,
            // loading: false,
        }))
        fetchMetaData()

    }

    function chartDataFetchFailure(error) {
        setState((new_state) => ({
            ...new_state,
            error: error,
            loading: false,
        }))
    }

    function fetchMetaData() {
        let myendpoint = GenerateURL({ task_id: complete_data.id, service_deployment_key: state.data.deployment_name ? state.data.deployment_name : deploy_name }, properties.api.canary_meta_data);

        var requestInfo = {
            endPoint: myendpoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, metaDataFetchSuccess, metaDataFetchFailure);

        setState(prevState => ({
            ...prevState,
            loading: true
        }))
    }

    function metaDataFetchSuccess(response) {
        console.log(response, "sbvhbfvhbfdhvbfdbdshbvhg")
        setState((new_state) => ({
            ...new_state,
            canary_meta_data: response,
            loading: false,
        }))
    }

    function metaDataFetchFailure(error) {
        setState((new_state) => ({
            ...new_state,
            error: error,
            loading: false,
        }))
    }

    function getMetricTabs(response) {
        let data = response
        let metrics = Object.keys(data)

        let tabs = []
        metrics.forEach((element, index) => {
            let obj = {
                tabName: element,
                order: index,
                status: data[element].status
            }

            tabs.push(obj)
        })

        return tabs;
    }

    function getCurrentMetricData() {
        let selectedTab = state.metric_tab_list && state.metric_tab_list.find((item) => item.order == state.selectedTabOrder)
        let selectedTabName = selectedTab?.tabName
        let metrices = Object.keys(state.canaryData)
        console.log(state.canaryData, selectedTabName, metrices, "sdnfsdhbjhdsbhjcbsdh")
        let selected_metric = metrices.find((item) => item.includes(selectedTabName))
        let selected_metric_data = state.canaryData[selected_metric]

        setState((new_state) => ({
            ...new_state,
            selected_metric_data: selected_metric_data,
            metric_graph_data: getMetricGraphData(selected_metric_data),
            start_time: getAnalysisStartTime(selected_metric_data),
            end_time: getAnalysisEndTime(selected_metric_data)
        }))
    }

    function getAnalysisStartTime(data) {
        console.log(data, "sbvjsdvbhss")
        let time = data && data.metric_data && data.metric_data[0] && data.metric_data[0].date
        let formatted_time = getDateWithTime(time)
        return formatted_time;
    }

    function getAnalysisEndTime(data) {
        let time = data && data.metric_data && data.metric_data[data.metric_data.length - 1] && data.metric_data[data.metric_data.length - 1].date
        let formatted_time = getDateWithTime(time)
        return formatted_time;
    }

    function getEquallySpacedData(data, numItems) {
        const totalItems = data.length;

        if (totalItems <= numItems) {
            return data;  // If there are fewer or equal items than requested, return all data
        }

        // Include the first and last objects
        const result = [data[0]];

        // Calculate the spacing between items
        const interval = (totalItems - 2) / (numItems - 2); // -2 to exclude the first and last objects

        for (let i = 1; i < numItems - 1; i++) {
            const index = Math.round(interval * i);
            result.push(data[index]);
        }

        // Add the last object
        result.push(data[totalItems - 1]);

        return result;
    }

    function getMetricGraphData(selected_metric_data) {
        let data = selected_metric_data?.metric_data
        let new_data;
        if (data) {
            new_data = getEquallySpacedData(data, 20);
        }
        console.log(data, "sgvhjhjbfkhvbhfd")
        let canary_data = []
        let baseline_data = []
        let baseline_threshold_data = []
        let baseline_critical_threshold_data = []
        let categories = []

        let updated_data;

        if (new_data) {
            new_data && new_data.forEach((item) => {
                canary_data.push(Number(item.canary_metric_value))
                baseline_data.push(Number(item.baseline_metric_value))
                baseline_threshold_data.push(Number(item.baseline_threshold))
                baseline_critical_threshold_data.push(Number(item.baseline_critical_threshold))
                categories.push(getTime(item.date))
            })

            updated_data = {
                chart: {
                    type: 'spline',

                },
                credits: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis: {
                    ...data.xAxis,
                    categories: [...categories]
                },
                series: [{
                    name: 'Canary',
                    color: "#0086FF80",
                    data: [...canary_data]
                }, {
                    name: 'Baseline',
                    data: [...baseline_data],
                    color: "#2EBE79",
                }, {
                    name: 'Baseline Threshold',
                    color: "#FEA11180",
                    data: [...baseline_threshold_data],
                    // dashStyle: 'longdash'
                }, {
                    name: 'Baseline Critical Threshold',
                    color: "#E53737",
                    data: [...baseline_critical_threshold_data],
                    // dashStyle: 'longdash'
                }],
            }

        }

        console.log(canary_data, baseline_data, categories, "sgvhjhjbfkhvbhfd")

        return updated_data;

    }

    function getTime(timestamp) {
        console.log(timestamp, "sbvfbvjhfhjvfvfhdh");

        // Create a Date object from the ISO string
        const date = new Date(timestamp);

        // Get the components of the time in UTC
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');

        // Format the time as "HH:MM:SS"
        return `${hours}:${minutes}:${seconds}`;
    }

    function getDateWithTime(timestamp) {
        console.log(timestamp, "sbvfbvjhfhjvfvfhdh")
        // Create a Date object from the ISO string
        const date = new Date(timestamp);

        // Get the components of the date in UTC
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');

        // Format the date as "YYYY-MM-DD HH:MM"
        return `${year}-${month}-${day} ${hours}:${minutes}`
    }

    console.log(state, "fsgvjfdhvhfdhvf")

    const updateStateHandler = (tab_order) => {
        setState(new_state => ({
            ...new_state,
            selectedTabOrder: tab_order,
        }));
    }

    function handleDataRefresh() {
        setState((new_state) => ({
            ...new_state,
            counter: new_state.counter + 1
        }))
    }

    console.log(state, "sdhvbsdhbvhjsbvf")

    return (
        <div className={classes.root}>
            {
                <Link to={
                    {
                        pathname: "/application/" + application_id + "/pipeline/" + pipeline_id + "/execution/" + pipeline_instance_id,
                    }
                }
                >
                    <div className='back-button'>
                        <span className='ri-arrow-left-line' style={{ color: "#0086FF" }}></span>
                        <p style={{ marginLeft: "4px", fontFamily: "Montserrat", fontSize: "12px", fontWeight: "500" }}>GO BACK</p>
                    </div>
                </Link>

            }
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className='font-16 font-weight-500' style={{ color: '#2f2f2f' }}>
                        {canary_dep_name} <span className='metric-chip'>{state.metrices_count} METRICS</span>
                    </div>
                    {/* <div style={{marginLeft:"10px"}}>
                        <MonitoringDialogue
                            service_id={component_id}
                            env_id={component_env_id}
                            application_id={application_id}
                            // env_name={selected_service_env.project_env.name} 
                            // service_data={{ name: service_name, type: properties.envs[selected_service_env.project_env ? selected_service_env.project_env.environment_master_id - 1 : 0].label }} 
                        />
                    </div> */}
                </div>
                <div className='ml-auto' style={{ display: "flex", alignItems: "center" }}>
                    <div className='d-grid align-center mb-0 input-mb-0' style={{ width: '400px', marginLeft: 10, gridTemplateColumns: '115px 1fr' }}>
                        <div className="font-11">Select Deployment: </div>
                        <Input
                            type="select-for-multideployment"
                            name="deployment_name"
                            list={state.deployment_list}
                            onChangeHandler={commonFunctions.onChangeHandler}
                            label=""
                            remove_default_value={true}
                            data={state.data}
                            error={state.error}
                        />
                    </div>
                    <Tooltip title="Refresh data">
                        <span className='ri-refresh-line' style={{ fontSize: "24px", cursor: "pointer", marginLeft: "10px" }} onClick={handleDataRefresh}></span>
                    </Tooltip>
                    {/* <Link to={
                        {
                            pathname: "/application/" + application_id + "/pipeline/" + pipeline_id + "/execution/" + pipeline_instance_id,
                        }
                    }
                    >
                        <button className='btn btn-primary' style={{ marginLeft: "15px" }}>Back</button>
                    </Link> */}
                </div>
            </div>
            {
                <div className="canary_analysis_card" style={{ marginTop: "20px" }}>
                    {
                        state.loading ?
                            <Loading />
                            :
                            Object.keys(state.canaryData).length > 0 ?
                                <>
                                    <div className='canary_analysis_card_header'>
                                        <div className='d-flex align-center font-12 space-between ' style={{ width: '100%' }}>
                                            <p style={{ color: '#505050' }}>METRICS</p>
                                            <p style={{ color: '#505050' }}>RESULTS</p>
                                        </div>
                                        <p className='ml-auto font-12'>
                                            <span className='key' style={{ color: '#787878' }}>Start Time: </span>
                                            <span className='value' style={{ color: '#2f2f2f' }}>{state.start_time}</span>&nbsp;&nbsp;
                                            {/* {
                                            state.selected_metric_data && state.selected_metric_data.status == "FAILED" || state.selectedTabName.status == "SUCCESS" &&
                                            <>
                                                <span className='key' style={{ color: '#787878' }}>End Time: </span>
                                                <span className='value' style={{ color: '#2f2f2f' }}>{state.end_time}</span>
                                            </>
                                        } */}
                                        </p>
                                    </div>
                                    <div className='canary_analysis_card_body' style={{ width: '100%' }}>
                                        <div className='canary_analysis_card_body_particals'>
                                            <CommonHorizontalTab
                                                tabList={state.metric_tab_list ? state.metric_tab_list : []}
                                                variant="canary_analysis_report_tab"
                                                selectedTabOrder={state.selectedTabOrder}
                                                updateSelectedTab={updateStateHandler}
                                            />
                                        </div>
                                        <div style={{ backgroundColor: "#FAFAFA" }}>
                                            <div className='header-section mb-20' style={{ backgroundColor: "#FAFAFA", marginLeft: "20px" }}>
                                                <div style={{ borderRight: '1px solid #E6E6E6', marginRight: '20px' }}>
                                                    <p className='text-anchor-blue font-12 font-weight-600'>BASELINE</p>
                                                    <div className='font-12 d-flex align-center' >
                                                        <span className='key' style={{ color: '#787878' }}>artifact: </span>
                                                        <Tooltip title={state.canary_meta_data?.baseline_deployment_metadata?.deployment_artifact}>
                                                            <span className='value text-ellipsis-150' style={{ color: '#2f2f2f', display: "inline-block" }}>{state.canary_meta_data?.baseline_deployment_metadata?.deployment_artifact}</span>
                                                        </Tooltip>

                                                    </div>
                                                </div>
                                                <div style={{ borderRight: '1px solid #E6E6E6', marginRight: '20px' }}>
                                                    <p className='text-anchor-blue font-12 font-weight-600'>CANARY</p>
                                                    <div className='font-12 d-flex align-center' >
                                                        <span className='key' style={{ color: '#787878' }}>artifact: </span>
                                                        <Tooltip title={state.canary_meta_data?.canary_deployment_metadata?.deployment_artifact}>
                                                            <span style={{ color: '#2f2f2f', display: "inline-block" }} className='value text-ellipsis-150'>{state.canary_meta_data?.canary_deployment_metadata?.deployment_artifact}</span>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                {/* <div>
                                        <p className='text-anchor-blue font-12 font-weight-600'>THRESHOLD</p>
                                        <div className='font-12' >
                                            <span className='key' style={{ color: '#787878' }}>Marginal: </span>
                                            <span className='value' style={{ color: '#2f2f2f' }}>75</span>
                                            &nbsp;&nbsp;
                                            <span className='key' style={{ color: '#787878' }}>Pass: </span>
                                            <span className='value' style={{ color: '#2f2f2f' }}>95</span>
                                        </div>
                                    </div> */}
                                            </div>
                                            <div style={{ marginLeft: "20px" }}>
                                                <HighchartsReact highcharts={Highcharts} options={state.metric_graph_data ? state.metric_graph_data : {}} /* ref={chartRef} */ />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                // <ErrorComponent variant="message-box" error="" />
                                <div className='no-data-view'>
                                    <div className='logo-div'>
                                        <span className='ri-database-2-fill' style={{color:"#CACACA",height:"45.13px",width:"42.75px"}}></span>
                                    </div>
                                    <p>Please Wait...</p>
                                    <p>The analysis has just started. Data not generated yet.</p>
                                </div>
                    }
                </div>

            }
        </div>
    )
}

//state.metric_graph_data ? state.metric_graph_data : { }

export default CanaryAnalysisReport

const useStyles = makeStyles(() => ({
    root: {
        padding: 20,

        "& .back-button": {
            display: "flex",
            alignItems: "center",
            color: "#0086FF",
        },
        '& .metric-chip': {
            backgroundColor: '#0086ff',
            color: '#fff',
            borderRadius: '4px',
            padding: '4px 6px',
            fontSize: 12,
            fontWeight: 500,
        },
        '& .header-section': {
            backgroundColor: '#fff',
            padding: '20px',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            borderRadius: 4
        },
        '& .canary_analysis_card': {
            minHeight: "400px",
            borderRadius: '4px',
            background: '#FFF',
            boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.08)",

            "& .no-data-view":{
                border : "1px solid #E6E6E6",
                borderRadius:"6px",
                width:"100%",
                height : "100%",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",

                "& .logo-div":{
                    width:"109px",
                    height:"109px",
                    borderRadius:"50%",
                    backgroundColor:"#F4F4F4",
                    display:"flex",
                    alignContent:"center",
                    justifyContent:"space-between",
                }

            }
        },
        '& .canary_analysis_card_header': {
            borderBottom: '1px solid #E5E5E5',
            backgroundColor: '#F5FAFF',
            padding: '12px',
            display: 'grid',
            gridTemplateColumns: '200px 1fr'
        },
        '& .canary_analysis_card_body': {
            display: 'grid',
            gridTemplateColumns: '235px 1fr'
        },
        '& .canary_analysis_card_body_particals': {
            height: '100%',
            overflowY: 'auto',
            borderRight: "1px solid #E5E5E5"
        },
        '& .success_chip_particals': {
            fontSize: '12px',
            fontWeight: '600',
            color: '#129E5B',
            backgroundColor: '#E6FBEA',
            borderRadius: '4px',
            padding: '2px 4px'
        },
        '& .failed_chip_particals': {
            fontSize: '12px',
            fontWeight: '600',
            color: '#C11212',
            backgroundColor: '#FFEBEB',
            borderRadius: '4px',
            padding: '2px 4px'
        },
        '& .canary_analysis_card_body_particals_row': {
            cursor: 'pointer',
            '&.active': {
                backgroundColor: '#DFEDFF',
                '&:hover': {
                    backgroundColor: '#DFEDFF'
                }
            },
            '&:hover': {
                backgroundColor: '#fafafa'
            }
        },
        "& .no-data-info": {
            // minHeight: "200px",
            // minWidth: "400px",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            // border: "1px solid #fafafa",
            // borderRadius: "6px",
            fontFamily: "Monsterrat",
            fontSize: '16px',
            fontWeight: '500',
            color: "rgb(47, 47, 47)"
        }
    }
}));