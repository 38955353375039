import React, { useState, useEffect } from 'react'
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails'
import { Input } from '../../../../components/genericComponents/Input';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import MultipleEnteriesInput, { getMultipleEnteries } from '../../../../components/genericComponents/MultipleEnteries/MultipleEnteriesInput';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi from '../../../../util/apiInvoker';
import { getListableArray } from '../../../../util/util';
import { Loading } from '../../../utils/Loading';
import { makeStyles } from "@material-ui/core/styles";
import { VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
const CanaryAnalysis = (props) => {
    const prev_state = props.prev_state ? props.prev_state : null;
    const env_list = props.env_list ? props.env_list : null;
    const inherits = props.inherits ? props.inherits : {};
    console.log(props, "sbsbvbvfvbdfhvbjdfd")
    const addQueriesList = props.addQueriesList ? props.addQueriesList : () => { }
    const [state, setState] = useState({
        data: {
            open: true,
            pass_on_critical_failure: false,
        },
        error: {},
        table_list: [],
        validations: {
            canary_analysis_template: [VALIDATION_TYPE_REQUIRED],
            warmup_delay_duration: [VALIDATION_TYPE_REQUIRED],
            components: [VALIDATION_TYPE_REQUIRED],
            // env: [VALIDATION_TYPE_REQUIRED],
            analysis_interval: [VALIDATION_TYPE_REQUIRED],
        },
        // multiple_enteries_child: null,
        // child_inherits: {
        //     multiple_enteries_child: {
        //         "validateForm": () => { return true },
        //         "getState": () => { return {} },
        //         "getData": () => { return {} }
        //     }
        // }
    })

    const commonFunctions = getCommonFunctions(state, setState, inherits);

    useEffect(() => {
        fetchCanaryTemplate()
    }, [])

    useEffect(() => {
        if (prev_state && prev_state.project_env_id) {
            fetchServicesForSelectedProject(prev_state.project_env_id)
            setState((new_state) => ({
                ...new_state,
                data: {
                    ...new_state.data,
                    ...prev_state
                }
            }))
        }
    }, [prev_state])

    console.log(state, "sdbvhjbsdvjhbfs")

    function fetchCanaryTemplate() {
        let myendpoint = GenerateURL({}, properties.api.canary_template_list);

        var requestInfo = {
            endPoint: myendpoint,
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }

        setState(prevState => ({
            ...prevState,
            loading: true
        }))

        InvokeApi(requestInfo, fetchCanaryTemplateSuccess, fetchCanaryTemplateFailed);
    }

    function fetchCanaryTemplateSuccess(response) {
        setState((new_state) => ({
            ...new_state,
            canary_template_data: response.results,
            canary_option_list: getListableArray(response.results)
        }))
    }

    function fetchCanaryTemplateFailed(error) {

    }

    const input_list = [
        {
            type: "text",
            name: "key",
            label: "Enter Key",
            placeholder: "Key",
        },
        {
            type: "text",
            name: "value",
            label: "Enter Value",
            placeholder: "Value",
        }
    ]
    const columnNames = [
        {
            label: 'Key',
            width: '50%',
            grey: false,
            name: 'key'
        },
        {
            label: 'Value',
            width: '50%',
            grey: true,
            name: 'value'
        }
    ]

    const saveDataToTable = () => {
        var children_validation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var multiple_enteries_child_data = child_states.multiple_enteries_child;
        if (children_validation_result.valid) {
            var final_arr = multiple_enteries_child_data.input_enteries_list;
            if (final_arr.length > 0) {
                let found_duplicate = final_arr.find(item => item.key == multiple_enteries_child_data.data.key)
                if (!found_duplicate) {
                    final_arr.push({
                        key: multiple_enteries_child_data.data.key,
                        value: multiple_enteries_child_data.data.value
                    })
                }
            } else {
                final_arr.push({
                    key: multiple_enteries_child_data.data.key,
                    value: multiple_enteries_child_data.data.value
                })
            }
            addQueriesList(final_arr);
            setState(new_state => ({
                ...new_state,
                multiple_enteries_child: {
                    ...getMultipleEnteries(),
                    data: {
                        key: "",
                        value: "",
                        open: true
                    },
                    open_div_form: false,
                    open_grid_form: false,
                    error: {

                    },
                    input_enteries_list: [
                        ...final_arr,
                    ]
                }
            }))

        } else {
            console.log(children_validation_result, "children_validation_result")
        }
    }
    const updateStateObj = (keyValue) => {
        console.log("Checking data__", keyValue)
        var children_validation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var multiple_enteries_child_data = child_states.multiple_enteries_child;
        if (children_validation_result.valid) {
            var final_arr = multiple_enteries_child_data.input_enteries_list;
            let updatedList = final_arr.map(item => {
                if (item.key == keyValue.value) {
                    return {
                        ...item, key: multiple_enteries_child_data.data.key,
                        value: multiple_enteries_child_data.data.value
                    };
                } else {
                    return item;
                }
            }
            )
            addQueriesList(updatedList);
            console.log(updatedList, "Checking data__", final_arr)
            setState(new_state => ({
                ...new_state,
                multiple_enteries_child: {
                    ...getMultipleEnteries(),
                    update_state_fn: false,
                    data: {
                        key: "",
                        value: "",
                        open: true
                    },
                    open_div_form: false,
                    open_grid_form: false,
                    error: {

                    },
                    input_enteries_list: [
                        ...updatedList,
                    ]
                }
            }))
        }
    }
    const clearAndSaveForm = () => {
        setState(new_state => ({
            ...new_state,
            update_state_fn: false,
            multiple_enteries_child: {
                ...getMultipleEnteries(),
                open_div_form: false,
                update_state_fn: false,
                data: {
                    key: "",
                    value: ""
                },
                error: {

                },
            }
        }))
    }

    const clearAndCloseForm = () => {
        setState(new_state => ({
            ...new_state,
            update_state_fn: false,
            multiple_enteries_child: {
                ...getMultipleEnteries(),
                open_div_form: false,
                data: {
                    key: "",
                    value: "",
                    open: true
                },
                error: {

                },
            }
        }))
    }

    const setDatatoEdit = (type, data) => {
        console.log(data, "fjsdajfja")
        setState(prevState => ({
            ...prevState,
            single_form_data: {
                key: data.key,
                value: data.value,
            },
            update_state_fn: true,
            multiple_enteries_child: {
                ...getMultipleEnteries(),
                ...prevState.multiple_enteries_child,
                open_div_form: true,
                update_state_fn: true,
                data: {
                    ...prevState.multiple_enteries_child.data,
                    key: data.key,
                    value: data.value,
                    open: true
                }
            }
        }))
    }

    function getSelectedEnv(id, data) {
        var selectedEnv = {};
        data.forEach(env => {
            if (env.id == id) {
                selectedEnv = { ...env }
            }
        })
        return selectedEnv;
    }

    function onEnvChangeHandler(e) {
        var key = e.target.name;
        var value = e.target.value;
        var selectedEnv;

        if (key == "env" && value != "") {
            // selectedEnv = getSelectedEnv(value, state.app_environment_list)
            fetchSingleEnvData(value);
            fetchServicesForSelectedProject(value);
        }
        setState((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [key]: value
            }
        }))
    }

    function fetchSingleEnvData(id) {
        var requestInfo = {
            endPoint: GenerateURL({ env_id: id }, properties.api.get_env),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, SingleEnvDataSuccess, SingleEnvDataSuccessFailed);
        setState(new_state => ({
            ...new_state,
            loading_data: true
        }));
    }

    function SingleEnvDataSuccess(response, type) {
        setState(new_state => ({
            ...new_state,
            current_env: response,
            cluster_id: response.cluster_id,
            namespace_name: response.project_env_namespace.namespace.name
        }));


    }
    function SingleEnvDataSuccessFailed(error, type) {
        var error_msg = error.toString()
        setState(new_state => ({
            ...new_state,
        }));

    }

    function fetchServicesForSelectedProject(project_env_id, type) {
        var requestInfo = {
            endPoint: GenerateURL({ project_env_id: project_env_id, job_type: "canary_analysis" }, properties.api.get_services_for_projectEnvV2),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        if (type == "promote") {
            requestInfo.endPoint = GenerateURL({ project_env_id: project_env_id, job_type: task_type_list.find((obj) => obj.id == state.data?.task_type)?.label.toLowerCase(), source_env: state.data.env }, properties.api.get_services_for_projectEnvV2_promote)
        }
        InvokeApi(requestInfo, fetchServicesSuccess, fetchServicesFailure);
        setState(new_state => ({
            ...new_state,
            service_loading: true,
        }));
    }

    function fetchServicesSuccess(response) {
        // fetchJobTemplatesList()
        console.log(response.results, "results__")
        if (response.results && response.results.length > 0) {
            setState(new_state => ({
                ...new_state,
                all_services: response.results,
                no_service_configured: false,
                service_loading: false
            }));
        } else {
            console.log(response.results, "results__")
            setState(new_state => ({
                ...new_state,
                all_services: [],
                no_service_configured: true,
                service_loading: false
            }));
        }

    }
    function fetchServicesFailure(error, exception) {
        setState(new_state => ({
            ...new_state,
            error: true
        }));
    }

    const classes = useStylesDialog();

    function onChangeHandlerForKeyValue(key, value) {
        console.log("sjdbsh", key, value);
        if (key == "components" || key == "pipelines" || key == "env_namespace_configmap" || key == "env_namespace_secret") {
            value.forEach((id, index) => {
                value[index] = Number(id);
            });
        }
        setState({
            ...state,
            data: {
                ...state.data,
                [key]: value
            },
            error: {
                ...state.error,
                [key]: null,
            }
        });

        console.log("jsbdhs", state);
    }

    function onSwitchChange(){
        setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                pass_on_critical_failure: !prevState.data.pass_on_critical_failure
            }
        }))
    }

    console.log(state, "ajdvcsdchjdsbvchjsdv")

    return (
        <>
            <Input
                type="select"
                name="canary_analysis_template"
                mandatorySign
                label="Canary Analysis Template"
                list={state.canary_option_list ? state.canary_option_list : []}
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error}
            />
            <Input
                type="select"
                mandatorySign
                name="env"
                list={getListableArray(env_list)}
                label="To Environment"
                onChangeHandler={onEnvChangeHandler}
                data={state.data}
                error={state.error}
            />
            {state.service_loading ?
                <Loading varient="light" /> :
                getListableArray(state.all_services).length > 0 &&
                <div className={classes.rooter}>
                    <Input
                        type="checkbox"
                        name="components"
                        label="Services"
                        mandatorySign
                        varient="inner_component"
                        data={state.data}
                        error={state.error}
                        list={getListableArray(state.all_services)}
                        onChangeHandler={state.all_services ? onChangeHandlerForKeyValue : () => { }}
                    />
                </div>

            }
            <div className='d-flex align-center' style={{ marginTop: "25px" }}>
                <span className='font-16 mr-12'>Do you want to continue execution of pipeline even after critical failure in canary analysis</span>
                <div className='remove-margin-from-input'>
                    <Input
                        type="only-switch"
                        label=""
                        name="pass_on_critical_failure"
                        data={state.data}
                        error={state.error}
                        onChangeHandler={onSwitchChange} />
                </div>
            </div>
            <div style={{ marginTop: "25px" }}>
                <Input
                    type="text"
                    name="warmup_delay_duration"
                    mandatorySign
                    label="Warmup Delay Duration(in minutes)"
                    onChangeHandler={commonFunctions.onChangeHandler}
                    data={state.data}
                    error={state.error}
                />
            </div>
            <Input
                type="text"
                name="analysis_interval"
                mandatorySign
                label="Analysis Interval(in minutes)"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error}
            />
            <Input
                type="text"
                name="canary_analysis_duration"
                mandatorySign
                label="Canary Analysis Duration(in minutes)"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error}
            />

            {/* <Input
                type="text"
                name="canary_template"
                mandatorySign
                label="Canary Overall Pass Percentage"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error}
            />
            <Input
                type="text"
                name="canary_template"
                mandatorySign
                label="Per Scan Fail Percentage"
                onChangeHandler={commonFunctions.onChangeHandler}
                data={state.data}
                error={state.error}
                extraDiv={<><Tooltip TransitionComponent={Zoom} title="f per scan fail percentage is zero then the canary will not fail, irrespective of the scan score." placement="right">
                    <span className='ri-information-line font-18 cursor-pointer'></span>
                </Tooltip></>}
            /> */}
            {/* <MultipleEnteriesInput
                multi_enteries_swtich_title={"Add Queries?"} //title ? title :
                input_list={input_list}
                card_label="Query Dynamic Params"
                columnNames={columnNames}
                apiFetchedData={state.table_list}
                custom_save_fn={
                    state.update_state_fn ?
                        () => { updateStateObj(state.single_form_data) } :
                        saveDataToTable
                }
                inherits={state.child_inherits.multiple_enteries_child}
                prev_state={state.multiple_enteries_child}
                clearAndSaveForm={clearAndSaveForm}
                setDatatoEdit={setDatatoEdit}
                clearAndCloseFrom={clearAndCloseForm}
                custom_save_fn_flag={true}
                no_data_added_msg="Please provide query dynamic params"
                no_data_added_button="Add Query"
            // handleDeleteRow = {handleDeleteRow}
            /> */}
        </>
    )
}

export default CanaryAnalysis

const useStylesDialog = makeStyles(() => ({

    borderRadius: {
        borderRadius: '5px'
    },
    searchBar: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 60px',
        border: '1px solid #b7b7b7',
        marginBottom: '15px',
        borderRadius: '4px',
        '& .input-component': {
            marginBottom: '0!important',
            borderRight: '1px solid #b7b7b7',
            '& input': {
                border: 'none',
                '&:focus': {
                    outline: 'none',
                    border: '0px!important'
                },
                '&:disabled': {
                    cursor: 'not-allowed'
                }
            }
        },
        '& .btn': {
            marginRight: 0,
            padding: '.3rem 1rem'
        }
    },
    card_shadow: {
        boxShadow: ' 0 3px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        color: '#6e6e6e',
        borderRadius: '25px',
        fontSize: '13px',
        width: '450px',
        marginTop: '25px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        border: 'none',
        "& .card-header": {
            width: '100%',
            borderRadius: '25px',
            border: 'none!important',
        },
    },
    conditions_div_header: {
        backgroundColor: "#fafafa",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '50px',
        borderBottom: '1px solid #dedede',
        padding: '10px',
        '& .heading': {
            lineHeight: '1.5',
            fontSize: '13px',
            color: '#000'
        },
    },

    condition_block: {
        display: 'flex', alignItems: 'center', borderBottom: "1px solid #a09e9e", borderTop: '1px solid #a09e9e', padding: "8px", backgroundColor: '#f4f4f4', justifyContent: 'space-between',
    }
}));