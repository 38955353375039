import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import BorderColorIcon from '@material-ui/icons/BorderColor';

import { Link } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';
import GenerateURL from '../../../../../../util/APIUrlProvider';
import properties from '../../../../../../properties/properties';
import { GetAuth } from '../../../../../../util/security';
import EditDetailsConfirmationDialog from './EditDetailsConfirmationDialog';
const TolerationsAndPriorityClassOverview = (props) => {
    const prev_state = props.prev_state;
    const cd_id = prev_state && prev_state.id ? prev_state.id : null
    const [showLoading, setShowLoading] = useState(false);

    const [state, setState] = useState({
        env_cd_tolerations: [],
        priority_class: ''
    });
    const extraProps = props.extraProps;
    const application_id = extraProps.application_id;
    const auto_approval_flag = extraProps.auto_approval_flag;
    const service_id = extraProps.service_id;
    const component_env_id = extraProps.component_env_id;
    var url = GenerateURL({ service_id: service_id, component_env_id: component_env_id }, properties.api.save_ci, true);
    const is_permitted = GetAuth().permission.POST.includes(url);
    useEffect(() => {
        setState(new_state => ({
            ...new_state, ...prev_state
        })
        )
    }, [prev_state])



    return (
        <div className="section-service-overview" style={{ border: '0px', padding: '10px' }}>
            <div className="d-flex align-center space-between">
                <div className="overview-heading">
                    Tolerations And Priority Class
                </div>
                {is_permitted ?

                    state.status == "DRAFT" ?
                        <Link to={{
                            pathname: "/application/" + application_id + "/service/" + service_id + "/env/" + component_env_id + "/cd/" + cd_id + "/edit",
                            query: { selectedTabOrder: 7, selected_cd_id: extraProps?.selected_cd_data?.id }
                        }}>
                            <BorderColorIcon style={{ color: '#0086ff' }} />
                        </Link> :
                        <EditDetailsConfirmationDialog
                            application_id={application_id}
                            service_id={service_id}
                            auto_approval_flag={auto_approval_flag}
                            component_env_id={component_env_id}
                            selectedTabOrder={7}
                            cd_data={prev_state}
                            selected_cd_id={extraProps?.selected_cd_data?.id}
                        />

                    : <Tooltip title="You are not allowed to perform this action" >
                        <BorderColorIcon style={{ color: '#818078' }} />
                    </Tooltip>}

            </div>
            <div className="overview-card-section grid-template-3" style={{ gridTemplateColumns: '2fr 1fr' }}>
                <div className="overview-single-card">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Tolerations
                        </p>
                        

                    </div>
                    <div className="overview-card-value-env-var">
                        <div className="heading-key-value" style={{ gridTemplateColumns: '22% 23% 23% 22%' }}>
                            <p>Key</p>
                            <p>Operator</p>
                            <p>Value</p>
                            <p>Effect</p>
                            <p>Seconds</p>
                        </div>
                        <div className="env-variable-box">

                            {state.env_cd_tolerations[0] ?
                                <>
                                    {state.env_cd_tolerations.map(item => (
                                        <div className="data-key-value-pair" style={{ gridTemplateColumns: '22% 23% 23% 22%' }}>
                                            <p>{item.key}</p>
                                            <p>{item.operator}</p>
                                            <p >

                                                {item.value}

                                            </p>
                                            <p>{item.effect}</p>
                                            <p>{item.toleration_seconds}</p>
                                        </div>
                                    ))}
                                </> : <p className="no-data__msg-env-var" >No Data Available</p>}
                        </div>
                    </div>
                </div>
                <div className="overview-single-card">
                    <div className="overview-card-heading-section">
                        <p className="overview-card-heading">
                            Priority Class ?
                        </p>
                        <span className={state.env_cd_liveness_probe ? "overview-chip-yes" : "overview-chip-no"} >
                            {state.priority_class ? "Yes" : "No"}
                        </span>
                    </div>
                    <div className="overview-card-value">

                        <p className="overview-card-value-text">
                            Priority Class : {state.priority_class ? state.priority_class : <span className="no-data__msg ">NA</span>}
                        </p>

                    </div>
                </div>
            </div>
        </div>

    )
}

TolerationsAndPriorityClassOverview.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
  }

export default TolerationsAndPriorityClassOverview;