import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import CloseIcon from '@material-ui/icons/Close';
import { Tooltip } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

function getStatusClass(tabStatus) {
    switch (tabStatus) {
        case 'green':
            return 'bullet green';
        case 'red':
            return 'bullet red';
        default:
            return null;
    }
}

const CommonHorizontalTab = (props) => {
    const tabList = props.tabList;
    const showStatusBullet = props.showStatusBullet;
    const disableClick = props.disableClick;
    const displayBullet = !showStatusBullet ? "none" : null;
    const selectedTabOrder = props.selectedTabOrder;
    const updateSelectedTabFunction = props.updateSelectedTab;
    const onRemoveHandler = props.onRemove
    const variant = props.variant ? props.variant : null;
    const inRollback = props.inRollback;
    const leastWidth = props.leastWidth ? props.leastWidth : 600;
    const ref = useRef();
    const [showButtons, setShowButtons] = useState(false);

    useEffect(() => {
        if (ref.current) {
            const divWidth = ref.current.clientWidth;
            console.log(leastWidth, "divWidthdivWidth")
            if (divWidth >= leastWidth) {
                setShowButtons(true);
            } else {
                setShowButtons(false);
            }
        }
    }, [tabList]);


    function onClick(order, data) {

        if (!disableClick) {
            updateSelectedTabFunction(order, data);
        }
    }


    const onRemove = (e, order) => {

        e.stopPropagation()
        onRemoveHandler(order)

    }


    const [displayBtnLeft, setDisplayBtnLeft] = useState(false);
    const [displayBtnRight, setDisplayBtnRight] = useState(false);

    const hasHorizontalScroll = (element) => {
        console.log(element.scrollLeft, "sfdsdfsdfsdfsfsfsf")
        if (element.scrollLeft === 0 && displayBtnLeft) {
            setDisplayBtnLeft(false)
        } else {
            setDisplayBtnLeft(true)
        }

        if (Math.abs(element.scrollWidth - (element.scrollLeft + element.clientWidth)) <= 5 && displayBtnRight) {
            setDisplayBtnRight(false);
        } else {
            setDisplayBtnRight(true)
        }


    };


    const handleScrollBackward = () => {
        let slider = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft - 200;
        setDisplayBtnRight(true)
        hasHorizontalScroll(slider)
    }
    const handleScrollForward = () => {
        let slider = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft + 200;
        setDisplayBtnLeft(true);
        hasHorizontalScroll(slider)
    }


    const handleChange = (e) => {
        console.log(e.target)
        let slider = document.getElementById("slider");
        hasHorizontalScroll(slider)
    }

    return (
        <>
            {
                variant == "button" ?
                    <div className="variant-button">
                        <ul className="headerul">
                            {tabList.map(tabData => (
                                <li className={tabData.order == selectedTabOrder ? "active" : ""}>
                                    <button
                                        className="tab-button"
                                        onClick={() => onClick(tabData.order)}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            padding: 0,
                                            color: 'inherit',
                                            textAlign: 'left',
                                            width: '100%',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <span className={getStatusClass(tabData.tabStatus)} style={{ display: displayBullet }}></span>
                                        {tabData.tabName}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    :
                    variant == "env_tab" ?
                        <ul className="headerul env-tabs">
                            {tabList.map(tabData => (
                                <li onKeyDown={() => { }} className={tabData.order == selectedTabOrder ? "active bg-white" : null} onClick={() => onClick(tabData.order)} >
                                    <span className={tabData.tabName == "DEV" ? "badge badge-dev" : tabData.tabName == "QA" ? "badge badge-qa" : tabData.tabName == "UAT" ? "badge badge-uat" : tabData.tabName == "PROD" ? "badge badge-prod" : tabData.tabName == "STAGING" ? "badge badge-staging" : "Y6"}>{tabData.tabName}</span>
                                    <span className="font-11" style={{ color: '#000' }}>&nbsp;&nbsp;({tabData.total_sub_env})&nbsp;&nbsp;</span><span className="text-grey-6e font-11">Environments</span>


                                </li>
                            ))}
                        </ul>
                        :
                        variant == "vertical_tab" ?
                            <div className="vertical-tabs">
                                <ul className="headerul">
                                    {tabList.map(tabData => (
                                        <>
                                            {
                                                inRollback && tabData.tabName.length > 15 ?
                                                    <Tooltip title={tabData.tabName}>
                                                        <li onKeyDown={() => { }} className={tabData.order == selectedTabOrder ? "active" : null} onClick={() => onClick(tabData.order)}>
                                                            <span className={getStatusClass(tabData.tabStatus)} style={{ display: displayBullet }}></span>
                                                            {tabData.tabName.substring(0, 15) + "..."}
                                                        </li>
                                                    </Tooltip>
                                                    :
                                                    <li onKeyDown={() => { }} className={tabData.order == selectedTabOrder ? "active" : null} onClick={() => onClick(tabData.order)}>
                                                        <span className={getStatusClass(tabData.tabStatus)} style={{ display: displayBullet }}></span>
                                                        {tabData.tabName}
                                                    </li>
                                            }
                                        </>


                                    ))}
                                </ul>
                            </div>
                            :
                            variant == "veritcal_tab_with_active_bar_without_check" ?
                                <div className="vertical-tabs active_bar">
                                    <ul className="headerul">
                                        {tabList.map(tabData => (
                                            <div className={tabData.order == selectedTabOrder ? "active-bar" : "not-active-bar"}>
                                                <li onKeyDown={() => { }} className={tabData.order == selectedTabOrder ? "active" : null} onClick={() => onClick(tabData.order, tabData.tabData ? tabData.tabData : null)}>
                                                    <Tooltip title={tabData.tabName}>
                                                        <div className="d-block  text-ellipsis">

                                                            {/* <span className={getStatusClass(tabData.tabStatus)} style={{ display: displayBullet }}></span> */}
                                                            {tabData.tabName}
                                                        </div>
                                                    </Tooltip>
                                                </li>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                                :
                                variant === "application_dashboard" ?
                                    <ul className="headerul appheaderul dashboard-tabs handle-overlap-1280" > {/*appheaderul*/}
                                        {tabList.map(tabData => (
                                            <li

                                                onKeyDown={() => { }}
                                                className={
                                                    tabData.order == selectedTabOrder ?
                                                        "expensionPanelTabApplication active" :
                                                        "expensionPanelTabApplication"
                                                }

                                                onClick={() => onClick(tabData.order)}
                                            >
                                                <span className={""} style={{ textTransform: "capitalize" }}>{tabData.tabName}</span>
                                                <span className="font-11" >&nbsp;&nbsp;({tabData.total_count})&nbsp;&nbsp;</span>
                                            </li>
                                        ))}
                                    </ul>
                                    :
                                    variant === "capsule" ?
                                        <ul className="headerul capsuleTab">
                                            {tabList.map(tabData => (
                                                <li onKeyDown={() => { }} className={tabData.order == selectedTabOrder ? "active" : null} onClick={() => onClick(tabData.order)}>
                                                    <span className={getStatusClass(tabData.tabStatus)} style={{ display: displayBullet }}></span>
                                                    {tabData.tabName}
                                                </li>
                                            ))}
                                        </ul>

                                        :

                                        variant === 'removable-tabs' ?

                                            <ul className="headerul">
                                                {tabList.map(tabData => (
                                                    <li onKeyDown={() => { }} style={{ display: 'flex', alignItems: 'center', lineHeight: 'inherit' }} className={tabData.order == selectedTabOrder ? "active removeable-tab-parent" : 'removeable-tab-parent'} onClick={() => onClick(tabData.order)}>
                                                        <span className={getStatusClass(tabData.tabStatus)} style={{ display: displayBullet }}></span>
                                                        {tabData.tabName}
                                                        <button style={{ fontSize: '0px' }} className="ml-15 btn-transparent tab-cross-button" onClick={(e) => onRemove(e, tabData.order)}><CloseIcon /></button>
                                                    </li>
                                                ))}
                                            </ul>
                                            :

                                            variant === 'color-button' ?
                                                <div style={{ border: '1px solid #b7b7b7', borderRight: 'none' }}>
                                                    <ul className="headerul">
                                                        {tabList.map(tabData => (
                                                            <button onClick={() => onClick(tabData.order)} className={tabData.order == selectedTabOrder ? 'color-tab-active' : 'color-tab-inactive'} style={{ width: '47px', height: '37px' }}>
                                                                <label>{tabData.tabName}</label>
                                                            </button>
                                                        ))}
                                                    </ul>
                                                </div>
                                                :
                                                variant === 'system-settings' ?

                                                    <div className="vertical-tabs-settings active_bar">
                                                        <ul className="headerul">
                                                            {tabList.map(tabData => (
                                                                <div className={tabData.order == selectedTabOrder ? "active-bar" : "not-active-bar"}>
                                                                    <li onKeyDown={() => { }} className={tabData.order == selectedTabOrder ? "active" : null} onClick={() => onClick(tabData.order)}>
                                                                        <div className="d-flex align-center ">
                                                                            <span className={getStatusClass(tabData.tabStatus)} style={{ display: displayBullet }}></span>
                                                                            {tabData.tabName}
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    :
                                                    variant === 'round-corner-tab' ?

                                                        <div className="round-corner-container">
                                                            {tabList.map(tabData => (
                                                                <button onClick={() => onClick(tabData.order)} className={tabData.order == selectedTabOrder ? 'rounded-corner-tab-active' : 'rounded-corner-tab'}>
                                                                    <label style={{ fontSize: '14px' }}>{tabData.tabName}</label>
                                                                </button>
                                                            ))}
                                                        </div>
                                                        :
                                                        variant === 'round-corner-tab-build-insight' ?

                                                            <div className="round-corner-container">
                                                                {tabList.map(tabData => (
                                                                    <button onClick={() => onClick(tabData)} className={tabData.order == selectedTabOrder ? 'rounded-corner-tab-active' : 'rounded-corner-tab'}>
                                                                        <label style={{ fontSize: '14px' }}>{tabData.tabName}</label>
                                                                    </button>
                                                                ))}
                                                            </div>
                                                            :
                                                            variant === 'circle-selector-tab' ?

                                                                <div className="circle-tab-container">
                                                                    {tabList.map(tabData => (
                                                                        <button onClick={() => onClick(tabData.order)} className={tabData.order == selectedTabOrder ? 'circle-tab-selector active' : 'circle-tab-selector'}>
                                                                            <label style={{ fontSize: '14px' }}>{tabData.tabName}</label>
                                                                        </button>
                                                                    ))}
                                                                </div>
                                                                :
                                                                variant === 'round-corner-tab-seperated' ?

                                                                    <div className="round-corner-seperated-container">
                                                                        {tabList.map(tabData => (
                                                                            <button onClick={() => onClick(tabData.order)} className={tabData.order == selectedTabOrder ? 'rounded-corner-tab-seperated-active' : 'rounded-corner-tab-seperated'}>
                                                                                <label style={{ fontSize: '14px' }}>{tabData.tabName}</label>
                                                                            </button>
                                                                        ))}
                                                                    </div>
                                                                    :
                                                                    variant === 'round-corner-tab-bulky' ?
                                                                        <div className="round-corner-seperated-container">
                                                                            {tabList.map(tabData => (
                                                                                <button onClick={() => onClick(tabData.order)} className={tabData.order == selectedTabOrder ? 'rounded-corner-tab-bulky-active' : 'rounded-corner-tab-bulky'}>
                                                                                    <label style={{ fontSize: '12px', cursor: "pointer" }}>{tabData.tabName}</label>
                                                                                </button>
                                                                            ))}
                                                                        </div>
                                                                        :
                                                                        variant === 'home-dashboard-url-data-tab' ?

                                                                            <div className="home-dashboard-url-data-tab-container">
                                                                                {tabList.map(tabData => (
                                                                                    <button onClick={() => onClick(tabData.order)} className={tabData.order == selectedTabOrder ? 'home-dashboard-url-data-tab-active' : 'home-dashboard-url-data-tab'}>
                                                                                        <label style={{ fontSize: '14px' }}>{tabData.tabName}</label>
                                                                                    </button>
                                                                                ))}
                                                                            </div>
                                                                            :
                                                                            variant === 'build-deployment-dashboard-tab' ?

                                                                                <div className="build-deployment-dashboard-tab-container">
                                                                                    {tabList.map(tabData => (
                                                                                        tabData.tabName == "Live" ?
                                                                                            <button onClick={() => onClick(tabData.order)} className={tabData.order == selectedTabOrder ? 'build-deployment-dashboard-tab-active' : 'build-deployment-dashboard-tab'} >
                                                                                                <FiberManualRecordIcon style={{ color: "#9BE1A9", marginRight: "1px" }} /><label style={{ fontSize: '14px' }}>{tabData.tabName}</label>
                                                                                            </button>
                                                                                            :
                                                                                            <button onClick={() => onClick(tabData.order)} className={tabData.order == selectedTabOrder ? 'build-deployment-dashboard-tab-active' : 'build-deployment-dashboard-tab'}>
                                                                                                <label style={{ fontSize: '14px' }}>{tabData.tabName}</label>
                                                                                            </button>
                                                                                    ))}
                                                                                </div>
                                                                                :
                                                                                variant === "event-listener-details-tab" ?
                                                                                    <ul className="headerul dashboard-tabs">

                                                                                        {tabList.map(tabData => (
                                                                                            <li
                                                                                                className={
                                                                                                    tabData.order == selectedTabOrder ?
                                                                                                        "eventListenerDetailsTab active" :
                                                                                                        "eventListenerDetailsTab"
                                                                                                }
                                                                                                onClick={() => onClick(tabData.order)}
                                                                                            >
                                                                                                <span className={""} style={{ textTransform: "capitalize" }}>{tabData.tabName}</span>
                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                    :
                                                                                    variant === 'dp-color-button' ?
                                                                                        <div style={{ border: '1px solid #EFEFEF', borderRight: 'none', width: 'fit-content', height: 'fit-content', borderRadius: '5px', boxShadow: '0px 0px 0px 3px rgba(0, 0, 0, 0.05)' }}>
                                                                                            <ul className="headerul">
                                                                                                {tabList.map((tabData) => (
                                                                                                    <button onClick={() => onClick(tabData.order)} className={tabData.order == selectedTabOrder ? 'color-tab-active-dp' : 'color-tab-inactive-dp'} style={{ width: '180px', height: '48px', borderRight: 'none', borderBottomLeftRadius: '5px', borderTopLeftRadius: '5px' }}>
                                                                                                        <label style={{ 'fontSize': '12px' }}>{tabData.tabName}</label>
                                                                                                    </button>
                                                                                                ))}
                                                                                            </ul>
                                                                                        </div>
                                                                                        :
                                                                                        variant === "rollback-dashboard" ?
                                                                                            <ul className="headerul dashboard-tabs">
                                                                                                {tabList.map(tabData => (
                                                                                                    <li

                                                                                                        onKeyDown={() => { }}
                                                                                                        className={
                                                                                                            tabData.order == selectedTabOrder ?
                                                                                                                "rollback-data-tab active" :
                                                                                                                "rollback-data-tab"
                                                                                                        }
                                                                                                        onClick={() => onClick(tabData.order)}
                                                                                                    >
                                                                                                        <span className={""} style={{ textTransform: "capitalize" }}>{tabData.tabName}</span>
                                                                                                    </li>
                                                                                                ))}
                                                                                            </ul>
                                                                                            :
                                                                                            variant === "canary_analysis_report_tab" ?
                                                                                                <>
                                                                                                    {
                                                                                                        tabList.map((item) =>
                                                                                                        (
                                                                                                            <div className='vertical-canary-tabs' style={selectedTabOrder == item.order ? { backgroundColor: "#DFEDFF", cursor:"pointer" } : {cursor:"pointer"}} onClick={() => onClick(item.order)}>
                                                                                                                <div className='metric-name'>{item.tabName}</div>
                                                                                                                {
                                                                                                                    item.status == "FAILED" ?
                                                                                                                        <div className='metric-status-failed'>{item.status}</div>
                                                                                                                        :
                                                                                                                        <div className='metric-status-pass'>{item.status}</div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        )
                                                                                                        )

                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                variant === "scrollable_div" ?
                                                                                                    <>
                                                                                                        <div className='horizontal_scroll_div' style={{ position: "relative" }}>
                                                                                                            {showButtons && <button style={{ visibility: displayBtnLeft ? "visible" : "hidden", transform: "rotate(180deg)", position: "absolute", }}
                                                                                                                onClick={handleScrollBackward}
                                                                                                            > <ArrowForwardIosIcon /></button>}
                                                                                                            <div className='tabs_only container-of-items' ref={ref} id='slider' onScroll={handleChange}>
                                                                                                                <ul className="headerul " ref={ref}>
                                                                                                                    {tabList.map((tabData, index) => (
                                                                                                                        <li className={tabData.order == selectedTabOrder ? "active" : null} style={{ width: 'fit-content', textAlign: 'center', margin: index === 0 ? "5px" : "" }} onClick={() => onClick(tabData.order)}>
                                                                                                                            <span className={getStatusClass(tabData.tabStatus)} style={{ display: displayBullet }}></span>
                                                                                                                            {tabData.tabName}
                                                                                                                        </li>
                                                                                                                    ))}
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                            {showButtons && <button style={{ visibility: displayBtnRight ? "visible" : "hidden", position: "absolute", right: '0' }}
                                                                                                                onClick={handleScrollForward}
                                                                                                            ><ArrowForwardIosIcon /> </button>}
                                                                                                        </div>
                                                                                                    </> :
                                                                                                    variant === "policy_template" ?
                                                                                                        <ul className="headerul ">

                                                                                                            {tabList.map(tabData => (
                                                                                                                <li

                                                                                                                    onKeyDown={() => { }}
                                                                                                                    className={
                                                                                                                        tabData.order == selectedTabOrder ?
                                                                                                                            "icon_checkbox active" :
                                                                                                                            "icon_checkbox"
                                                                                                                    }

                                                                                                                    onClick={() => onClick(tabData.order)}
                                                                                                                >
                                                                                                                    <div className="icon_avatar">
                                                                                                                        {tabData.div}
                                                                                                                    </div>
                                                                                                                    <div className="icon_checkbox_footer">
                                                                                                                        {tabData.tabName}
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            ))}
                                                                                                        </ul> :

                                                                                                        variant === "v3-advance-tabs" ?
                                                                                                            <div className='v3-advance-tabs'>
                                                                                                                <div className='ad-left-arrow'>left</div>
                                                                                                                {
                                                                                                                    tabList.map((tabData, index) => {
                                                                                                                        return (
                                                                                                                            <div onClick={() => onClick(tabData.order)} className={selectedTabOrder != index ? 'tabs' : 'active-tabs'}>
                                                                                                                                {tabData.tabName}
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                                <div className='ad-right-arrow'>right</div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            variant === "btn-tab" ?
                                                                                                                <ul className="headerul appheaderul">
                                                                                                                    {tabList.map(tabData => (
                                                                                                                        <>
                                                                                                                        <button className={tabData.order == selectedTabOrder ? 'btn btn-primary btn-sm' : "btn btn-outline-primary btn-sm"} onClick={() => onClick(tabData.order)}>
                                                                                                                        {tabData.tabName}
                                                                                                                        </button>
                                                                                                                        
                                                                                                                        </>
                                                                                                                    ))}
                                                                                                                </ul>
                                                                                            
                                                                                                            :
                                                                                                            variant === "v3-versioning-tabs" ?
                                                                                                                <div className='version-list' style={{ padding: '10px' }}>
                                                                                                                    {
                                                                                                                        tabList.map((tabData, index) => {
                                                                                                                            return (
                                                                                                                                <div className='version-card' style={tabData.order == selectedTabOrder ? { padding: '10px', backgroundColor: '#EBF5FF', borderRadius: 6, cursor: 'pointer' } : { padding: '10px', cursor: 'pointer' }} onClick={() => onClick(tabData.order, tabData.active)}>
                                                                                                                                    <div className='d-flex align-center space-between'>
                                                                                                                                        <span style={tabData.order == selectedTabOrder ? { border: '1px solid #DFEDFF', padding: '6px 10px', backgroundColor: '#0086FF', fontSize: '14px', borderRadius: 4, color: '#fff' } : { border: '1px solid #DFEDFF', padding: '6px 10px', color: '#0086FF', fontSize: '14px', borderRadius: 4 }}>{tabData.tabName}</span>
                                                                                                                                        <span className='font-12' style={{ color: '#505050' }}>{tabData.active ? "Active" : "Inactive"}</span>
                                                                                                                                    </div>
                                                                                                                                    <span className='font-12' style={{ color: '#505050' }}>Created on {tabData.created_at}</span>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>
                                                                                                                :
                                                                                                                <ul className="headerul appheaderul">
                                                                                                                    {tabList.map(tabData => (
                                                                                                                        <li className={tabData.order == selectedTabOrder ? "active" : null} style={{ width: '100%', textAlign: 'center' }} onClick={() => onClick(tabData.order)}>
                                                                                                                            <span className={getStatusClass(tabData.tabStatus)} style={{ display: displayBullet }}></span>
                                                                                                                            {tabData.tabName}
                                                                                                                        </li>
                                                                                                                    ))}
                                                                                                                </ul>
            }
        </>
    )
}

CommonHorizontalTab.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}

export default CommonHorizontalTab