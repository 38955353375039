import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Grid } from '@material-ui/core';
import { getCommonFunctions } from '../../../serviceRevamp/add/ci_flow/SourceDetails';
import { TrainRounded } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import MultiRow, { getMultiRowDefaultState } from '../../../../components/genericComponents/MultiRow';
import { Input } from '../../../../components/genericComponents/Input';
import AddIcon from '@material-ui/icons/Add';
import { ValidateDataSet, VALIDATION_TYPE_REQUIRED } from '../../../../util/Validator';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import MultipleEnteriesInput, { getMultipleEnteries } from '../../../../components/genericComponents/MultipleEnteries/MultipleEnteriesInput';
const ConditionsDialog = props => {
    const classes = useStyles();
    const inherits = props.inherits ? props.inherits : {};
    const selected_conditions = props.selected_conditions;

    const add_conditions_function = props.add_conditions_function
    const get_run_conditions_function = props.get_run_conditions_function;
    const conditionsList = [...get_run_conditions_function().conditions, {label:'UAT Required', id:'uat_required'}]

    const variant = props.variant;
    const title = props.title ? props.title : null;
    const table_list = props.table_list ? props.table_list : [];
    const [state, setState] = useState({
        open: false,
        fullWidth: true,
        maxWidth: 'md',
        data: {
            open: true
        },
        error: {},
        conditions_list: {},
        validations: {
        },
        multiple_enteries_child: null,
        child_inherits: {
            multiple_enteries_child: {
                "validateForm": () => { return true },
                "getState": () => { return {} },
                "getData": () => { return {} }
            }
        }
    })
    const commonFunctions = getCommonFunctions(state, setState, inherits);
    useEffect(() => {
        console.log(selected_conditions,state,"selected_conditions___")
        if (selected_conditions != {} && selected_conditions != null) {
            if (Object.keys(selected_conditions).length > 0) {
                const result = Object.entries(selected_conditions).map(([condition_key, condition_value]) => ({
                    condition_key,
                    condition_value
                }));
                setState(new_state => ({
                    ...new_state,
                    multiple_enteries_child: {
                        ...getMultipleEnteries(),
                        data: {
                            condition_key: "",
                            condition_value: false,
                            open: true
                        },
                        open_div_form: false,
                        open_grid_form: false,
                        error: {

                        },
                        input_enteries_list: [
                            ...result,
                        ]
                    }
                }))
            }
        } else {
            
            setState(new_state => ({
                ...new_state
            }))
        }

    }, [selected_conditions])
    function removeDuplicates(entries) {
        const uniqueEntries = [];
        const idsSeen = new Set();
        for (const entry of entries) {
            if (!idsSeen.has(entry.id)) {
                uniqueEntries.push(entry);
                idsSeen.add(entry.id);
            }
        }
        return uniqueEntries;
    }


    const input_list = [
        {
            type: "select",
            name: "condition_key",
            label: "Select Conditions",
            placeholder: "Select Conditions",
            list: removeDuplicates(conditionsList),
        },
        {
            type: "true-false-switch",
            name: "condition_value",
            label: "If value is?",
        }
    ]
    const columnNames = [
        {
            label: 'Condition',
            width: '50%',
            grey: false,
            name: 'condition_key'
        },
        {
            label: 'Value',
            width: '50%',
            grey: true,
            name: 'condition_value'
        }
    ]
    const saveDataToTable = () => {
        console.log("clkdskcska")
        var children_validation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var multiple_enteries_child_data = child_states.multiple_enteries_child;
        console.log(multiple_enteries_child_data, "inherits.validateForm", child_states)
        if (children_validation_result.valid) {
            var final_arr = multiple_enteries_child_data.input_enteries_list;
            if (final_arr.length > 0) {
                let found_duplicate = final_arr.find(item => item.condition_key == multiple_enteries_child_data.data.condition_key)
                if (!found_duplicate) {
                    final_arr.push({
                        condition_key: multiple_enteries_child_data.data.condition_key,
                        condition_value: multiple_enteries_child_data.data.condition_value ? "True" : "False"
                    })
                }
            } else {
                final_arr.push({
                    condition_key: multiple_enteries_child_data.data.condition_key,
                    condition_value: multiple_enteries_child_data.data.condition_value ? "True" : "False"
                })
            }
            add_conditions_function(final_arr);
            setState(new_state => ({
                ...new_state,
                multiple_enteries_child: {
                    ...getMultipleEnteries(),
                    data: {
                        condition_key: "",
                        condition_value: false,
                        open: true
                    },
                    open_div_form: false,
                    open_grid_form: false,
                    error: {

                    },
                    input_enteries_list: [
                        ...final_arr,
                    ]
                }
            }))

        } else {
            console.log(children_validation_result, "children_validation_result")
        }
    }
    const updateStateObj = (keyValue) => {

        var children_validation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var multiple_enteries_child_data = child_states.multiple_enteries_child;
        console.log(multiple_enteries_child_data, "inherits.validateForm")
        if (children_validation_result.valid) {
            var final_arr = multiple_enteries_child_data.input_enteries_list;
            let updatedList = final_arr.map(item => {
                if (item.condition_key == keyValue.condition_key) {
                    return {
                        ...item, condition_key: multiple_enteries_child_data.data.condition_key,
                        condition_value: multiple_enteries_child_data.data.condition_value ? "True" : "False"
                    };
                } else {
                    return item;
                }
            }
            )
            add_conditions_function(updatedList);
            setState(new_state => ({
                ...new_state,
                multiple_enteries_child: {
                    ...getMultipleEnteries(),
                    update_state_fn: false,
                    data: {
                        condition_key: "",
                        condition_value: false,
                        open: true
                    },
                    open_div_form: false,
                    open_grid_form: false,
                    error: {

                    },
                    input_enteries_list: [
                        ...updatedList,
                    ]
                }
            }))
        }
    }

    const handleDeleteRow = (data) => {
        var children_validation_result = inherits.validateForm ? inherits.validateForm() ? inherits.validateForm() : { valid: true } : { valid: true };
        var child_states = inherits.getState ? inherits.getState() : null;
        var multiple_enteries_child_data = child_states.multiple_enteries_child;
        console.log(multiple_enteries_child_data, "inherits.validateForm del", data, multiple_enteries_child_data.input_enteries_list)
        var total_arr = multiple_enteries_child_data.input_enteries_list;
        total_arr.forEach((item, key) => {
            console.log(item, "inherits.validateForm del",data)
            if (item.condition_key == data.condition_key) {
                
                total_arr.splice(key, 1)
            }
        })
        console.log(total_arr, "inherits.validateForm del")
        add_conditions_function(total_arr,true);
        setState(new_state => ({
            ...new_state,
            multiple_enteries_child: {
                ...getMultipleEnteries(),
                open_div_form: false,
                data: {
                    condition_key: "",
                    condition_value: false,
                    open: true
                },
                input_enteries_list:[
                    ...total_arr
                ],
                error: {

                },
            }
        }))
    }
    const clearAndSaveForm = () => {
        setState(new_state => ({
            ...new_state,
            update_state_fn: false,
            multiple_enteries_child: {
                ...getMultipleEnteries(),
                open_div_form: false,
                update_state_fn: false,
                data: {
                    condition_key: "",
                    condition_value: false
                },
                error: {

                },
            }
        }))
    }

    const clearAndCloseForm = () => {
        setState(new_state => ({
            ...new_state,
            update_state_fn: false,
            multiple_enteries_child: {
                ...getMultipleEnteries(),
                open_div_form: false,
                data: {
                    condition_key: "",
                    condition_value: false,
                    open: true
                },
                error: {

                },
            }
        }))
    }


    


    const setDatatoEdit = (type, data) => {
        console.log(data, "fjsdajfja")
        setState(prevState => ({
            ...prevState,
            single_form_data: {
                condition_key: data.condition_key,
                condition_value: data.condition_value == "False" ? false : true,
            },
            update_state_fn: true,
            multiple_enteries_child: {
                ...getMultipleEnteries(),
                ...prevState.multiple_enteries_child,
                open_div_form: true,
                update_state_fn: true,
                data: {
                    ...prevState.multiple_enteries_child.data,
                    condition_key: data.condition_key,
                    condition_value: data.condition_value == "False" ? false : true,
                    open: true
                }
            }
        }))
    }
    const updateSwitchState = (value) => {
        if(!value){
            add_conditions_function([]);
        }
       
    }
    return (
        <div className={classes.condition_css}>
            <MultipleEnteriesInput
                multi_enteries_swtich_title={"Do you want to add conditions to run this Job?"} //title ? title :
                input_list={input_list}
                card_label="Add Conditions"
                columnNames={columnNames}
                apiFetchedData={state.table_list}
                custom_save_fn={
                    state.update_state_fn ?
                        () => { updateStateObj(state.single_form_data) } :
                        saveDataToTable
                }
                inherits={state.child_inherits.multiple_enteries_child}
                prev_state={state.multiple_enteries_child}
                clearAndSaveForm={clearAndSaveForm}
                setDatatoEdit={setDatatoEdit}
                clearAndCloseFrom={clearAndCloseForm}
                custom_save_fn_flag={true}
                no_data_added_msg="Please provide conditions to run this stage or job"
                no_data_added_button="Add Conditions"
                handleDeleteRow={handleDeleteRow}
                updateSwitchState={updateSwitchState}
            />

        </div>
    )
}
export default ConditionsDialog;




const useStyles = makeStyles((theme) => ({
    condition_css: {
        '& .multiple-enteries-card': {
            '& .multiple-enteries-card-body': {
                display: 'grid',
                gridTemplateColumns: '1fr 200px',
                gap: 20
            }
        }
    }
}));
